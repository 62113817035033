import { PageHeader } from '@infinitusai/shared';
import { Table, useTable, Toolbar } from '@infinitusai/table';
import { useBreakpoint } from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Table as TableInterface, createColumnHelper } from '@tanstack/react-table';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import { useGetPayersWithDetails } from 'api/customer';

import { PayerRow, getPayerRows } from './utils';

function CustomToolbar({
  table,
  showBlues,
  showDivider,
  onShowBluesChange,
}: {
  table: TableInterface<PayerRow>;
  showBlues: boolean;
  showDivider: boolean;
  onShowBluesChange: (show: boolean) => void;
}) {
  return (
    <Toolbar table={table}>
      <React.Fragment>
        <FormControlLabel
          sx={{ m: 0 }}
          control={
            <Switch
              checked={showBlues}
              onChange={(_, checked) => {
                onShowBluesChange(checked);
              }}
            />
          }
          label="Show Blues"
        />
        {showDivider ? (
          <Divider orientation="horizontal" sx={{ borderWidth: '1px', m: '0 1rem' }} />
        ) : null}
      </React.Fragment>
    </Toolbar>
  );
}

function PayersPageBeta() {
  const smBreakpoint = useBreakpoint('sm');
  const { enqueueSnackbar } = useSnackbar();
  const getPayersWithDetails = useGetPayersWithDetails();
  const [showBlues, setShowBlues] = React.useState(false);

  const payerRows = React.useMemo(() => {
    return getPayerRows(showBlues, getPayersWithDetails.data);
  }, [getPayersWithDetails.data, showBlues]);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<PayerRow>();
    return [
      columnHelper.accessor('name', {
        header: 'Payor Name',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor('id', {
        header: 'Infinitus Payor ID',
        cell: (info) => (
          <Tooltip title="Copy Id">
            <Typography
              onClick={() => {
                navigator.clipboard.writeText(info.getValue());
                enqueueSnackbar(`Infinitus Payer Id copied to clipboard!`, {
                  variant: 'success',
                });
              }}
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            >
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor('supportedTaskTypes', {
        header: 'Payor supported for task types',
        cell: (info) => {
          const value = info.getValue();
          const isValueKnown = value.length === 0;
          return (
            <Typography sx={{ display: isValueKnown ? 'none' : '' }}>
              {info.getValue().join(', ')}
            </Typography>
          );
        },
        meta: {
          download: true,
          filterSelectOptions: [],
        },
      }),
      columnHelper.accessor('enabledAt', {
        header: 'Date the payor was enabled for this org',
        cell: (info) => {
          const value = info.getValue();
          const isValueKnown = value === 'Unknown';
          return (
            <Typography sx={{ display: isValueKnown ? 'none' : '' }}>{info.getValue()}</Typography>
          );
        },
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor('association', {
        header: 'Payor Association Group',
        cell: (info) => {
          const value = info.getValue();
          const isValueKnown = value === 'Unknown';
          return (
            <Typography sx={{ display: isValueKnown ? 'none' : '' }}>{info.getValue()}</Typography>
          );
        },
        meta: {
          download: true,
        },
      }),
    ];
  }, [enqueueSnackbar]);

  const table = useTable({
    id: 'payers',
    data: payerRows,
    columns,
    isLoading: getPayersWithDetails.isLoading,
    totalRows: payerRows.length,
    noRowsMessage: 'No payors found.',
    enableSharing: smBreakpoint,
    enableSorting: true,
    enableDownload: smBreakpoint,
    enableFilters: true,
    enableGlobalFilter: true,
    enableUrlSync: true,
    getRowId: (row) => row.id,
  });

  return (
    <React.Fragment>
      <PageHeader title="Supported Payors [BETA]" variant="h5" />
      <Box>
        <Table
          table={table}
          overrides={{
            toolbar: (
              <CustomToolbar
                table={table}
                showBlues={showBlues}
                showDivider={smBreakpoint}
                onShowBluesChange={setShowBlues}
              />
            ),
          }}
        />
      </Box>
    </React.Fragment>
  );
}

export default PayersPageBeta;
