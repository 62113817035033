import { useBreakpoint } from '@infinitusai/ui';
import { useTheme } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { darkTheme } from 'utils/rechartUtils';

interface TimeSavingsChartProps {
  timeSavingsData: { name: string; value: number }[];
}

const gradient = (
  <defs>
    <linearGradient id="lineGradient" x1="1" y1="0" x2="0" y2="0">
      <stop offset="0.534394" stopColor="#2F80ED" />
      <stop stopColor="#56CCF2" />
    </linearGradient>
  </defs>
);

function TimeSavingsChart({ timeSavingsData }: TimeSavingsChartProps) {
  const theme = useTheme();
  const lgBreakpoint = useBreakpoint('lg');
  const mdBreakpoint = useBreakpoint('md');
  const smBreakpoint = useBreakpoint('sm');
  const width = lgBreakpoint ? 750 : mdBreakpoint ? 700 : smBreakpoint ? 700 : 750;

  const xAxisInterval = Math.round(timeSavingsData.length / 5);

  return (
    <ResponsiveContainer width="100%" height={smBreakpoint ? width / 2 + 30 : width}>
      <LineChart
        width={width}
        height={smBreakpoint ? width / 2 + 30 : width}
        data={timeSavingsData}
        margin={{
          top: 10,
          bottom: 25,
          right: 100,
          left: 50,
        }}
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        {gradient}
        <CartesianGrid strokeDasharray="none" stroke="#eee" />
        <XAxis
          dataKey="name"
          label={{ value: 'Date', dy: 20, position: 'insideEnd' }}
          interval={xAxisInterval}
          hide={!smBreakpoint}
        />
        <YAxis
          dataKey="value"
          label={{ value: 'Time Saved (minutes)', angle: -90, position: 'insideEnd', dx: -40 }}
          overflow="visible"
        />
        <Tooltip
          wrapperStyle={theme.palette.mode === 'dark' ? darkTheme.tooltip.wrapperStyle : {}}
          contentStyle={theme.palette.mode === 'dark' ? darkTheme.tooltip.contentStyle : {}}
        />
        <Line
          type="monotone"
          dataKey="value"
          stroke="url(#lineGradient)"
          dot={false}
          strokeWidth={4}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
export default TimeSavingsChart;
