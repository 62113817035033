import { useAuth, PermissionName } from '@infinitusai/auth';
import { PageHeader } from '@infinitusai/shared';
import { Table, useTable } from '@infinitusai/table';
import { Button, useBreakpoint } from '@infinitusai/ui';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { useGetPayerIntelRulesQuery, useGetPayers } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

import RuleDetailsDialog from './RuleDetailsDialog';
import { createDataRowFromRules } from './helpers';
import { PayerIntelligenceDataRow } from './types';

const PayerIntelligencePage = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const ruleId = searchParams.get('show') || '';
  const navigate = useNavigate();
  const getRulesQuery = useGetPayerIntelRulesQuery();
  const getPayers = useGetPayers();
  const smBreakpoint = useBreakpoint('sm');

  // TODO: Add task type switcher & use selected task type
  const { data: postgresRules, isLoading: loadingRules } = getRulesQuery;
  const { data: payerDocs, isLoading: loadingPayers } = getPayers;

  const isLoading = loadingPayers || loadingRules;

  // states for details dialog

  const [selectedRule, setSelectedRule] = useState<PayerIntelligenceDataRow>();
  const importRules = () => {
    navigate(`/${sessionStorage.getItem('orgName')}/account/payer-intelligence/import`);
  };

  const memoizedRows = useMemo(() => {
    if (!postgresRules || !payerDocs) {
      return [];
    }
    const payerNameMap: { [payerID: string]: string } = {};
    payerDocs.forEach((p) => {
      // Make sure to lowercase payer ID when creating map
      if (p.id && p.name) payerNameMap[p.id.toLowerCase()] = p.name;
    });
    const rulesGroupedByPIEId = postgresRules?.reduce((acc: any, curr: infinitusai.tasks.Rule) => {
      if (curr.payerIntelId) {
        acc[curr.payerIntelId] = [...(acc[curr.payerIntelId] || []), curr];
      }
      return acc;
    }, {});

    return Object.keys(rulesGroupedByPIEId || {})
      .map((id) => rulesGroupedByPIEId[id])
      .map((r) => createDataRowFromRules(r, payerNameMap))
      .filter((d: PayerIntelligenceDataRow | undefined): d is PayerIntelligenceDataRow => !!d);
  }, [postgresRules, payerDocs]);

  return (
    <React.Fragment>
      <PageHeader title="Payer Intelligence Rules" variant="h5">
        <Box
          flexGrow={1}
          display={smBreakpoint ? 'flex' : ''}
          justifyContent={smBreakpoint ? 'flex-end' : ''}
          width="100%"
        >
          <Button
            unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_PIE_WRITE])}
            color="primary"
            variant="contained"
            size="large"
            fullWidth={!smBreakpoint}
            onClick={importRules}
            startIcon={<AddRoundedIcon />}
          >
            Import Rules
          </Button>
        </Box>
      </PageHeader>

      <Box sx={{ mt: 1, display: 'flex' }}>
        {
          <MemoizedPayerIntelligenceTable
            loading={isLoading}
            rows={memoizedRows}
            setSelectedRule={setSelectedRule}
          />
        }
      </Box>
      <RuleDetailsDialog data={selectedRule} dialogOpen={ruleId !== ''} />
    </React.Fragment>
  );
};

interface PayerIntelligenceTableProps {
  loading: boolean;
  rows: PayerIntelligenceDataRow[];
  setSelectedRule: any;
}

const PayerIntelligenceTable: React.FC<PayerIntelligenceTableProps> = ({
  loading,
  rows,
  setSelectedRule,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<PayerIntelligenceDataRow>();
    return [
      columnHelper.accessor('payerIntelligenceID', {
        header: 'Payer Intelligence ID',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.accessor(
        (originalRow) => originalRow.conditions.filter((e) => e.displayName === 'Payer')[0]?.value,
        {
          header: 'Payer',
          cell: (info) => (info.getValue() ? info.getValue() : '-'),
        }
      ),
      columnHelper.accessor(
        (originalRow) => originalRow.conditions.filter((e) => e.displayName === 'state')[0]?.value,
        { header: 'Provider State', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.conditions.filter((e) => e.displayName === 'Member Prefix')[0]?.value,
        { header: 'Member Prefix', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.conditions.filter((e) => e.displayName === 'Plan Type')[0]?.value,
        { header: 'Plan Type', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.conditions.filter((e) => e.displayName === 'Plan Name')[0]?.value,
        { header: 'Plan Name', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.effects.filter((e) => e.displayName === 'Prior Auth Required')[0]?.value,
        { header: 'Prior Auth Required', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.conditions.filter((e) => e.displayName === 'Policy Type')[0]?.value,
        { header: 'Policy Type', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.conditions.filter((e) => e.displayName === 'Group #')[0]?.value,
        { header: 'Group #', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.conditions.filter((e) => e.displayName === 'Diagnosis Code')[0]?.value,
        { header: 'Diagnosis Code', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.conditions.filter((e) => e.displayName === 'Product Code')[0]?.value,
        { header: 'Product Code', cell: (info) => (info.getValue() ? info.getValue() : '-') }
      ),
      columnHelper.accessor(
        (originalRow) =>
          originalRow.conditions.filter((e) => e.displayName === 'Specialty Pharmacy Name')[0]
            ?.value,
        {
          header: 'Specialty Pharmacy Name',
          cell: (info) => (info.getValue() ? info.getValue() : '-'),
        }
      ),
    ];
  }, []);

  const table = useTable({
    id: 'payer-intelligence',
    data: rows,
    columns,
    isLoading: loading,
    totalRows: rows.length,
    noRowsMessage: 'There are no uploads. Get started by creating a new one.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableHiding: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableUrlSync: true,
    onRowClick: (row) => {
      setSelectedRule(row.original);
      searchParams.set('show', row.original.payerIntelligenceID);
      setSearchParams(searchParams, { replace: true });
    },
  });

  return <Table table={table} />;
};

// For performance
const MemoizedPayerIntelligenceTable = React.memo(PayerIntelligenceTable);

export default React.memo(PayerIntelligencePage);
