import { useAuth, PermissionName } from '@infinitusai/auth';
import { PageHeader } from '@infinitusai/shared';
import { Table, useTable, DateField } from '@infinitusai/table';
import { Button, useBreakpoint } from '@infinitusai/ui';
import { possibleLongToNumber } from '@infinitusai/utils';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetOrgRoles } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

import RoleDrawer from './RoleDrawer';

function RolesPage() {
  const auth = useAuth();
  const getOrgRoles = useGetOrgRoles();
  const smBreakpoint = useBreakpoint('sm');
  const [showNewRoleDrawer, setShowNewRoleDrawer] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const showRoleId = searchParams.get('show') || '';
  const rbacEnabled = auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE]);

  const handleRoleDrawerClose = () => {
    searchParams.delete('show');
    setSearchParams(searchParams, { replace: true });
    setShowNewRoleDrawer(false);
  };

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.auth.Role>();
    return [
      columnHelper.accessor('name', {
        header: 'Role Name',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor('description', {
        header: 'Description',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor('userCount', {
        header: 'Assigned Members',
        cell: (info) => `${info.getValue()} member${info.getValue() === 1 ? '' : 's'}`,
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((role) => new Date(possibleLongToNumber(role.updateTimeMillis)), {
        id: 'updateTimeMillis',
        header: 'Last Modified',
        cell: (info) => <DateField date={info.getValue()} />,
        meta: {
          download: true,
          downloadValue: (row) =>
            Number(row.original.updateTimeMillis) <= 0
              ? ' '
              : format(
                  new Date(possibleLongToNumber(row.original.updateTimeMillis)),
                  'yyyy-MM-dd HH:mm:ss'
                ),
        },
      }),
      columnHelper.display({
        id: 'navigate',
        cell: () => (
          <Stack display="flex">
            <IconButton
              disableRipple
              sx={{
                display: 'flex',
                justifyContent: 'right',
                ':hover': { bgcolor: 'transparent' },
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, []);
  const table = useTable({
    id: 'roles',
    data: getOrgRoles.data,
    columns,
    isLoading: getOrgRoles.isLoading,
    totalRows: getOrgRoles.data.length,
    noRowsMessage: 'No roles found. Create your first role.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableDownload: smBreakpoint,
    enableGlobalFilter: true,
    enableUrlSync: true,
    getRowId: (row) => row.uuid,
    onRowClick: (row) => {
      searchParams.set('show', row.original.uuid);
      setSearchParams(searchParams, { replace: true });
    },
  });

  return (
    <React.Fragment>
      <PageHeader title="Roles" variant="h5">
        <Box
          flexGrow={1}
          display={smBreakpoint ? 'flex' : ''}
          justifyContent={smBreakpoint ? 'flex-end' : ''}
          width="100%"
        >
          <Button
            size="large"
            color="primary"
            fullWidth={!smBreakpoint}
            variant="contained"
            disabled={!rbacEnabled}
            unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
            onClick={() => setShowNewRoleDrawer(true)}
            startIcon={<AddRoundedIcon />}
          >
            Create New Role
          </Button>
        </Box>
      </PageHeader>
      <Box sx={{ mt: 1, display: 'flex' }}>
        <Table table={table} />
      </Box>
      <RoleDrawer
        role={
          showNewRoleDrawer
            ? null
            : getOrgRoles.data.find((role) => role.uuid === showRoleId) || null
        }
        open={showRoleId !== '' || showNewRoleDrawer}
        onClose={handleRoleDrawerClose}
        onUpdateOrCreate={(roleUuid) => table.options.meta?.highlightRow?.(roleUuid)}
      />
    </React.Fragment>
  );
}

export default RolesPage;
