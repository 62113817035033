import { PermissionName, useAuth } from '@infinitusai/auth';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useRemoveMember } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

type Props = {
  email?: string;
  name?: string;
  onClose: () => void;
  open: boolean;
  teamName?: string;
  teamUuid: string;
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '615px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  px: 4,
  py: 2,
};

function RemoveMemberModal({ email, name, teamName, teamUuid, open, onClose }: Props) {
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const removeMember = useRemoveMember();

  const handleRemoveMember = () => {
    const req = infinitusai.teams.DeleteMembershipRequest.fromObject({
      teamUuid,
      userEmail: email,
    });
    removeMember.mutate(req, {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(`Successfully removed member '${name}' from ${teamName}`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(`Failed to remove member '${name}' from ${teamName}`, {
          variant: 'error',
        });
      },
    });
    onClose();
  };

  return (
    <Modal aria-labelledby="remove-member-modal-title" onClose={onClose} open={open}>
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography component="h2" id="remove-member-modal-title" variant="h6">
            Remove member from team?
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography>
            Are you sure you want to remove this team member? The member will be removed from the{' '}
            {teamName} only can always be added back via the <strong>Add member</strong> button.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography>
            Member name: <strong>{name}</strong>
          </Typography>
          <Typography>
            Member email: <strong>{email}</strong>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button
            disabled={removeMember.isLoading}
            onClick={onClose}
            sx={{ borderRadius: '16px', mr: 1, minWidth: '150px' }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            color="error"
            disabled={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
            loading={removeMember.isLoading}
            onClick={handleRemoveMember}
            sx={{
              borderRadius: '16px',
              bgcolor: (theme) => theme.palette.warning.main,
              minWidth: '150px',
              textTransform: 'none',
            }}
            variant="contained"
          >
            Remove member
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default RemoveMemberModal;
