import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  AudioPlayer,
  useCustomerPortalFeatureEnabled,
  useCallRecordings,
  CustomerPortalFeature,
  Org,
} from '@infinitusai/shared';
import { Button } from '@infinitusai/ui';
import ExpandLessIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { get } from 'lodash';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  getCustomerFacingTaskStateCustomer,
  CustomerFacingTaskState,
  getCustomerFacingTaskState,
  getSortedTaskOutputsResults,
} from 'utils';

import { useGetTaskResults } from 'api/customer';
import { infinitusai, infinitusapi } from 'proto/pbjs';
import { getRuleTaskTypeDisplayName } from 'utils/displayNames';
import { getTaskTypeDisplayName } from 'utils/displayNames';

import OutputsPanel from './OutputsPanel';
import TaskInProgressPlaceholder from './TaskInProgressPlaceholder';
import {
  getTaskOutputsMap,
  OutputDataFieldMap,
  flexibleTaskOutputsDataFieldMap,
  allOutputsMap,
  flexibleTaskOutputs,
  getProviderNetworkStatusVal,
} from './constants';

interface Props {
  customerTask: infinitusai.be.CustomerTaskDoc;
  tasks: infinitusai.be.TaskDoc[] | null;
  org: Org | null;
  taskNotes?: string;
}
function TaskOutputsTab({ customerTask, tasks, org, taskNotes }: Props) {
  const { tid } = useParams();
  const { hasPermission } = useAuth();
  const hasDownloadRecordingPermission = hasPermission([
    PermissionName.CUSTOMER_TASK_RECORDINGS_DOWNLOAD,
  ]);
  const [expanded, setExpanded] = React.useState(true);
  const { loading, error, requested, recordings, fetch } = useCallRecordings(tid || '');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const billingNotesEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.BILLING_NOTES);
  const getTaskResults = useGetTaskResults([customerTask.taskUuid]);
  const [searchParams, setSearchParams] = useSearchParams();
  const show = searchParams.get('show') || '0';
  const tasksIndex = React.useCallback(
    (show: string) => {
      if (!getTaskResults?.data?.taskResults || !tasks) {
        return null;
      }
      let infTaskType = getTaskResults.data.taskResults[Number(show)]?.taskType;
      var taskType: infinitusai.be.TaskType;
      if (!infTaskType) {
        return null;
      }

      switch (
        infinitusai.tasks.INFTaskType[
          infTaskType as unknown as keyof typeof infinitusai.tasks.INFTaskType
        ]
      ) {
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION:
          taskType = infinitusai.be.TaskType.TASK_TYPE_FULL_BI;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER:
          taskType = infinitusai.be.TaskType.TASK_TYPE_PBM_BV;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER:
          taskType = infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS:
          taskType = infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP:
          taskType = infinitusai.be.TaskType.TASK_TYPE_CLAIMS_FOLLOWUP;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
          taskType = infinitusai.be.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION:
          taskType = infinitusai.be.TaskType.TASK_TYPE_DENTAL_BV;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_SHIPMENT_FOLLOWUP:
          taskType = infinitusai.be.TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK:
          taskType = infinitusai.be.TaskType.TASK_TYPE_PHARMACY_STOCK_CHECK;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH:
          taskType = infinitusai.be.TaskType.TASK_TYPE_PROVIDER_OUTREACH;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH:
          taskType = infinitusai.be.TaskType.TASK_TYPE_PATIENT_OUTREACH;
          break;
        case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BASIC_BV:
          taskType = infinitusai.be.TaskType.TASK_TYPE_BASIC_BV;
          break;
        default:
          taskType = infinitusai.be.TaskType.TASK_TYPE_UNKNOWN;
          break;
      }
      for (var i = 0; i < tasks.length; i++) {
        if (tasks[i].taskType === taskType) {
          return i;
        }
      }
      return null;
    },
    [getTaskResults.data?.taskResults, tasks]
  );
  const numResults = getTaskResults.data?.taskResults.length as number;
  const taskIndexShow = tasksIndex(show);
  const customerFacingStateCustomer = getCustomerFacingTaskStateCustomer(customerTask);
  const customerFacingState = (index: number | null) => {
    if (!tasks || index == null) {
      return CustomerFacingTaskState.UNKNOWN;
    }
    const taskDoc = infinitusai.be.TaskDoc.fromObject(tasks[index]);
    const taskState = getCustomerFacingTaskState(taskDoc || '');
    return taskState;
  };
  const data = getTaskResults.data?.taskResults[Number(show)];
  const output =
    data?.bvTaskOutput ||
    data?.pbmTaskOutput ||
    data?.pbmDiscoveryTaskOutput ||
    data?.prescriptionTransferTaskOutput ||
    data?.prescriptionSavingsTaskOutput ||
    data?.priorAuthTaskOutput ||
    flexibleTaskOutputs(data?.taskOutput);
  const taskOutputsMap = getTaskOutputsMap(
    data as infinitusapi.GetTaskResultsResponse.IDataMessage,
    billingNotesEnabled
  );

  const taskRecording = recordings?.[Number(show)] || null;

  const taskOutputsDataFieldMap: OutputDataFieldMap = (() => {
    if (data?.taskOutput) return flexibleTaskOutputsDataFieldMap(data.taskOutput);

    return allOutputsMap;
  })();

  const taskInProgress =
    infinitusapi.INFTaskStatus.INF_TASK_STATUS_SUCCESSFUL !==
    Number(infinitusapi.INFTaskStatus[data?.taskStatus as infinitusapi.INFTaskStatus]);

  if (getTaskResults.isLoading) {
    return (
      <Box
        sx={{
          minHeight: '100%',
          minWidth: '100%',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      {numResults > 1 ? (
        <ToggleButtonGroup
          exclusive
          aria-label="show"
          value={show}
          onChange={(_, newValue) => {
            if (newValue) {
              searchParams.set('show', String(newValue));
              setSearchParams(searchParams, { replace: true });
            }
          }}
          sx={{ mt: 2 }}
        >
          {getSortedTaskOutputsResults(customerTask, getTaskResults.data?.taskResults)?.map(
            (taskResult, index) => {
              const taskType = getTaskTypeDisplayName(
                taskResult.taskType || infinitusai.tasks.INFTaskType.INF_TASK_TYPE_UNKNOWN
              );

              const networkStatus =
                getProviderNetworkStatusVal(
                  get(taskResult, 'bvTaskOutput.planInfo.providerNetworkStatus')
                ) || '';
              return (
                <ToggleButton
                  value={String(index)}
                  key={`${taskType}-${networkStatus ? ` - ${networkStatus}` : ''}`}
                >
                  {taskType} {networkStatus ? ` - ${networkStatus}` : ''}
                </ToggleButton>
              );
            }
          )}
        </ToggleButtonGroup>
      ) : null}
      <Box
        sx={{
          minHeight: '100%',
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {taskInProgress ? (
          <TaskInProgressPlaceholder
            customerFacingTaskState={
              tasksIndex(show) !== null &&
              !(
                getCustomerFacingTaskStateCustomer(customerTask) !==
                  CustomerFacingTaskState.COMPLETED &&
                getCustomerFacingTaskStateCustomer(customerTask) !== CustomerFacingTaskState.PARTIAL
              )
                ? customerFacingState(tasksIndex(show))
                : customerFacingStateCustomer
            }
            taskNotes={taskNotes}
            task={tasks && taskIndexShow ? tasks[taskIndexShow] : customerTask}
          />
        ) : (
          <>
            <Grid container display="flex" justifyContent="space-between" minHeight="100%">
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                sx={{ flexGrow: 1, overflowY: 'scroll', width: '100%', height: '100%' }}
              >
                <OutputsPanel
                  task={tasks && taskIndexShow ? tasks[taskIndexShow] : null}
                  outputMap={taskOutputsMap}
                  output={output}
                  outputDataFieldMap={taskOutputsDataFieldMap}
                  org={org}
                />
              </Grid>
            </Grid>
            <Box
              position="sticky"
              p={2}
              bottom={0}
              m="-2rem"
              mt={1}
              bgcolor="background.default"
              borderTop={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <Fade mountOnEnter unmountOnExit in={true}>
                <Grid container direction="column">
                  {hasPermission([PermissionName.CUSTOMER_TASK_RECORDINGS_READ]) &&
                  (!requested || loading) ? (
                    <Grid>
                      <Button
                        size="medium"
                        color="primary"
                        variant="contained"
                        sx={{ justifyContent: 'center', alignContent: 'center' }}
                        onClick={() => fetch()}
                        disabled={requested || loading}
                        unauthorized={
                          !hasPermission([PermissionName.CUSTOMER_TASK_RECORDINGS_LISTEN])
                        }
                      >
                        Fetch Call Recording
                      </Button>
                    </Grid>
                  ) : !error && taskRecording ? (
                    <Box minWidth="100%" key={taskRecording.taskType}>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        onClick={handleExpandClick}
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                      >
                        <Typography variant="h6" fontSize="1rem" mb={1}>
                          {getRuleTaskTypeDisplayName(String(taskRecording.taskType))} Recording
                        </Typography>
                        <IconButton
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton>
                      </Grid>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {taskRecording.urls?.map((url: string) => (
                          <AudioPlayer
                            key={url}
                            url={url}
                            downloadDisabled={!hasDownloadRecordingPermission}
                          />
                        ))}
                      </Collapse>
                    </Box>
                  ) : null}
                </Grid>
              </Fade>
            </Box>
          </>
        )}
      </Box>
    </React.Fragment>
  );
}
export default TaskOutputsTab;
