import { useAuth, PermissionName } from '@infinitusai/auth';
import { useBreakpoint } from '@infinitusai/ui';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import CountUp from 'react-countup';
import { useParams, useNavigate } from 'react-router-dom';

const MAX_TASKS_COUNT = 5000;

export interface Props {
  callsInQueueCount?: number;
  callsInProgressCount?: number;
  callsCompletedTodayCount?: number;
  className?: string;
}
const BORDER_RADIUS = 10;
const STAGE_HEIGHT = 150;

const CLICK_TRANSFORM = 'translateY(1px)';

const PipelineDiagram: React.FC<Props> = ({
  callsInQueueCount,
  callsInProgressCount,
  callsCompletedTodayCount,
  className,
}) => {
  const { org } = useParams();
  const navigate = useNavigate();
  const mdBreakpoint = useBreakpoint('md');
  const { hasPermission } = useAuth();
  const hasTasksReadPermissions = hasPermission([PermissionName.CUSTOMER_TASKS_READ]);

  const cssRightArrow = {
    '&:before': {
      content: '" "',
      position: 'absolute',
      right: '-15px',
      height: 0,
      width: 0,
      borderTop: `${STAGE_HEIGHT / 2}px solid transparent`,
      borderBottom: `${STAGE_HEIGHT / 2}px solid transparent`,
      borderLeft: '15px solid transparent',
      borderLeftColor: 'background.paper',
      borderRadius: '4px',
    },
    '&:hover:before': {
      borderColor: 'transparent',
      borderLeftColor: 'action.hover',
    },
    '&:active:before': {
      borderColor: 'transparent',
      borderLeftColor: 'action.hover',
    },
  };
  const cssLeftNotch = {
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      outline: 'none',
      border: 'none',
      borderTop: `${STAGE_HEIGHT / 2}px solid transparent`,
      borderBottom: `${STAGE_HEIGHT / 2}px solid transparent`,
      borderLeft: `15px solid `,
      borderLeftColor: 'background.default',
      borderRadius: '4px',
    },
  };

  const cssBottomArrow = {
    bgcolor: 'background.paper',
    position: 'relative',
    border: '4px solid',
    borderColor: 'background.paper',
    content: '""',
    height: STAGE_HEIGHT,
    bottom: '50%',
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      border: 'solid transparent',
      borderColor: 'transparent',
      borderBottomColor: 'background.paper',
      width: 0,
      height: 0,
      bottom: 0,
      transform: 'rotate(180deg)',
      borderWidth: '25px',
      marginLeft: '-25px',
      marginBottom: '-50px',
    },
    ':hover': {
      bgcolor: 'action.hover',
      borderColor: 'action.hover',
      borderBottom: 'action.hover',
    },
    '&:hover:before': {
      borderBottomColor: 'action.hover',
    },
  };

  const cssTopArrow = {
    position: 'relative',
    border: '4px solid ',
    borderColor: 'background.paper',
    bgcolor: 'background.paper',
    content: '""',
    height: STAGE_HEIGHT,
    top: '50%',

    '&:after': {
      position: 'absolute',
      border: 'solid transparent',
      content: '""',
      width: 0,
      height: 0,
      top: 0,
      borderColor: 'transparent',
      borderTopColor: 'background.default',
      borderWidth: '25px',
      marginLeft: '-25px',
      marginTop: '-5px',
    },
    ':hover': {
      bgcolor: 'action.hover',
      borderColor: 'action.hover',
      borderTopColor: 'action.hover',
    },
  };

  const stageCommonCSS = () => ({
    position: 'relative',
    bgcolor: 'background.paper',
    height: STAGE_HEIGHT,
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      marginRight: 2,
    },
    ':first-of-type': {
      borderTopLeftRadius: BORDER_RADIUS,
      borderBottomLeftRadius: BORDER_RADIUS,
    },
    ':last-of-type': {
      borderTopRightRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
  });

  const classes = {
    box: {
      outline: 'none',
      ...stageCommonCSS(),
      ...cssRightArrow,
      ...cssLeftNotch,
      '&:hover': {
        cursor: hasTasksReadPermissions ? 'pointer' : 'default',
        bgcolor: 'action.hover',
      },
      '&:active': {
        bgcolor: 'action.hover',
      },
      '&:last-child': {
        '&:before': {
          display: 'none',
        },
      },
      '&:first-of-type': {
        '&:after': {
          display: 'none',
        },
      },
    },
    mobileTopStage: {
      ...cssBottomArrow,
      position: 'relative',
      borderRadius: '10px',
      '&:hover': {
        bgcolor: 'action.hover',
      },
      '&:active': {
        transform: CLICK_TRANSFORM,
        bgcolor: 'primary.main',
      },
    },
    mobileCenterStage: {
      ...cssBottomArrow,
      ...cssTopArrow,
      borderRadius: '10px',
      '&:hover': {
        bgcolor: 'action.hover',
      },
      '&:active': {
        transform: CLICK_TRANSFORM,
        bgcolor: 'action.hover',
      },
    },
    mobileBottomStage: {
      ...cssTopArrow,
      borderRadius: '10px',
      '&:hover': {
        bgcolor: 'action.hover',
      },
      '&:active': {
        transform: CLICK_TRANSFORM,
        bgcolor: 'action.hover',
      },
    },
    count: {
      opacity: 0.8,
    },
    countContainer: {
      position: 'relative',
    },
    noPadding: {
      padding: 0,
    },
  };

  const handleClick = (url: string) => navigate(`/${org}/tasks/all-tasks?tab=${url}`);

  const renderPipelineStage = (
    title: string,
    count: number | undefined,
    classes: any,
    url: string,
    color?: any,
    Icon?: React.ComponentType
  ) => (
    <Grid
      container
      justifyContent="space-evenly"
      alignItems="center"
      alignContent="center"
      sx={classes}
      onClick={() => (hasTasksReadPermissions ? handleClick(url) : () => {})}
    >
      <Grid item xs={6}>
        <Typography variant="h6" whiteSpace="nowrap">
          {title}
        </Typography>
        <Typography variant="h2" color={color ? color : 'default'}>
          {count === undefined ? '-' : <CountUp end={count} duration={1.75} />}
          {count === MAX_TASKS_COUNT ? '+' : ''}
        </Typography>
      </Grid>

      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', top: 0 }}>
          <NorthEastIcon fontSize="medium" />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right' }}></Box>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      className={className}
      justifyContent="space-evenly"
      wrap="nowrap"
      display="flex"
      flexDirection={mdBreakpoint ? 'row' : 'column'}
      gap={mdBreakpoint ? 0 : 3}
    >
      {renderPipelineStage(
        'IN QUEUE',
        callsInQueueCount,
        mdBreakpoint ? classes.box : classes.mobileTopStage,
        'in-queue'
      )}
      {renderPipelineStage(
        'IN PROGRESS',
        callsInProgressCount,
        mdBreakpoint ? classes.box : classes.mobileCenterStage,
        'in-progress'
      )}
      {renderPipelineStage(
        'COMPLETED TODAY',
        callsCompletedTodayCount,
        mdBreakpoint ? classes.box : classes.mobileBottomStage,
        'completed'
      )}
    </Grid>
  );
};

export default PipelineDiagram;
