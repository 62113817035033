import {
  useAppState,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
} from '@infinitusai/shared';
import { Table, useTable, DateField } from '@infinitusai/table';
import { Chips } from '@infinitusai/ui';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import NavigateNext from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { createColumnHelper } from '@tanstack/react-table';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomerFacingTaskState, getCustomerFacingTaskState, getTaskFailureReason } from 'utils';

import { useGetOrgPrograms, useGetTasks, useGetWebhooks } from 'api/customer';
import StatusChip from 'components/StatusChip';
import { infinitusai } from 'proto/pbjs';
import {
  getTaskTypeColumnDisplayName,
  getUuidDisplayName,
  getProductNamesFromTasks,
  getTaskStatusFilterOptions,
  checkFilters,
  getFailureReasonFilterOptions,
  getTaskTypeFilterOptionsPortal,
  getTasksWithProducts,
} from 'utils/displayNames';
import { getWebhookStatusDisplayName, getWebhookStatusFilterOptions } from 'utils/webhook';

import { CustomToolbarWithResendWebhook } from './CustomToolbar';

function TasksCompletedTab() {
  const appState = useAppState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Fetch data
  const getCompletedTasks = useGetTasks(searchParams.get('tab') || '');
  const getWebhookConfigs = useGetWebhooks();
  const getOrgPrograms = useGetOrgPrograms();

  const filters = JSON.parse(searchParams.get('filters') as string);
  const products = filters
    ?.filter((filter: any) => filter.id === 'productNames')[0]
    ?.value.join(', ');
  const taskType = filters?.filter((filter: any) => filter.id === 'taskType')[0]?.value;
  const taskStateFilter: string = filters?.filter((filter: any) => filter.id === 'state')[0]?.value;

  // Local state
  const [showOptions, setShowOptions] = React.useState(false);

  // Filters on FE
  const updatedTasks = React.useMemo(
    () => getTasksWithProducts(getCompletedTasks.data.tasks, getCompletedTasks.data.taskInputs),
    [getCompletedTasks.data.taskInputs, getCompletedTasks.data.tasks]
  );
  const filteredTasks = React.useMemo(
    () =>
      checkFilters(updatedTasks, {
        taskTypeFilter: taskType,
        productNamesFilter: products,
      }),
    [products, taskType, updatedTasks]
  );

  const showTimeSavingsEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.SHOW_TIME_SAVINGS
  );
  const hasProductsFilterEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.TASK_TABLE_PRODUCTS
  );
  const showHumanInvolvementFlagEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.SHOW_HUMAN_INVOLVEMENT_FLAG
  );

  const hasTaskTypeFilterEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.TASK_TABLE_TASK_TYPES
  );
  const bundledTasksEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.BUNDLE_CUSTOMER_TASKS
  );

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.be.CustomerTaskDoc>();
    return [
      columnHelper.accessor('customerAssignedId', {
        id: 'customerAssignedId',
        header: 'Customer ID',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.accessor((customerTask) => getCustomerFacingTaskState(customerTask), {
        id: 'state',
        header: 'Task Status',
        cell: (info) => (
          <StatusChip label={getCustomerFacingTaskState(info.row.original) || null} />
        ),
        meta: {
          filterType: 'select',
          filterSelectOptions: getTaskStatusFilterOptions(),
        },
      }),
      columnHelper.accessor(
        (task) =>
          getTaskFailureReason(
            task.failureReasonChosen?.type as infinitusai.be.TaskFailReason.Type
          ),
        {
          id: 'failureReasonChosen',
          header: 'Failure Reason',
          enableGlobalFilter: false,
          enableSorting: false,
          enableColumnFilter:
            taskStateFilter?.toUpperCase() === CustomerFacingTaskState.UNSUCCESSFUL ||
            taskStateFilter?.toUpperCase() === CustomerFacingTaskState.PARTIAL ||
            taskStateFilter === undefined,
          cell: (info) =>
            info.row.original.state === infinitusai.be.TaskState.TASK_STATE_FAILED ||
            info.row.original.state === infinitusai.be.TaskState.TASK_STATE_PARTIAL_SUCCESS
              ? info.getValue()
              : '-',
          meta: {
            download: true,

            filterType: 'select',
            filterSelectOptions: getFailureReasonFilterOptions(),
          },
        }
      ),
      columnHelper.accessor('taskReviewMillis', {
        id: 'taskReviewMillis',
        header: 'Completed On',
        cell: (info) =>
          info.getValue() !== 0 ? <DateField date={new Date(info.getValue() as number)} /> : '-',
        meta: {
          filterType: 'daterange',
        },
      }),
      columnHelper.accessor('taskType', {
        id: 'taskType',
        header: 'Task Type',
        enableColumnFilter: hasTaskTypeFilterEnabled,
        cell: (info) => getTaskTypeColumnDisplayName(info.row.original),
        meta: {
          filterType: 'select',
          filterSelectOptions: getTaskTypeFilterOptionsPortal().map((key) => {
            return {
              value: key,
              label: key,
            };
          }),
        },
      }),
      columnHelper.accessor('taskUuid', {
        header: 'Infinitus ID',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => getUuidDisplayName(info.getValue()),
      }),
      columnHelper.accessor('calleeName', {
        id: 'calleeName',
        header: 'Callee Name',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.accessor('programName', {
        id: 'programName',
        header: 'Program ID',
        enableSorting: false,
        meta: {
          filterType: 'select',
          filterSelectOptions: getOrgPrograms?.data.map((program) => {
            return { value: program.name, label: program.displayName };
          }),
        },
      }),
      columnHelper.accessor((original) => original.bvInputs?.productInfos, {
        id: 'productNames',
        header: 'Products',
        enableSorting: false,
        enableColumnFilter: hasProductsFilterEnabled,
        cell: (info) =>
          info.getValue() ? (
            <Chips
              size="small"
              max={2}
              direction="column"
              labelSize={10}
              labels={info.getValue()?.map((product) => product.productName) as string[]}
            />
          ) : (
            '-'
          ),
        meta: {
          filterType: 'multiselect',
          filterSelectOptions: getProductNamesFromTasks(updatedTasks).map((product) => {
            return { value: product, label: product };
          }),
        },
      }),
      columnHelper.accessor('hasPotentialAdverseEvent', {
        header: 'Flagged',
        cell: (info) => (info.getValue()?.value ? <FlagRoundedIcon color="error" /> : '-'),
        meta: {
          filterType: 'boolean',
        },
      }),
      columnHelper.accessor('customerReview', {
        header: 'Reviewed',
        cell: (info) => (info.getValue() ? <FlagRoundedIcon color="success" /> : '-'),
        meta: {
          filterType: 'boolean',
        },
      }),
      showTimeSavingsEnabled &&
        columnHelper.accessor('timeSavingsMinutes', {
          header: 'Time Saved',
          enableGlobalFilter: false,
          enableHiding: !showTimeSavingsEnabled,
          cell: (info) => {
            const showTimeSavings = showTimeSavingsEnabled && info.getValue();
            return showTimeSavings && info.getValue() > 0
              ? `${Math.ceil(info.getValue())} minutes`
              : '-';
          },
        }),
      showHumanInvolvementFlagEnabled
        ? columnHelper.accessor('humanInvolved', {
            header: 'Has Human Involvement',
            cell: (info) => (!info.getValue() ? '-' : info.getValue()?.value ? 'Yes' : 'No'),
            meta: {
              filterType: 'boolean',
            },
          })
        : null,
      getWebhookConfigs.data.length > 0
        ? columnHelper.accessor(
            (customerTask) => getWebhookStatusDisplayName(customerTask.webhook?.status),
            {
              id: 'webhookDeliveryStatus',
              header: 'Webhook Status',
              cell: (info) => {
                return info.getValue() ? info.getValue() : '-';
              },
              meta: {
                filterType: 'select',
                filterSelectOptions: getWebhookStatusFilterOptions(),
              },
            }
          )
        : null,
      bundledTasksEnabled
        ? columnHelper.accessor('bundleTaskId', {
            header: 'Has Bundle Task',
            cell: (info) => {
              return info.getValue() ? 'Yes' : 'No';
            },
            meta: {
              filterType: 'boolean',
            },
          })
        : null,
      columnHelper.display({
        id: 'action',
        enableHiding: false,
        enableColumnFilter: false,
        cell: (info) => (
          <Stack display="flex">
            <IconButton
              sx={{
                display: 'flex',
                justifyContent: 'right',
                ':hover': { bgcolor: 'transparent' },
              }}
              disableRipple
            >
              <NavigateNext />
            </IconButton>
          </Stack>
        ),
      }),
    ].filter(Boolean) as any[];
  }, [
    getOrgPrograms?.data,
    getWebhookConfigs.data.length,
    hasProductsFilterEnabled,
    hasTaskTypeFilterEnabled,
    showHumanInvolvementFlagEnabled,
    showTimeSavingsEnabled,
    bundledTasksEnabled,
    taskStateFilter,
    updatedTasks,
  ]);

  const table = useTable({
    id: 'completed-tasks',
    data: filteredTasks,
    columns,
    manualFiltering: true,
    isLoading: getCompletedTasks.isLoading,
    totalRows: filteredTasks.length,
    noRowsMessage: 'No tasks completed yet.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableColumnFilters: true,
    enableHiding: true,
    enableGlobalFilter: true,
    enableRowSelection: showOptions,
    enableSavedFilters: true,
    enableUrlSync: true,
    getRowId: (row) => row.taskUuid,
    onRowClick: (row) => {
      navigate(`/${appState.org?.name}/tasks/${row.original.taskUuid}`);
    },
  });

  React.useEffect(() => {
    if (getCompletedTasks.isError) {
      enqueueSnackbar(`Please try again. We currently don't support that filter combination.`, {
        variant: 'error',
      });
    }
  }, [getCompletedTasks.isError]);

  return (
    <Box mt={3}>
      <Table
        table={table}
        overrides={{
          toolbar: (
            <CustomToolbarWithResendWebhook
              table={table}
              setShowOptions={setShowOptions}
              showOptions={showOptions}
              onSuccess={() => {
                getCompletedTasks.refetch();
              }}
            />
          ),
        }}
      />
    </Box>
  );
}

export default TasksCompletedTab;
