import { useAuth, PermissionName } from '@infinitusai/auth';
import { useAppState, Tabs } from '@infinitusai/shared';
import { Drawer, DrawerHeader, useConfirm } from '@infinitusai/ui';
import { getInitialsFromName } from '@infinitusai/utils';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import { useRemoveUserFromOrg } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

import EditRoles from './EditRolesDrawer';
import PermissionsTab from './PermissionsTab';
import RolesTab from './RolesTab';

interface Props {
  open: boolean;
  user?: infinitusai.auth.User;
  onClose: () => void;
}

function MemberDrawer({ open, user, onClose }: Props) {
  const auth = useAuth();
  const confirm = useConfirm();
  const appState = useAppState();
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const removeUserFromOrg = useRemoveUserFromOrg();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editRoles, setEditRoles] = React.useState(false);
  const menuOpen = Boolean(anchorEl);
  const hasRbacAssignPermission = hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN]);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveUser = () => {
    confirm({
      title: 'Remove Member From Organization?',
      description:
        'Are you sure you want to remove "' +
        user?.email +
        '" from the "' +
        appState.orgName +
        '" organization?',
      footnote:
        'If so, all roles and permissions will be removed and ' +
        user?.email +
        ' will not be able to access the Infinitus Dashboard.',
      confirmText: 'Remove Member Permanently',
      onConfirm: () => {
        onClose();
        const req = infinitusai.auth.RemoveUserFromOrgRequest.fromObject({
          userUid: user?.uid || '',
        });
        removeUserFromOrg.mutate(req, {
          onSuccess: () => {
            enqueueSnackbar('Member successfully removed', {
              variant: 'success',
            });
          },
          onError: (error) => {
            enqueueSnackbar(`Failed to remove user: ${error.message}`, {
              variant: 'error',
            });
          },
        });
      },
    });
  };

  return (
    <Drawer open={open} onClose={onClose}>
      {editRoles ? (
        <EditRoles user={user} onClose={() => setEditRoles(false)} />
      ) : (
        <React.Fragment>
          <DrawerHeader>
            {hasRbacAssignPermission ? (
              <React.Fragment>
                <IconButton
                  onClick={handleMenuClick}
                  id="menu-button"
                  aria-controls={menuOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? 'true' : undefined}
                  disableRipple
                >
                  <MoreVertOutlinedIcon fontSize="large" />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleRemoveUser}>Remove Member From Org</MenuItem>
                </Menu>
              </React.Fragment>
            ) : (
              <Box />
            )}
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </DrawerHeader>
          <Stack display="flex" direction="column" sx={{ alignItems: 'center' }}>
            <Avatar
              {...getInitialsFromName(user?.displayName || user?.email || '')}
              sx={{ mb: 2, width: '50px', height: '50px', fontSize: '24px' }}
            />
            <Box display="flex" alignContent="center" justifyContent="center">
              <Typography variant="h5">{user?.displayName || user?.email}</Typography>
            </Box>
            <Typography variant="body2">{user?.email}</Typography>
            <Divider sx={{ mt: 2 }} />
            <Stack direction="row" gap={1}>
              <Chip
                label={`Joined ${formatDistanceStrict(
                  new Date(user?.createTimeMillis ? Number(user?.createTimeMillis) : 0),
                  new Date(),
                  {
                    addSuffix: true,
                  }
                )}`}
              />
              <Chip
                label={`Active ${formatDistanceStrict(
                  new Date(user?.lastActiveTimeMillis ? Number(user?.lastActiveTimeMillis) : 0),
                  new Date(),
                  {
                    addSuffix: true,
                  }
                )}`}
              />
            </Stack>
          </Stack>
          <Divider sx={{ mt: 3 }} />
          <Tabs
            id="member-drawer"
            tabs={[
              {
                value: 'roles',
                label: 'Roles Assigned',
                content: (
                  <RolesTab user={user} onEditRoles={() => setEditRoles(true)} onClose={onClose} />
                ),
                tabProps: { sx: { minWidth: '50%' } },
                unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_RBAC_READ]),
              },
              {
                value: 'permissions',
                label: 'Permissions Assigned',
                content: <PermissionsTab userUid={user?.uid || ''} />,
                tabProps: { sx: { minWidth: '50%' } },
                unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_RBAC_READ]),
              },
            ]}
          />
        </React.Fragment>
      )}
    </Drawer>
  );
}

export default MemberDrawer;
