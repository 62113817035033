import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  useAppState,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
  Tabs,
} from '@infinitusai/shared';
import { Button, useBreakpoint } from '@infinitusai/ui';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetOrgInvites, useGetOrgUsers } from 'api/auth';

import InvitesTab from './InvitesTab';
import MembersTab from './MembersTab';
import NewInvitesDrawer from './NewInvitesDrawer';
import TeamsTab from './TeamsTab';
import TeamDetailsPage from './team-details';

function TeamsMembersPage() {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const appState = useAppState();
  const smBreakpoint = useBreakpoint('sm');
  const getOrgUsers = useGetOrgUsers();
  const getOrgInvites = useGetOrgInvites();
  const currentSeats = getOrgInvites.data.length + getOrgUsers.data.length;
  const seatsLeft = appState.org?.availableSeats ? appState.org?.availableSeats - currentSeats : 0;
  const [showNewInvitesDrawer, setShowNewInvitesDrawer] = React.useState(false);

  const hasTeamMgmtFeature = useCustomerPortalFeatureEnabled(CustomerPortalFeature.TEAM_MANAGEMENT);

  const teamUuid = hasTeamMgmtFeature ? searchParams.get('teamUuid') : null;

  // When a teamUuid is present in the URL, render the team details page
  if (teamUuid) {
    return <TeamDetailsPage teamUuid={teamUuid} />;
  }

  const title = hasTeamMgmtFeature ? 'Teams & Members' : 'Members';

  const handleNewInvitesDrawerClose = () => {
    setShowNewInvitesDrawer(false);
  };

  const tabs = [
    {
      value: 'members',
      label: 'Members',
      content: <MembersTab />,
      unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_RBAC_READ]),
      tabProps: !smBreakpoint ? { sx: { minWidth: '50%' } } : undefined,
    },
    {
      value: 'invites',
      label: 'Invites',
      content: <InvitesTab />,
      unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_RBAC_READ]),
      tabProps: !smBreakpoint ? { sx: { minWidth: '50%' } } : undefined,
    },
  ];

  // If the team management feature is enabled, add the team management tab as the first tab
  if (hasTeamMgmtFeature) {
    tabs.unshift({
      value: 'teams',
      label: 'Teams',
      content: <TeamsTab />,
      unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_RBAC_READ]),
      tabProps: !smBreakpoint ? { sx: { minWidth: '50%' } } : undefined,
    });
  }

  return (
    <React.Fragment>
      <Stack direction={smBreakpoint ? 'row' : 'column'} justifyContent="space-between">
        <Box pb={1} display="flex" flexDirection="row" justifyContent="space-between">
          <Grid alignItems="center" container spacing={1}>
            <Grid item>
              <GroupsIcon />
            </Grid>
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          </Grid>
          {smBreakpoint ? null : (
            <Typography>
              {seatsLeft > 0 ? `${seatsLeft} seats left` : `No seats remaining`}
            </Typography>
          )}
        </Box>

        <Box
          display="flex"
          alignItems={smBreakpoint ? 'center' : ''}
          flexDirection={smBreakpoint ? 'row' : 'column'}
        >
          {smBreakpoint ? (
            <Box pr={1}>
              <Typography display="flex" justifyContent="flex-end">
                {seatsLeft > 0 ? `${seatsLeft} seats left` : `No seats remaining`}
              </Typography>
            </Box>
          ) : null}
          <Box>
            <Button
              disableRipple
              size="large"
              color="primary"
              variant="contained"
              fullWidth={!smBreakpoint}
              startIcon={<AddRoundedIcon />}
              disabled={!seatsLeft}
              onClick={() => setShowNewInvitesDrawer(true)}
              unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
            >
              Invite Members
            </Button>
          </Box>
        </Box>
      </Stack>
      <Tabs id="members" syncUrl resetUrlOnChange unmountOnChange tabs={tabs} />
      <NewInvitesDrawer open={showNewInvitesDrawer} onClose={handleNewInvitesDrawerClose} />
    </React.Fragment>
  );
}

export default TeamsMembersPage;
