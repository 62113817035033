import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

import { infinitusai } from 'proto/pbjs';

import { PayerIntelligenceDataRow, PayerIntelligenceEffectForDisplay } from './types';

// payerNameMap is a payer ID to name lookup, where payer ID is normalized
// to be all lowercase
export const createDataRowFromRules = (
  rules: infinitusai.tasks.Rule[],
  payerNameMap: { [payerID: string]: string }
): PayerIntelligenceDataRow | undefined => {
  if (rules === undefined) {
    return;
  }
  if (rules.length < 1) {
    return;
  }

  if (new Set(rules.map((r) => r.payerIntelId)).size !== 1) {
    console.error(`Mismatching IDs! ${JSON.stringify(rules)}`);
    return;
  }

  // Sanity check: all rules should have the same set of conditions
  const uniqueConditionSets = uniqWith(
    rules.map((r) => r.conditions),
    isEqual
  );
  if (uniqueConditionSets.length > 1) {
    console.error(
      `Mismatching conditions! Rules with payer intelligence IDs ${JSON.stringify(
        rules.map((rule) => rule.payerIntelId)
      )}`
    );
    return;
  }
  const Conditions: infinitusai.tasks.Condition[] = rules[0].conditions
    .map((conditions) =>
      conditions.conditions
        ? conditions.conditions?.map((condition) =>
            infinitusai.tasks.Condition.fromObject(condition)
          )
        : [infinitusai.tasks.Condition.fromObject({})]
    )
    .flat();
  const data: PayerIntelligenceDataRow = {
    payerIntelligenceID: rules[0].payerIntelId,
    conditions: getConditions(Conditions, payerNameMap) || [],
    effects: rules.flatMap(({ effects }) =>
      getEffects(effects.map((effect) => infinitusai.tasks.Effect.fromObject(effect)))
    ),
  };
  return data;
};

const FIELD_NAME_TO_DATA_FIELD: {
  conditions: { [outputName: string]: string };
  effects: {
    [outputName: string]: {
      displayName: string;
      valueToDisplay?: (v: any) => string;
    };
  };
} = {
  conditions: {
    planName: 'Plan Name',
    planType: 'Plan Type',
    policyType: 'Policy Type',
    groupNumber: 'Group #',
    'policyInfo.memberId': 'Member Prefix',
    currentPlanPriority: 'Current Plan Priority',
    priorAuthRequired: 'Prior Auth Required',
    'payerInfo.infinitusId': 'Payer',
    'payerInfo.association': 'Payer',
    'providerInfo.addressInfo.state': 'Provider State',
    'treatmentInfo.treatmentCode': 'Diagnosis Code',
    'treatmentInfo.facilityType.type': 'Facility Type',
    'productInfos[0].productCode': 'Product Code',
    specialtyPharmacyName: 'Specialty Pharmacy Name',
  },
  effects: {
    isStepTherapyRequired: { displayName: 'Step Therapy Required' },
    fundingType: {
      displayName: 'Funding Type',
      valueToDisplay: (v: boolean) => (v ? 'Self Funded' : 'Fully Funded'),
    },
    buyAndBillAvailable: {
      displayName: 'Buy and Bill Availability',
      valueToDisplay: (v: boolean) => (v ? 'Available' : 'Not Available'),
    },
    priorAuthRequired: { displayName: 'Prior Auth Required' },
    obtainPredeterminationOrg: { displayName: 'Predetermination Org' },
    obtainPredeterminationPhone: { displayName: 'Prior Authorization Phone' },
    obtainPredeterminationFax: { displayName: 'Prior Authorization Fax' },
    obtainPredeterminationWebsite: { displayName: 'Prior Authorization Website' },
    obtainPriorAuthOrg: { displayName: 'Prior Authorization Department' },
    obtainPriorAuthPhone: { displayName: 'Prior Authorization Phone' },
    obtainPriorAuthFax: { displayName: 'Prior Authorization Fax' },
    obtainPriorAuthWebsite: { displayName: 'Prior Authorization Website' },
    predeterminationRequirement: { displayName: 'Predetermination Requirement' },
    predeterminationMethodEntity: { displayName: 'Predetermination Department' },
    predeterminationMethodContact: { displayName: 'Predetermination Phone' },
    specialtyPharmacyAvailable: {
      displayName: 'Specialty Pharmacy Available',
      valueToDisplay: (v: boolean) => (v ? 'Available' : 'Not Available'),
    },
    specialtyPharmacyName: { displayName: 'Specialty Pharmacy Name' },
    specialtyPharmacyPhone: { displayName: 'Specialty Pharmacy Phone' },
    specialtyPharmacyFax: { displayName: 'Specialty Pharmacy Fax' },
  },
};

const getConditions = (
  conditions: infinitusai.tasks.Condition[],
  payerNameMap: { [payerID: string]: string }
) => {
  // bit of a hack: conditions without a description don't need to be displayed
  // (used for default conditions - plan is primary, and not MEDICARE_SUPPLEMENT)

  const allVisibleConds = conditions.filter((c) => c.description);
  return compact(allVisibleConds?.map((fc) => getRowDataFromFieldCondition(fc, payerNameMap)));
};
const mapValsFromPredicate = (eqVal: infinitusai.tasks.EqualValue) => {
  var value: string = '';
  if (eqVal.boolType) {
    value = String(eqVal.boolType);
  } else if (eqVal.stringType) {
    value = eqVal.stringType;
  } else if (eqVal.enumType) {
    value = eqVal.enumType;
  } else if (eqVal.moneyType) {
    value = String(eqVal.moneyType);
  } else if (eqVal.intType) {
    value = String(eqVal.intType);
  }
  return value;
};

const getRowDataFromFieldCondition = (
  fc: infinitusai.tasks.Condition,
  payerNameMap: { [payerID: string]: string }
) => {
  const conditions = FIELD_NAME_TO_DATA_FIELD.conditions;

  if (!fc.outputName || !fc.predicate) {
    return;
  }

  const displayName = conditions[fc.outputName];

  let value = '';
  let vals: string[] = [];

  if (fc.predicate.equalPredicate) {
    value = mapValsFromPredicate(
      infinitusai.tasks.EqualValue.fromObject(fc.predicate.equalPredicate)
    );
  } else if (fc.predicate.notEqualPredicate) {
    value = mapValsFromPredicate(
      infinitusai.tasks.EqualValue.fromObject(fc.predicate.notEqualPredicate)
    );
  } else if (fc.predicate.inPredicate) {
    const cond = fc.predicate.inPredicate; // typescript throws a tantrum if you put it in directly
    const pred = infinitusai.tasks.RepeatedEqualValue.fromObject({ cond }).equalValue.map(
      (eqVal) => eqVal
    );
    for (let i = 0; i < pred.length; i++) {
      const eqVal = pred[i];
      vals.push(mapValsFromPredicate(infinitusai.tasks.EqualValue.fromObject({ eqVal })));
    }
    value = vals.join();
  } else if (fc.predicate.hasPrefixPredicate) {
    value = fc.predicate.hasPrefixPredicate;
  } else if (fc.predicate.hasSubstringPredicate) {
    value = fc.predicate.hasSubstringPredicate;
  }
  // special treatment to get payer name
  if (fc.outputName === 'payerInfo.infinitusId') {
    const payerId = String(value).toLowerCase();
    if (!payerNameMap[payerId]) {
      console.error(`Unabled to find payer id ${value}`);
      value = 'UNKNOWN';
    } else {
      value = payerNameMap[payerId];
    }
  } else if (fc.outputName === 'payerInfo.association' && value === 'ASSOCIATION_BCBS') {
    value = 'BCBS';
  }

  return {
    displayName,
    value,
  };
};

const getEffects = (effects: infinitusai.tasks.Effect[]): PayerIntelligenceEffectForDisplay[] => {
  const effectsMap = FIELD_NAME_TO_DATA_FIELD.effects;

  const effectValueForDisplay = (value: any, valueToDisplay?: (v: any) => string) => {
    if (valueToDisplay) {
      return valueToDisplay(value);
    }

    // convert "true" and "false" to "Yes" and "No"
    if (value === 'true') return 'Yes';
    if (value === 'false') return 'No';

    return value as string;
  };

  const data = compact(
    effects.map((fe) => {
      if (!fe.outputFieldValueEffect) {
        return undefined;
      }
      if (
        !fe.outputFieldValueEffect.fieldValue ||
        !fe.outputFieldValueEffect.fieldPath ||
        !effectsMap[fe.outputFieldValueEffect.fieldPath]
      ) {
        return undefined;
      }
      if (!fe.outputFieldValueEffect.effectType || fe.outputFieldValueEffect.effectType === null) {
        return undefined;
      }
      const displayName = effectsMap[fe.outputFieldValueEffect.fieldPath].displayName;
      const value = fe.outputFieldValueEffect.fieldValue;
      const valueForDisplay = effectValueForDisplay(
        value,
        effectsMap[fe.outputFieldValueEffect.fieldPath].valueToDisplay
      );

      return {
        displayName: displayName,
        value: valueForDisplay,
        effectType: fe.outputFieldValueEffect.effectType,
      };
    })
  );
  return data;
};
