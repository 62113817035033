import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  TextField,
  useConfirm,
} from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState, ChangeEvent } from 'react';

import { domainRegex } from 'utils/validations';

interface Props {
  open: boolean;
  onClose: () => void;
  updateMutation: any;
  formik: any;
  form: any;
}

function EmailDomainDrawer({ open, onClose, updateMutation, formik, form }: Props) {
  const confirm = useConfirm();
  const [newEmailDomain, setNewEmailDomain] = useState('');
  const [isEmailDomainValid, setIsEmailDomainValid] = useState(true);
  const [isDuplicated, setIsDuplicated] = useState(false);

  // Create a hash table to check duplication in email domain
  const hash: { [key: string]: boolean } = form.values.emailDomains.reduce(
    (map: { [key: string]: boolean }, ipAddress: string) => {
      map[ipAddress] = true;
      return map;
    },
    {}
  );

  const handleAbandonAdding = () => {
    setNewEmailDomain('');
    onClose();
  };

  const handleClose = () => {
    const hasUnsavedChanges = newEmailDomain.length > 0;
    if (hasUnsavedChanges) {
      confirm({
        title: 'Abandon Adding an Email Domain',
        description: 'Are you sure you want to abandon adding a new email domain?',
        footnote: 'If you proceed, the progress of adding a new email domain will be lost.',
        confirmText: 'Abandon New Email Domain',
        onConfirm: handleAbandonAdding,
      });
    } else {
      onClose();
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setNewEmailDomain(value);
    if (value.length === 0) {
      setIsEmailDomainValid(true);
    } else {
      // Validate only the new Email domain
      setIsEmailDomainValid(domainRegex.test(value));
      setIsDuplicated(hash[value] === true);
    }
  };

  const handleAdd = async () => {
    if (newEmailDomain.length > 0) {
      form.setFieldValue('emailDomains', [...form.values.emailDomains, newEmailDomain]);
      await formik.submitForm();
      setNewEmailDomain('');
    }
    onClose();
  };

  return (
    <Drawer open={open} onClose={handleClose}>
      <DrawerHeader title="Add a Valid Email Domain" onClose={handleClose} />
      <DrawerBody>
        <Typography variant="overline" sx={{ mx: 2 }}>
          ENTER A NEW VALID EMAIL DOMAIN
        </Typography>
        <TextField
          debounce
          id="email_domain"
          label="Valid Domain"
          size="medium"
          value={newEmailDomain}
          onChange={handleTextChange}
          helperText="Specify allowed email domains to login to the Infinitus Dashboard (ex. infinitus.ai)"
          inputProps={{ maxLength: 50 }}
          sx={{ m: 2, mt: 1 }}
        />
        {!isEmailDomainValid && ( // conditionally render error message
          <Typography variant="body2" color="error" sx={{ ml: 2 }}>
            Invalid Email Domain
          </Typography>
        )}
        {isDuplicated && ( // conditionally render error message
          <Typography variant="body2" color="error" sx={{ ml: 2 }}>
            Duplicated Email Domain
          </Typography>
        )}
      </DrawerBody>
      <DrawerFooter>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" size="large" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={handleAdd}
          disabled={
            newEmailDomain.length === 0 ||
            !isEmailDomainValid ||
            isDuplicated ||
            updateMutation.isLoading
          }
          sx={{ ml: 2 }}
        >
          Add Email Domain
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}

export default EmailDomainDrawer;
