import { AppName } from '@infinitusai/api';
import { AuthProvider } from '@infinitusai/auth';
import { isInfinautEmail } from '@infinitusai/utils';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { initializeApp } from 'firebase/app';
import {
  initializeAuth,
  browserSessionPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from 'app';
import { Basename } from 'layout';
import {
  BETA_VERSION,
  ALLOW_INTERNAL_BETA_TESTERS,
  ALLOW_EXTERNAL_BETA_TESTERS,
} from 'utils/constants';
import createQueryClient from 'utils/queryClient';

import reportWebVitals from './reportWebVitals';

// Firebase configuration
const gcpConfig = {
  projectId: process.env.REACT_APP_GCP_PROJECT_ID,
};
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:
    process.env.NODE_ENV === 'development'
      ? gcpConfig.projectId + '.firebaseapp.com'
      : window.location.hostname,
  databaseURL: 'https://' + gcpConfig.projectId + '.firebaseio.com',
  projectId: gcpConfig.projectId,
  storageBucket: gcpConfig.projectId + '.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
  persistence: browserSessionPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});

// Initialize react query
const queryClient = createQueryClient(auth);

// Render react tree
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider
        auth={auth}
        options={{
          appName: AppName.CUSTOMER,
          onUserAuthenticated: (user) => {
            if (
              BETA_VERSION &&
              (!ALLOW_INTERNAL_BETA_TESTERS ||
                (!ALLOW_EXTERNAL_BETA_TESTERS && !isInfinautEmail(user.email)))
            ) {
              window.location.href = window.location.href.replace(Basename.Beta, Basename.Default);
            }
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={2}
            preventDuplicate
            autoHideDuration={5000}
            style={{ pointerEvents: 'all' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <BrowserRouter basename={BETA_VERSION ? Basename.Beta : Basename.Default}>
              <App />
            </BrowserRouter>
          </SnackbarProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AuthProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
