import { Table, useTable } from '@infinitusai/table';
import { possibleLongToNumber } from '@infinitusai/utils';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createColumnHelper } from '@tanstack/react-table';
import formatDistance from 'date-fns/formatDistance';
import * as React from 'react';

import { useGetWebhooks } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

import DeleteWebhookButton from './DeleteWebhookButton';

function WebhooksTab() {
  const webhooksQuery = useGetWebhooks();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.be.WebhookConfigDoc>();
    return [
      columnHelper.accessor('url', {
        header: 'Webhook URL',
      }),
      columnHelper.accessor('createdAtMillis', {
        header: 'Date Generated',
        cell: (info) =>
          formatDistance(possibleLongToNumber(info.getValue()), new Date(), {
            addSuffix: true,
          }),
      }),
      columnHelper.display({
        id: 'action',
        cell: (info) => (
          <Box display="flex" justifyContent="flex-end">
            <DeleteWebhookButton webhooksMetadata={info.row.original} />
          </Box>
        ),
      }),
    ];
  }, []);

  const table = useTable({
    id: 'webhooks',
    data: webhooksQuery.data,
    columns,
    isLoading: webhooksQuery.isLoading,
    totalRows: webhooksQuery.data.length,
    noRowsMessage: 'There are no active webhooks. Get started by creating a new one above.',
    hideToolbar: true,
    enableSharing: false,
    enableSorting: false,
    enableFilters: false,
    enableGlobalFilter: true,
    enableUrlSync: true,
    hidePagination: true,
  });

  return (
    <>
      <Typography gutterBottom>
        Infinitus provides an ability for customers to configure a webhook to be notified when a
        task is complete.
      </Typography>
      <Typography gutterBottom>
        When a task is completed and a webhook is configured, Infinitus will send a post request to
        the webhook URL with a JSON request body containing the task results.
      </Typography>
      <Typography>A few things to note about Infinitus Webhooks:</Typography>
      <ul>
        <li>
          Customers can configure up to <strong>1</strong> webhook
        </li>
        <li>
          A webhook <strong>must</strong> contain a valid URL with <i>https</i>
        </li>
        <li>
          A webhook <strong>must</strong> specify basic authentication (username and password)
        </li>
        <li>
          A webhook can include up to <strong>5</strong> optional customer headers for additional
          security
        </li>
      </ul>
      <br />
      <Box>
        <Table table={table} />
      </Box>
    </>
  );
}

export default WebhooksTab;
