import { ErrorType } from '@infinitusai/api';
import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  useAppState,
  ErrorMessage,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
  Tabs,
} from '@infinitusai/shared';
import { useBreakpoint } from '@infinitusai/ui';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import WebhookIcon from '@mui/icons-material/Webhook';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getCustomerFacingTaskState } from 'utils';

import {
  useBundledCustomerTasks,
  useGetTask,
  useGetWebhooks,
  useResendWebhookNotifications,
} from 'api/customer';
import DataField from 'components/DataField';
import StatusChip from 'components/StatusChip';
import { infinitusai, infinitusapi } from 'proto/pbjs';
import { getTaskTypeColumnDisplayName, getUuidDisplayName } from 'utils/displayNames';
import { isTaskInFinalState } from 'utils/taskState';
import { processResendWebhookResponse } from 'utils/webhook';

import BundledTasksTab from './BundledTasksTab';
import CancelTaskButton from './CancelTaskButton';
import CompleteTaskButton from './CompleteTaskButton';
import GenerateTestOutputsButton from './GenerateTestOutputsButton';
import MarkAsProcessed from './MarkAsProcessed';
import TaskInputsTab from './TaskInputsTab';
import TaskOutputsTab from './TaskOutputsTab';
import TaskPreviousOutputsTab from './TaskPreviousOutputsTab';
import TaskRecordingTab from './TaskRecordingTab';
import { shouldShowResendWebhookButton, shouldShowSuccessWebhookIcon } from './utils';

const INTEGRATION_TEST_PROGRAM_NAME = 'integration_test';

function formatDate(timestampMillis: number) {
  return format(new Date(timestampMillis), 'MMM d, yyyy h:mm aa');
}

function TaskDetailsPage() {
  const { tid, org: orgName } = useParams();
  const appState = useAppState();
  const { hasPermission } = useAuth();
  const smBreakpoint = useBreakpoint('sm');
  const mdBreakpoint = useBreakpoint('md');

  // Features
  const completeTaskEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.MARK_TASK_COMPLETE
  );
  const showTimeSavingsEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.SHOW_TIME_SAVINGS
  );
  const selfServeTestingEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.SELF_SERVE_TESTING
  );
  const showHumanInvolvementFlagEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.SHOW_HUMAN_INVOLVEMENT_FLAG
  );
  const bundledTasksEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.BUNDLE_CUSTOMER_TASKS
  );

  // Data hooks
  const getTask = useGetTask(tid as string);
  const getWebhooks = useGetWebhooks();
  const resendWebhookNotifications = useResendWebhookNotifications();
  const bundledCustomerTasksResult = useBundledCustomerTasks(tid || '', bundledTasksEnabled);

  const customerTaskDoc = getTask?.data?.customerTask as infinitusai.be.CustomerTaskDoc;
  const task = getTask?.data?.task;
  const previousOutputs = getTask?.data
    ?.previousOutputs as infinitusai.be.GetTaskResponse.PreviousOutputs[];
  const flexibleTaskInputs = getTask?.data?.flexibleTaskInputs as infinitusapi.IINFTaskInput[];
  const bundledCustomerTasks = bundledCustomerTasksResult.data?.tasks || [];
  const isIntegrationTestProgram = customerTaskDoc?.programName === INTEGRATION_TEST_PROGRAM_NAME;
  const inProduction = appState?.org?.isLive;
  const testEnabled = appState?.org?.enableTesting;
  const customerFacing = !appState.org?.isInternal;

  const shouldShowCompleteTaskButton =
    customerTaskDoc?.state === infinitusai.be.TaskState.TASK_STATE_CREATED &&
    completeTaskEnabled &&
    isIntegrationTestProgram;

  const shouldShowTimeSavings =
    showTimeSavingsEnabled && isTaskInFinalState(customerTaskDoc?.state);

  // Enable Generate Test Task Button when task is in queue and it's in a sandbox org
  // Sandbox Org = Customer Facing (!isInternal) AND NOT In Production (!isLive)
  const enableGenerateTestOutputsButton =
    customerTaskDoc?.state === infinitusai.be.TaskState.TASK_STATE_CREATED &&
    !inProduction &&
    testEnabled &&
    selfServeTestingEnabled &&
    customerFacing;

  const showResendWebhookButton = shouldShowResendWebhookButton(
    customerTaskDoc?.state,
    customerTaskDoc?.webhook,
    getWebhooks.data || []
  );

  async function handleResendWebhookNotifications() {
    const body = infinitusai.be.ResendWebhookNotificationsRequest.fromObject({
      taskIds: [customerTaskDoc.taskUuid],
    });

    resendWebhookNotifications.mutate(body, {
      onSuccess: (data) => {
        const result = processResendWebhookResponse(data);
        enqueueSnackbar(result.message, { variant: result.variant });
        getTask.refetch();
      },
      onError: (error) => {
        enqueueSnackbar('Failed to send webhook notification', { variant: 'error' });
      },
    });
  }

  if (getTask.isLoading) {
    return (
      <Box
        sx={{
          height: '100%',
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (getTask.isError) {
    return <ErrorMessage errorType={ErrorType.NOT_FOUND} entity="Task" />;
  }

  if (!task && customerTaskDoc)
    return (
      <Box textAlign="center">
        <Typography>Task deleted</Typography>
      </Box>
    );

  if (!task || !customerTaskDoc) {
    return null;
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          p: '1rem',
          flexGrow: 1,
          flexDirection: 'column',
        }}
      >
        <Stack
          flexDirection={smBreakpoint ? 'row' : 'column'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          gap={smBreakpoint ? 0 : 2}
        >
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography variant="h5">
              Task {getUuidDisplayName(customerTaskDoc.taskUuid)} Results{' '}
            </Typography>
            <StatusChip label={getCustomerFacingTaskState(customerTaskDoc) || null} />
            <Box alignItems="center" alignContent="center">
              {shouldShowSuccessWebhookIcon(customerTaskDoc.webhook) ? (
                <Tooltip title="Webhook Successfully Delivered">
                  <WebhookIcon color="success" />
                </Tooltip>
              ) : null}
            </Box>
          </Box>
          <Stack
            display="flex"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            alignContent="center"
            gap={1}
          >
            {showResendWebhookButton ? (
              <Box>
                <Button variant="outlined" onClick={handleResendWebhookNotifications}>
                  Resend Webhook Notification
                </Button>
              </Box>
            ) : null}
            {enableGenerateTestOutputsButton ? (
              <Box>
                <GenerateTestOutputsButton task={customerTaskDoc} />
              </Box>
            ) : null}
            {task.state === infinitusai.be.TaskState.TASK_STATE_CREATED ? (
              <Box>
                <CancelTaskButton />
              </Box>
            ) : null}
            {shouldShowCompleteTaskButton ? (
              <Box>
                <CompleteTaskButton />
              </Box>
            ) : null}
            {isTaskInFinalState(customerTaskDoc?.state) ? (
              <Box>
                <MarkAsProcessed task={customerTaskDoc} />
              </Box>
            ) : null}

            {customerTaskDoc.hasPotentialAdverseEvent?.value ? (
              <Box>
                <Tooltip title="Task has been flagged for review">
                  <FlagRoundedIcon color="error" />
                </Tooltip>
              </Box>
            ) : null}
          </Stack>
        </Stack>
        <Box
          sx={{
            border: `1.75px solid transparent`,
            borderColor: 'grey',
            boxShadow: (theme) => `0px 0px 4px ${theme.palette.divider}`,
            borderOpacity: '5%',
            borderRadius: '10px',
          }}
          flexDirection="column"
          display="flex"
          p={2}
          mt={smBreakpoint ? 1 : 2}
          position="relative"
          width="100%"
          height="100%"
        >
          {customerTaskDoc && (
            <Grid
              container
              spacing={{ xs: 2 }}
              columns={{ xs: 2, sm: 3, md: 12 }}
              direction="row"
              justifyContent="space-between"
              gap={mdBreakpoint ? 2 : 0}
              mb={2}
            >
              <Grid item>
                <DataField
                  label="Task Type"
                  value={getTaskTypeColumnDisplayName(customerTaskDoc)}
                />
              </Grid>
              <Grid item>
                <DataField label="Program" value={customerTaskDoc.programName || ''} />
              </Grid>
              <Grid item>
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                  <DataField label="Customer Id" value={customerTaskDoc.customerAssignedId} />
                  {customerTaskDoc.customerUrl ? (
                    <Link href={customerTaskDoc.customerUrl} target="_blank">
                      <IconButton color="primary" aria-label="open customer url">
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                    </Link>
                  ) : null}
                </Box>
              </Grid>
              {customerTaskDoc.customerSegment ? (
                <Grid item>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <DataField label="Customer Segment" value={customerTaskDoc.customerSegment} />
                  </Box>
                </Grid>
              ) : null}
              <Grid item>
                <DataField label="Callee Name" value={customerTaskDoc.calleeName} />
              </Grid>
              {appState.org?.demo && (
                <Grid item>
                  <DataField
                    label="Request submitted by"
                    value={customerTaskDoc.taskCreator?.userEmail || ''}
                  />
                </Grid>
              )}
              <Grid item>
                <DataField
                  label="Created On"
                  value={formatDate(Number(customerTaskDoc.taskCreationMillis))}
                />
              </Grid>
              <Grid item>
                <DataField
                  label="Completed On"
                  value={
                    Number(customerTaskDoc?.taskReviewMillis) !== 0
                      ? formatDate(Number(customerTaskDoc?.taskReviewMillis))
                      : '-'
                  }
                />
              </Grid>
              {shouldShowTimeSavings && customerTaskDoc.timeSavingsMinutes > 0 && (
                <Grid item>
                  <DataField
                    label="Estimated Time Saved"
                    value={
                      `${Math.ceil(Number(customerTaskDoc.timeSavingsMinutes))} minutes` || '-'
                    }
                  />
                </Grid>
              )}
              {customerTaskDoc.webhookDelivered?.value && (
                <Grid item>
                  <DataField
                    label="Webhook Delivered At"
                    value={formatDate(
                      Number(
                        customerTaskDoc.webhook?.deliveryAttempts?.[
                          customerTaskDoc.webhook?.deliveryAttempts?.length - 1
                        ].deliveryAttemptedAtMillis
                      )
                    )}
                  />
                </Grid>
              )}
            </Grid>
          )}

          <Tabs
            id="all-tasks"
            syncUrl
            resetUrlOnChange
            unmountOnChange
            variant="fullWidth"
            tabs={[
              {
                value: 'task-inputs',
                label: 'Task Inputs',
                tabProps: { sx: { minWidth: '25%' } },
                unauthorized: !hasPermission([PermissionName.CUSTOMER_TASKS_READ]),
                content: (
                  <TaskInputsTab
                    tasks={(getTask.data?.tasks as infinitusai.be.TaskDoc[]) || []}
                    org={appState.org}
                    flexibleTaskInputs={flexibleTaskInputs}
                  />
                ),
              },
              {
                value: 'task-outputs',
                label: 'Task Outputs',
                tabProps: { sx: { minWidth: '25%' } },
                unauthorized: !hasPermission([PermissionName.CUSTOMER_TASKS_READ]),
                content: (
                  <TaskOutputsTab
                    customerTask={customerTaskDoc || null}
                    tasks={(getTask.data?.tasks as infinitusai.be.TaskDoc[]) || []}
                    org={appState.org}
                    taskNotes={getTask.data?.task?.taskNotes || ''}
                  />
                ),
              },
              {
                hidden: appState.org?.disableFetchRecording,
                value: 'task-recording',
                label: (
                  <Box display="flex" alignContent="center" alignItems="center" gap={1}>
                    <Typography variant="body2"> Task Audio Recording </Typography>
                    {showHumanInvolvementFlagEnabled && customerTaskDoc.humanInvolved?.value && (
                      <Tooltip title="Human was involved">
                        <RecordVoiceOverRoundedIcon color="action" fontSize="small" />
                      </Tooltip>
                    )}
                  </Box>
                ),
                unauthorized: !hasPermission([PermissionName.CUSTOMER_TASK_RECORDINGS_READ]),
                tabProps: { sx: { minWidth: '25%' } },
                content: <TaskRecordingTab task={customerTaskDoc || null} />,
              },
              {
                hidden: previousOutputs.length === 0,
                unauthorized: !hasPermission([PermissionName.CUSTOMER_TASKS_READ]),
                value: 'task-previous-outputs',
                label: 'Task Previous Outputs',
                tabProps: { sx: { minWidth: '25%' } },
                content: (
                  <TaskPreviousOutputsTab
                    task={task}
                    previousOutputs={previousOutputs}
                    org={appState.org}
                  />
                ),
              },
              {
                hidden: !(bundledTasksEnabled && bundledCustomerTasks.length > 1),
                unauthorized: !hasPermission([PermissionName.CUSTOMER_TASKS_READ]),
                value: 'bundled-tasks',
                label: 'Bundled Tasks',
                tabProps: { sx: { minWidth: '25%' } },
                content: (
                  <BundledTasksTab
                    orgName={orgName || ''}
                    currentTaskId={tid || ''}
                    bundledCustomerTasks={bundledCustomerTasks}
                  />
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}
export default TaskDetailsPage;
