// Function to convert a string to a color
// This function is used to generate a color based on a string
// If the same string is passed to this function, it will always return the same color
const stringToColorCache: { [key: string]: string } = {};

export const stringToColor = (str: string) => {
  // Check if color is cached
  if (stringToColorCache[str]) {
    return stringToColorCache[str];
  }

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    // Add a minimum value of 0x20 to ensure the color is not too light
    const darkValue = Math.max(value, 0x20) & 0xff;
    color += ('00' + darkValue.toString(16)).slice(-2);
  }

  // Cache color for later retrieval
  stringToColorCache[str] = color;

  return color;
};
