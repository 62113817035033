import Grid from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';

export interface Props {
  label: string;
  value: string | boolean | number | JSX.Element;
  footnote?: string;
  whiteSpace?: 'nowrap' | 'normal';
}

function DataField({
  label,
  value,
  footnote,
  whiteSpace = 'nowrap',
  ...valueProps
}: Props & TypographyProps) {
  return (
    <Grid container direction="column" mb={2}>
      <Grid item>
        <Typography
          variant="overline"
          color="GrayText"
          sx={{
            textTransform: 'uppercase',
            whiteSpace,
            overflow: 'hidden',
            fontSize: '14px',
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6" color="textPrimary" fontSize="1rem" {...valueProps}>
          {value ? value : '-'}
        </Typography>
      </Grid>
      {footnote && (
        <Grid item>
          <Typography variant="caption">{footnote}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default DataField;
