import Grid from '@mui/material/Grid';
import React from 'react';

import Accordion, { AccordionSummary, AccordionDetails } from 'components/Accordion';

interface Props {
  displayName: string;
  data: any[];
  setExpanded: React.Dispatch<React.SetStateAction<Set<string>>>;
  expanded: Set<string>;
  numberOfEntities?: number;
}

function DetailsAccordion({ displayName, numberOfEntities, data, setExpanded, expanded }: Props) {
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    if (newExpanded) {
      setExpanded(new Set(expanded?.add(panel)));
    } else {
      setExpanded((expanded) => {
        const newExpanded = new Set(expanded);
        newExpanded.delete(panel);
        return newExpanded;
      });
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded.has(displayName)}
        onChange={handleChange(displayName)}
        elevation={0}
        disableGutters
        sx={{
          bgcolor: 'transparent',
          borderBottomColor: 'transparent',

          ':before': {
            display: 'none',
          },
          mb: 2,
        }}
      >
        <AccordionSummary title={displayName} entities={numberOfEntities} />

        <AccordionDetails>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 7, md: 9, lg: 10, xl: 12 }}
          >
            {data}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default DetailsAccordion;
