import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getCustomerFacingTaskState } from 'utils';

import StatusChip from 'components/StatusChip';
import { infinitusai } from 'proto/pbjs';
import { getDetailedTaskPageUrl } from 'utils/url';

function getRelatedTasksText(length: number) {
  if (length === 0) {
    return 'There are no related bundled tasks.';
  }
  if (length === 1) {
    return 'There is 1 related bundled task.';
  }
  return `There are ${length} related bundled tasks.`;
}

interface Props {
  orgName: string;
  currentTaskId: string;
  bundledCustomerTasks: infinitusai.be.CustomerTaskDoc[];
}

function BundledTasksTab({ orgName, currentTaskId, bundledCustomerTasks }: Props) {
  const relatedCustomerTasks = bundledCustomerTasks.filter((customerTask) => {
    return customerTask.taskUuid !== currentTaskId;
  });

  return (
    <Box padding={2}>
      <Typography variant="body1" color="textSecondary">
        {getRelatedTasksText(relatedCustomerTasks.length)}
      </Typography>
      <List>
        {relatedCustomerTasks.map((customerTask) => {
          return (
            <ListItem key={customerTask.taskUuid}>
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Typography variant="body2" color="textSecondary">
                  Task:
                </Typography>
                <Link href={getDetailedTaskPageUrl(orgName, customerTask.taskUuid || '')}>
                  <Typography variant="body2">{customerTask.taskUuid}</Typography>
                </Link>
                <StatusChip label={getCustomerFacingTaskState(customerTask) || null} />
              </Stack>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
export default BundledTasksTab;
