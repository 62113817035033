import { useBreakpoint, truncate } from '@infinitusai/ui';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { formatDistance } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCustomerFacingTaskState, getOrgName } from 'utils';

import DataField from 'components/DataField';
import StatusChip from 'components/StatusChip';
import { infinitusai } from 'proto/pbjs';

export interface Props {
  task: infinitusai.be.CustomerTaskDoc;
}

function TaskListItem({ task }: Props) {
  const mdBreakpoint = useBreakpoint('md');
  const navigate = useNavigate();
  const location = useLocation();
  const orgName = getOrgName(location.pathname);

  const customerId = task?.customerAssignedId || '';

  const addedBy = task.taskCreator?.userEmail ? task.taskCreator?.userEmail.split('@')[0] : null;

  const addedAgo = formatDistance(new Date(), new Date(Number(task.taskCreationMillis))) + ' ago ';

  const handleItemClicked = () => {
    navigate(`/${orgName}/tasks/${task.taskUuid}`);
  };

  return (
    <ListItemButton
      sx={{
        borderRadius: 1,
        bgcolor: 'drawer.background',
        border: (theme) => `2px solid ${theme.palette.divider}`,
        borderOpacity: '0.25',
        '&:hover': {
          bgcolor: 'action.hover',
        },
        height: '100%',
      }}
      onClick={handleItemClicked}
    >
      <Grid container direction="column">
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <NorthEastIcon fontSize="medium" />
        </Grid>
        <Grid item container direction="row" spacing={4} pr={2}>
          <Grid item xs={mdBreakpoint ? 4 : 6}>
            <DataField
              sx={{ wordBreak: 'break-word' }}
              label="Customer ID"
              value={customerId}
              whiteSpace="normal"
            />
          </Grid>

          {mdBreakpoint ? (
            <Grid item xs={4}>
              <DataField
                label="Callee Name"
                value={truncate(task.calleeName) || ''}
                whiteSpace="normal"
              />
            </Grid>
          ) : null}
          <Grid item xs={mdBreakpoint ? 4 : 6}>
            <DataField
              label="Status"
              value={<StatusChip label={getCustomerFacingTaskState(task) || null} />}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            Added {addedAgo}{' '}
            {addedBy && (
              <>
                {' by'} <span style={{ textTransform: 'capitalize' }}>{addedBy}</span>
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
    </ListItemButton>
  );
}

export default TaskListItem;
