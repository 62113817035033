import { useAuth, PermissionName } from '@infinitusai/auth';
import { Org, useAppState } from '@infinitusai/shared';
import { Button, TextField, useBreakpoint } from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Form, FormikProvider, Field, useFormik, useFormikContext, FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';

import { useUpdateOrgMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { useNavigationBlocker } from 'utils/useNavigationBlocker';
import { profileValidationSchema } from 'utils/validations';

interface Props {
  org: Org;
}

function CompanyProfileTab({ org }: Props) {
  const auth = useAuth();
  const { orgUuid, updateOrg } = useAppState();
  const smBreakpoint = useBreakpoint('sm');
  const mdBreakpoint = useBreakpoint('md');
  const { enqueueSnackbar } = useSnackbar();
  const updateMutation = useUpdateOrgMutation();

  const handleFormSubmitted: FormikConfig<Org>['onSubmit'] = async (values) => {
    const body = infinitusai.orgs.UpdateOrgRequest.fromObject({
      orgImageUrl: values.imageUrl,
      orgEmailDomains: values.emailDomains,
      orgDisplayName: values.displayName,
      orgSupportEmail: values.supportEmail,
      orgWhitelistedIpAddresses: values.whitelistedIpAddresses,
    });

    try {
      await updateMutation.mutateAsync(body, {
        onSuccess: () => {
          enqueueSnackbar('Settings Updated', { variant: 'success' });
          updateOrg(auth.user, orgUuid);
        },
        onError: (error) => {
          enqueueSnackbar(`Failed to update org settings: ${error.message}.`, {
            variant: 'error',
          });
        },
      });
    } catch (e: any) {
      enqueueSnackbar(`Failed to update org settings: ${e?.response?.data || e.message}`, {
        variant: 'error',
      });
    }
  };

  const handleOnReset = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: org,
    validationSchema: profileValidationSchema,
    onSubmit: handleFormSubmitted,
    onReset: () => org,
  });

  const PromptIfDirty: React.FC = () => {
    const formik = useFormikContext();
    useNavigationBlocker(
      'You are leaving with unsaved changes',
      formik.dirty && formik.submitCount === 0
    );
    return null;
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <PromptIfDirty />
        <Stack
          direction={mdBreakpoint ? 'row' : 'column'}
          gap={mdBreakpoint ? 5 : 3}
          sx={{ mt: 2 }}
        >
          <Stack gap={1}>
            <Typography variant="h6">Company Display Name</Typography>
            <Field name="displayName">
              {({ field, meta }: any) => (
                <div>
                  <TextField
                    debounce
                    InputLabelProps={{ shrink: true }}
                    disabled={formik.isSubmitting}
                    sx={{ width: smBreakpoint ? '450px' : '100%' }}
                    helperText="This will be displayed for all your internal members"
                    size="small"
                    unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_WRITE])}
                    {...field}
                  />
                  {meta.touched && meta.error && (
                    <Typography variant="body2" color="error" sx={{ ml: 2 }}>
                      {meta.error}
                    </Typography>
                  )}
                </div>
              )}
            </Field>
          </Stack>
          <Stack gap={1}>
            <Typography variant="h6"> Supported Email Address</Typography>
            <Field name="supportEmail">
              {({ field, form, meta }: any) => (
                <div>
                  <TextField
                    debounce
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    disabled={formik.isSubmitting}
                    sx={{ width: smBreakpoint ? '450px' : '100%' }}
                    helperText="Email notifications (such as items that need your review) will be sent here."
                    unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_WRITE])}
                    {...field}
                  />
                  {meta.touched && meta.error && (
                    <Typography variant="body2" color="error" sx={{ ml: 2 }}>
                      {meta.error}
                    </Typography>
                  )}
                </div>
              )}
            </Field>
          </Stack>
        </Stack>
        <Stack gap={1} mt={2}>
          <Typography variant="h6">Company Logo</Typography>
          <Field name="imageUrl">
            {({ field, meta }: any) => (
              <div>
                <TextField
                  debounce
                  disabled={formik.isSubmitting}
                  sx={{ width: mdBreakpoint ? '940px' : '100%' }}
                  helperText="Please only use a svg or png image. We suggest using a greyscale logo with a transparent background."
                  size="small"
                  unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_WRITE])}
                  {...field}
                />
                {meta.touched && meta.error && (
                  <Typography variant="body2" color="error" sx={{ ml: 2 }}>
                    {meta.error}
                  </Typography>
                )}
              </div>
            )}
          </Field>
        </Stack>
        <Stack
          direction={mdBreakpoint ? 'row' : 'column'}
          gap={mdBreakpoint ? 5 : 3}
          sx={{ mt: 2 }}
        >
          <Stack gap={1} sx={{ width: smBreakpoint ? '450px' : '100%' }}>
            <Typography variant="h6">Light Background Preview</Typography>
            {formik.values.imageUrl ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '200px',
                  border: '2px solid rgba(0, 0, 0, 0.12)',
                  bgcolor: '#fdfdfd',
                  borderRadius: '8px',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    my: 4,
                    width: '120px',
                  }}
                  alt="organization logo"
                  src={formik.values.imageUrl}
                />
              </Box>
            ) : null}
          </Stack>
          <Stack gap={1} sx={{ width: smBreakpoint ? '450px' : '100%' }}>
            <Typography variant="h6">Dark Background Preview</Typography>
            {formik.values.imageUrl ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '200px',
                  background: '#151515',
                  border: '1px solid #FFFFFF',
                  borderRadius: '8px',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    my: 4,
                    width: '120px',
                  }}
                  alt="organization logo"
                  src={formik.values.imageUrl}
                />
              </Box>
            ) : null}
          </Stack>
        </Stack>
        {formik.dirty &&
          (org.displayName !== formik.values.displayName ||
            org.supportEmail !== formik.values.supportEmail ||
            org.imageUrl !== formik.values.imageUrl) && (
            <Box sx={{ mt: 5 }}>
              <Box display="flex" gap={2}>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                  unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_WRITE])}
                  disabled={formik.isSubmitting || updateMutation.isLoading}
                >
                  Save Changes
                </Button>
                <Button color="primary" size="large" variant="outlined" onClick={handleOnReset}>
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
      </Form>
    </FormikProvider>
  );
}
export default CompanyProfileTab;
