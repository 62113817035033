import { Table, useTable, DateField } from '@infinitusai/table';
import { Chips } from '@infinitusai/ui';
import { isTimestampExpired, possibleLongToNumber } from '@infinitusai/utils';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetOrgInvites } from 'api/auth';
import { infinitusai } from 'proto/pbjs';
import { getInviteStateDisplayName } from 'utils/displayNames';

import InviteDrawer from './InviteDrawer';
import InviteMenu from './InviteMenu';

function InvitesTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const getOrgInvites = useGetOrgInvites();
  const showInviteUuid = searchParams.get('show') || '';

  const handleInviteDrawerClose = () => {
    searchParams.delete('show');
    setSearchParams(searchParams, { replace: true });
  };

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.auth.Invite>();
    return [
      columnHelper.accessor('inviteeEmail', {
        header: 'Email Invited',
      }),
      columnHelper.accessor('inviterEmail', {
        header: 'Invited By',
      }),
      columnHelper.accessor((invite) => invite.roleNames.join(','), {
        id: 'roleNames',
        header: 'Role(s)',
        enableSorting: false,
        cell: (info) => <Chips labelSize={25} labels={info.getValue().split(',')} />,
      }),
      columnHelper.accessor((invite) => new Date(possibleLongToNumber(invite.updateTimeMillis)), {
        id: 'updateTimeMillis',
        header: 'Invite Sent',
        cell: (info) => <DateField date={info.getValue()} />,
      }),
      columnHelper.accessor(
        (invite) =>
          isTimestampExpired(invite.updateTimeMillis, 7)
            ? 'Expired'
            : getInviteStateDisplayName(invite.state),
        {
          id: 'state',
          header: 'Invite Status',
          enableSorting: false,
          cell: (info) => (
            <Chip
              label={info.getValue()}
              color={
                isTimestampExpired(info.row.original.updateTimeMillis, 7)
                  ? 'error'
                  : info.row.original.state === infinitusai.auth.InviteState.INVITE_STATE_PENDING
                  ? 'warning'
                  : 'default'
              }
            />
          ),
        }
      ),
      columnHelper.display({
        id: 'menu',
        cell: (info) => (
          <Stack display="flex">
            <InviteMenu
              invite={info.row.original}
              onUpdate={(inviteUuid) => info.table.options.meta?.highlightRow?.(inviteUuid)}
            />
          </Stack>
        ),
      }),
    ];
  }, []);

  const table = useTable({
    id: 'invites',
    data: getOrgInvites.data,
    columns,
    isLoading: getOrgInvites.isLoading,
    totalRows: getOrgInvites.data.length,
    noRowsMessage: 'No invites found.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableGlobalFilter: true,
    enableUrlSync: true,
    getRowId: (row) => row.uuid,
  });

  return (
    <React.Fragment>
      <Box sx={{ mt: 2 }}>
        <Table table={table} />
      </Box>
      <InviteDrawer
        open={showInviteUuid !== ''}
        invite={getOrgInvites.data.find((invite) => invite.uuid === showInviteUuid)}
        onClose={handleInviteDrawerClose}
        onUpdate={(inviteUuid) => table.options.meta?.highlightRow?.(inviteUuid)}
      />
    </React.Fragment>
  );
}
export default InvitesTab;
