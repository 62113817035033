import { ErrorType } from '@infinitusai/api';
import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  useAppState,
  ErrorMessage,
  Heartbeat,
  Page,
  NotFoundPage,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
} from '@infinitusai/shared';
import * as React from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';

import { useGetMyAnnouncements } from 'api/customer';
import AnnouncementsDialog from 'components/AnnouncementsDialog';
import { resetTaskImportCache } from 'utils/localStorage';

import AccountPage from './account';
import ApiKeysPage from './account/api';
import EulaPage from './account/eula/eula';
import RolesPage from './account/roles';
import OrgSettingsPage from './account/settings';
import TeamsMembersPage from './account/teams-members';
import BillingPage from './billing';
import DocsPage from './docs';
import DocsApiPage from './docs/ApiDocs';
import DocsPayersPage from './docs/supported-payers';
import DocsPayersPageBeta from './docs/supported-payers-beta';
import FieldsReferencePage from './fields-reference';
import HomePage from './home';
import PayerIntelligencePage from './payer_intel';
import ImportRulesPage from './payer_intel/import';
import ProfilePage from './profile';
import TasksPage from './tasks';
import AllTasksPage from './tasks/all-tasks';
import CreateTasksPage from './tasks/create-tasks';
import ReportsPage from './tasks/reports';
import TaskDetailsPage from './tasks/task-details';

function PortalPage() {
  const { org: orgName } = useParams();
  const [error, setError] = React.useState<Error | null>(null);
  const { user, orgs, updatePermissions, hasPermission } = useAuth();
  const [orgLoading, setOrgLoading] = React.useState(true);
  const getMyAnnouncements = useGetMyAnnouncements();
  const { org, updateOrg, setOrgUuid, setOrgName, setIsLoading } = useAppState();
  const orgUuid = orgs?.find((org) => org.name === orgName)?.uuid;
  const billingEnabled = hasPermission([PermissionName.CUSTOMER_BILLING_READ]);
  const reportsEnabled = hasPermission([
    PermissionName.CUSTOMER_TASK_REPORTS_READ,
    PermissionName.CUSTOMER_TASK_REPORTS_WRITE,
  ]);
  const payerIntelligenceEnabled = hasPermission([
    PermissionName.CUSTOMER_PIE_READ,
    PermissionName.CUSTOMER_PIE_WRITE,
  ]);

  const isGenentechOrg = React.useMemo(
    () =>
      org?.displayName.includes('Genentech') ||
      org?.name.includes('gne') ||
      org?.name.includes('genentech'),
    [org?.displayName, org?.name]
  );

  const hasFastTrackFeature = useCustomerPortalFeatureEnabled(CustomerPortalFeature.IVR_AND_HOLD);
  const hasTeamMgmtFeature = useCustomerPortalFeatureEnabled(CustomerPortalFeature.TEAM_MANAGEMENT);

  const showAnnouncement =
    getMyAnnouncements.data.length !== 0 && !isGenentechOrg && !hasFastTrackFeature;

  // Save org info
  React.useEffect(() => {
    if (orgUuid && orgName) {
      setIsLoading(true);
      setOrgLoading(true);
      setOrgUuid(orgUuid);
      setOrgName(orgName);
      Promise.all([updateOrg(user, orgUuid), updatePermissions(user, orgUuid)])
        .then(() => {
          setError(null);
          setIsLoading(false);
          setOrgLoading(false);
        })
        .catch((error) => {
          setError(typeof error === 'string' ? new Error(error) : error);
          setIsLoading(false);
          setOrgLoading(false);
        });
    } else {
      setError(null);
      setIsLoading(false);
      setOrgLoading(false);
    }
  }, [user, orgUuid, orgName, setOrgUuid, setOrgName, setIsLoading, updateOrg, updatePermissions]);

  React.useEffect(() => {
    return () => {
      resetTaskImportCache();
    };
  }, []);

  if (orgLoading) return null;

  if (error) return <ErrorMessage showUserInfo entity="page" errorType={error.name} />;

  if (!org) return <ErrorMessage showUserInfo entity="page" errorType={ErrorType.UNAUTHORIZED} />;

  const steps = [
    {
      src: `${process.env.PUBLIC_URL}/assets/blue_gradient.png`,
      label: 'Welcome!',
      description: `The portal has a fresh coat of paint. See what's new...`,
    },
    {
      src: `${process.env.PUBLIC_URL}/assets/navigation.png`,
      label: 'Reorganized Navigation',
      description: `Improved navigation makes it easier to get to where you need to go.`,
    },
    {
      src: `${process.env.PUBLIC_URL}/assets/feedback_gif.gif`,
      label: "Tell us how we're doing",
      description: `Request features, report bugs, and get help with our new feedback tool.`,
    },
  ];

  const links = [
    {
      name: 'Before you go!',
      description: 'Take a tour of all the updates in this step-by-step guide.',
      url: 'https://info.infinitus.ai/whats-new',
      linkButtonText: 'Learn More',
    },
  ];

  return (
    <React.Fragment>
      <Heartbeat />
      {showAnnouncement ? (
        <AnnouncementsDialog
          steps={steps}
          links={links}
          announcement={getMyAnnouncements.data[0]}
          open={true}
        />
      ) : null}
      <Routes>
        <Route index element={<Navigate to="home" />} />
        <Route path="dashboard" element={<Navigate to={`/${org.name}/home`} />} />
        <Route
          path="profile"
          element={
            <Page title="My Profile">
              <ProfilePage />
            </Page>
          }
        />
        <Route
          path="home"
          element={
            <Page title="Home">
              <HomePage />
            </Page>
          }
        />
        <Route path="tasks" element={<TasksPage />}>
          <Route index element={<Navigate to="all-tasks" />} />
          <Route
            path="all-tasks"
            element={
              <Page
                title="All Tasks"
                unauthorized={!hasPermission([PermissionName.CUSTOMER_TASKS_READ])}
              >
                <AllTasksPage />
              </Page>
            }
          />
          <Route
            path="new"
            element={
              <Page
                title="Create Tasks"
                sx={{ p: 0 }}
                unauthorized={!hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_READ])}
              >
                <CreateTasksPage />
              </Page>
            }
          />
          <Route
            path="reports"
            element={
              <Page
                title="Reports"
                hidden={!reportsEnabled}
                unauthorized={!hasPermission([PermissionName.CUSTOMER_TASK_REPORTS_READ])}
              >
                <ReportsPage />
              </Page>
            }
          />
          <Route
            path="/tasks/:tid"
            element={
              <Page
                sx={{ p: 0 }}
                title="Task"
                unauthorized={!hasPermission([PermissionName.CUSTOMER_TASKS_READ])}
              >
                <TaskDetailsPage />
              </Page>
            }
          />
        </Route>
        <Route
          path="billing"
          element={
            <Page
              title="Billing"
              hidden={!billingEnabled}
              unauthorized={!hasPermission([PermissionName.CUSTOMER_BILLING_READ])}
            >
              <BillingPage />
            </Page>
          }
        />
        <Route
          path="eula"
          element={
            <Page sx={{ p: 0 }} title="EULA" unauthorized={!hasFastTrackFeature}>
              <EulaPage />
            </Page>
          }
        />
        <Route path="account" element={<AccountPage />}>
          <Route index element={<Navigate to="settings" />} />
          <Route
            path="settings"
            element={
              <Page
                title="Settings"
                unauthorized={!hasPermission([PermissionName.CUSTOMER_SETTINGS_READ])}
              >
                <OrgSettingsPage />
              </Page>
            }
          />
          <Route
            path="members"
            element={
              <Page
                title={hasTeamMgmtFeature ? 'Teams & Members' : 'Members'}
                unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_READ])}
              >
                <TeamsMembersPage />
              </Page>
            }
          />
          <Route
            path="roles"
            element={
              <Page
                title="Roles"
                unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_READ])}
              >
                <RolesPage />
              </Page>
            }
          />
          <Route
            path="api"
            element={
              <Page
                title="API Keys & Webhooks"
                unauthorized={!hasPermission([PermissionName.CUSTOMER_API_KEYS_READ])}
              >
                <ApiKeysPage />
              </Page>
            }
          />
        </Route>
        <Route path="docs" element={<DocsPage />}>
          <Route index element={<Navigate to="api" />} />
          <Route
            path="api"
            element={
              <Page
                title="Docs"
                sx={{ p: 0 }}
                unauthorized={!hasPermission([PermissionName.CUSTOMER_DOCS_READ])}
              >
                <DocsApiPage />
              </Page>
            }
          />
          <Route
            path="supported-payors"
            element={
              <Page
                title="Supported Payors"
                unauthorized={!hasPermission([PermissionName.CUSTOMER_PAYORS_READ])}
              >
                <DocsPayersPage />
              </Page>
            }
          />
          {/* [TL-511] Experimental page until we are ready to replace it the original <DocsPayersPage /> above */}
          <Route
            path="supported-payors-beta"
            element={
              <Page
                title="Supported Payors [Beta]"
                unauthorized={!hasPermission([PermissionName.CUSTOMER_PAYORS_READ])}
              >
                <DocsPayersPageBeta />
              </Page>
            }
          />
        </Route>
        <Route
          path="account/payer-intelligence"
          element={
            <Page
              title="Payor Intelligence"
              hidden={!payerIntelligenceEnabled}
              unauthorized={!hasPermission([PermissionName.CUSTOMER_PIE_READ])}
            >
              <PayerIntelligencePage />
            </Page>
          }
        />
        <Route
          path="/account/payer-intelligence/import"
          element={
            <Page
              title="Import Rules"
              unauthorized={!hasPermission([PermissionName.CUSTOMER_PIE_READ])}
            >
              <ImportRulesPage />
            </Page>
          }
        />
        <Route
          path="/fields-reference"
          element={
            <Page
              title="Fields Reference"
              unauthorized={!hasPermission([PermissionName.CUSTOMER_TASKS_READ])}
            >
              <FieldsReferencePage />
            </Page>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </React.Fragment>
  );
}

export default PortalPage;
