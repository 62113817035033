import { useCustomerPortalFeatureEnabled, CustomerPortalFeature, Org } from '@infinitusai/shared';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { infinitusai, infinitusapi } from 'proto/pbjs';
import { getTaskTypeDisplayName } from 'utils/displayNames';

import OutputsPanel from './OutputsPanel';
import { getTaskOutputsMap, getPreviousBvData } from './constants';

function extractPreviousOutputDate(
  task: infinitusai.be.GetTaskResponse['task'],
  infTaskType: infinitusapi.INFTaskType
) {
  // TODO[TL-611]
  // Unfortunately, we are currently not using proto types correctly.
  // We should probably convert the API data into the proto format
  // in useGetTask() function so that we use consistent typing in the application.
  // Use this temporary code for now. Will address typing issues in another PR.

  if (typeof infTaskType === 'string') {
    if (infTaskType === 'INF_TASK_TYPE_BENEFITS_VERIFICATION') {
      return task?.previousBvDate || '';
    }
    if (infTaskType === 'INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER') {
      return task?.previousPbmDate || '';
    }
  }
  return '';
}

interface Props {
  task: infinitusai.be.GetTaskResponse['task'];
  org: Org | null;
  previousOutputs: infinitusai.be.GetTaskResponse.PreviousOutputs[];
}

function TaskPreviousOutputsTab({ task, org, previousOutputs }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const showOutput = searchParams.get('showOutput') || '0';
  const data = previousOutputs[Number(showOutput)];
  const billingNotesEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.BILLING_NOTES);
  const output = getPreviousBvData(data);
  const taskOutputsMap = getTaskOutputsMap(data, billingNotesEnabled);

  return (
    <React.Fragment>
      <ToggleButtonGroup
        exclusive
        aria-label="show"
        value={showOutput}
        onChange={(_, newValue) => {
          if (newValue) {
            searchParams.set('showOutput', String(newValue));
            setSearchParams(searchParams, { replace: true });
          }
        }}
        sx={{ mt: 2 }}
      >
        {previousOutputs.map((taskResult, index) => {
          const taskTypeDisplayName = getTaskTypeDisplayName(
            taskResult.taskType || infinitusai.tasks.INFTaskType.INF_TASK_TYPE_UNKNOWN
          );
          // [TL-517] Customer can send more than one previous outputs with different timestamps.
          // However, it's rare for customers to send more than one previous outputs.
          // As such, BE makes simplification and assumes one timestamp for all previous outputs.
          // Therefore, we show one timestamp for all previous outputs for now.
          const previousOutputDate = extractPreviousOutputDate(task, taskResult.taskType);
          let buttonContent = taskTypeDisplayName;
          if (previousOutputDate) {
            buttonContent += ` (${previousOutputDate})`;
          }
          return (
            <ToggleButton value={String(index)} key={taskTypeDisplayName}>
              {buttonContent}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <Grid container>
        <Grid item xs={12}>
          <OutputsPanel outputMap={taskOutputsMap} output={output} org={org} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default TaskPreviousOutputsTab;
