import { ErrorMessage, Loading } from '@infinitusai/shared';
import { isTimestampExpired, capitalizeStringFirstLetter } from '@infinitusai/utils';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { useGetInvite, useAcceptInvite } from 'api/auth';
import { infinitusai } from 'proto/pbjs';
import { BETA_VERSION } from 'utils/constants';

function InvitePage() {
  const { uuid } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const getInvite = useGetInvite(uuid || '');
  const { mutate: acceptInvite } = useAcceptInvite();
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    if (
      getInvite.data &&
      getInvite.data?.invite.state === infinitusai.auth.InviteState.INVITE_STATE_PENDING &&
      !isTimestampExpired(getInvite.data.invite.updateTimeMillis, 7)
    ) {
      const orgName = getInvite.data.orgName;
      const req = infinitusai.auth.AcceptInviteRequest.fromObject({
        uuid: getInvite.data.invite.uuid,
      });
      acceptInvite(req, {
        onSuccess: () => {
          window.location.href = `${BETA_VERSION ? '/beta' : ''}/${orgName}`;
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  }, [getInvite.data, acceptInvite, enqueueSnackbar]);

  if (getInvite.isLoading) return <Loading label="Fetching invite..." />;

  if (
    getInvite.data &&
    getInvite.data?.invite.state !== infinitusai.auth.InviteState.INVITE_STATE_ACCEPTED &&
    isTimestampExpired(getInvite.data.invite.updateTimeMillis, 7)
  ) {
    return (
      <ErrorMessage
        showUserInfo
        title="Invite Expired"
        description="This invite has expired. Please have your org admin resend it."
      />
    );
  }

  if (
    getInvite.data &&
    getInvite.data?.invite.state !== infinitusai.auth.InviteState.INVITE_STATE_ACCEPTED &&
    !error
  ) {
    return <Loading label="Accepting invite..." />;
  } else if (
    getInvite.data &&
    getInvite.data?.invite.state === infinitusai.auth.InviteState.INVITE_STATE_ACCEPTED
  ) {
    const orgName = getInvite.data?.orgName;
    return <Navigate to={`/${orgName}/dashboard`} />;
  }
  return (
    <ErrorMessage
      showUserInfo
      entity="invite"
      errorType={getInvite.error?.name || error?.name}
      description={error ? `${capitalizeStringFirstLetter(error?.message)}.` : undefined}
    />
  );
}

export default InvitePage;
