import { useAuth, PermissionName } from '@infinitusai/auth';
import { PageHeader, Tabs } from '@infinitusai/shared';
import { Button, useBreakpoint } from '@infinitusai/ui';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetWebhooks } from 'api/customer';

import ApiKeyDrawer from './ApiKeyDrawer';
import ApiKeysTab from './ApiKeysTab';
import WebhookDrawer from './WebhookDrawer';
import WebhooksTab from './WebhooksTab';

function ApiKeysPage() {
  const auth = useAuth();
  const smBreakpoint = useBreakpoint('sm');
  const [showNewApiKeyDrawer, setShowNewApiKeyDrawer] = React.useState(false);
  const [showNewWebhookDrawer, setShowNewWebhookDrawer] = React.useState(false);
  const [searchParams] = useSearchParams();
  const tabs: any = { api_keys: 'API Keys', webhooks: 'Webhooks' };
  const tab = searchParams.get('tab') || 'api_keys';
  const webhooksQuery = useGetWebhooks();

  return (
    <React.Fragment>
      <PageHeader title={tabs[tab]} variant="h5">
        <Box
          flexGrow={1}
          display={smBreakpoint ? 'flex' : ''}
          justifyContent={smBreakpoint ? 'flex-end' : ''}
          width="100%"
        >
          {tab === 'api_keys' ? (
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth={!smBreakpoint}
              unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
              onClick={() => setShowNewApiKeyDrawer(true)}
              startIcon={<AddRoundedIcon />}
            >
              Add API Key
            </Button>
          ) : tab === 'webhooks' ? (
            <Button
              color="primary"
              fullWidth={!smBreakpoint}
              variant="contained"
              size="large"
              onClick={() => setShowNewWebhookDrawer(true)}
              disabled={webhooksQuery?.data?.length === 1}
              unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
              startIcon={<AddRoundedIcon />}
            >
              Add Webhook
            </Button>
          ) : null}
        </Box>
      </PageHeader>

      <Tabs
        sx={{ mb: 2 }}
        id="API Keys & Webhooks"
        syncUrl
        tabs={[
          {
            value: 'api_keys',
            label: 'API Keys',
            content: <ApiKeysTab />,
            unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_READ]),
            tabProps: !smBreakpoint ? { sx: { minWidth: '50%' } } : undefined,
          },
          {
            value: 'webhooks',
            label: 'Webhooks',
            content: <WebhooksTab />,
            unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_READ]),
            tabProps: !smBreakpoint ? { sx: { minWidth: '50%' } } : undefined,
          },
        ]}
      />
      <ApiKeyDrawer open={showNewApiKeyDrawer} onClose={() => setShowNewApiKeyDrawer(false)} />
      <WebhookDrawer open={showNewWebhookDrawer} onClose={() => setShowNewWebhookDrawer(false)} />
    </React.Fragment>
  );
}

export default ApiKeysPage;
