import { useBreakpoint } from '@infinitusai/ui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
  entities?: number;
  children?: React.ReactNode;
}

function AccordionSummary({ title, entities, children }: Props) {
  const smBreakpoint = useBreakpoint('sm');
  return (
    <MuiAccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        m: 0,
        bgcolor: 'background.paper',
        borderRadius: '8px',
      }}
      expandIcon={<ExpandMoreIcon fontSize="medium" />}
    >
      <Typography
        fontSize={smBreakpoint ? '1.25rem' : '1rem'}
        fontWeight={700}
        letterSpacing="0.25px"
        display="flex"
        alignItems="center"
        mr={1}
        mb="2px"
      >
        {title}
      </Typography>
      <Typography
        display="flex"
        fontWeight={900}
        alignItems="center"
        color="GrayText"
        whiteSpace="nowrap"
      >
        {entities ? `${entities} ${entities === 1 ? 'Entity' : 'Entities'}` : null}
      </Typography>
      {children}
    </MuiAccordionSummary>
  );
}
export default AccordionSummary;
