import { useAuth, PermissionName } from '@infinitusai/auth';
import { Button, useConfirm } from '@infinitusai/ui';
import { useSnackbar } from 'notistack';

import { useDisableApiKeyMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

interface Props {
  keyMetadata: infinitusai.be.ApiKeyDoc;
}

function DeleteButton({ keyMetadata }: Props) {
  const auth = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const disableApiKey = useDisableApiKeyMutation();

  const handleDisableApiKey = () => {
    const req = infinitusai.be.DisableApiKeyRequest.fromObject({
      keyId: keyMetadata?.keyId,
    });
    disableApiKey.mutate(req, {
      onSuccess: () => {
        enqueueSnackbar(`Successfully deleted API key: ${keyMetadata?.keyName}`, {
          variant: 'success',
        });
      },
    });
  };

  return (
    <Button
      size="small"
      color="error"
      variant="text"
      sx={{
        mx: 1,
      }}
      unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
      onClick={() =>
        confirm({
          title: 'Delete API Key',
          description: `Are you sure you want to delete the following API key: ${keyMetadata?.keyName}?`,
          confirmText: 'Yes, Delete',
          onConfirm: handleDisableApiKey,
        })
      }
    >
      Delete
    </Button>
  );
}

export default DeleteButton;
