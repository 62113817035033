import { AppName } from '@infinitusai/api';
import { useAuth, AuthRequired } from '@infinitusai/auth';
import {
  App as InfApp,
  Page,
  LandingPage,
  SigninPage,
  SignoutPage,
  DefaultSigninPage,
  SamlSigninPage,
  NotFoundPage,
  useAppState,
  ErrorBoundary,
} from '@infinitusai/shared';
import { ConfirmProvider } from '@infinitusai/ui';
import * as React from 'react';
import { Routes, Route, Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { Layout, Basename } from 'layout';
import Authorized from 'pages/fhir/authorized';
import TaskDetails from 'pages/fhir/authorized/TaskDetails';
import InvitePage from 'pages/invite';
import PortalPage from 'pages/portal';
import { BETA_VERSION, IS_DEV_ENV } from 'utils/constants';

process.env.REACT_APP_BUILD_VERSION &&
  console.log(
    `Loading Infinitus Customer Portal with version '${process.env.REACT_APP_BUILD_VERSION}'`
  );

function App() {
  const auth = useAuth();
  const appState = useAppState();
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [usePopupSignin, setUsePopupSignin] = React.useState(false);
  const [searchParamsLoading, setSearchParamsLoading] = React.useState(true);

  // approvedAuthProviders: the approved auth providers in the query string
  const approvedAuthProviders = searchParams.get('ap');

  // If there are approved auth providers in the query string,
  // we will add them at the end of the query string after URI encoding for the signin page
  const additionalQueryStr4AP = approvedAuthProviders
    ? `ap=${encodeURIComponent(approvedAuthProviders)}`
    : '';

  React.useEffect(() => {
    const popupSignin = searchParams.get('popupSignin') === 'true';
    if (popupSignin) {
      setUsePopupSignin(true);
      searchParams.delete('popupSignin');
      setSearchParams(searchParams);
    }
    // TODO: fix auth providers url param
    setSearchParamsLoading(false);
  }, [searchParams, setSearchParams]);

  if (searchParamsLoading) {
    return null;
  }

  return (
    <InfApp appName={AppName.CUSTOMER}>
      <ErrorBoundary>
        <ConfirmProvider>
          <Routes>
            {!BETA_VERSION ? <Route path="/beta" element={<Navigate to="/" />} /> : null}
            <Route path="/signin" element={<Navigate to={`/login${search}`} />} />
            <Route path="/signout" element={<Navigate to={`/logout${search}`} />} />
            <Route path="/signin/saml" element={<Navigate to={`/login/saml${search}`} />} />
            <Route path="/integration/landing-page" element={<Authorized />} />
            <Route path="/integration/task-details" element={<TaskDetails />} />
            <Route
              path="/docs"
              element={<Navigate to={appState.orgName ? `/${appState.orgName}/docs` : '/'} />}
            />
            {/* <SigninPage> will be open when there are no additional path segments or query parameters */}
            <Route
              path="/login"
              element={
                <Page title="Sign In" sx={{ p: 0 }}>
                  <SigninPage />
                </Page>
              }
            >
              {/* <DefaultSigninPage> will be open when there are additional path segments or query parameters */}
              <Route
                index
                element={<DefaultSigninPage popupSignIn={usePopupSignin || IS_DEV_ENV} />}
              />
              <Route
                path="saml"
                element={<SamlSigninPage popupSignIn={usePopupSignin || IS_DEV_ENV} />}
              />
            </Route>
            <Route
              path="/logout"
              element={
                <Page title="Sign Out">
                  <SignoutPage />
                </Page>
              }
            />
            <Route
              path="/"
              element={
                <AuthRequired
                  auth={auth}
                  unauthorized={
                    <Navigate
                      replace
                      to={`/login?${additionalQueryStr4AP}&from=${pathname}${search}`}
                    />
                  }
                >
                  <Layout basename={BETA_VERSION ? Basename.Beta : Basename.Default} />
                </AuthRequired>
              }
            >
              <Route
                index
                element={
                  appState.orgName ? <Navigate to={`/${appState.orgName}`} /> : <LandingPage />
                }
              />
              <Route
                path="invite/:uuid"
                element={
                  <Page title="Invite" sx={{ p: 0 }}>
                    <InvitePage />
                  </Page>
                }
              />
              <Route path=":org/*" element={<PortalPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </ConfirmProvider>
      </ErrorBoundary>
    </InfApp>
  );
}

export default App;
