import { fetchApi } from '@infinitusai/api';
import { useApiParams } from '@infinitusai/shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import { infinitusai } from 'proto/pbjs';
import { BETA_VERSION } from 'utils/constants';

export function useGetMyAuthConfig() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetMyAuthConfigRequest.fromObject({});
  const getMyAuthConfig = useQuery<infinitusai.auth.GetMyAuthConfigResponse, Error>([
    orgUuid,
    'auth',
    'getMyAuthConfig',
    reqBody,
  ]);

  const data = React.useMemo(
    () =>
      getMyAuthConfig.data?.authConfig
        ? infinitusai.auth.AuthConfig.fromObject(getMyAuthConfig.data.authConfig)
        : null,
    [getMyAuthConfig.data]
  );

  return { ...getMyAuthConfig, data };
}

export function useGetOrgRoles() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetOrgRolesRequest.fromObject({
    types: [infinitusai.auth.RoleType.ROLE_TYPE_CUSTOMER],
  });
  const getOrgRoles = useQuery<infinitusai.auth.GetOrgRolesResponse, Error>([
    orgUuid,
    'auth',
    'getOrgRoles',
    reqBody,
  ]);

  const data = React.useMemo(
    () =>
      getOrgRoles.data
        ? getOrgRoles.data.roles.map((orgRole) => infinitusai.auth.Role.fromObject(orgRole))
        : [],
    [getOrgRoles.data]
  );

  return { ...getOrgRoles, data };
}

export function useGetUserRoles(userUid: string) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetUserRolesRequest.fromObject({
    userUid,
    types: [infinitusai.auth.RoleType.ROLE_TYPE_CUSTOMER],
  });

  const getUserRoles = useQuery<infinitusai.auth.GetUserRolesResponse, Error>(
    [orgUuid, 'auth', 'getUserRoles', reqBody],
    {
      enabled: userUid !== '',
    }
  );

  const data = React.useMemo(
    () =>
      getUserRoles.data
        ? getUserRoles.data?.roles.map((orgRole) => infinitusai.auth.Role.fromObject(orgRole))
        : [],
    [getUserRoles.data]
  );

  return { ...getUserRoles, data };
}

export function useGetRoleUsers(roleUuid: string) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetRoleUsersRequest.fromObject({
    roleUuid,
    types: [infinitusai.auth.UserType.USER_TYPE_CUSTOMER],
    limit: 3,
  });

  const getRoleUsers = useQuery<infinitusai.auth.GetRoleUsersResponse, Error>(
    [orgUuid, 'auth', 'getRoleUsers', reqBody],
    {
      enabled: roleUuid !== '',
    }
  );

  const data = React.useMemo(
    () =>
      getRoleUsers.data
        ? getRoleUsers.data.users.map((user) => infinitusai.auth.User.fromObject(user))
        : [],
    [getRoleUsers.data]
  );

  return { ...getRoleUsers, data };
}

export function useGetPermissions() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetOrgPermissionsRequest.fromObject({
    types: [infinitusai.auth.RoleType.ROLE_TYPE_CUSTOMER],
  });
  const getPermissions = useQuery<infinitusai.auth.GetOrgPermissionsResponse, Error>(
    [orgUuid, 'auth', 'getOrgPermissions', { ...reqBody, readOnly: false }],
    {
      cacheTime: Infinity,
    }
  );

  const data = React.useMemo(
    () =>
      getPermissions.data
        ? getPermissions.data.permissions.map((permission) =>
            infinitusai.auth.Permission.fromObject(permission)
          )
        : [],
    [getPermissions.data]
  );

  return { ...getPermissions, data };
}

export function useGetRolePermissions(roleUuid: string) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetRolePermissionsRequest.fromObject({
    roleUuid,
  });
  const getRolePermissions = useQuery<infinitusai.auth.GetRolePermissionsResponse, Error>(
    [orgUuid, 'auth', 'getRolePermissions', reqBody],
    {
      cacheTime: Infinity,
      enabled: roleUuid !== '',
    }
  );

  const data = React.useMemo(
    () =>
      getRolePermissions.data
        ? getRolePermissions.data.permissions.map((permission) =>
            infinitusai.auth.Permission.fromObject(permission)
          )
        : [],
    [getRolePermissions.data]
  );

  return { ...getRolePermissions, data };
}

export function useCreateRole() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const createRole = useMutation<
    infinitusai.auth.CreateRoleResponse,
    Error,
    infinitusai.auth.CreateRoleRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/auth/createRole', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgRoles']);
    },
  });

  return createRole;
}

export function useUpdateRole() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const updateRole = useMutation<
    infinitusai.auth.UpdateRoleResponse,
    Error,
    infinitusai.auth.UpdateRoleRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/auth/updateRole', body: req }), {
    onSuccess: (res) => {
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgRoles']);
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgUsers']);
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgInvites']);
    },
  });

  return updateRole;
}

export function useAddPermissionToRole() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const addPermissionToRole = useMutation<
    infinitusai.auth.AddPermissionToRoleResponse,
    Error,
    infinitusai.auth.AddPermissionToRoleRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/auth/addPermissionToRole', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'auth', 'getRolePermissions']);
    },
  });

  return addPermissionToRole;
}

export function useRemovePermissionFromRole() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const removePermissionFromRole = useMutation<
    infinitusai.auth.RemovePermissionFromRoleResponse,
    Error,
    infinitusai.auth.RemovePermissionFromRoleRequest
  >(
    (req) =>
      fetchApi({
        appName,
        user,
        orgUuid,
        path: '/auth/removePermissionFromRole',
        body: req,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'auth', 'getRolePermissions']);
      },
    }
  );

  return removePermissionFromRole;
}

export function useDeleteRole() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const deleteRole = useMutation<
    infinitusai.auth.DeleteRoleResponse,
    Error,
    infinitusai.auth.DeleteRoleRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/auth/deleteRole', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgRoles']);
    },
  });

  return deleteRole;
}

export function useGetOrgUsers() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetOrgUsersRequest.fromObject({
    types: [infinitusai.auth.UserType.USER_TYPE_CUSTOMER],
  });
  const getOrgUsers = useQuery<infinitusai.auth.GetOrgUsersResponse, Error>([
    orgUuid,
    'auth',
    'getOrgUsers',
    reqBody,
  ]);

  const data = React.useMemo(
    () =>
      getOrgUsers.data
        ? getOrgUsers.data.users.map((user) => infinitusai.auth.User.fromObject(user))
        : [],
    [getOrgUsers.data]
  );

  return { ...getOrgUsers, data };
}

export function useAddRoleToUser() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const addRoleToUser = useMutation<
    infinitusai.auth.AddRoleToUserResponse,
    Error,
    infinitusai.auth.AddRoleToUserRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/auth/addRoleToUser', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'auth', 'getUserRoles']);
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgUsers']);
    },
  });

  return addRoleToUser;
}

export function useRemoveRoleFromUser() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const removeRoleFromUser = useMutation<
    infinitusai.auth.RemoveRoleFromUserResponse,
    Error,
    infinitusai.auth.RemoveRoleFromUserRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/auth/removeRoleFromUser', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'auth', 'getUserRoles']);
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgUsers']);
    },
  });

  return removeRoleFromUser;
}

export function useGetUserPermissions(userUid: string) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetUserPermissionsRequest.fromObject({
    userUid,
  });
  const getUserPermissions = useQuery<infinitusai.auth.GetUserPermissionsResponse, Error>(
    [orgUuid, 'auth', 'getUserPermissions', reqBody],
    {
      enabled: userUid !== '',
    }
  );

  const data = React.useMemo(
    () =>
      getUserPermissions.data
        ? getUserPermissions.data.permissions.map((permission) =>
            infinitusai.auth.Permission.fromObject(permission)
          )
        : [],
    [getUserPermissions.data]
  );

  return { ...getUserPermissions, data };
}

export function useRemoveUserFromOrg() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const removeUserFromOrg = useMutation<
    infinitusai.auth.RemoveUserFromOrgResponse,
    Error,
    infinitusai.auth.RemoveUserFromOrgRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/auth/removeUserFromOrg', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgUsers']);
    },
  });

  return removeUserFromOrg;
}

export function useGetOrgInvites() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetOrgInvitesRequest.fromObject({
    types: [infinitusai.auth.InviteType.INVITE_TYPE_CUSTOMER],
    states: [infinitusai.auth.InviteState.INVITE_STATE_PENDING],
  });
  const getOrgInvites = useQuery<infinitusai.auth.GetOrgInvitesResponse, Error>([
    orgUuid,
    'auth',
    'getOrgInvites',
    reqBody,
  ]);

  const data = React.useMemo(
    () =>
      getOrgInvites.data
        ? getOrgInvites.data.invites.map((invite) => infinitusai.auth.Invite.fromObject(invite))
        : [],
    [getOrgInvites.data]
  );

  return { ...getOrgInvites, data };
}

export function useGetInvite(uuid: string) {
  const reqBody = infinitusai.auth.GetInviteRequest.fromObject({ uuid });
  const getInvite = useQuery<infinitusai.auth.GetInviteResponse, Error>(
    ['', 'auth', 'getInvite', reqBody],
    {
      retry: 0,
      enabled: uuid !== '',
      staleTime: Infinity,
    }
  );

  const data = React.useMemo(
    () =>
      getInvite.data
        ? {
            invite: infinitusai.auth.Invite.fromObject(getInvite.data.invite || {}),
            orgName: getInvite.data.orgName,
          }
        : null,
    [getInvite.data]
  );

  return { ...getInvite, data };
}

export function useAssignRolesOrCreateInvite() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const assignRolesOrCreateInvite = useMutation<
    infinitusai.auth.AssignRolesOrCreateInviteResponse,
    Error,
    any
  >(
    (req) =>
      fetchApi({
        appName,
        user,
        orgUuid,
        path: '/auth/assignRolesOrCreateInvite',
        body: {
          ...req,
          inviteBaseUrl: `${window.location.origin || ''}${BETA_VERSION ? '/beta' : ''}`,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgInvites']);
        queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgUsers']);
      },
    }
  );

  return assignRolesOrCreateInvite;
}

export function useCancelInvite() {
  const queryClient = useQueryClient();
  const { appName, user, orgUuid } = useApiParams();
  const cancelInvite = useMutation<
    infinitusai.auth.CancelInviteResponse,
    Error,
    infinitusai.auth.CancelInviteRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/auth/cancelInvite', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'auth', 'getOrgInvites']);
    },
  });

  return cancelInvite;
}

export function useAcceptInvite() {
  const { appName, user } = useApiParams();
  const acceptInvite = useMutation<
    infinitusai.auth.AcceptInviteResponse,
    Error,
    infinitusai.auth.AcceptInviteRequest
  >((req) => fetchApi({ appName, user, path: '/auth/acceptInvite', body: req }));

  return acceptInvite;
}
