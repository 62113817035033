import { Button, DrawerBody } from '@infinitusai/ui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

import { useGetUserPermissions } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

interface Props {
  userUid: string;
}

function AssignedPermissionsTab({ userUid }: Props) {
  const getUserPermissions = useGetUserPermissions(userUid);
  const [permissionsExpanded, setPermissionsExpanded] = React.useState<Set<string>>(new Set());

  const permissionMap =
    getUserPermissions.data?.reduce((value, cur) => {
      const permissionCategory = cur.category;
      if (!value[permissionCategory]) {
        value[permissionCategory] = [];
      }
      value[permissionCategory].push(cur);
      return value;
    }, {} as { [key: string]: infinitusai.auth.Permission[] }) || {};

  const handleExpandAll = () => {
    setPermissionsExpanded(new Set(Object.keys(permissionMap)));
  };

  const handleCollapseAll = () => {
    setPermissionsExpanded(new Set());
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    if (newExpanded) {
      setPermissionsExpanded(new Set(permissionsExpanded.add(panel)));
    } else {
      setPermissionsExpanded((permissionsExpanded) => {
        const newPermissionsExpanded = new Set(permissionsExpanded);
        newPermissionsExpanded.delete(panel);
        return newPermissionsExpanded;
      });
    }
  };

  return (
    <DrawerBody>
      <Box display="flex" justifyContent="flex-end" sx={{ mt: 2, mr: 2 }}>
        {permissionsExpanded.size === Object.keys(permissionMap).length ? (
          <Button color="primary" onClick={handleCollapseAll}>
            Collapse All
          </Button>
        ) : (
          <Button color="primary" onClick={handleExpandAll}>
            Expand All
          </Button>
        )}
      </Box>
      {Object.keys(permissionMap).map(function (category) {
        return (
          <Accordion
            key={category}
            disableGutters
            elevation={0}
            square
            sx={{
              borderBottom: 0,
              ':before': {
                display: 'none',
              },
            }}
            expanded={permissionsExpanded.has(category)}
            onChange={handleChange(category)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                flexDirection: 'row-reverse',
              }}
            >
              <Typography variant="subtitle2" ml={3}>
                {category}
              </Typography>
            </AccordionSummary>
            {permissionMap[category].map((permission) => {
              return (
                <AccordionDetails key={permission.uuid} sx={{ p: 0, m: 0, display: 'flex' }}>
                  <Box width="100%" mr={2}>
                    <Box ml={10} display="flex">
                      <Stack ml={4} mb={1}>
                        <Typography variant="subtitle2" color="textSecondary">
                          {permission.displayName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {permission.description}
                        </Typography>
                      </Stack>
                      <Divider sx={{ mt: 2 }} />
                    </Box>
                  </Box>
                </AccordionDetails>
              );
            })}
          </Accordion>
        );
      })}
    </DrawerBody>
  );
}

export default AssignedPermissionsTab;
