import React from 'react';
import { CustomerFacingTaskState, getCustomerFacingTaskStateCustomer } from 'utils';

import { infinitusai } from 'proto/pbjs';

import RecordingPlayer from './RecordingPlayer';
import TaskInProgressPlaceholder from './TaskInProgressPlaceholder';

interface Props {
  task: infinitusai.be.CustomerTaskDoc;
}
function TaskRecordingTab({ task }: Props) {
  const customerFacingState = getCustomerFacingTaskStateCustomer(task);
  const allowRecording =
    customerFacingState === CustomerFacingTaskState.COMPLETED ||
    customerFacingState === CustomerFacingTaskState.PARTIAL ||
    customerFacingState === CustomerFacingTaskState.BAD_DATA_INPUT;

  return (
    <React.Fragment>
      {!allowRecording ? (
        <TaskInProgressPlaceholder customerFacingTaskState={customerFacingState} task={task} />
      ) : (
        <RecordingPlayer />
      )}
    </React.Fragment>
  );
}
export default TaskRecordingTab;
