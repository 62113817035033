import { PageHeader, Tabs } from '@infinitusai/shared';
import { Table, useTable } from '@infinitusai/table';
import { useBreakpoint } from '@infinitusai/ui';
import { downloadCsvFromString } from '@infinitusai/utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { RowData, useGetCsvSpec } from 'api/customer';
import { infinitusapi } from 'proto/pbjs';
import { getTaskTypeDisplayName } from 'utils/displayNames';

enum SearchParam {
  MajorMedical = 'majorMedical',
  PBM = 'pbm',
  PrescriptionTransfer = 'prescriptionTransfer',
  PrescriptionSavings = 'prescriptionSavings',
  ClaimsFollowUp = 'claimsFollowUp',
  PriorAuthFollowUp = 'priorAuthFollowUp',
  DentalBV = 'dentalBv',
  PharmacyStockCheck = 'pharmacyStockCheck',
  ProviderOutreach = 'providerOutreach',
  PatientOutreach = 'patientOutreach',
  PBMDiscovery = 'pbmDiscovery',
}

const getTaskType = (searchParam: SearchParam) => {
  let infTaskType;
  switch (searchParam) {
    case SearchParam.MajorMedical:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION),
      };
      break;
    case SearchParam.PBM:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER,
        label: getTaskTypeDisplayName(
          infinitusapi.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER
        ),
      };
      break;
    case SearchParam.PrescriptionTransfer:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER),
      };
      break;
    case SearchParam.PrescriptionSavings:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS),
      };
      break;
    case SearchParam.ClaimsFollowUp:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP),
      };
      break;
    case SearchParam.PriorAuthFollowUp:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP),
      };
      break;
    case SearchParam.DentalBV:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION,
        label: getTaskTypeDisplayName(
          infinitusapi.INFTaskType.INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION
        ),
      };
      break;
    case SearchParam.PharmacyStockCheck:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK),
      };
      break;
    case SearchParam.ProviderOutreach:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH),
      };
      break;
    case SearchParam.PatientOutreach:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH),
      };
      break;
    case SearchParam.PBMDiscovery:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_PBM_DISCOVERY,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PBM_DISCOVERY),
      };
      break;
    default:
      infTaskType = {
        taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_UNKNOWN,
        label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_UNKNOWN),
      };
  }
  return infTaskType;
};

function FieldsReferencePage() {
  const smBreakpoint = useBreakpoint('sm');
  const [searchParam] = useSearchParams();
  const getCsvSpec = useGetCsvSpec(
    getTaskType(searchParam.get('taskType') as SearchParam).taskType
  );
  const csvSpec = getCsvSpec.inputSpecData as RowData[];

  const inputData = getCsvSpec.inputSpecData || [];
  const outputData = getCsvSpec.outputSpecData || [];

  const inputColumns = React.useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    return [
      columnHelper.accessor((originalRow) => originalRow[0], {
        header: 'Column Name',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((originalRow) => originalRow[1], {
        header: 'Required',
        cell: (info) => <Typography>{info.row.original[1] && <>✓</>} </Typography>,
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((originalRow) => originalRow[2], {
        header: 'Type',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((originalRow) => originalRow[3], {
        header: 'Description',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((originalRow) => originalRow[4], {
        header: 'Example',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((originalRow) => originalRow[5], {
        header: 'Allowed Values',
        cell: (info) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {info.row.original[5].map((allowedValue: string) => (
              <Typography variant="body2" key={allowedValue}>
                {allowedValue}
              </Typography>
            ))}
          </div>
        ),
        meta: {
          download: true,
        },
      }),
    ];
  }, []);

  const outputColumns = React.useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    return [
      columnHelper.accessor((originalRow) => originalRow[0], {
        header: 'Output Name',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((originalRow) => originalRow[1], {
        header: 'Type',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((originalRow) => originalRow[2], {
        header: 'Description',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((originalRow) => originalRow[4], {
        header: 'Possible Values',
        cell: (info) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {info.row.original[4].map((allowedValue: string) => (
              <Typography variant="body2" key={allowedValue}>
                {allowedValue}
              </Typography>
            ))}
          </div>
        ),
        meta: {
          download: true,
        },
      }),
    ];
  }, []);

  const inputTable = useTable({
    id: 'fields-reference-inputs',
    data: inputData,
    columns: inputColumns,
    isLoading: getCsvSpec.isLoading,
    totalRows: 100,
    noRowsMessage: 'No Input CSV Spec for this task type currently.',
    manualPagination: true,
    hidePagination: true,
    enableSharing: true,
    enableDownload: true,
    enableSorting: false,
    enableFilters: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    getRowId: (row) => row.uuid,
  });

  const outputTable = useTable({
    id: 'fields-reference-outputs',
    data: outputData,
    columns: outputColumns,
    isLoading: getCsvSpec.isLoading,
    totalRows: 100,
    noRowsMessage: 'No Output Specification for this task type currently.',
    manualPagination: true,
    hidePagination: true,
    enableSharing: true,
    enableDownload: true,
    enableSorting: false,
    enableFilters: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    getRowId: (row) => row.uuid,
  });

  const taskObject = getTaskType(
    (searchParam.get('taskType') as SearchParam) || infinitusapi.INFTaskType.INF_TASK_TYPE_UNKNOWN
  );

  const handleDownloadTemplate = () => {
    const columnNames = csvSpec.map((fields) => fields[0]);
    const csvContent = columnNames.join(',');
    downloadCsvFromString(
      csvContent,
      `${searchParam.get('taskType') as SearchParam}_input_csv_template.csv`
    );
  };

  return (
    <React.Fragment>
      <PageHeader title={`${taskObject.label} Fields Reference`} variant="h5" />
      {csvSpec && csvSpec.length > 0 && (
        <>
          <Box>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={handleDownloadTemplate}
              sx={{ marginBottom: smBreakpoint ? 0 : 1 }}
            >
              Download template CSV file
            </Button>
            <Tabs
              id="fields-reference-tabs"
              variant="scrollable"
              tabs={[
                {
                  label: 'Inputs',
                  value: 'inputs',
                  tabProps: { sx: { minWidth: '30%' } },
                  content: (
                    <Box display="block" width="100%" mt={2} height="100%">
                      <Table table={inputTable} />
                    </Box>
                  ),
                },
                {
                  label: 'Outputs',
                  value: 'outputs',
                  tabProps: { sx: { minWidth: '30%' } },
                  content: (
                    <Box display="block" width="100%" mt={2} height="100%">
                      <Table table={outputTable} />
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
        </>
      )}
    </React.Fragment>
  );
}
export default FieldsReferencePage;
