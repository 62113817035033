import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  PageHeader,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
  Tabs,
} from '@infinitusai/shared';
import { Button, useBreakpoint } from '@infinitusai/ui';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { getOrgName } from 'utils';

import { useGetTasks, useGetTaskCounts, useGetTasksToReview } from 'api/customer';

import PipelineDiagram from './PipelineDiagram';
import TaskListPanel from './TaskListPanel';
import TimeSavingsSection from './TimeSavingsSection';

function HomePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const smBreakpoint = useBreakpoint('sm');
  const mdBreakpoint = useBreakpoint('md');
  const lgBreakpoint = useBreakpoint('lg');
  const { user, hasPermission } = useAuth();

  const getTaskCountsQuery = useGetTaskCounts();
  const getTasks = useGetTasks(searchParams.get('tab') || 'in-queue');

  const getTasksToReviewQuery = useGetTasksToReview();

  const hasCustomerPortalAccess = hasPermission([PermissionName.CUSTOMER_PORTAL_ACCESS]);
  const hasTasksReadPermissions = hasPermission([PermissionName.CUSTOMER_TASKS_READ]);
  const createTaskEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.CREATE_TASK);
  const hasCreateTaskPermission = hasPermission([PermissionName.CUSTOMER_TASKS_WRITE]);
  const csvImportEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.IMPORT_TASKS_CSV);
  const hasCSVImportPermission = hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_READ]);
  const timeSavingsEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.SHOW_TIME_SAVINGS
  );

  return (
    <React.Fragment>
      <PageHeader
        title={
          smBreakpoint
            ? `Welcome Back, ${(user?.displayName || user?.email)?.split(' ')[0]}!`
            : 'Welcome Back!'
        }
        variant="h4"
      >
        <Box
          flexGrow={1}
          display={smBreakpoint ? 'flex' : ''}
          justifyContent={smBreakpoint ? 'flex-end' : ''}
          width="100%"
        >
          {createTaskEnabled && !csvImportEnabled && hasCreateTaskPermission && smBreakpoint ? (
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth={!smBreakpoint}
              onClick={() => navigate(`/${getOrgName(location.pathname)}/tasks/new`)}
              startIcon={<AddRoundedIcon />}
              unauthorized={!hasPermission([PermissionName.CUSTOMER_TASKS_WRITE])}
            >
              Create Task
            </Button>
          ) : csvImportEnabled && hasCSVImportPermission && smBreakpoint ? (
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth={!smBreakpoint}
              onClick={() =>
                navigate(`/${getOrgName(location.pathname)}/tasks/new?tab=import-tasks`)
              }
              startIcon={<AddRoundedIcon />}
              unauthorized={!hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_WRITE])}
            >
              Import Tasks
            </Button>
          ) : null}
        </Box>
      </PageHeader>

      <React.Fragment>
        {hasCustomerPortalAccess || hasTasksReadPermissions ? (
          <PipelineDiagram
            callsInQueueCount={getTaskCountsQuery.data?.tasksInQueueCount}
            callsInProgressCount={getTaskCountsQuery.data?.tasksInProgressCount}
            callsCompletedTodayCount={getTaskCountsQuery.data?.tasksCompletedTodayCount}
          />
        ) : null}
        {timeSavingsEnabled && hasTasksReadPermissions ? (
          <Grid
            container
            mt={2}
            // TODO: bring back when we roll back ROI
            // justifyContent="space-between"
            // gap={1}
            // direction={lgBreakpoint ? 'row' : 'column'}
          >
            {/* <TaskSummarySection /> */}
            <TimeSavingsSection />
          </Grid>
        ) : null}
        {hasTasksReadPermissions ? (
          <React.Fragment>
            {mdBreakpoint ? (
              <>
                <Typography variant="h5" mt={3}>
                  Tasks Preview
                </Typography>
              </>
            ) : null}
            <Grid
              container
              display="flex"
              direction={lgBreakpoint ? 'row' : 'column-reverse'}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={6}>
                <Stack>
                  <Tabs
                    id="home"
                    syncUrl
                    tabs={[
                      {
                        tabProps: {
                          sx: { minWidth: '30%' },
                        },
                        value: 'in-queue',
                        label: 'In Queue',
                        content: (
                          <TaskListPanel
                            tasks={
                              getTasks.data.tasks ? getTasks.data.tasks.slice(0, 5) : undefined
                            }
                            isLoading={getTasks.isLoading}
                          />
                        ),
                        unauthorized: !hasPermission([PermissionName.CUSTOMER_TASKS_READ]),
                      },
                      {
                        tabProps: {
                          sx: { minWidth: '30%' },
                        },
                        value: 'in-progress',
                        label: 'In Progress',
                        content: (
                          <TaskListPanel
                            tasks={
                              getTasks.data?.tasks ? getTasks.data.tasks.slice(0, 5) : undefined
                            }
                            isLoading={getTasks.isLoading}
                          />
                        ),
                        unauthorized: !hasPermission([PermissionName.CUSTOMER_TASKS_READ]),
                      },
                      {
                        tabProps: {
                          sx: { minWidth: '30%' },
                        },
                        value: 'completed',
                        label: 'Completed',
                        content: (
                          <TaskListPanel
                            tasks={
                              getTasks.data.tasks ? getTasks.data.tasks.slice(0, 5) : undefined
                            }
                            isLoading={getTasks.isLoading}
                          />
                        ),
                        unauthorized: !hasPermission([PermissionName.CUSTOMER_TASKS_READ]),
                      },
                    ]}
                  />
                </Stack>
              </Grid>
              {getTasksToReviewQuery.data ? (
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="body1" color="error" ml={1} mt={2} mb={1}>
                      Needs Review ({getTasksToReviewQuery.data?.length})
                    </Typography>
                    <TaskListPanel
                      tasks={getTasksToReviewQuery.data || []}
                      isLoading={getTasksToReviewQuery.isLoading}
                    />
                  </Stack>
                </Grid>
              ) : null}
            </Grid>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
}

export default HomePage;
