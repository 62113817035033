import { Table, useTable, DateField } from '@infinitusai/table';
import Box from '@mui/material/Box';
import { createColumnHelper } from '@tanstack/react-table';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustomerFacingTaskState } from 'utils';

import StatusChip from 'components/StatusChip';
import { infinitusai } from 'proto/pbjs';

import { useGetPatientTasks } from '../fhirClient';
interface Props {
  patientId: string;
  accessToken: string;
  idToken: string;
}
function AllTasksTab({ patientId, accessToken, idToken }: Props) {
  const navigate = useNavigate();
  const getCustomerTasks = useGetPatientTasks(patientId, accessToken, idToken);
  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.be.CustomerTaskDoc>();
    return [
      columnHelper.accessor('taskUpdatedMillis', {
        id: 'taskUpdatedMillis',
        header: 'Last Updated',
        cell: (info) =>
          info.getValue() !== 0 ? <DateField date={new Date(info.getValue() as number)} /> : '-',
      }),
      columnHelper.accessor((customerTask) => getCustomerFacingTaskState(customerTask), {
        id: 'state',
        header: 'Task Status',
        enableGlobalFilter: false,
        enableSorting: false,
        cell: (info) => <StatusChip label={info.getValue() || null} />,
      }),
      columnHelper.accessor('calleeName', {
        header: 'Callee Name',
        enableSorting: false,
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
    ];
  }, []);

  const table = useTable({
    id: 'all-tasks',
    data: getCustomerTasks.data,
    columns,
    manualFiltering: true,
    isLoading: getCustomerTasks.isLoading,
    totalRows: getCustomerTasks.data.length,
    noRowsMessage: 'No tasks in this organization.',
    enableUrlSync: true,
    getRowId: (row) => row.taskUuid,
    onRowClick: (row) => {
      navigate(`/integration/task-details?tid=${row.original.taskUuid}`);
    },
  });

  React.useEffect(() => {
    if (getCustomerTasks.isError) {
      enqueueSnackbar(`Failed to fetch tasks for org`, {
        variant: 'error',
      });
    }
  }, [getCustomerTasks.isError]);

  return (
    <Box mt={3} sx={{ paddingX: 2 }}>
      <Table
        table={table}
        overrides={{
          toolbar: <div />,
        }}
      />
    </Box>
  );
}

export default AllTasksTab;
