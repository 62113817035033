import { Org } from '@infinitusai/shared';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import get from 'lodash/get';
import React, { Fragment } from 'react';

import DataField from 'components/DataField';
import { infinitusai, infinitusapi } from 'proto/pbjs';

import DetailsAccordion from './DetailsAccordion';
import { allOutputsMap, OutputDataFieldMap, ARRAY_INDEX } from './constants';

export interface Props {
  outputMap?: {
    label: string;
    fields: string[];
    idField?: string;
    parentField?: string;
  }[];
  output?:
    | infinitusapi.IINFPrescriptionSavingsTaskOutput
    | infinitusapi.IINFPBMTaskOutput
    | infinitusapi.IINFPrescriptionTransferTaskOutput
    | infinitusapi.IINFBVTaskOutput
    | null
    | undefined;
  outputDataFieldMap?: OutputDataFieldMap;
  org: Org | null;
  hiddenLabels?: string[];
  task?: infinitusai.be.TaskDoc | null;
}

export interface OutputSectionProps {
  sectionHeader: string;
  output:
    | infinitusapi.IINFBVTaskOutput
    | infinitusapi.IINFPBMTaskOutput
    | infinitusapi.IINFPrescriptionTransferTaskOutput
    | infinitusapi.IINFPrescriptionSavingsTaskOutput
    | { [key: string]: any }
    | null
    | undefined;
  outputDataFieldMap: OutputDataFieldMap;
  fields: string[];
  parentField?: string;
  org: Org | null;
  setExpanded: React.Dispatch<React.SetStateAction<Set<string>>>;
  expanded: Set<string>;
  task?: infinitusai.be.TaskDoc | null;
}

export function OutputSection({
  sectionHeader,
  output,
  outputDataFieldMap,
  fields,
  parentField,
  org,
  setExpanded,
  expanded,
  task,
}: OutputSectionProps) {
  if (sectionHeader === 'Products') {
    const products = (output as infinitusapi.IINFPrescriptionSavingsTaskOutput)
      ?.products as infinitusapi.IINFPrescriptionSavingsTaskOutputProduct[];
    return products.length === 0 ? null : (
      <DetailsAccordion
        displayName={sectionHeader}
        setExpanded={setExpanded}
        expanded={expanded}
        data={products?.map((info: any, index) => (
          <>
            {Object.keys(info).map((field, data) => {
              const key = `products.${field}`;
              const fieldProperties = get(outputDataFieldMap, key);
              return (
                <Grid key={key} item xs={fields.length === 1 ? 12 : 3}>
                  <DataField
                    label={`${fieldProperties.label} #${index + 1}` || ''}
                    value={info[field] ?? '-'}
                    whiteSpace="normal"
                  />
                </Grid>
              );
            })}
          </>
        ))}
      />
    );
  } else {
    return (
      <DetailsAccordion
        displayName={sectionHeader}
        numberOfEntities={fields.length}
        setExpanded={setExpanded}
        expanded={expanded}
        data={fields
          .filter((field) => !org?.custom?.prunedBvOutputs?.includes(field))
          .map((field) => {
            // key for lookup in outputDataFieldMap
            const key = parentField ? `${parentField}.${field}` : field;
            const fieldProperties = get(outputDataFieldMap, key);
            const getValue = fieldProperties?.getValue;
            let value = getValue(get(output, field)) ? getValue(get(output, field)) : '-';
            if (['billingNotes', 'additionalNotes'].includes(key)) {
              let values = value.split(/\r?\n/);
              const notes = (
                <Typography key={key}>
                  {values.map((note, index) => (
                    <Fragment key={`${key}-${index}`}>
                      {note}
                      <br />
                    </Fragment>
                  ))}
                </Typography>
              );
              return (
                <Grid item xs={fields.length === 1 ? 12 : 3} key={fieldProperties?.label}>
                  <DataField
                    label={fieldProperties?.label || ''}
                    value={notes}
                    whiteSpace="normal"
                  />
                </Grid>
              );
            }
            return (
              <Grid item xs={fields.length === 1 ? 12 : 3} key={fieldProperties?.label}>
                <DataField label={fieldProperties?.label || ''} value={value} whiteSpace="normal" />
              </Grid>
            );
          })}
      />
    );
  }
}
const getHeader = (r: any, idx: number, label: string, idField: string, prefix?: string) => {
  if (idField === ARRAY_INDEX) {
    return `${label}: ${prefix} ${idx + 1}`;
  }
  let id = get(r, idField);
  return `${label}: ${id}`;
};
export default function OutputsPanel({
  outputMap,
  output,
  outputDataFieldMap = allOutputsMap,
  org,
  hiddenLabels = [],
  task,
}: Props) {
  const accordions =
    outputMap &&
    outputMap
      .filter(({ label }: { label: string }) => !hiddenLabels.includes(label))
      .map(
        ({
          label,
          fields,
          idField,
          parentField,
          prefix,
        }: {
          label: string;
          fields: string[];
          idField?: string;
          parentField?: string;
          prefix?: string;
        }) => {
          if (parentField && idField) {
            const repeated = get(output, parentField);
            return repeated.map((r: any, i: number) => getHeader(r, i, label, idField, prefix));
          } else {
            return label;
          }
        }
      )
      .flat();

  const [expanded, setExpanded] = React.useState<Set<string>>(new Set([accordions![0]]));
  const handleExpandAll = () => {
    setExpanded(new Set(accordions));
  };

  const handleCollapseAll = () => {
    setExpanded(new Set());
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        {expanded.size === accordions?.length ? (
          <Button color="primary" onClick={handleCollapseAll}>
            Collapse All
          </Button>
        ) : (
          <Button color="primary" onClick={handleExpandAll}>
            Expand All
          </Button>
        )}
      </Box>

      {outputMap &&
        outputMap
          .filter(({ label }: { label: string }) => !hiddenLabels.includes(label))
          .map(
            ({
              label,
              fields,
              idField,
              parentField,
              prefix,
            }: {
              label: string;
              fields: string[];
              idField?: string;
              parentField?: string;
              prefix?: string;
            }) => {
              if (parentField && idField) {
                const repeated = get(output, parentField);
                return repeated.map((r: any, i: number) => (
                  <OutputSection
                    key={`${label}-${i}`}
                    sectionHeader={`${getHeader(r, i, label, idField, prefix)}`}
                    output={r}
                    outputDataFieldMap={outputDataFieldMap}
                    fields={fields}
                    parentField={parentField}
                    org={org}
                    setExpanded={setExpanded}
                    expanded={expanded}
                    task={task}
                  />
                ));
              }
              return (
                <OutputSection
                  key={label}
                  sectionHeader={label}
                  output={output}
                  outputDataFieldMap={outputDataFieldMap}
                  fields={fields}
                  org={org}
                  setExpanded={setExpanded}
                  expanded={expanded}
                  task={task}
                />
              );
            }
          )}
    </Box>
  );
}
