export type TaskImportCache = {
  successCount: number;
  failedCount: number;
  errorCount: number;
};
const LOCAL_STORAGE_KEY_TASK_IMPORT_COUNTS = 'TASK_IMPORT_COUNTS';
export function resetTaskImportCache() {
  localStorage.removeItem(LOCAL_STORAGE_KEY_TASK_IMPORT_COUNTS);
}
export function updateTaskImportCache(newCacheData: TaskImportCache) {
  localStorage.setItem(LOCAL_STORAGE_KEY_TASK_IMPORT_COUNTS, JSON.stringify(newCacheData));
}

function getDefaultTaskImportCache(): TaskImportCache {
  return {
    successCount: 0, // Server responds with success code and there are no rejected tasks
    failedCount: 0, // Server responds with success code and there are no rejected tasks
    errorCount: 0, // Server responds with non success code (e.g. 400, 500)
  };
}

export function getTaskImportCache(): TaskImportCache {
  const taskImportCache = getDefaultTaskImportCache();
  const dataFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY_TASK_IMPORT_COUNTS);

  if (dataFromLocalStorage) {
    try {
      const cache = JSON.parse(dataFromLocalStorage);
      if (cache.successCount) taskImportCache.successCount = cache.successCount;
      if (cache.failedCount) taskImportCache.failedCount = cache.failedCount;
      if (cache.errorCount) taskImportCache.errorCount = cache.errorCount;
    } catch (e) {
      console.error('Error in getting LastFailedTaskImport from local storage', e);
    }
  }

  return taskImportCache;
}
