import { PageHeader } from '@infinitusai/shared';
import { Table, useTable } from '@infinitusai/table';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import format from 'date-fns/format';
import React from 'react';

import { useGetReportsQuery, useGetOrgPrograms } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { getTaskTypeDisplayNamePortal } from 'utils/displayNames';

import DownloadButton from './DownloadButton';
import GenerateReportBox from './GenerateReportBox';

const ReportsPage: React.FC = () => {
  const getReportsQuery = useGetReportsQuery();
  const getOrgProgramsQuery = useGetOrgPrograms();
  const queryClient = useQueryClient();
  const [tryCount, setTryCount] = React.useState(0);

  React.useEffect(() => {
    if (getReportsQuery.data && getReportsQuery.data[0]) {
      // sets to processing the first time so need to wait to do it again until file is hosted
      if (
        getReportsQuery.data[0].gcsPath === '' &&
        getReportsQuery.data[0].errorMessage === '' &&
        tryCount < 3
      ) {
        setTimeout(() => {
          getReportsQuery.refetch();
        }, 3000);
        setTryCount(tryCount + 1);
      } else if (
        !(getReportsQuery.data[0].gcsPath === '') ||
        !(getReportsQuery.data[0].errorMessage === '')
      ) {
        setTryCount(0);
      }
    }
  }, [getReportsQuery, getReportsQuery.data, queryClient, tryCount]);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.tasks.GeneratedCSVDoc>();
    return [
      columnHelper.accessor('generatedAtMillis', {
        header: 'Generated At',
        cell: (info) => (
          <Stack display="flex" justifyContent="center" alignContent="center">
            <Typography variant="subtitle2">
              {format(new Date(info.getValue() ? Number(info.getValue()) : 0), 'MMM d, yyyy')}
            </Typography>
            <Typography variant="body2">
              {format(new Date(info.getValue() ? Number(info.getValue()) : 0), 'h:mm aa')}
            </Typography>
          </Stack>
        ),
      }),
      columnHelper.accessor('query', {
        id: 'dateRange',
        header: 'Date Range',
        enableSorting: false,
        cell: (info) => (
          <Typography sx={{ fontSize: '0.875rem' }}>
            {` ${format(
              new Date(Number(info.row.original.query?.reviewedMillisStart)),
              'MMM d, yyyy h:mm a'
            )} - ${format(
              new Date(
                info.row.original.query?.reviewedMillisEnd
                  ? Number(info.row.original.query?.reviewedMillisEnd)
                  : 0
              ),
              'MMM d, yyyy h:mm a'
            )}`}
          </Typography>
        ),
      }),
      columnHelper.accessor('query', {
        id: 'program',
        header: 'Program',
        enableSorting: false,
        cell: (info) => (
          <Typography sx={{ fontSize: '0.875rem' }}>
            {!info.row.original.query?.programId
              ? 'All'
              : getOrgProgramsQuery.data?.find(
                  (program) => program.name === info.row.original.query?.programId
                )?.displayName || info.row.original.query?.programId}
          </Typography>
        ),
      }),
      columnHelper.accessor('query', {
        id: 'taskType',
        header: 'Task Type',
        enableSorting: false,
        cell: (info) => (
          <Typography sx={{ fontSize: '0.875rem' }}>
            {!info.row.original.query?.taskType ||
            info.row.original.query?.taskType?.valueOf() ===
              infinitusai.tasks.TaskType.TASK_TYPE_UNKNOWN
              ? 'All'
              : getTaskTypeDisplayNamePortal(info.row.original.query?.taskType)}
          </Typography>
        ),
      }),
      columnHelper.accessor('errorMessage', {
        header: '',
        enableSorting: false,
        cell: (info) => {
          if (info.row.original.gcsPath) {
            return <DownloadButton csv={info.row.original} />;
          }
          if (info.row.original.errorMessage) {
            return (
              <Typography display="flex" justifyContent="flex-end">
                {info.row.original.errorMessage}
              </Typography>
            );
          }
          return (
            <Typography display="flex" justifyContent="flex-end">
              Processing...
            </Typography>
          );
        },
      }),
    ];
  }, [getOrgProgramsQuery.data]);

  const table = useTable({
    id: 'reports',
    data: getReportsQuery.data,
    columns,
    isLoading: getReportsQuery.isLoading,
    totalRows: getReportsQuery.data?.length,
    noRowsMessage: 'No Reports for this period',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableHiding: true,
    enableColumnFilters: false,
    enableGlobalFilter: true,
    enableUrlSync: true,
    hideToolbar: true,
  });

  return (
    <React.Fragment>
      <PageHeader title="Reports" variant="h5" />
      <Box>
        <GenerateReportBox programs={getOrgProgramsQuery.data ? getOrgProgramsQuery.data : []} />
        <Box mt={2}>
          <Table table={table} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ReportsPage;
