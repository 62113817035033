import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React from 'react';

import { CSWII_ROW_ID, CsWiiTable } from './types';
import { mapRowToValues } from './utils';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import './style.css';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface CsWiiProps {
  CSVColumnNames: string[];
  whichColumnsHaveErrors: Map<string, boolean>;
  csWiiTable: CsWiiTable;
  handleUserJustEditedCsWiiCell: (event: string, rowName: string, columnName: string) => void;
}

const CSWii: React.FC<CsWiiProps> = ({
  CSVColumnNames,
  whichColumnsHaveErrors,
  csWiiTable: rowIdToRowMap,
  handleUserJustEditedCsWiiCell,
}) => {
  const columns = CSVColumnNames.map((columnName) => ({
    headerName: columnName,
    field: columnName,
    resizable: true,
    valueGetter: (params: any) => {
      return params.data[columnName].value;
    },
    tooltipValueGetter: (params: any) => {
      if (params.data[columnName].hasError) {
        return params.data[columnName].errorMessage; // Tooltip for cells with an error
      }
      return null; // No tooltip for cells without an error
    },
    cellStyle: (params: any) => {
      if (params.data[columnName].hasError) {
        return {
          borderColor: 'red',
          borderStyle: 'solid',
          borderWidth: '1px',
        };
      }
      return null;
    },
    headerClass: (params: any) =>
      whichColumnsHaveErrors.get(columnName) ? 'header-error' : 'header-regular',
    editable: columnName !== CSWII_ROW_ID,
    suppressMovable: true,
  }));

  let rows = Array.from(rowIdToRowMap.entries()).map(([rowId, rowData], i) => ({
    ...mapRowToValues(rowData),
  }));

  const onCellValueChanged = (params: any) => {
    // params contains information about the edited cell
    // e.g. params.data contains the row, params.newValue contains the new value of the cell
    const { data, newValue } = params;
    const rowName = data[CSWII_ROW_ID]['value'];
    const colName = params.column.getColId();

    //#TODO: log these to BQ or the synced frontend logger used in the operator portal
    console.info('new value user edited', newValue);
    console.info('Column name of edited value:', colName);
    console.info('Row name of edited value:', rowName);
    handleUserJustEditedCsWiiCell(newValue, rowName, colName);
  };

  return (
    <div className="ag-theme-material" style={{ height: 500, width: 2000 }}>
      <AgGridReact
        columnDefs={columns}
        rowData={rows}
        onCellEditingStopped={onCellValueChanged}
      ></AgGridReact>
    </div>
  );
};

export default CSWii;
