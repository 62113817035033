import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ReactDropzone from 'react-dropzone';

interface Props {
  text: string;
  subText: string;
  accept: string[];
  handleDrop: (file: File) => void;
  disabled?: boolean;
  unauthorized?: boolean;
}

function Dropzone({
  text,
  subText,
  accept,
  handleDrop,
  disabled = false,
  unauthorized = false,
}: Props) {
  return (
    <Tooltip title={unauthorized ? 'You do not have the required permission' : ''}>
      <span>
        <ReactDropzone
          onDrop={(acceptedFiles) => {
            if (acceptedFiles.length > 0) {
              const file = acceptedFiles[0];
              handleDrop(file);
            }
          }}
          accept={{
            'text/csv': accept,
            'application/vnd.ms-excel': accept,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': accept,
          }}
          disabled={disabled || unauthorized}
        >
          {({ getRootProps, getInputProps, isDragAccept, isDragReject, acceptedFiles }) => {
            const acceptedFile = acceptedFiles?.[0];

            const styleDropbox = isDragAccept
              ? {
                  backgroundColor: (theme: any) => alpha(theme.palette.success.main, 0.2),
                }
              : isDragReject
              ? {
                  backgroundColor: (theme: any) => alpha(theme.palette.error.main, 0.2),
                }
              : acceptedFile
              ? {
                  borderColor: (theme: any) => theme.palette.success.main,
                }
              : null;
            return (
              <Box
                {...getRootProps()}
                sx={{
                  p: 2,
                  borderRadius: '8px',
                  border: (theme) => `2px dashed ${theme.palette.grey[400]}`,
                  backgroundColor: 'background.paper',
                  ...styleDropbox,
                  color: '#9e9e9e',
                  cursor: 'pointer',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <input {...getInputProps()} />
                {acceptedFile && (
                  <Box
                    key={`${acceptedFile.name}-${acceptedFile.lastModified}`}
                    alignItems="left"
                    bgcolor="divider"
                    display="inline-block"
                    borderRadius="8px"
                    width="auto"
                    justifyContent="left"
                    p={2}
                  >
                    <Typography color="textPrimary" mr={1} variant="subtitle2">
                      {acceptedFile.name}
                    </Typography>
                  </Box>
                )}
                {!acceptedFile && (
                  <Box
                    display="flex"
                    gap={3}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    height="100%"
                    minHeight="200px"
                  >
                    <UploadFileRoundedIcon fontSize="large" />
                    <Typography variant="subtitle1">{text}</Typography>
                    <Typography variant="subtitle1">{subText}</Typography>
                  </Box>
                )}
              </Box>
            );
          }}
        </ReactDropzone>
      </span>
    </Tooltip>
  );
}

export default Dropzone;
