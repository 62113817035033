import { PageHeader, Tabs } from '@infinitusai/shared';
import * as React from 'react';

import AllTasksTab from './AllTasksTab';
import TasksCompletedTab from './CompletedTab';
import TasksInProgressTab from './InProgressTab';
import TasksInQueueTab from './InQueueTab';

function AllTasksPage() {
  return (
    <React.Fragment>
      <PageHeader title="All Tasks" variant="h5" />
      <Tabs
        id="all-tasks"
        syncUrl
        variant="scrollable"
        unmountOnChange
        resetUrlOnChange
        tabs={[
          {
            value: 'all-tasks',
            label: 'All Tasks',
            content: <AllTasksTab />,
          },
          {
            value: 'in-queue',
            label: 'In Queue',
            content: <TasksInQueueTab />,
          },
          {
            value: 'in-progress',
            label: 'In Progress',
            content: <TasksInProgressTab />,
          },
          {
            value: 'completed',
            label: 'Completed',
            content: <TasksCompletedTab />,
          },
        ]}
      />
    </React.Fragment>
  );
}

export default AllTasksPage;
