import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  TextField,
  FadeTransition,
  useConfirm,
} from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import { useCreateNewWebhookMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

interface Props {
  open: boolean;
  onClose: () => void;
}

function WebhookDrawer({ open, onClose }: Props) {
  const auth = useAuth();
  const [newKeyValue, setNewKeyValue] = React.useState('');
  const [keyDialogOpen, setKeyDialogOpen] = React.useState(false);
  const textFieldRef = React.useRef<HTMLDivElement>(null);
  const createWebhookConfig = useCreateNewWebhookMutation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const handleClose = () => {
    if (formik.dirty) {
      confirm({
        title: 'Abandon Creating New Webhook?',
        description: 'Are you sure you want to abandon creating a new webhook?',
        confirmText: 'Yes, Abandon',
        onConfirm: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      url: '',
      auth: {
        username: '',
        password: '',
      },
      headers: [],
    },
    onSubmit: async (values) => {
      const body = infinitusai.be.CreateWebhookConfigRequest.fromObject(values);
      await createWebhookConfig.mutateAsync(body, {
        onSuccess: () => {
          enqueueSnackbar('Successfully created webhook!', { variant: 'success' });
        },
        onError: (error) => {
          enqueueSnackbar(`Failed to create webhook: ${error.message}.`, {
            variant: 'error',
          });
        },
      });
      formik.resetForm();
      onClose();
    },
  });

  return (
    <FormikProvider value={formik}>
      <Drawer open={open} onClose={handleClose}>
        <DrawerHeader title="Create New Webhook" onClose={handleClose} />
        <DrawerBody>
          <Stack mb={10}>
            <Typography variant="overline" sx={{ mx: 2 }}>
              ENTER THE WEBHOOK URL
            </Typography>
            <Stack mb="1rem" ml="1rem" mr="1rem">
              <TextField
                debounce
                required
                id="webhookURL"
                label="Webhook URL"
                placeholder="https://webhookURLhere.com"
                size="medium"
                sx={{ mb: 1 }}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={
                  (formik.touched.url && formik.errors.url) ||
                  `Enter the URL that you'd like for us to send task results to. Please use https:// only.`
                }
                unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
                {...formik.getFieldProps('url')}
              />
            </Stack>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="overline" sx={{ mx: 2 }}>
              WEBHOOK BASIC AUTHENTICATION
            </Typography>
            <Stack ml="1rem" mr="1rem" gap={1}>
              <TextField
                debounce
                required
                id="webhookUsername"
                type="username"
                label="Username"
                size="medium"
                sx={{ mb: 1 }}
                unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
                {...formik.getFieldProps('auth.username')}
              />
              <TextField
                debounce
                required
                id="webhookPassword"
                type="password"
                label="Password"
                size="medium"
                sx={{ mb: 1 }}
                unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
                {...formik.getFieldProps('auth.password')}
              />
            </Stack>
            <Divider sx={{ mt: 4, mb: 2 }} />
            <Typography variant="overline" sx={{ mx: 2 }}>
              CUSTOM HEADERS (OPTIONAL)
            </Typography>
            <Stack ml="1rem" mr="1rem" gap={1}>
              <FieldArray
                name="headers"
                render={(arrayHelpers) => (
                  <Box>
                    {formik.values.headers.map(
                      (header, index) =>
                        index < 5 && (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="bottom"
                            margin="10px 0"
                            key={index}
                          >
                            <TextField
                              debounce
                              unauthorized={
                                !auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])
                              }
                              label="key"
                              variant="outlined"
                              style={{ width: '30%' }}
                              {...formik.getFieldProps(`headers[${index}].key`)}
                            />

                            <TextField
                              debounce
                              unauthorized={
                                !auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])
                              }
                              label="value"
                              variant="outlined"
                              style={{ width: '50%' }}
                              {...formik.getFieldProps(`headers[${index}].value`)}
                            />
                            <Button
                              size="large"
                              color="error"
                              sx={{ height: '100%' }}
                              unauthorized={
                                !auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])
                              }
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              -
                            </Button>
                          </Box>
                        )
                    )}
                    <Button
                      type="button"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      size="large"
                      disabled={formik.values.headers.length === 5}
                      unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
                      onClick={() => arrayHelpers.push({ key: '', value: '' })}
                    >
                      + Add Custom Header
                    </Button>
                  </Box>
                )}
              />
            </Stack>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ mr: 2 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={formik.submitForm}
            disabled={
              formik.values.url.length === 0 ||
              formik.values.auth.username.length === 0 ||
              formik.values.auth.password.length === 0 ||
              createWebhookConfig.isLoading
            }
            unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
          >
            Submit
          </Button>
        </DrawerFooter>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={keyDialogOpen}
          TransitionComponent={FadeTransition}
          disableEscapeKeyDown
          aria-labelledby="key-dialog-title"
          aria-describedby="key-dialog-description"
        >
          <DialogTitle id="key-dialog-title">Copy New API Key</DialogTitle>
          <DialogContent>
            <DialogContentText id="key-dialog-text">
              Make sure to copy your new API key now. You won't be able to see it again!
            </DialogContentText>
            <TextField
              sx={{
                mt: 2,
              }}
              unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
              fullWidth
              label="API Key"
              ref={textFieldRef}
              variant="outlined"
              value={newKeyValue}
              onClick={(e: any) => {
                navigator.clipboard.writeText(e.target.value);
                enqueueSnackbar('API Key has been successfully copied! Please keep it safe!', {
                  variant: 'success',
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              size="medium"
              color="primary"
              onClick={() => {
                setKeyDialogOpen(false);
                setNewKeyValue('');
                onClose();
              }}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
    </FormikProvider>
  );
}

export default WebhookDrawer;
