import { useApiParams } from '@infinitusai/shared';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

function useGetMyUserRoles() {
  const { user, orgUuid } = useApiParams();
  const reqBody = {
    types: ['ROLE_TYPE_CUSTOMER'],
  };

  const getMyUserRoles = useQuery<any, Error>([orgUuid, 'auth', 'getMyUserRoles', reqBody], {
    enabled: user !== null,
  });
  const data = React.useMemo(
    () => (getMyUserRoles.data ? getMyUserRoles.data.roles.map((role: any) => role) : []),
    [getMyUserRoles.data]
  );
  return { ...getMyUserRoles, data };
}

const RolesAssignedTab = () => {
  const getUserRoles = useGetMyUserRoles();

  return (
    <Stack gap={1}>
      {getUserRoles.data && getUserRoles.data.length > 0 ? (
        <List sx={{ ml: 5 }}>
          {getUserRoles.data.map((role: any) => (
            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>{role.name}</ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No Roles Assigned to Member</Typography>
      )}
    </Stack>
  );
};
export default RolesAssignedTab;
