import {
  useAppState,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
} from '@infinitusai/shared';
import { Table, useTable, DateField } from '@infinitusai/table';
import { Chips } from '@infinitusai/ui';
import { possibleLongToNumber } from '@infinitusai/utils';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import NavigateNext from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { createColumnHelper } from '@tanstack/react-table';
import format from 'date-fns/format';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomerFacingTaskState, getCustomerFacingTaskState, getTaskFailureReason } from 'utils';

import { useGetTasks, useGetOrgPrograms } from 'api/customer';
import StatusChip from 'components/StatusChip';
import { infinitusai } from 'proto/pbjs';
import {
  getTaskTypeColumnDisplayName,
  getUuidDisplayName,
  getProductNamesFromTasks,
  getTaskStatusFilterOptions,
  checkFilters,
  getTaskTypeFilterOptionsPortal,
  getFailureReasonFilterOptions,
  getTasksWithProducts,
} from 'utils/displayNames';
import { isTaskInFinalState } from 'utils/taskState';

import { CustomToolbar } from './CustomToolbar';

function AllTasksTab() {
  const appState = useAppState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Fetch data
  const getOrgPrograms = useGetOrgPrograms();
  const getTasks = useGetTasks(searchParams.get('tab') || '');

  const filters = JSON.parse(searchParams.get('filters') as string);
  const products = filters
    ?.filter((filter: any) => filter.id === 'productNames')[0]
    ?.value.join(', ');
  const taskType = filters?.filter((filter: any) => filter.id === 'taskType')[0]?.value;
  const taskStateFilter: string = filters?.filter((filter: any) => filter.id === 'state')[0]?.value;

  // Filters on FE
  const updatedTasks = React.useMemo(
    () => getTasksWithProducts(getTasks.data?.tasks, getTasks.data?.taskInputs),
    [getTasks.data?.taskInputs, getTasks.data?.tasks]
  );
  const filteredTasks = React.useMemo(() => {
    return checkFilters(updatedTasks, {
      taskTypeFilter: taskType,
      productNamesFilter: products,
    });
  }, [updatedTasks, products, taskType]);

  const showTimeSavingsEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.SHOW_TIME_SAVINGS
  );
  const showHumanInvolvementFlagEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.SHOW_HUMAN_INVOLVEMENT_FLAG
  );
  const hasProductsFilterEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.TASK_TABLE_PRODUCTS
  );
  const hasTaskTypeFilterEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.TASK_TABLE_TASK_TYPES
  );
  const bundledTasksEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.BUNDLE_CUSTOMER_TASKS
  );

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.be.CustomerTaskDoc>();
    return [
      columnHelper.accessor('customerAssignedId', {
        id: 'customerAssignedId',
        header: 'Customer ID',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor((customerTask) => getCustomerFacingTaskState(customerTask), {
        id: 'state',
        header: 'Task Status',
        enableGlobalFilter: false,
        enableSorting: false,
        cell: (info) => <StatusChip label={info.getValue() || null} />,
        meta: {
          download: true,
          filterType: 'select',
          filterSelectOptions: getTaskStatusFilterOptions(true),
        },
      }),
      columnHelper.accessor(
        (task) =>
          getTaskFailureReason(
            task.failureReasonChosen?.type as infinitusai.be.TaskFailReason.Type
          ),
        {
          id: 'failureReasonChosen',
          header: 'Failure Reason',
          enableGlobalFilter: false,
          enableSorting: false,
          enableColumnFilter:
            taskStateFilter?.toUpperCase() === CustomerFacingTaskState.UNSUCCESSFUL ||
            taskStateFilter?.toUpperCase() === CustomerFacingTaskState.PARTIAL ||
            taskStateFilter === undefined,
          cell: (info) => {
            return info.row.original.state === infinitusai.be.TaskState.TASK_STATE_FAILED ||
              info.row.original.state === infinitusai.be.TaskState.TASK_STATE_PARTIAL_SUCCESS
              ? info.getValue()
              : '-';
          },
          meta: {
            download: true,
            filterType: 'select',
            filterSelectOptions: getFailureReasonFilterOptions(),
          },
        }
      ),
      columnHelper.accessor('calleeName', {
        header: 'Callee Name',
        enableSorting: false,
        meta: {
          download: true,
        },
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.accessor('taskCreationMillis', {
        header: 'Created On',
        enableGlobalFilter: false,
        meta: {
          download: true,
          filterType: 'daterange',
          downloadValue: (row) =>
            Number(row.original.taskCreationMillis) <= 0
              ? ' '
              : format(
                  new Date(possibleLongToNumber(row.original.taskCreationMillis)),
                  'yyyy-MM-dd HH:mm:ss'
                ),
        },
        cell: (info) =>
          info.getValue() !== 0 ? <DateField date={new Date(info.getValue() as number)} /> : '-',
      }),
      columnHelper.accessor('taskReviewMillis', {
        header: 'Completed On',
        enableGlobalFilter: false,
        meta: {
          download: true,
          filterType: 'daterange',
          downloadValue: (row) =>
            Number(row.original.taskReviewMillis) <= 0
              ? ' '
              : format(
                  new Date(possibleLongToNumber(row.original.taskReviewMillis)),
                  'yyyy-MM-dd HH:mm:ss'
                ),
        },
        cell: (info) =>
          info.getValue() !== 0 ? <DateField date={new Date(info.getValue() as number)} /> : '-',
      }),
      columnHelper.accessor('taskUuid', {
        id: 'taskUuid',
        header: 'Infinitus ID',
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        meta: {
          download: true,
        },
        cell: (info) => getUuidDisplayName(info.getValue()),
      }),
      columnHelper.accessor('programName', {
        header: 'Program ID',
        enableSorting: false,
        enableGlobalFilter: false,
        meta: {
          download: true,
          filterType: 'select',
          filterSelectOptions: getOrgPrograms.data?.map((program) => {
            return {
              value: program.name,
              label: program.displayName,
            };
          }),
        },
      }),
      columnHelper.accessor((original) => original.bvInputs?.productInfos, {
        id: 'productNames',
        header: 'Products',
        enableGlobalFilter: false,
        enableSorting: false,
        enableColumnFilter: hasProductsFilterEnabled,
        cell: (info) => {
          return info.getValue() ? (
            <Chips
              size="small"
              max={2}
              direction="column"
              labelSize={10}
              labels={info.getValue()?.map((product) => product.productName) as string[]}
            />
          ) : (
            '-'
          );
        },
        meta: {
          download: true,
          filterType: 'multiselect',
          filterSelectOptions: getProductNamesFromTasks(updatedTasks).map((product) => {
            return { value: product, label: product };
          }),
          downloadValue: (row) =>
            row.original.bvInputs?.productInfos
              ?.map((product) => product.productName)
              .join(', ') as string,
        },
      }),
      columnHelper.accessor(
        (originalRow) =>
          getTaskTypeColumnDisplayName(originalRow as infinitusai.be.CustomerTaskDoc),
        {
          id: 'taskType',
          header: 'Task Type',
          enableGlobalFilter: false,
          enableSorting: false,
          enableColumnFilter: hasTaskTypeFilterEnabled,
          cell: (info) => getTaskTypeColumnDisplayName(info.row.original),
          meta: {
            download: true,
            filterType: 'select',
            filterSelectOptions: getTaskTypeFilterOptionsPortal().map((key) => {
              return {
                value: key,
                label: key,
              };
            }),
          },
        }
      ),
      columnHelper.accessor('hasPotentialAdverseEvent', {
        header: 'Flagged',
        enableGlobalFilter: false,
        cell: (info) => (info.getValue()?.value ? <FlagRoundedIcon color="error" /> : '-'),
        meta: {
          download: true,
          filterType: 'boolean',
          downloadValue: (row) => (row.original.hasPotentialAdverseEvent ? 'Yes' : 'No'),
        },
      }),
      columnHelper.accessor('customerReview', {
        header: 'Reviewed',
        enableGlobalFilter: false,
        enableHiding: true,
        cell: (info) => (info.getValue() ? <FlagRoundedIcon color="success" /> : '-'),
        meta: {
          download: true,
          filterType: 'boolean',
          downloadValue: (row) => (row.original.customerReview ? 'Yes' : 'No'),
        },
      }),
      showTimeSavingsEnabled
        ? columnHelper.accessor('timeSavingsMinutes', {
            header: 'Time Saved',
            enableGlobalFilter: false,
            enableHiding: !showTimeSavingsEnabled,
            cell: (info) => {
              const isCompletedTask = isTaskInFinalState(info.row.original.state);
              const showTimeSavings =
                showTimeSavingsEnabled && info.getValue() > 0 && isCompletedTask;
              return showTimeSavings ? `${Math.ceil(info.getValue())} minutes` : '-';
            },
          })
        : null,
      showHumanInvolvementFlagEnabled
        ? columnHelper.accessor('humanInvolved', {
            header: 'Has Human Involvement',
            cell: (info) => {
              const isCompletedTask = isTaskInFinalState(info.row.original.state);
              return !info.getValue() || !isCompletedTask
                ? '-'
                : info.getValue()?.value
                ? 'Yes'
                : 'No';
            },
            meta: {
              filterType: 'boolean',
            },
          })
        : null,
      bundledTasksEnabled
        ? columnHelper.accessor('bundleTaskId', {
            header: 'Has Bundle Task',
            cell: (info) => {
              return info.getValue() ? 'Yes' : 'No';
            },
            meta: {
              filterType: 'boolean',
            },
          })
        : null,
      columnHelper.display({
        id: 'action',
        enableHiding: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (info) => (
          <Stack display="flex">
            <IconButton
              sx={{
                display: 'flex',
                justifyContent: 'right',
                ':hover': { bgcolor: 'transparent' },
              }}
              disableRipple
            >
              <NavigateNext />
            </IconButton>
          </Stack>
        ),
      }),
    ].filter(Boolean) as any[];
  }, [
    getOrgPrograms.data,
    hasProductsFilterEnabled,
    hasTaskTypeFilterEnabled,
    showHumanInvolvementFlagEnabled,
    showTimeSavingsEnabled,
    bundledTasksEnabled,
    taskStateFilter,
    updatedTasks,
  ]);

  const table = useTable({
    id: 'all-tasks',
    data: filteredTasks,
    columns,
    manualFiltering: true,
    isLoading: getTasks.isLoading,
    totalRows: filteredTasks.length,
    noRowsMessage: 'No tasks in this organization.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableDownload: true,
    enableColumnFilters: true,
    enableHiding: true,
    enableGlobalFilter: true,
    enableSavedFilters: true,
    enableUrlSync: true,
    getRowId: (row) => row.taskUuid,
    onRowClick: (row) => {
      navigate(`/${appState.org?.name}/tasks/${row.original.taskUuid}`);
    },
  });

  React.useEffect(() => {
    if (getTasks.isError) {
      enqueueSnackbar(`Please try again. We currently don't support that filter combination.`, {
        variant: 'error',
      });
    }
  }, [getTasks.isError]);

  return (
    <Box mt={3}>
      <Table
        table={table}
        overrides={{
          toolbar: <CustomToolbar table={table} />,
        }}
      />
    </Box>
  );
}

export default AllTasksTab;
