import { useAppState } from '@infinitusai/shared';
import { Table, useTable } from '@infinitusai/table';
import { possibleLongToNumber } from '@infinitusai/utils';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createColumnHelper } from '@tanstack/react-table';
import formatDistance from 'date-fns/formatDistance';
import * as React from 'react';
import { Link as RRLink } from 'react-router-dom';

import { useGetApiKeys } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

import DeleteButton from './DeleteButton';

function ApiKeysTab() {
  const appState = useAppState();
  const getApiKeys = useGetApiKeys();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.be.ApiKeyDoc>();
    return [
      columnHelper.accessor('keyName', {
        header: 'API Keys',
      }),
      columnHelper.accessor('generatedAtMillis', {
        header: 'Date Generated',
        cell: (info) =>
          formatDistance(possibleLongToNumber(info.getValue()), new Date(), {
            addSuffix: true,
          }),
      }),
      columnHelper.display({
        id: 'action',
        enableHiding: false,
        cell: (info) => (
          <Box display="flex" justifyContent="flex-end">
            <DeleteButton keyMetadata={info.row.original} />
          </Box>
        ),
      }),
    ];
  }, []);

  const table = useTable({
    id: 'api-keys',
    data: getApiKeys.data,
    columns,
    isLoading: getApiKeys.isLoading,
    totalRows: getApiKeys.data.length,
    noRowsMessage: 'There are no active API Keys. Get started by creating a new one above.',
    hideToolbar: true,
    hidePagination: true,
    enableSharing: false,
    enableSorting: false,
    enableFilters: false,
    enableGlobalFilter: true,
    enableUrlSync: true,
  });

  return (
    <>
      <Typography gutterBottom variant="body1">
        Infinitus provides an API to programmatically perform the functions that are available
        through this portal. <br />
        Consult our{' '}
        <Link component={RRLink} to={`/${appState.orgName}/docs/api`}>
          <strong>API Documentation</strong>
        </Link>{' '}
        for details on each available API endpoint.
      </Typography>
      <br />
      <Typography>
        Your API keys provide write-access to your Infinitus org and should be treated like
        passwords:
      </Typography>
      <ul>
        <li>
          Do <strong>not</strong> share them, or check them into code repositories.
        </li>
        <li>
          If you suspect one of your API keys may have been compromised you can{' '}
          <strong>disable</strong> them here.
        </li>
      </ul>
      <br />
      <Box>
        <Table table={table} />
      </Box>
    </>
  );
}
export default ApiKeysTab;
