import { FadeTransition, useBreakpoint } from '@infinitusai/ui';
import { Button, TextField } from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useState, ChangeEvent } from 'react';

import { useIncidentTypes, useCreateIncidentMutation } from 'api/customer';

import { getTaskUploadFeedbackType, getCreateIncidentFormData } from './utilsFeedback';

interface DialogImportFeedbackProps {
  open: boolean;
  onClose: () => void;
}

export default function DialogImportFeedback({ open, onClose }: DialogImportFeedbackProps) {
  const smBreakpoint = useBreakpoint('sm');
  const { enqueueSnackbar } = useSnackbar();

  // Query and mutations
  const incidentTypes = useIncidentTypes();
  const createIncident = useCreateIncidentMutation();

  // Local states
  const [description, setDescription] = useState('');
  const [score, setScore] = useState('');

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value as string);
  };

  const handleCreateIncident = async () => {
    const feedbackType = getTaskUploadFeedbackType(incidentTypes.data || []);
    if (!feedbackType) {
      // When feedbackType can not be found, close the dialog as we don't want this to be blocking
      console.error('feedbackType not found in getTaskUploadFeedbackType');
      return onClose();
    }
    const formData = getCreateIncidentFormData(feedbackType, description, score);

    await createIncident.mutateAsync(formData, {
      onSuccess: (_result) => {
        enqueueSnackbar(`Feedback successfully sent. Thank you for your feedback.`, {
          variant: 'success',
        });
      },
      onError: (error) => {
        enqueueSnackbar(`Upload failed: ${error.message}`, {
          variant: 'error',
        });
      },
    });

    // If upload was successful, reset form data
    if (!createIncident.isError) {
      setDescription('');
    }

    // Close the dialog because we don't want this to be blocking customers
    // when there is an issue on our end
    onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      open={open}
      onClose={onClose}
      TransitionComponent={FadeTransition}
    >
      <Box maxHeight={smBreakpoint ? '450px' : '80%'}>
        <DialogTitle variant="h5" id="confirm-dialog-title" display="flex">
          We would love your feedback
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" mt={1.0}>
            Let us know your experience with task creation.
          </Typography>

          <Typography mt={4.0} variant="subtitle1">
            How easy was it to import tasks? *
          </Typography>
          <Typography variant="body2" color="textSecondary">
            A score of 1 means extremely difficult and a score of 7 means extremely easy.
          </Typography>
          <Stack mt={1.0}>
            <Pagination
              color="primary"
              variant="outlined"
              hidePrevButton
              hideNextButton
              count={7}
              boundaryCount={7}
              page={parseInt(score) || 0}
              onChange={(_, pageNumber) => setScore(pageNumber.toString())}
            />
          </Stack>
          <Typography variant="subtitle1" mt={4.0} mb={1.0}>
            How can we improve the task import experience?
          </Typography>
          <TextField
            fullWidth
            debounce
            id="feedback-description"
            label="Share your thoughts and feedback..."
            multiline
            rows={2}
            size="medium"
            value={description}
            onChange={handleTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button size="large" color="primary" onClick={onClose}>
            Close
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleCreateIncident}
            disabled={incidentTypes.isLoading || createIncident.isLoading || !score}
          >
            Send Feedback
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
