import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  Button,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Checklist,
  useConfirm,
} from '@infinitusai/ui';
import { getArrayDifference, isArrayEqual } from '@infinitusai/utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';

import { useGetOrgRoles, useGetUserRoles, useRemoveRoleFromUser, useAddRoleToUser } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

interface Props {
  user?: infinitusai.auth.User;
  onClose: () => void;
}

function EditRolesDrawer({ user, onClose }: Props) {
  const confirm = useConfirm();
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const getOrgRoles = useGetOrgRoles();
  const getUserRoles = useGetUserRoles(user?.uid || '');
  const addOrgRoleToUser = useAddRoleToUser();
  const removeRoleFromUser = useRemoveRoleFromUser();
  const currentRoles = React.useMemo(
    () => getUserRoles.data?.map((role) => role.uuid) || [],
    [getUserRoles.data]
  );
  const hasOwnerPermission = hasPermission([PermissionName.CUSTOMER_OWNER]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleConfirmClose = () => {
    if (formik.dirty) {
      confirm({
        title: 'Abandon Assigning Roles?',
        description: "Are you sure you want to abandon role assignments you've made?",
        footnote: 'If you proceed, role assignments progress will be lost.',
        confirmText: 'Abandon Role Assignments',
        onConfirm: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { roles: currentRoles },
    onSubmit: async (values) => {
      const rolesToAdd = getArrayDifference(values.roles, currentRoles);
      const rolesToRemove = getArrayDifference(currentRoles, values.roles);
      for (const uuid of rolesToAdd) {
        const req = infinitusai.auth.AddRoleToUserRequest.fromObject({
          userUid: user?.uid || '',
          roleUuid: uuid,
        });
        await addOrgRoleToUser.mutateAsync(req);
      }
      for (const uuid of rolesToRemove) {
        const req = infinitusai.auth.RemoveRoleFromUserRequest.fromObject({
          userUid: user?.uid || '',
          roleUuid: uuid,
        });
        await removeRoleFromUser.mutateAsync(req);
      }
      enqueueSnackbar(`Role Successfully Assigned`, {
        variant: 'success',
      });
      onClose();
    },
  });

  return (
    <React.Fragment>
      <DrawerHeader>
        <IconButton onClick={handleConfirmClose}>
          <ArrowBackIcon sx={{ fontSize: 25 }} />
        </IconButton>
      </DrawerHeader>
      <DrawerBody>
        <Typography variant="overline" sx={{ mx: 2 }}>
          Roles Assigned To {user?.email}
        </Typography>
        <Box px={2}>
          <Checklist
            value={formik.values.roles}
            options={
              getOrgRoles.data.map((role) => ({
                value: role.uuid,
                label: role.name,
                disabled: role.readOnly && !hasOwnerPermission,
              })) || []
            }
            onChange={(_, newValue) => formik.setFieldValue('roles', newValue)}
          />
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          sx={{ mr: 2 }}
          size="large"
          variant="outlined"
          onClick={handleConfirmClose}
          unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
        >
          Back
        </Button>
        <Button
          size="large"
          sx={{ ml: 2 }}
          variant="contained"
          onClick={formik.submitForm}
          disabled={
            !formik.dirty ||
            formik.values.roles.length === 0 ||
            isArrayEqual(formik.values.roles, currentRoles) ||
            addOrgRoleToUser.isLoading ||
            removeRoleFromUser.isLoading
          }
          unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
        >
          Update Roles
        </Button>
      </DrawerFooter>
    </React.Fragment>
  );
}

export default EditRolesDrawer;
