import { downloadCsvFromString } from '@infinitusai/utils';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as IconError } from 'assets/progress-call-failed.svg';

interface Props {
  filename: string;
  csvString: string;
  specSearchParam: string;
  error: any;
}

const FileNotProcessed: React.FC<Props> = ({ filename, csvString, error, specSearchParam }) => {
  const { orgName } = useParams<{ orgName: string }>();
  const formattedErrorMessage = error.message.charAt(0).toUpperCase() + error.message.slice(1);

  return (
    <Box width="100%">
      <Grid container alignItems="center">
        <IconError
          style={{ alignContent: 'center', display: 'flexbox', justifyContent: 'center' }}
        />
        <Typography
          variant="h4"
          sx={{ display: 'flexbox', textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}
        >
          CSV/XLS/XLSX file could not be processed
        </Typography>
      </Grid>

      <Box mt={2} mb={1} sx={{ marginLeft: 2 }}>
        <Typography variant="body2">
          We could not process your file,{' '}
          <Link component="button" onClick={() => downloadCsvFromString(csvString, filename)}>
            {filename}
          </Link>{' '}
          due to the following errors:
        </Typography>
      </Box>

      <Card sx={{ mb: 1, display: 'flex', justifyContent: 'center', marginLeft: 1 }}>
        <CardContent>
          <List>
            {formattedErrorMessage.split('  ').map((errorMessage: string) => (
              <ListItem
                key={errorMessage}
                sx={{
                  wordBreak: 'break-all',
                }}
              >
                {errorMessage}
              </ListItem>
            ))}
          </List>
          <Typography sx={{ fontSize: 14 }}>
            Please check the csv matches the task template requirements.
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="body2" sx={{ marginLeft: 2 }}>
        Reference our{' '}
        <Link
          href={`/${orgName}/fields-reference?taskType=${specSearchParam}`}
          target="_blank"
          rel="noopener"
        >
          CSV format specification
        </Link>{' '}
        to correct these errors, and then try again below:
      </Typography>
    </Box>
  );
};

export default FileNotProcessed;
