import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  TextField,
  useConfirm,
} from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState, ChangeEvent } from 'react';

import { ipRegex } from 'utils/validations';

interface Props {
  open: boolean;
  onClose: () => void;
  updateMutation: any;
  formik: any;
  form: any;
}

function IpAddressDrawer({ open, onClose, updateMutation, formik, form }: Props) {
  const confirm = useConfirm();
  const [newIpAddress, setNewIpAddress] = useState('');
  const [isIpAddressValid, setIsIpAddressValid] = useState(true);
  const [isDuplicated, setIsDuplicated] = useState(false);

  // Create a hash table to check duplication in IP addresses
  const hash: { [key: string]: boolean } = form.values.whitelistedIpAddresses.reduce(
    (map: { [key: string]: boolean }, ipAddress: string) => {
      map[ipAddress] = true;
      return map;
    },
    {}
  );

  const handleAbandonAdding = () => {
    setNewIpAddress('');
    onClose();
  };

  const handleClose = () => {
    const hasUnsavedChanges = newIpAddress.length > 0;
    if (hasUnsavedChanges) {
      confirm({
        title: 'Abandon Adding an IP Address',
        description: 'Are you sure you want to abandon adding a new IP address?',
        footnote: 'If you proceed, the progress of adding a new IP address will be lost.',
        confirmText: 'Abandon New IP Address',
        onConfirm: handleAbandonAdding,
      });
    } else {
      onClose();
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setNewIpAddress(value);
    if (value.length === 0) {
      setIsIpAddressValid(true);
    } else {
      // Validate only the new IP address
      setIsIpAddressValid(ipRegex.test(value));
      setIsDuplicated(hash[value] === true);
    }
  };

  const handleAdd = async () => {
    if (newIpAddress.length > 0) {
      form.setFieldValue('whitelistedIpAddresses', [
        ...form.values.whitelistedIpAddresses,
        newIpAddress,
      ]);
      await formik.submitForm();
      setNewIpAddress('');
    }
    onClose();
  };

  return (
    <Drawer open={open} onClose={handleClose}>
      <DrawerHeader title="Add a Valid IP Address" onClose={handleClose} />
      <DrawerBody>
        <Typography variant="overline" sx={{ mx: 2 }}>
          ENTER A NEW VALID IP ADDRESS
        </Typography>
        <TextField
          debounce
          id="ip_address"
          label="IP Address"
          value={newIpAddress}
          onChange={handleTextChange}
          size="medium"
          helperText="Specify individual IPs or IP address ranges (ex. 192.168.0.1 or 192.168.0.1/16"
          inputProps={{ maxLength: 50 }}
          sx={{ m: 2, mt: 1 }}
        />
        {!isIpAddressValid && ( // conditionally render error message
          <Typography variant="body2" color="error" sx={{ ml: 2 }}>
            Invalid IP Address
          </Typography>
        )}
        {isDuplicated && ( // conditionally render error message
          <Typography variant="body2" color="error" sx={{ ml: 2 }}>
            Duplicated IP Address
          </Typography>
        )}
      </DrawerBody>
      <DrawerFooter>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" size="large" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={handleAdd}
          disabled={
            newIpAddress.length === 0 ||
            !isIpAddressValid ||
            isDuplicated ||
            updateMutation.isLoading
          }
          sx={{ ml: 2 }}
        >
          Add IP Address
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}

export default IpAddressDrawer;
