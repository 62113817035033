import { useAuth, PermissionName } from '@infinitusai/auth';
import { useConfirm } from '@infinitusai/ui';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCancelInvite, useAssignRolesOrCreateInvite } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

interface Props {
  invite: infinitusai.auth.Invite;
  onUpdate: (inviteUuid: string) => void;
}

function InviteMenu({ invite, onUpdate }: Props) {
  const auth = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const cancelInvite = useCancelInvite();
  const assignRolesOrCreateInvite = useAssignRolesOrCreateInvite();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const hasRbacAssignPermission = auth.hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN]);

  const handleMenuOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewEditClick = () => {
    handleMenuClose();
    searchParams.set('show', invite.uuid);
    setSearchParams(searchParams, { replace: true });
  };

  const handleCancelClick = () => {
    handleMenuClose();

    confirm({
      title: 'Cancel Invite?',
      description: 'Are you sure you want to cancel the invite for "' + invite.inviteeEmail + '"?',
      cancelText: 'Never mind',
      confirmText: 'Yes, Cancel Invite',
      onConfirm: () => {
        const req = infinitusai.auth.CancelInviteRequest.fromObject({
          uuid: invite.uuid,
        });
        cancelInvite.mutate(req, {
          onSuccess: () => {
            enqueueSnackbar(`Invite successfully cancelled`, {
              variant: 'success',
            });
          },
          onError: (error) => {
            enqueueSnackbar(`Cancel invite unsuccessful: ${error.message}`, {
              variant: 'error',
            });
          },
        });
      },
    });
  };

  const handleResendClick = () => {
    handleMenuClose();

    // resend invite
    const req = infinitusai.auth.AssignRolesOrCreateInviteRequest.fromObject({
      inviteeEmail: invite.inviteeEmail,
      roleUuids: invite.roleUuids,
      type: infinitusai.auth.InviteType.INVITE_TYPE_CUSTOMER,
    });
    assignRolesOrCreateInvite.mutate(req, {
      onSuccess: () => {
        onUpdate(invite.uuid);
        enqueueSnackbar(`Invite successfully re-sent`, {
          variant: 'success',
        });
      },
      onError: (error) => {
        enqueueSnackbar(`Resending invite unsuccessful: ${error.message}`, {
          variant: 'error',
        });
      },
    });
  };

  if (!hasRbacAssignPermission) return null;

  return (
    <React.Fragment>
      <IconButton
        disableRipple
        onClick={handleMenuOpen}
        sx={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        keepMounted
        id="invite-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleViewEditClick()}>Edit Invite</MenuItem>
        <MenuItem onClick={() => handleCancelClick()}>Cancel Invite</MenuItem>
        <MenuItem onClick={() => handleResendClick()}>Resend Invite</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default InviteMenu;
