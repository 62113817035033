import { PermissionName, useAuth } from '@infinitusai/auth';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  TextField,
  FadeTransition,
  useConfirm,
  useBreakpoint,
} from '@infinitusai/ui';
import { getArrayDifference, possibleLongToNumber } from '@infinitusai/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import {
  useGetRoleUsers,
  useGetPermissions,
  useGetRolePermissions,
  useCreateRole,
  useUpdateRole,
  useDeleteRole,
  useAddPermissionToRole,
  useRemovePermissionFromRole,
} from 'api/auth';
import { infinitusai } from 'proto/pbjs';

interface PermissionMetadata {
  checked: boolean;
  permission: infinitusai.auth.Permission;
}

interface Props {
  open: boolean;
  role: infinitusai.auth.Role | null;
  onClose: () => void;
  onUpdateOrCreate: (roleUuid: string) => void;
}

function RoleDrawer({ open, role, onClose, onUpdateOrCreate }: Props) {
  const smBreakpoint = useBreakpoint('sm');
  const auth = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const getPermissions = useGetPermissions();
  const getRolePermissions = useGetRolePermissions(role?.uuid || '');
  const getRoleUsers = useGetRoleUsers(role?.uuid || '');
  const createRole = useCreateRole();
  const updateRole = useUpdateRole();
  const deleteRole = useDeleteRole();
  const addPermissionToRole = useAddPermissionToRole();
  const removePermissionFromRole = useRemovePermissionFromRole();
  const [permissionsExpanded, setPermissionsExpanded] = React.useState<Set<string>>(new Set());
  const [openDeleteRoleDialog, setOpenDeleteRoleDialog] = React.useState(false);
  const [roleConfirmDeletionText, setRoleConfirmDeletionText] = React.useState('');
  const portalAccessPermissionUuid = React.useMemo(
    () =>
      getPermissions?.data.find(
        (permission) =>
          permission.name === infinitusai.auth.PermissionName.PERMISSION_CUSTOMER_PORTAL_ACCESS
      )?.uuid || '',
    [getPermissions.data]
  );
  const initialPermissions = React.useMemo(() => {
    const permissions = getRolePermissions.data?.map((permission) => permission.uuid) ?? [];
    if (
      portalAccessPermissionUuid &&
      !permissions.includes(portalAccessPermissionUuid) &&
      !role?.name
    ) {
      permissions.push(portalAccessPermissionUuid);
    }
    return permissions;
  }, [getRolePermissions.data, portalAccessPermissionUuid, role?.name]);
  const rbacEnabled = auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE]);

  const handleExpandAll = () => {
    setPermissionsExpanded(new Set(Object.keys(permissionMap)));
  };

  const handleCollapseAll = () => {
    setPermissionsExpanded(new Set());
  };

  const permissionMap = React.useMemo(
    () =>
      getPermissions.data.reduce((value, cur) => {
        const permissionCategory = cur.category;
        if (!value[permissionCategory]) {
          value[permissionCategory] = [];
        }
        const permissionMetadata: PermissionMetadata = {
          permission: cur,
          checked: getPermissions.data?.find((perm) => perm.uuid === cur.uuid) !== undefined,
        };
        value[permissionCategory].push(permissionMetadata);
        return value;
      }, {} as { [key: string]: PermissionMetadata[] }) || {},
    [getPermissions.data]
  );

  const handleAbandonCreate = () => {
    formik.resetForm();
    setPermissionsExpanded(new Set());
    onClose();
  };

  const handleAbandonUpdate = () => {
    setPermissionsExpanded(new Set());
    onClose();
  };

  const handleClose = () => {
    // delete the role or show a warning if there are unsaved changes
    const isNewRole = !role;
    const hasUnsavedChanges = formik.dirty;
    if (hasUnsavedChanges && isNewRole) {
      confirm({
        title: 'Abandon Role Creation?',
        description: 'Are you sure you want to abandon role creation?',
        footnote: 'If you proceed, role creation progress will be lost.',
        confirmText: 'Abandon Role Creation',
        onConfirm: handleAbandonCreate,
      });
    } else if (hasUnsavedChanges && !isNewRole) {
      confirm({
        title: 'Abandon Role Edits?',
        description: "Are you sure you want to abandon role edits you've made?",
        footnote: 'If you proceed, role edits progress will be lost.',
        confirmText: 'Abandon Role Edits',
        onConfirm: handleAbandonUpdate,
      });
    } else {
      setPermissionsExpanded(new Set());
      onClose();
      setOpenDeleteRoleDialog(false);
      setRoleConfirmDeletionText('');
    }
  };

  // Compare checked to activePermissions and send appropriate api request
  const updatePermissions = () => {
    const rolePermissionUUIDs = getRolePermissions.data?.map((permission) => permission.uuid) ?? [];
    const permissionsToRemove = getArrayDifference(rolePermissionUUIDs, formik.values.permissions);
    const permissionsToAdd = getArrayDifference(formik.values.permissions, rolePermissionUUIDs);
    for (const uuid of permissionsToRemove) {
      const req = infinitusai.auth.RemovePermissionFromRoleRequest.fromObject({
        roleUuid: role?.uuid,
        permissionUuid: uuid,
      });
      removePermissionFromRole.mutate(req);
    }
    for (const uuid of permissionsToAdd) {
      const req = infinitusai.auth.AddPermissionToRoleRequest.fromObject({
        roleUuid: role?.uuid || '',
        permissionUuid: uuid,
      });
      addPermissionToRole.mutate(req);
    }
  };

  const categoryIsChecked = (permissionMetadata?: PermissionMetadata[]) => {
    if (permissionMetadata) {
      for (const metadata of permissionMetadata) {
        if (!formik.values.permissions.includes(metadata.permission.uuid)) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const handleDelete = () => {
    if (roleConfirmDeletionText === role?.name || role?.userCount === 0) {
      const reqBody = infinitusai.auth.DeleteRoleRequest.fromObject({
        uuid: role?.uuid,
      });
      deleteRole.mutate(reqBody, {
        onSuccess: () => {
          handleClose();
          var snackBarMessage = 'Successfully deleted role';
          if (role?.userCount && possibleLongToNumber(role?.userCount) > 0) {
            snackBarMessage += ' and removed from ' + role?.userCount.toString() + ' members';
          }
          enqueueSnackbar(snackBarMessage, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        },
      });
    }
  };

  const formatRoleUsers = () => {
    const userCount = Number(role?.userCount);
    let formattedUsers = '';

    for (let [index, user] of Object.entries(getRoleUsers.data ?? [])) {
      let name = user.displayName || user.email;
      if (role?.userCount === 1) {
        formattedUsers += ` ${name}`;
        break;
      }
      if (Number(index) < 3) {
        formattedUsers += `${Number(index) === userCount - 1 ? ` & ${name}.` : ` ${name},`} `;
      }
      if (Number(index) === 2) {
        formattedUsers += ` & ${userCount - Number(index) - 1} other${
          1 < userCount - Number(index) ? `s` : ''
        }.`;
        break;
      }
    }
    return formattedUsers;
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    if (newExpanded) {
      setPermissionsExpanded(new Set(permissionsExpanded.add(panel)));
    } else {
      setPermissionsExpanded((permissionsExpanded) => {
        const newPermissionsExpanded = new Set(permissionsExpanded);
        newPermissionsExpanded.delete(panel);
        return newPermissionsExpanded;
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: role?.name ?? '',
      description: role?.description ?? '',
      permissions: initialPermissions,
    },

    onSubmit: (values) => {
      if (role) {
        confirm({
          title: 'Are you sure you want to edit this role?',
          description: `There ${
            role.userCount === 1 ? `is ${role.userCount} user` : `are ${role.userCount} users`
          } that will be affected with these role changes.`,
          confirmText: 'Confirm Edit',
          onConfirm: () => {
            // Update role
            updatePermissions();
            const req = infinitusai.auth.UpdateRoleRequest.fromObject({
              uuid: role.uuid,
              name: values.name,
              description: values.description,
            });
            updateRole.mutate(req, {
              onSuccess: (res) => {
                enqueueSnackbar(`Successfully updated role: ${values.name}`, {
                  variant: 'success',
                  autoHideDuration: 2000,
                });
                onClose();
                formik.resetForm();
                onUpdateOrCreate(res.role?.uuid || '');
              },
              onError: (res) => {
                enqueueSnackbar(`Failed to update role: ${res.message}`, {
                  variant: 'error',
                  autoHideDuration: 2000,
                });
              },
            });
          },
        });
      } else {
        // Create a new role
        const reqBody = infinitusai.auth.CreateRoleRequest.fromObject({
          name: values.name,
          description: values.description,
          type: infinitusai.auth.RoleType.ROLE_TYPE_CUSTOMER,
          readOnly: false,
        });
        createRole.mutate(reqBody, {
          onSuccess: (res) => {
            // Add permissions to role
            for (const permissionUUID of formik.values.permissions) {
              const req = infinitusai.auth.AddPermissionToRoleRequest.fromObject({
                roleUuid: res.role?.uuid,
                permissionUuid: permissionUUID,
              });
              addPermissionToRole.mutate(req);
            }
            enqueueSnackbar(`Successfully created role: ${values.name}`, {
              variant: 'success',
              autoHideDuration: 2000,
            });
            onClose();
            formik.resetForm();
            onUpdateOrCreate(res.role?.uuid || '');
          },
          onError: (err) => {
            enqueueSnackbar(`Failed to create role: ${err.message}`, {
              variant: 'error',
              autoHideDuration: 2000,
            });
          },
        });
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Drawer open={open} onClose={handleClose}>
        <DrawerHeader title={role?.name || 'Create New Role'} onClose={handleClose} />
        <DrawerBody>
          {!role ? (
            <Typography variant="overline" sx={{ mx: 2 }}>
              DEFINE THE ROLE
            </Typography>
          ) : null}
          <TextField
            debounce
            id="name"
            label="Role Name"
            size="medium"
            disabled={role?.readOnly || !rbacEnabled}
            unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
            helperText="Add a name that will help you find this role later on (30 character max*)"
            inputProps={{ maxLength: 30 }}
            sx={{ m: 2, mt: 1 }}
            {...formik.getFieldProps('name')}
          />
          <TextField
            debounce
            id="description"
            label="Role Description"
            size="medium"
            disabled={role?.readOnly || !rbacEnabled}
            unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
            helperText="Use a few words to describe what's contained in this role (100 character max*)"
            inputProps={{ maxLength: 100 }}
            sx={{ ml: 2, mr: 2 }}
            {...formik.getFieldProps('description')}
          />
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" mr={2}>
            <Typography variant="overline" sx={{ mx: 2 }}>
              {role ? 'PERMISSIONS' : 'ADD PERMISSIONS'}
            </Typography>
            {permissionsExpanded.size === Object.keys(permissionMap).length ? (
              <Button color="primary" onClick={handleCollapseAll}>
                Collapse All
              </Button>
            ) : (
              <Button color="primary" onClick={handleExpandAll}>
                Expand All
              </Button>
            )}
          </Box>
          <FieldArray
            name="permissions"
            render={(arrayHelpers) => (
              <div className="checked">
                {Object.keys(permissionMap).map((category) => {
                  const checkedPermissionsLength = permissionMap[category].filter(
                    (permissionMetadata) => {
                      return formik.values.permissions.includes(permissionMetadata.permission.uuid);
                    }
                  ).length;
                  const checkedPermissions =
                    checkedPermissionsLength !== 0 &&
                    checkedPermissionsLength !== permissionMap[category].length;

                  return (
                    <div key={category}>
                      <Accordion
                        defaultExpanded
                        disableGutters
                        square
                        elevation={0}
                        sx={{
                          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                          ':not(:last-child)': {
                            borderBottom: 0,
                          },
                          ':before': {
                            display: 'none',
                          },
                        }}
                        expanded={permissionsExpanded.has(category)}
                        onChange={handleChange(category)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon fontSize="medium" />}
                          sx={{
                            flexDirection: 'row-reverse',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                          }}
                        >
                          <Checkbox
                            sx={{
                              p: 0,
                              ml: 4,
                            }}
                            size="small"
                            id={category}
                            disabled={
                              role?.readOnly ||
                              !rbacEnabled ||
                              (category === 'Portal' &&
                                categoryIsChecked(permissionMap[category])) ||
                              !auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])
                            }
                            checked={categoryIsChecked(permissionMap[category])}
                            indeterminate={checkedPermissions}
                            onClick={(event) => {
                              if (permissionsExpanded.has(category)) {
                                event.stopPropagation();
                              }
                            }}
                            onChange={(event) => {
                              if (permissionMap[category]) {
                                let permissionsToRemove: string[] = [];
                                for (const metadata of permissionMap[category]) {
                                  if (event.target.checked) {
                                    if (
                                      !formik.values.permissions.includes(metadata.permission.uuid)
                                    ) {
                                      arrayHelpers.push(metadata.permission.uuid);
                                    }
                                  } else {
                                    if (
                                      formik.values.permissions.includes(metadata.permission.uuid)
                                    ) {
                                      permissionsToRemove.push(metadata.permission.uuid);
                                    }
                                  }
                                }
                                if (permissionsToRemove.length > 0) {
                                  formik.setFieldValue(
                                    'permissions',
                                    formik.values.permissions.filter(
                                      (uuid) => !permissionsToRemove.includes(uuid)
                                    )
                                  );
                                }
                              }
                            }}
                            disableRipple
                          />
                          <Box
                            display="flex"
                            flexDirection={smBreakpoint ? 'row' : 'column'}
                            ml={3}
                            gap={1}
                          >
                            <Typography variant="subtitle2">{category}</Typography>
                            <Typography variant="subtitle2" fontWeight={500} color="textSecondary">
                              {checkedPermissions &&
                                `${
                                  smBreakpoint ? '· ' : ''
                                } ${checkedPermissionsLength} permission${
                                  checkedPermissionsLength !== 1 ? 's' : ''
                                } selected`}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        {permissionMap[category].map((permissionMetadata) => (
                          <AccordionDetails
                            key={permissionMetadata.permission.uuid}
                            sx={{ p: 0, m: 0, display: 'flex' }}
                          >
                            <Box width="100%" mr={2}>
                              <Box ml={10} display="flex">
                                <Checkbox
                                  size="small"
                                  name="checked"
                                  id={permissionMetadata.permission.uuid}
                                  checked={formik.values.permissions.includes(
                                    permissionMetadata.permission.uuid
                                  )}
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      arrayHelpers.push(permissionMetadata.permission.uuid);
                                    } else {
                                      const idx = formik.values.permissions.indexOf(
                                        permissionMetadata.permission.uuid
                                      );
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                  disableRipple
                                  disabled={
                                    role?.readOnly ||
                                    !rbacEnabled ||
                                    (permissionMetadata.permission.uuid ===
                                      portalAccessPermissionUuid &&
                                      formik.values.permissions.includes(
                                        permissionMetadata.permission.uuid
                                      )) ||
                                    !auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])
                                  }
                                  sx={{ p: 0, ml: 4 }}
                                />
                                <Stack ml={4} mb={1}>
                                  <Typography variant="subtitle2" color="textSecondary">
                                    {permissionMetadata.permission.displayName}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {permissionMetadata.permission.description}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        ))}
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            )}
          />
        </DrawerBody>
        <DrawerFooter>
          <Box sx={{ flexGrow: 1 }} />
          {!role || (role && formik.dirty) ? (
            <Button
              size="large"
              variant="outlined"
              unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
              onClick={handleClose}
            >
              Cancel
            </Button>
          ) : (
            <Button
              size="large"
              color="error"
              variant="outlined"
              disabled={role?.readOnly || !rbacEnabled}
              unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
              onClick={() => setOpenDeleteRoleDialog(true)}
            >
              Delete Role
            </Button>
          )}
          <Button
            size="large"
            variant="contained"
            onClick={formik.submitForm}
            unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
            disabled={
              updateRole.isLoading ||
              createRole.isLoading ||
              formik.values.name.length === 0 ||
              formik.values.permissions.length === 0 ||
              !formik.dirty
            }
            sx={{ ml: 2 }}
          >
            {!role ? 'Create New Role' : 'Save Changes'}
          </Button>
        </DrawerFooter>
      </Drawer>
      <Dialog
        fullWidth
        open={openDeleteRoleDialog}
        onClose={handleClose}
        TransitionComponent={FadeTransition}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Role Permanently?</DialogTitle>
        <DialogContent sx={{ p: '16px 24px' }}>
          <DialogContentText id="confirm-dialog-description">
            This action <b>cannot</b> be undone. This will permanently delete the role
            <b> {role?.name}</b> and remove the role from all assigned members of the organization.
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }} id="confirm-dialog-description2">
            {role?.userCount === 0
              ? '0 members assigned to this role.'
              : `Members assigned this role are as follows: `}
            <b>{formatRoleUsers()}</b>
          </DialogContentText>
          {role?.userCount !== 0 ? (
            <React.Fragment>
              <DialogContentText sx={{ mt: 2 }} id="confirm-dialog-description">
                Please type the role name below to confirm deletion: <b>{role?.name}</b>
              </DialogContentText>
              <div style={{ marginTop: '16px' }} id="confirm-dialog-confirmDeleteTextField">
                <TextField
                  debounce
                  fullWidth
                  variant="outlined"
                  label="Type Role Name To Confirm Deletion"
                  InputLabelProps={{ sx: { textTransform: 'capitalize' } }}
                  value={roleConfirmDeletionText}
                  onChange={(e) => setRoleConfirmDeletionText(e.target.value)}
                />
              </div>
            </React.Fragment>
          ) : null}
        </DialogContent>
        <DialogActions sx={{ mb: 1, pr: 2 }}>
          <Button onClick={() => setOpenDeleteRoleDialog(false)}>Cancel</Button>
          <Button
            onClick={handleDelete}
            autoFocus
            disabled={
              role?.userCount === 0
                ? false
                : roleConfirmDeletionText !== role?.name || roleConfirmDeletionText === ''
            }
            color="error"
            disableRipple
          >
            I Understand, Delete This Role
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
}

export default RoleDrawer;
