import { infinitusai } from 'proto/pbjs';

import { getTaskFailureReason } from '.';

type TaskTypeKey = keyof typeof infinitusai.tasks.INFTaskType;

export const TypedObjectValues = Object.values as <T>(o: T) => Extract<T[keyof T], string>[];

export function getUuidDisplayName(uuid: string) {
  if (!/-/.test(uuid)) return uuid;
  return uuid.split('-')[0];
}

export const getTaskTypeDisplayName = (
  taskType: infinitusai.tasks.TaskType | infinitusai.tasks.INFTaskType | TaskTypeKey
): string => {
  const enumValue =
    typeof taskType === 'string' ? infinitusai.tasks.INFTaskType[taskType] : taskType;

  switch (enumValue) {
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION:
      return 'Major Medical';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER:
      return 'PBM';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER:
      return 'Prescription Transfer';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS:
      return 'Prescription Savings';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP:
      return 'Claims Follow Up';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
      return 'Prior Auth Follow Up';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION:
      return 'Dental BV';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_SHIPMENT_FOLLOWUP:
      return 'Shipment Follow Up';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH:
      return 'Patient Outreach';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH:
      return 'Provider Outreach';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK:
      return 'Pharmacy Stock Check';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BASIC_BV:
      return 'Basic Benefits Verification';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL:
      return 'Fasttrack Claims Appeal';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL:
      return 'Fasttrack Claims Denial';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP:
      return 'Fasttrack Claims Follow Up';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_MM:
      return 'Fasttrack Major Medical';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_PBM:
      return 'Fasttrack PBM';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PBM_DISCOVERY:
      return 'PBM Discovery';
    default:
      return 'Unknown';
  }
};

export const getTaskTypeDisplayNamePortal = (
  taskType: infinitusai.be.TaskType | keyof typeof infinitusai.be.TaskType
): string => {
  if (!taskType) return 'Unknown';
  const enumValue = typeof taskType === 'string' ? infinitusai.be.TaskType[taskType] : taskType;
  switch (enumValue) {
    case infinitusai.be.TaskType.TASK_TYPE_FULL_BI:
      return 'Major Medical';
    case infinitusai.be.TaskType.TASK_TYPE_PBM_BV:
      return 'PBM';
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER:
      return 'Prescription Transfer';
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS:
      return 'Prescription Savings';
    case infinitusai.be.TaskType.TASK_TYPE_CLAIMS_FOLLOWUP:
      return 'Claims Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
      return 'Prior Auth Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_DENTAL_BV:
      return 'Dental BV';
    case infinitusai.be.TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP:
      return 'Shipment Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_PATIENT_OUTREACH:
      return 'Patient Outreach';
    case infinitusai.be.TaskType.TASK_TYPE_PROVIDER_OUTREACH:
      return 'Provider Outreach';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_MM:
      return 'Major Medical';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_PBM:
      return 'Pharmacy Benefit Manager';
    case infinitusai.be.TaskType.TASK_TYPE_PHARMACY_STOCK_CHECK:
      return 'Pharmacy Stock Check';
    case infinitusai.be.TaskType.TASK_TYPE_BASIC_BV:
      return 'Basic Benefits Verification';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_APPEAL:
      return 'Fasttrack Claims Appeal';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_DENIAL:
      return 'Fasttrack Claims Denial';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP:
      return 'Fasttrack Claims Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_PBM_DISCOVERY:
      return 'PBM Discovery';
    default:
      return 'Unknown';
  }
};

export const getTaskTypeColumnDisplayName = (task: infinitusai.be.CustomerTaskDoc) => {
  const type =
    typeof task.taskType === 'string'
      ? infinitusai.be.TaskType[task.taskType]
      : (task.taskType as infinitusai.be.TaskType);
  const chainPbm = task.chainPbm;
  const chainBv = task.chainBv;

  if (type === infinitusai.be.TaskType.TASK_TYPE_FULL_BI && chainPbm) {
    return 'Major Medical and PBM';
  }
  if (type === infinitusai.be.TaskType.TASK_TYPE_PBM_BV && chainBv) {
    return 'PBM and Major Medical';
  }
  return getTaskTypeDisplayNamePortal(type as infinitusai.be.TaskType);
};

export const getTaskTypeSearchParamDisplayName = (
  taskType: infinitusai.tasks.INFTaskType
): string => {
  switch (taskType) {
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION:
      return 'majorMedical';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER:
      return 'pbm';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER:
      return 'prescriptionTransfer';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS:
      return 'prescriptionSavings';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP:
      return 'claimsFollowUp';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
      return 'priorAuthFollowUp';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK:
      return 'pharmacyStockCheck';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH:
      return 'providerOutreach';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH:
      return 'patientOutreach';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_MM:
      return 'fasttrackMajorMedical';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_PBM:
      return 'fasttrackPbm';
    case infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PBM_DISCOVERY:
      return 'pbmDiscovery';
    default:
      return 'unknown';
  }
};

export const getRuleTaskTypeDisplayName = (taskType: string): string => {
  switch (taskType) {
    case 'TASK_TYPE_FULL_BI':
      return 'Major Medical';
    case 'TASK_TYPE_PBM_BV':
      return 'PBM';
    case 'TASK_TYPE_PRESCRIPTION_TRANSFER':
      return 'Prescription Transfer';
    case 'TASK_TYPE_PRESCRIPTION_SAVINGS':
      return 'Prescription Savings';
    case 'TASK_TYPE_CLAIMS_FOLLOWUP':
      return 'Claims Follow Up';
    case 'TASK_TYPE_PRIOR_AUTH_FOLLOWUP':
      return 'Prior Auth Follow Up';
    case 'TASK_TYPE_DENTAL_BV':
      return 'Dental BV';
    case 'TASK_TYPE_PHARMACY_STOCK_CHECK':
      return 'Pharmacy Stock Check';
    case 'TASK_TYPE_PROVIDER_OUTREACH':
      return 'Provider Outreach';
    case 'TASK_TYPE_PATIENT_OUTREACH':
      return 'Patient Outreach';
    default:
      return 'Unknown';
  }
};

export const getFacilityTypeDisplayName = (
  facilityType: infinitusai.be.FacilityType.Type
): string => {
  if (!facilityType) return 'Unknown';
  const enumValue =
    typeof facilityType === 'string'
      ? infinitusai.be.FacilityType.Type[facilityType]
      : facilityType;
  switch (enumValue) {
    case infinitusai.be.FacilityType.Type.HOSPITAL_OUTPATIENT:
      return 'Hospital Outpatient';
    case infinitusai.be.FacilityType.Type.PHARMACY:
      return 'Pharmacy';
    case infinitusai.be.FacilityType.Type.SPECIALIST_OFFICE:
      return 'Specialist Office';
    case infinitusai.be.FacilityType.Type.SHIP_TO_HOME:
      return 'Ship to Home';
    case infinitusai.be.FacilityType.Type.INFUSION_CENTER:
      return 'Infusion Center';
    case infinitusai.be.FacilityType.Type.AMBULATORY_SURGICAL_CENTER:
      return 'Ambulatory Surgical Center';
    case infinitusai.be.FacilityType.Type.HOME_INFUSION:
      return 'Home Infusion';
    case infinitusai.be.FacilityType.Type.FED_QUALIFIED_HC:
      return 'Federally Qualified Health Center';
    case infinitusai.be.FacilityType.Type.HOSPICE:
      return 'Hospice';
    case infinitusai.be.FacilityType.Type.HOSPITAL_INPATIENT:
      return 'Hospital Inpatient';
    case infinitusai.be.FacilityType.Type.HOSPITAL_OUTPATIENT_OFF_CAMPUS:
      return 'Hospital Off-Campus Outpatient';
    case infinitusai.be.FacilityType.Type.IHC:
      return 'IHC';
    case infinitusai.be.FacilityType.Type.INPATIENT_REHAB:
      return 'Inpatient Rehab';
    case infinitusai.be.FacilityType.Type.OUTPATIENT_REHAB:
      return 'Outpatient Rehab';
    case infinitusai.be.FacilityType.Type.PUBLIC_HEALTH_CLINIC:
      return 'Public Health Clinic';
    case infinitusai.be.FacilityType.Type.RURAL_HEALTH_CLINIC:
      return 'Rural Health Clinic';
    case infinitusai.be.FacilityType.Type.SNF:
      return 'Skilled Nursing Facility';
    case infinitusai.be.FacilityType.Type.DIAGNOSTIC_TEST_LAB:
      return 'Diagnostic Test Lab';
    default:
      return 'Unknown';
  }
};

export function getInviteStateDisplayName(inviteState?: infinitusai.auth.InviteState) {
  switch (inviteState) {
    case infinitusai.auth.InviteState.INVITE_STATE_ACCEPTED:
      return 'Accepted';
    case infinitusai.auth.InviteState.INVITE_STATE_CANCELED:
      return 'Canceled';
    case infinitusai.auth.InviteState.INVITE_STATE_DECLINED:
      return 'Declined';
    case infinitusai.auth.InviteState.INVITE_STATE_PENDING:
      return 'Pending';
    default:
      return 'Unknown';
  }
}

export function getTaskTypeFilterOptions(): { [key: string]: infinitusai.be.TaskType } {
  const taskTypes: infinitusai.be.TaskType[] = TypedObjectValues(infinitusai.be.TaskType);
  const filterOptions = taskTypes.reduce((prev: any, curr: infinitusai.be.TaskType) => {
    prev[getTaskTypeDisplayName(curr)] = infinitusai.be.TaskType[curr];
    return prev;
  }, {});
  // It's possible a TaskType without a display name gets defined
  // as 'UNKNOWN' instead of TASK_TYPE_UNKNOWN in the map. Therefore, we should
  // overwrite the unknown task type
  filterOptions[getTaskTypeDisplayNamePortal(infinitusai.be.TaskType.TASK_TYPE_UNKNOWN)] =
    infinitusai.be.TaskType.TASK_TYPE_UNKNOWN;
  return filterOptions;
}

export function getTaskTypeFilterOptionsPortal(): string[] {
  return [
    ...Object.keys(getTaskTypeFilterOptions()),
    'Major Medical and PBM',
    'PBM and Major Medical',
  ];
}

export const getProductNamesFromTasks = (
  tasks: (infinitusai.be.TaskDoc | infinitusai.be.CustomerTaskDoc)[] | null
) => {
  let productNames: string[] = [];
  tasks?.forEach((task) =>
    task.bvInputs?.productInfos?.forEach((productInfo) => {
      if (productInfo?.productName && !productNames.includes(productInfo?.productName)) {
        productNames.push(productInfo.productName);
      }
    })
  );
  return productNames;
};

interface CheckFilters {
  processedFilter?: string | null;
  taskTypeFilter?: infinitusai.tasks.TaskType | string | null;
  productNamesFilter?: string | null;
}
export const checkFilters = (
  tasks: infinitusai.be.CustomerTaskDoc[],
  { processedFilter, taskTypeFilter, productNamesFilter }: CheckFilters
): infinitusai.be.CustomerTaskDoc[] => {
  return tasks.filter((task) => {
    let shouldInclude = true;
    if (processedFilter === 'No' && task.customerReviewExists?.value) {
      shouldInclude = false;
    }
    if (taskTypeFilter) {
      //TODO(Onur)
      shouldInclude = getTaskTypeColumnDisplayName(task) === taskTypeFilter;
    }
    if (productNamesFilter) {
      productNamesFilter.split(',').forEach((productName: string) => {
        if (
          !task.bvInputs?.productInfos?.some(
            (productInfo) => productInfo.productName === productName.trim()
          )
        ) {
          shouldInclude = false;
        }
      });
    }
    return shouldInclude;
  });
};

// Get Task Outcome Filter Options
export function getTaskStatusFilterOptions(includeAll?: boolean) {
  const options = [
    { value: 'in-queue', label: 'In Queue' },
    { value: 'in-progress', label: 'In Progress' },
  ];
  const completedOptions = [
    { value: 'successful', label: 'Successful' },
    { value: 'partial', label: 'Partially Successful' },
    { value: 'unsuccessful', label: 'Unsuccessful' },
  ];
  return includeAll ? [...options, ...completedOptions] : completedOptions;
}

export const getFailureReasonFilterOptions = () => {
  const failureReasonTypes: infinitusai.be.TaskFailReason.Type[] = TypedObjectValues(
    infinitusai.be.TaskFailReason.Type
  );
  const reasonsToHide = [
    infinitusai.be.TaskFailReason.Type.REASON_PAST_DUE,
    infinitusai.be.TaskFailReason.Type.REASON_TASK_EXPIRED,
    infinitusai.be.TaskFailReason.Type.REASON_IVR_NAVIGATION_FAILURE,
    infinitusai.be.TaskFailReason.Type.REASON_HOLD_NAVIGATION_FAILURE,
    infinitusai.be.TaskFailReason.Type.REASON_PRESCRIBER_UNREACHABLE,
    infinitusai.be.TaskFailReason.Type.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD,
    infinitusai.be.TaskFailReason.Type.REASON_AGENT_AUTOMATION_HANGUP,
    infinitusai.be.TaskFailReason.Type.REASON_OTHER,
  ];
  const filterOptions = failureReasonTypes
    .filter((frt) => !reasonsToHide.includes(frt))
    .map((frt) => ({
      label: getTaskFailureReason(frt),
      value: infinitusai.be.TaskFailReason.Type[frt],
    }));

  return filterOptions.filter((option: any) => option.label !== 'Unknown');
};

export function getProductDaysSupplyDisplay(daysSupply: infinitusai.be.ProductInfo.DaysSupply) {
  switch (+daysSupply) {
    case infinitusai.be.ProductInfo.DaysSupply.DAYS_SUPPLY_30_DAYS: {
      return '30 Days';
    }
    case infinitusai.be.ProductInfo.DaysSupply.DAYS_SUPPLY_60_DAYS:
      return '60 Days';
    case infinitusai.be.ProductInfo.DaysSupply.DAYS_SUPPLY_90_DAYS:
      return '90 Days';
    default:
      return 'Unknown';
  }
}

export function getTasksWithProducts(
  tasks: infinitusai.be.CustomerTaskDoc[],
  taskInputs: infinitusai.be.GetTasksResponse.ITaskInputListElement[]
) {
  const taskInputMap = new Map(taskInputs?.map((input) => [input.taskUuid, input.input]));

  const newData = tasks?.map((task) => {
    const inputs = taskInputMap.get(task.taskUuid);
    const productsList = taskInputs?.map((taskInput) => {
      const taskUuid = taskInput.taskUuid;
      let products: string[] = [];
      taskInput?.input?.inputs
        ?.find((input) => input.array?.name === 'products')
        ?.array?.nestedFields?.map((field: any) =>
          field.values.map((value: any) => {
            if (value.name === 'productName') {
              return products.push(getFTIValue(value) as string);
            }
            return products;
          })
        );
      return { taskUuid, productInfos: products };
    });

    if (inputs) {
      const newTask: infinitusai.be.CustomerTaskDoc = infinitusai.be.CustomerTaskDoc.fromObject({
        ...task,
        bvInputs: {
          ...task.bvInputs,
          productInfos: productsList
            ?.find((product) => product.taskUuid === task.taskUuid)
            ?.productInfos.map(
              (product) => ({ productName: product } as infinitusai.be.ProductInfo)
            ),
        },
      });
      return newTask;
    }
    return task as infinitusai.be.CustomerTaskDoc;
  });
  return newData;
}

export function formatValue(values: any) {
  const newVal = Object.fromEntries(
    Object.entries(values).filter(([key, value]) => {
      return value !== null;
    })
  );

  return newVal;
}

export function getFTIValue(value: any) {
  let val: string | boolean | number = '-';
  const keys = formatValue(value);

  for (let i in keys) {
    switch (i) {
      case 'date':
        val = value.date;
        break;
      case 'string':
        val = value.string;
        break;
      case 'float':
        val = value.float;
        break;
      case 'int':
        val = value.int;
        break;
      case 'money':
        val = value.money;
        break;
      case 'bool':
        val = value.bool ? value.bool.toString() : value.bool;
        break;
    }
  }

  return val;
}

export const getPayerAssociationDisplayName = (payerAssociation: infinitusai.be.Association) => {
  switch (payerAssociation) {
    case infinitusai.be.Association.ASSOCIATION_BCBS:
      return 'BCBS';
    case infinitusai.be.Association.ASSOCIATION_BCBS_FEDERAL:
      return 'BCBS Federal';
    default:
      return 'Unknown';
  }
};
