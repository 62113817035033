import Papa from 'papaparse';

import {
  ParsedCSVRow,
  CsWiiTable,
  CSWII_ROW_ID,
  CSWiiRow,
  ErrorRecord,
  CSVColumnName,
} from './types';

// Helper function to check if a row has any cells with errors.
export const rowHasError = (
  row: ParsedCSVRow,
  rowIdToRowMap: CsWiiTable,
  CSVColumnNames: string[]
) => {
  const rowName = row[CSWII_ROW_ID];
  const rowMap = rowIdToRowMap.get(rowName);
  if (rowMap) {
    for (const columnName of CSVColumnNames) {
      if (rowMap[columnName]?.hasError) {
        return true;
      }
    }
  }
  return false;
};

export function filterRowsWithErrors(csWiiTable: CsWiiTable): CsWiiTable {
  const filteredTable = new Map(csWiiTable);

  filteredTable.forEach((rowData, rowName) => {
    let hasError = false;

    for (const cell of Object.values(rowData)) {
      if (cell.hasError) {
        hasError = true;
        break;
      }
    }

    if (!hasError) {
      filteredTable.delete(rowName);
    }
  });

  return filteredTable;
}

export function convertCSVTableBackToCsvString(csWiiTable: CsWiiTable): string {
  if (!csWiiTable.size) {
    const msg = 'The provided CSV file is empty.';
    console.error(msg, csWiiTable);
    throw new Error(msg);
  }

  const firstDataRow = Array.from(csWiiTable.values())[0];
  const headerKeys = firstDataRow ? [...Object.keys(firstDataRow)] : [];

  const csvRows: string[][] = [];

  csWiiTable.forEach((rowData, rowName) => {
    const csvRow = headerKeys.map((key, index) => {
      if (index === 0) return rowName;
      return rowData[key]?.value || '';
    });

    csvRows.push(csvRow);
  });

  let csvString = Papa.unparse({
    fields: headerKeys,
    data: csvRows,
  });

  return csvString;
}

export function clearAllErrors(csWiiTable: CsWiiTable): CsWiiTable {
  const updatedTable = new Map(csWiiTable);

  updatedTable.forEach((row, rowId) => {
    const updatedRow: CSWiiRow = {};
    for (const [columnName, cell] of Object.entries(row)) {
      updatedRow[columnName] = {
        ...cell,
        hasError: false,
        errorMessage: '',
      };
    }
    updatedTable.set(rowId, updatedRow);
  });

  return updatedTable;
}

export function convertCsvStringToCswiiTable(csv: string): CsWiiTable {
  const csWiiTable: CsWiiTable = new Map();
  const parsedCSV = Papa.parse(csv, { header: true });

  parsedCSV.data.forEach((parsedCSVRows: any) => {
    const rowName = parsedCSVRows[CSWII_ROW_ID];
    const csWiiRow: CSWiiRow = {};

    Object.keys(parsedCSVRows).forEach((colName) => {
      const cellValue = parsedCSVRows[colName];

      csWiiRow[colName] = {
        value: cellValue,
        hasError: false,
        errorMessage: '',
      };
    });

    csWiiTable.set(rowName, csWiiRow);
  });

  return csWiiTable;
}

export function updateCsWiiTableCellsWithErrors(
  errors: ErrorRecord[],
  cswiiTable: CsWiiTable,
  whichColumnsHaveErrors: Map<string, boolean>
): [CsWiiTable, Map<string, boolean>] {
  // Use the clearAllErrors function to reset all errors
  const newCsWiiTable = clearAllErrors(cswiiTable);

  // Reset the error flags for the columns
  const colHasErrorCopy = new Map(
    [...whichColumnsHaveErrors].map(([colName, hasError]) => [colName, false])
  );

  // Update the new table based on the errors provided
  for (const error of errors) {
    const csWiiRow = newCsWiiTable.get(error.customerTaskId);
    if (!csWiiRow) continue;

    const displayName = extractColumnNameFromCsvValidationError(error, newCsWiiTable);
    if (!displayName || !csWiiRow[displayName]) continue;

    if (displayName === CSWII_ROW_ID) {
      const oldErrorMessage = csWiiRow[displayName].errorMessage;
      csWiiRow[displayName] = {
        ...csWiiRow[displayName],
        hasError: true,
        errorMessage: oldErrorMessage + '\n\n ' + error.errorMessage,
      };
    } else {
      csWiiRow[displayName] = {
        ...csWiiRow[displayName],
        hasError: true,
        errorMessage: error.errorMessage,
      };
    }

    colHasErrorCopy.set(displayName, true);
  }

  const filteredCsWiiTable = filterRowsWithErrors(newCsWiiTable);

  return [filteredCsWiiTable, colHasErrorCopy];
}

//  The BE API returns an error message for each cell with errors
//  We need to parse the error message to find which column name has the error
export function extractColumnNameFromCsvValidationError(
  error: ErrorRecord,
  csWiiTable: CsWiiTable
): string | null {
  const { errorMessage, customerTaskId: rowIdOfError } = error;

  const columnName = getColumnNameFromCsvRules(errorMessage);

  if (columnName) return columnName;

  //If the err  message has a pattern like (cell_value):, then we can extract the column name from it by finding the cell value in the row
  const matchCellValueInsideParentheses = errorMessage.match(/\(([^)]+)\):/);
  const rowWithError = csWiiTable.get(rowIdOfError);

  if (matchCellValueInsideParentheses) {
    const cellValueToFind = matchCellValueInsideParentheses[1];

    if (rowWithError) {
      const foundCells = Object.entries(rowWithError).filter(
        ([colName, cell]) => cell.value === cellValueToFind && !cell.hasError
      );

      if (foundCells && foundCells.length === 1) return foundCells[0][0];

      return CSWII_ROW_ID;
    }
  }

  return CSWII_ROW_ID;
}

// We used to rely on the BE API to tell us which column had an error
// We no longer can do that, since changing the error message will break customer integrations
// Instead, we will try to parse the error message to find the column name using rules as temporary workaround
// Long run, we should change the Create Task API to return the column name
export function getColumnNameFromCsvRules(errorMessage: string): CSVColumnName | null {
  if (errorMessage.includes('Unknown Program ID')) {
    return CSVColumnName.ProgramID;
  } else if (
    errorMessage.includes('Unsupported payer') ||
    errorMessage.includes('infinitusPayerId')
  ) {
    return CSVColumnName.InfinitusPayerID;
  } else if (errorMessage.includes('Facility Type')) {
    return CSVColumnName.FacilityType;
  } else if (errorMessage.includes('Provider Network Status')) {
    return CSVColumnName.ProviderNetworkStatus;
  } else if (errorMessage.includes('patient birthday')) {
    return CSVColumnName.MemberDateOfBirth;
  } else if (errorMessage.includes('treatment date')) {
    return CSVColumnName.TreatmentDate;
  } else if (errorMessage.includes('Other Insurance Status')) {
    return CSVColumnName.OtherInsuranceStatus;
  } else if (errorMessage.includes('address state')) {
    if (errorMessage.includes('practice')) {
      return CSVColumnName.PracticeState;
    } else if (errorMessage.includes('patient')) {
      return CSVColumnName.PatientState;
    } else if (errorMessage.includes('provider')) {
      return CSVColumnName.ProviderState;
    }
  } else if (errorMessage.includes('member ID')) {
    return CSVColumnName.SubscriberID;
  } else if (errorMessage.includes('zip')) {
    if (errorMessage.includes('member') || errorMessage.includes('patient')) {
      return CSVColumnName.MemberZip;
    } else if (errorMessage.includes('provider')) {
      return CSVColumnName.ProviderZip;
    } else if (errorMessage.includes('practice')) {
      return CSVColumnName.PracticeZip;
    }
  } else if (errorMessage.includes('tax id')) {
    if (errorMessage.includes('provider')) {
      return CSVColumnName.ProviderTaxID;
    } else if (errorMessage.includes('practice')) {
      return CSVColumnName.PracticeTaxID;
    }
  }

  return null;
}

export const mapRowToValues = (
  row: CSWiiRow
): { [columnName: string]: { value: string; errorMessage: string; hasError: boolean } } => {
  return Object.entries(row).reduce((acc, [columnName, cell]) => {
    acc[columnName] = {
      value: cell.value,
      errorMessage: cell.errorMessage,
      hasError: cell.hasError,
    };
    return acc;
  }, {} as { [columnName: string]: { value: string; errorMessage: string; hasError: boolean } });
};
