import { useAuth, PermissionName } from '@infinitusai/auth';
import { useAppState } from '@infinitusai/shared';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';
import RequestPageRoundedIcon from '@mui/icons-material/RequestPageRounded';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMatch } from 'react-router-dom';

interface Props {
  hasMatch: boolean;
  openDocs: boolean;
  onItemClick: (path: string) => void;
  styleIcon: (match: boolean) => {};
}

function DocsCollapsible({ hasMatch, openDocs, onItemClick, styleIcon }: Props) {
  const appState = useAppState();
  const { hasPermission } = useAuth();
  const docsSupportedPayorsMatch = useMatch(`/${appState.orgName}/docs/supported-payors`);
  const docsApiRouteMatch = useMatch(`/${appState.orgName}/docs/api`);

  const hasPayorsReadPermission = hasPermission([PermissionName.CUSTOMER_PAYORS_READ]);

  const docsItems = [
    hasPayorsReadPermission && {
      path: `/${appState.orgName}/docs/supported-payors`,
      title: `Supported Payors`,
      icon: (
        <RequestPageRoundedIcon sx={styleIcon(hasMatch && docsSupportedPayorsMatch !== null)} />
      ),
      match: hasMatch && docsSupportedPayorsMatch !== null,
    },
    {
      path: `/${appState.orgName}/docs/api`,
      title: `API Integration Docs`,
      icon: (
        <IntegrationInstructionsRoundedIcon
          sx={styleIcon(hasMatch && docsApiRouteMatch !== null)}
        />
      ),
      match: hasMatch && docsApiRouteMatch !== null,
    },
  ].filter(Boolean);

  return (
    <Collapse in={openDocs} timeout="auto" unmountOnExit>
      <List>
        {docsItems.map((item) =>
          item ? (
            <ListItemButton
              key={item.title}
              sx={{ ml: 4 }}
              onClick={() => onItemClick(item.path)}
              selected={item.match}
              disableRipple
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{ variant: 'subtitle2' }}
              />
            </ListItemButton>
          ) : null
        )}
      </List>
    </Collapse>
  );
}

export default DocsCollapsible;
