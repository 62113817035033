import { useAuth, PermissionName } from '@infinitusai/auth';
import { useAppState, OrgPicker } from '@infinitusai/shared';
import { useBreakpoint } from '@infinitusai/ui';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ElectricCarRoundedIcon from '@mui/icons-material/ElectricCarRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ViewListSharpIcon from '@mui/icons-material/ViewListSharp';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { Basename } from '../types';
import DocsCollapsible from './CollapsibleItems/DocsCollapsible';
import SettingsCollapsible from './CollapsibleItems/SettingsCollapsible';
import TaskCollapsible from './CollapsibleItems/TaskCollapsible';

interface Props {
  basename: Basename;
  drawerOpen: boolean;
  onItemClick?: () => void;
  onDropdownClick?: () => void;
}

function PortalListItems({ basename, drawerOpen, onItemClick, onDropdownClick }: Props) {
  const navigate = useNavigate();
  const appState = useAppState();
  const { hasPermission } = useAuth();
  const smBreakpoint = useBreakpoint('sm');

  // Beta
  const isBeta = basename === Basename.Beta;

  // Match routes
  const allRouteMatch = useMatch(`/${appState.orgName}/*`);
  const homeRouteMatch = useMatch(`/${appState.orgName}/home`);
  const tasksRouteMatch = useMatch(`/${appState.orgName}/tasks/*`);
  const docsRouteMatch = useMatch(`/${appState.orgName}/docs/*`);
  const accountRouteMatch = useMatch(`/${appState.orgName}/account/*`);
  const billingRouteMatch = useMatch(`/${appState.orgName}/billing`);

  // open collapsibles state
  const [openTasks, setOpenTasks] = React.useState(tasksRouteMatch !== null);
  const [openSettings, setOpenSettings] = React.useState(accountRouteMatch !== null);
  const [openDocs, setOpenDocs] = React.useState(docsRouteMatch !== null);

  // Permissions
  const hasSettingsReadPermission = hasPermission([PermissionName.CUSTOMER_SETTINGS_READ]);
  const hasManageBillingPermission = hasPermission([PermissionName.CUSTOMER_BILLING_READ]);
  const hasTasksReadPermission = hasPermission([PermissionName.CUSTOMER_TASKS_READ]);
  const hasTasksImportReadPermission = hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_READ]);
  const hasTasksImportWritePermission = hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_WRITE]);
  const hasCreateTaskPermission = hasPermission([PermissionName.CUSTOMER_TASKS_WRITE]);
  const hasTaskReportsReadPermission = hasPermission([PermissionName.CUSTOMER_TASK_REPORTS_READ]);
  const hasRBACReadPermission = hasPermission([PermissionName.CUSTOMER_RBAC_READ]);
  const hasAPIReadPermission = hasPermission([PermissionName.CUSTOMER_API_KEYS_READ]);
  const hasPIEReadPermission = hasPermission([PermissionName.CUSTOMER_PIE_READ]);
  const hasDocsReadPermission = hasPermission([PermissionName.CUSTOMER_DOCS_READ]);
  const hasPayorsReadPermission = hasPermission([PermissionName.CUSTOMER_PAYORS_READ]);
  const hasFTSoftphonePermission = hasPermission([PermissionName.CUSTOMER_FT_SOFTPHONE]);
  const hasFTSetupApprovePermission = hasPermission([PermissionName.CUSTOMER_FT_SETUP_APPROVE]);
  const hasFTTasksManagePermission = hasPermission([PermissionName.CUSTOMER_FT_TASKS_MANAGE]);

  const hasSomeTaskPermission =
    hasTasksReadPermission ||
    hasCreateTaskPermission ||
    hasTasksImportWritePermission ||
    hasTasksImportReadPermission ||
    hasTaskReportsReadPermission;

  const hasSomeSettingsSectionPermission =
    hasSettingsReadPermission ||
    hasRBACReadPermission ||
    hasAPIReadPermission ||
    hasPIEReadPermission;

  const hasSomeDocsPermission = hasDocsReadPermission || hasPayorsReadPermission;

  const hasSomeFasttrackPermission =
    hasFTSetupApprovePermission || hasFTTasksManagePermission || hasFTSoftphonePermission;

  React.useEffect(() => {
    if (!drawerOpen) {
      setOpenTasks(false);
      setOpenSettings(false);
      setOpenDocs(false);
    } else {
      setOpenTasks(tasksRouteMatch !== null);
      setOpenSettings(accountRouteMatch !== null);
      setOpenDocs(docsRouteMatch !== null);
    }
  }, [drawerOpen, allRouteMatch, tasksRouteMatch, accountRouteMatch, docsRouteMatch]);

  // Handler functions for collapsibles
  const handleTasksClick = () => {
    onDropdownClick?.();
    setTimeout(() => setOpenTasks(!openTasks), drawerOpen ? 0 : 100);
  };

  const handleSettingsClick = () => {
    onDropdownClick?.();
    setTimeout(() => setOpenSettings(!openSettings), drawerOpen ? 0 : 100);
  };

  const handleDocsClick = () => {
    onDropdownClick?.();
    setTimeout(() => setOpenDocs(!openDocs), drawerOpen ? 0 : 100);
  };

  // Style functions for ListItems
  const styleIcon = (match: boolean) => {
    return match ? { color: 'primary.main' } : {};
  };

  const handleNavigate = (path: string) => {
    onItemClick?.();
    navigate(path);
  };

  return (
    <React.Fragment>
      <List component="nav" aria-labelledby="nav" sx={{ mt: 1 }}>
        {allRouteMatch && !smBreakpoint ? (
          <OrgPicker selectedOrgName={appState.org?.name || ''} />
        ) : null}

        {/* Dashboard Navigation */}
        <ListItemButton
          disableRipple
          selected={homeRouteMatch !== null}
          onClick={() => handleNavigate(`/${appState.orgName}/home`)}
        >
          <ListItemIcon>
            <DashboardIcon sx={styleIcon(homeRouteMatch !== null)} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" primaryTypographyProps={{ variant: 'subtitle1' }} />
        </ListItemButton>

        {/* Tasks */}
        {hasSomeTaskPermission ? (
          <React.Fragment>
            <ListItemButton
              disableRipple
              onClick={handleTasksClick}
              selected={tasksRouteMatch !== null}
            >
              <ListItemIcon>
                <ViewListSharpIcon sx={styleIcon(tasksRouteMatch !== null)} />
              </ListItemIcon>
              <ListItemText primary="Tasks" primaryTypographyProps={{ variant: 'subtitle1' }} />
              {openTasks ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <TaskCollapsible
              openTasks={openTasks}
              hasMatch={tasksRouteMatch !== null}
              onItemClick={(path: string) => handleNavigate(path)}
              styleIcon={styleIcon}
            />
          </React.Fragment>
        ) : null}

        {/* Billing */}
        {hasManageBillingPermission ? (
          <ListItemButton
            disableRipple
            selected={billingRouteMatch !== null}
            onClick={() => handleNavigate(`/${appState.orgName}/billing`)}
          >
            <ListItemIcon>
              <MonetizationOnRoundedIcon sx={styleIcon(billingRouteMatch !== null)} />
            </ListItemIcon>
            <ListItemText primary="Billing" primaryTypographyProps={{ variant: 'subtitle1' }} />
          </ListItemButton>
        ) : null}

        {/* Settings */}
        {hasSomeSettingsSectionPermission ? (
          <>
            <ListItemButton
              disableRipple
              onClick={handleSettingsClick}
              selected={accountRouteMatch !== null}
            >
              <ListItemIcon>
                <SettingsRoundedIcon sx={styleIcon(accountRouteMatch !== null)} />
              </ListItemIcon>
              <ListItemText primary="Settings" primaryTypographyProps={{ variant: 'subtitle1' }} />
              {openSettings ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <SettingsCollapsible
              openSettings={openSettings}
              hasMatch={accountRouteMatch !== null}
              onItemClick={(path: string) => handleNavigate(path)}
              styleIcon={styleIcon}
            />
          </>
        ) : null}

        {/* Documentation */}
        {hasSomeDocsPermission ? (
          <>
            <ListItemButton
              disableRipple
              onClick={handleDocsClick}
              selected={docsRouteMatch !== null}
            >
              <ListItemIcon>
                <MenuBookRoundedIcon sx={styleIcon(docsRouteMatch !== null)} />
              </ListItemIcon>
              <ListItemText
                primary="Documentation"
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
              {openDocs ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <DocsCollapsible
              openDocs={openDocs}
              hasMatch={docsRouteMatch !== null}
              onItemClick={(path: string) => handleNavigate(path)}
              styleIcon={styleIcon}
            />
          </>
        ) : null}

        {/* FastTrack */}
        {hasSomeFasttrackPermission ? (
          <ListItemButton
            disableRipple
            onClick={() => {
              window.location.href = `${window.location.origin}${
                isBeta ? '/fasttrack_beta/' : '/fasttrack/'
              }`;
            }}
          >
            <ListItemIcon>
              <ElectricCarRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="FastTrack" primaryTypographyProps={{ variant: 'subtitle1' }} />
          </ListItemButton>
        ) : null}
      </List>

      <Box sx={{ flexGrow: 1 }} />

      {/* Organization Logo */}
      {appState.org?.imageUrl && drawerOpen && appState.displayMode === 'light' ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              my: 4,
              width: '120px',
              opacity: '35%',
            }}
            alt="organization logo"
            src={appState.org?.imageUrl}
          />
        </Box>
      ) : null}
    </React.Fragment>
  );
}

export default PortalListItems;
