import { useAuth, PermissionName } from '@infinitusai/auth';
import { Button } from '@infinitusai/ui';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import formatDistance from 'date-fns/formatDistance';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useProcessTaskMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

interface Props {
  task: infinitusai.be.CustomerTaskDoc;
}

function MarkAsProcessed({ task }: Props) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { tid } = useParams();
  const [processing, setProcessing] = React.useState(false);
  const customerReview = task.customerReview || undefined;
  const processTaskMutation = useProcessTaskMutation();

  const handleProcess = async (values: any) => {
    setProcessing(true);
    const body = infinitusai.be.ProcessTaskRequest.fromObject({
      taskUuid: tid,
    });
    processTaskMutation.mutate(body, {
      onSuccess: () => {
        setProcessing(false);
        enqueueSnackbar(`Task ${tid} marked as processed.`, { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(`Could not mark task ${tid} as processed: ${error.message}`, {
          variant: 'error',
        });
      },
    });
  };

  return customerReview ? (
    <Grow in>
      <Grid container direction="column" bgcolor="grey.600" p={1} borderRadius={2}>
        <Grid item>
          <Typography color="white">
            <strong>Processed</strong> by {customerReview.reviewedByName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="white">
            {customerReview.reviewedOnMillis
              ? formatDistance(new Date(Number(customerReview?.reviewedOnMillis)), new Date(), {
                  addSuffix: true,
                  includeSeconds: true,
                })
              : null}
          </Typography>
        </Grid>
      </Grid>
    </Grow>
  ) : (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleProcess}
      disabled={processing}
      unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_TASKS_WRITE])}
    >
      Mark as processed
    </Button>
  );
}

export default MarkAsProcessed;
