import { useAuth, PermissionName } from '@infinitusai/auth';
import { Org } from '@infinitusai/shared';
import { Button, TextField, useBreakpoint } from '@infinitusai/ui';
import DeleteIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Form, Field, FieldArray, useFormik, FormikProvider, FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import { useState, Fragment } from 'react';

import { useUpdateOrgMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { profileValidationSchema } from 'utils/validations';

import EmailDomainDrawer from './SecurityDrawer/EmailDomainDrawer';
import IpAddressDrawer from './SecurityDrawer/IpAddressDrawer';

interface Props {
  org: Org;
}

function SecurityTab({ org }: Props) {
  const auth = useAuth();
  const smBreakpoint = useBreakpoint('sm');
  const mdBreakpoint = useBreakpoint('md');
  const { enqueueSnackbar } = useSnackbar();
  const updateMutation = useUpdateOrgMutation();
  const [showIpAddressDrawer, setShowIpAddressDrawer] = useState(false);
  const [showEmailDomainDrawer, setShowEmailDomainDrawer] = useState(false);

  const handleFormSubmitted: FormikConfig<Org>['onSubmit'] = async (values) => {
    const body = infinitusai.orgs.UpdateOrgRequest.fromObject({
      orgImageUrl: values.imageUrl,
      orgEmailDomains: values.emailDomains,
      orgDisplayName: values.displayName,
      orgSupportEmail: values.supportEmail,
      orgWhitelistedIpAddresses: values.whitelistedIpAddresses,
    });
    try {
      await updateMutation.mutateAsync(body, {
        onSuccess: () => {
          enqueueSnackbar('Settings Updated', { variant: 'success' });
        },
        onError: (error) => {
          enqueueSnackbar(`Failed to update org settings: ${error.message}.`, {
            variant: 'error',
          });
        },
      });
    } catch (e: any) {
      enqueueSnackbar(`Failed to update org settings: ${e?.response?.data || e.message}`, {
        variant: 'error',
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: org,
    validationSchema: profileValidationSchema,
    onSubmit: handleFormSubmitted,
    onReset: () => org,
  });

  const handleIpAddressDrawerClose = () => {
    setShowIpAddressDrawer(false);
  };

  const handleEmailDomainDrawerClose = () => {
    setShowEmailDomainDrawer(false);
  };

  return (
    <Stack>
      <FormikProvider value={formik}>
        <Form>
          <Grid
            container
            display="flex"
            justifyContent="space-evenly"
            alignContent={smBreakpoint ? 'left' : 'center'}
            flexDirection={mdBreakpoint ? 'row' : 'column'}
            sx={{ mt: 1 }}
            gap={smBreakpoint ? 5 : 0}
          >
            <Grid item xs>
              <Typography variant="h6">Valid IP Addresses</Typography>
              <Typography variant="caption">
                Can specify individual IPs or IP address ranges (ex. 192.168.0.1 or 192.168.0.1/16)
                <br />
                When configured, access to the customer portal and APIs is limited exclusively to
                the specified IP addresses.
              </Typography>
              <FieldArray
                name="whitelistedIpAddresses"
                render={(arrayHelpers) => (
                  <>
                    <List>
                      {formik.values.whitelistedIpAddresses.map((address: string, i: number) => (
                        <Fragment key={address}>
                          <ListItem>
                            <ListItemText>{address}</ListItemText>
                            <ListItemSecondaryAction>
                              <Tooltip title="Remove IP address" enterDelay={500}>
                                <>
                                  <Button
                                    size="small"
                                    aria-label="delete"
                                    onClick={() => {
                                      arrayHelpers.remove(i);
                                      formik.submitForm();
                                    }}
                                    unauthorized={
                                      !auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_WRITE])
                                    }
                                    disabled={formik.isSubmitting}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </Button>
                                </>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider component="li" />
                        </Fragment>
                      ))}
                      <ListItem>
                        <Button
                          variant="text"
                          size="medium"
                          color="primary"
                          unauthorized={
                            !auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_WRITE])
                          }
                          disabled={formik.isSubmitting || updateMutation.isLoading}
                          onClick={() => {
                            setShowIpAddressDrawer(true);
                          }}
                        >
                          Add new
                        </Button>
                      </ListItem>
                    </List>
                    <FormHelperText error={!!formik.errors.whitelistedIpAddresses}>
                      {formik.errors.whitelistedIpAddresses}
                    </FormHelperText>
                  </>
                )}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="h6">Valid Email Domains</Typography>
              <Typography variant="caption">
                Allow registered users from the following email domains to login:
              </Typography>
              <FieldArray
                name="emailDomains"
                render={(arrayHelpers) => (
                  <>
                    <List>
                      {formik.values.emailDomains.map((domain: string, i: number) => (
                        <Fragment key={domain}>
                          <ListItem>
                            <ListItemText>{domain}</ListItemText>
                            <ListItemSecondaryAction>
                              <Tooltip title="Remove domain" enterDelay={500}>
                                <>
                                  <Button
                                    size="small"
                                    aria-label="delete"
                                    onClick={() => {
                                      arrayHelpers.remove(i);
                                      formik.submitForm();
                                    }}
                                    unauthorized={
                                      !auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_WRITE])
                                    }
                                    disabled={formik.isSubmitting}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </Button>
                                </>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider component="li" />
                        </Fragment>
                      ))}
                      <ListItem>
                        <Button
                          variant="text"
                          size="medium"
                          color="primary"
                          unauthorized={
                            !auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_WRITE])
                          }
                          disabled={formik.isSubmitting || updateMutation.isLoading}
                          onClick={() => {
                            setShowEmailDomainDrawer(true);
                          }}
                        >
                          <span>Add new</span>
                        </Button>
                      </ListItem>
                    </List>
                    <FormHelperText error={!!formik.errors.emailDomains}>
                      {formik.errors.emailDomains}
                    </FormHelperText>
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Field name="whitelistedIpAddresses">
            {({ form }: any) => (
              <IpAddressDrawer
                open={showIpAddressDrawer}
                onClose={handleIpAddressDrawerClose}
                updateMutation={updateMutation}
                formik={formik}
                form={form}
              />
            )}
          </Field>
          <Field name="emailDomains">
            {({ form }: any) => (
              <EmailDomainDrawer
                open={showEmailDomainDrawer}
                onClose={handleEmailDomainDrawerClose}
                updateMutation={updateMutation}
                formik={formik}
                form={form}
              />
            )}
          </Field>
        </Form>
      </FormikProvider>
      <Divider sx={{ mb: 2 }} />
      <Stack gap={1}>
        <Typography variant="h6"> Days Tasks Available For Access</Typography>
        <TextField
          defaultValue={org.daysTasksAvailableForAccess}
          InputLabelProps={{ shrink: true }}
          disabled
          sx={{ width: smBreakpoint ? '450px' : '100%' }}
          size="small"
        />
      </Stack>
    </Stack>
  );
}
export default SecurityTab;
