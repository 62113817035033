import { infinitusapi, infinitusai } from 'proto/pbjs';

export const ARRAY_INDEX = 'index';

export interface TaskOutputsMap {
  fields: string[];
  idField?: string;
  label: string;
  parentField?: string;
}

export interface TaskInputsMap {
  getValue: (val?: any) => string;
  type: string;
}

export const getTaskOutputsMap = (
  data: infinitusapi.GetTaskResultsResponse.IDataMessage,
  billingNotesEnabled?: boolean
): TaskOutputsMap[] => {
  if (data?.bvTaskOutput) return bvTaskOutputsMap(billingNotesEnabled);
  if (data?.pbmTaskOutput) return pbmTaskOutputsMap(billingNotesEnabled);
  if (data?.pbmDiscoveryTaskOutput) return pbmDiscoveryTaskOutputsMap(billingNotesEnabled);
  if (data?.prescriptionTransferTaskOutput) return ptTaskOutputsMap;
  if (data?.prescriptionSavingsTaskOutput) return psTaskOutputsMap;
  if (data?.priorAuthTaskOutput) return paTaskOutputsMap;
  if (data?.taskOutput) return flexibleTaskOutputsMap(data.taskOutput);
  return [];
};

export const getTaskInputsMap = (
  taskType: infinitusai.be.TaskType
): { [key: string]: TaskInputsMap } => {
  const enumValue = typeof taskType === 'string' ? infinitusai.be.TaskType[taskType] : taskType;
  switch (enumValue) {
    case infinitusai.be.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
      return paTaskInputsMap;
    default:
      return {};
  }
};

export const useTaskInputsMap = (taskInputs: any, map: { [key: string]: TaskInputsMap }): any => {
  let newTaskInputs = [...taskInputs];
  if (newTaskInputs.length && Object.keys(map).length) {
    newTaskInputs = newTaskInputs.map((input: any) => {
      input.value = input.value.map((obj: any) => {
        const inputMap = map[obj.displayName];
        if (inputMap) {
          const updatedObj = { ...obj };
          const { type, getValue } = inputMap;
          updatedObj[type] = getValue(obj[type]);
          obj = updatedObj;
        }
        return obj;
      });
      return input;
    });
  }
  return newTaskInputs;
};

export const getPreviousBvData = (
  data: infinitusapi.GetTaskResultsResponse.IDataMessage
):
  | infinitusapi.IINFBVTaskOutput
  | infinitusapi.IINFPBMTaskOutput
  | infinitusapi.IINFPrescriptionTransferTaskOutput
  | infinitusapi.IINFTaskOutput => {
  if (data.bvTaskOutput) return data.bvTaskOutput;
  if (data.pbmTaskOutput) return data.pbmTaskOutput;
  if (data.prescriptionTransferTaskOutput) return data.prescriptionTransferTaskOutput;
  if (data.taskOutput) return data.taskOutput;

  return {};
};

export const getIntVal = (val?: number | null) => {
  if (val === undefined || val === null) {
    return '-';
  }
  return `${Math.round(val)}`;
};

export const getStringVal = (val?: string | null) => val || '';

export const getBoolVal = (val?: boolean | null) => {
  switch (val) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return '-';
  }
};

// This function is a  hack, we should aim to remove this if we can. It was introduced because the customer swagger api returns a reversed field than our internal apis.
// look at specialtyPharmacyInfoRequired in swagger api and skipSpecialtyPharmacyInfo in internal api.
export const getReversedBoolVal = (val?: boolean | null) => {
  const reversedVal = val === true || val === false ? !val : null;
  return getBoolVal(reversedVal);
};

export const getPercentVal = (val?: number | null) => {
  if (val === undefined || val === null) {
    return '-';
  }
  return `${val.toFixed(2)}%`;
};

export const getAddressVal = (val?: any) => {
  if (val === undefined || val === null) {
    return '';
  }
  return [
    getStringVal(val.streetAddress),
    getStringVal(val.streetAddressLine2),
    getStringVal(val.city),
    getStringVal(val.state),
    getStringVal(val.zip),
  ]
    .filter((x) => !!x)
    .join(', ');
};

export const getMoneyVal = (val?: number | null) => {
  if (val === undefined || val === null) {
    return '-';
  }
  const money = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return money.format(val);
};

export const getReferralRequirementsVal = (val?: string) => {
  switch (val) {
    case 'INF_REFERRAL_REQUIREMENTS_NOT_REQUIRED':
      return 'Not Required';
    case 'INF_REFERRAL_REQUIREMENTS_REQUIRED':
      return 'Required';
    case 'INF_REFERRAL_REQUIREMENTS_REQUIRED_BUT_WAIVED':
      return 'Required (but waived)';
    case 'INF_REFERRAL_REQUIREMENTS_DOCTOR_TO_DOCTOR':
      return 'Doctor to Doctor';
    default:
      return '';
  }
};

export const getNetworkStatusOnlyVal = (val?: string) => {
  switch (val) {
    case 'INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK':
      return 'In Network';
    case 'INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS':
      return 'In Network with Different Address';
    case 'INF_PROVIDER_NETWORK_STATUS_ONLY_OUT_OF_NETWORK':
      return 'Out of Network';
    default:
      return '';
  }
};

export const getProviderNetworkStatusVal = (val?: string) => {
  switch (val) {
    case 'INF_PROVIDER_NETWORK_STATUS_IN_NETWORK':
      return 'In Network';
    case 'INF_PROVIDER_NETWORK_STATUS_OUT_OF_NETWORK':
      return 'Out of Network';
    case 'INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_IN':
      return 'Undetermined (In Network Benefits)';
    case 'INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_IN':
      return 'Undetermined (In Network Benefits)';
    case 'INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_OUT':
      return 'Undetermined (Out of Network Benefits)';
    default:
      return '';
  }
};

export const getNetworkStatusVal = (val?: string) => {
  switch (val) {
    case 'INF_INPUT_NETWORK_STATUS_IN_NETWORK':
    case 'INF_PLACE_OF_SERVICE_NETWORK_STATUS_IN_NETWORK':
      return 'In Network';
    case 'INF_INPUT_NETWORK_STATUS_OUT_OF_NETWORK':
    case 'INF_PLACE_OF_SERVICE_NETWORK_STATUS_OUT_OF_NETWORK':
      return 'Out of Network';
    case 'INF_INPUT_NETWORK_STATUS_UNKNOWN':
    case 'INF_PLACE_OF_SERVICE_NETWORK_STATUS_UNKNOWN':
      return 'Unknown';
    default:
      return '';
  }
};

export const getPlaceOfServiceVal = (val?: string) => {
  switch (val) {
    case 'INF_FACILITY_TYPE_SPECIALIST_OFFICE':
      return 'Specialist Office';
    case 'INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT':
      return 'Hospital Outpatient';
    case 'INF_FACILITY_TYPE_PHARMACY':
      return 'Pharmacy';
    case 'INF_FACILITY_TYPE_SHIP_TO_HOME':
      return 'Ship to Home';
    case 'INF_FACILITY_TYPE_INFUSION_CENTER':
      return 'Infusion Center';
    case 'INF_FACILITY_TYPE_AMBULATORY_SURGICAL_CENTER':
      return 'Ambulatory Surgical Center';
    case 'INF_FACILITY_TYPE_HOME_INFUSION':
      return 'Home Infusion';
    case 'INF_FACILITY_TYPE_FED_QUALIFIED_HC':
      return 'Federally Qualified Health Center';
    case 'INF_FACILITY_TYPE_HOSPICE':
      return 'Hospice';
    case 'INF_FACILITY_TYPE_HOSPITAL_INPATIENT':
      return 'Inpatient';
    case 'INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT_OFF_CAMPUS':
      return 'Hospital Off-Campus Outpatient';
    case 'INF_FACILITY_TYPE_IHC':
      return 'IHC';
    case 'INF_FACILITY_TYPE_INPATIENT_REHAB':
      return 'Inpatient Rehab';
    case 'INF_FACILITY_TYPE_OUTPATIENT_REHAB':
      return 'Outpatient Rehab';
    case 'INF_FACILITY_TYPE_PUBLIC_HEALTH_CLINIC':
      return 'Public Health Clinic';
    case 'INF_FACILITY_TYPE_RURAL_HEALTH_CLINIC':
      return 'Rural Health Clinic';
    case 'INF_FACILITY_TYPE_SNF':
      return 'Skilled Nursing Facility';
    case 'INF_FACILITY_TYPE_DIAGNOSTIC_TEST_LAB':
      return 'Diagnostic Test Lab';
    default:
      return 'Unknown';
  }
};

export const allOutputsMap: {
  [key: string]: {
    getValue: (val?: any) => string;
    label: string;
  };
} = {
  patientOnFileConfirmation: {
    label: 'Patient on File Confirmation',
    getValue: getBoolVal,
  },
  taskLink: {
    label: 'Task Link',
    getValue: getStringVal,
  },
  additionalNotes: {
    label: '',
    getValue: getStringVal,
  },
  billingNotes: {
    label: '',
    getValue: getStringVal,
  },
  pbmName: {
    label: 'PBM name',
    getValue: getStringVal,
  },
  payerPhone: {
    label: 'Payer Phone',
    getValue: getStringVal,
  },
  infinitusPayerId: {
    label: 'Infinitus Payer ID',
    getValue: getStringVal,
  },
  payerName: {
    label: 'Payer Name',
    getValue: getStringVal,
  },
  referenceNumber: {
    label: 'Reference Number',
    getValue: getStringVal,
  },
  payerAgentName: {
    label: 'Payer Agent Name',
    getValue: getStringVal,
  },
  reasonForNonCoverage: {
    label: 'Reason For Non Coverage',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM':
          return 'No Rx benefits as drug covered under PBM';
        case 'INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS':
          return 'No Infusion Or No Injectable Benefits';
        case 'INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B':
          return 'Not Enrolled In Part B';
        case 'INF_REASON_FOR_NON_COVERAGE_CAP_MET':
          return 'Non Coverage Cap Met';
        case 'INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS':
          return 'Non Coverage Exclusion Diagnosis';
        case 'INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG':
          return 'Non Coverage Exclusion drug';
        case 'INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT':
          return 'Covered Under Medical Benefit';
        case 'INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG':
          return 'Federal Legend Drug';
        case 'INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL':
          return 'Need Appeal';
        case 'INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY':
          return 'Non-Formulary';
        case 'INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION':
          return 'Plan Exclusion';
        case 'INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY':
          return 'Plan Is Secondary';
        case 'INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED':
          return 'Prior Authorization Required';
        case 'INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED':
          return 'Step Therapy Required';
        case 'INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK':
          return 'Out of Network';
        default:
          return '';
      }
    },
  },
  'planInfo.RxGroupNumber': {
    label: 'Rx Group Number',
    getValue: getStringVal,
  },
  'planInfo.bankIdentificationNumber': {
    label: 'BIN',
    getValue: getStringVal,
  },
  'planInfo.processorControlNumber': {
    label: 'PCN',
    getValue: getStringVal,
  },
  'planInfo.planName': {
    label: 'Plan Name',
    getValue: getStringVal,
  },
  'planInfo.planCode': {
    label: 'Plan Code',
    getValue: getStringVal,
  },
  'planInfo.planGroupNumber': {
    label: 'Plan Group Number',
    getValue: getStringVal,
  },
  'planInfo.planFax': {
    label: 'Plan fax',
    getValue: getStringVal,
  },
  'planInfo.policyNumber': {
    label: 'Policy Number',
    getValue: getStringVal,
  },
  'planInfo.policyType': {
    label: 'Policy Type',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_POLICY_TYPE_OTHER':
          return 'Other';
        case 'INF_POLICY_TYPE_COMMERCIAL':
          return 'Commercial';
        case 'INF_POLICY_TYPE_GOVERNMENT_MEDICARE':
          return 'Government Medicare';
        case 'INF_POLICY_TYPE_GOVERNMENT_MEDICAID':
          return 'Government Medicaid';
        case 'INF_POLICY_TYPE_GOVERNMENT_TRICARE':
          return 'Government Tricare';
        case 'INF_POLICY_TYPE_FEDERAL_EMPLOYEE_PROGRAM':
          return 'Federal Employee Program';
        case 'INF_POLICY_TYPE_MANAGED_MEDICAID':
          return 'Managed Medicaid';
        case 'INF_POLICY_TYPE_MEDICARE_ADVANTAGE':
          return 'Medicare Advantage';
        case 'INF_POLICY_TYPE_MEDICARE_PDP':
          return 'Medicare PDP';
        default:
          return '';
      }
    },
  },
  'planInfo.planEffectiveDate': {
    label: 'Plan Effective Date',
    getValue: getStringVal,
  },
  'planInfo.planTerminationDate': {
    label: 'Plan Termination Date',
    getValue: getStringVal,
  },
  'planInfo.newPlanAvailable': {
    label: 'Is New Plan Available',
    getValue: getBoolVal,
  },
  'planInfo.newPlanSubscriberId': {
    label: 'New Plan Subscriber ID',
    getValue: getStringVal,
  },
  'planInfo.newPlanEffectiveDate': {
    label: 'New Plan Effective Date',
    getValue: getStringVal,
  },
  'planInfo.otherInsuranceExists': {
    label: 'Other Insurance Exists?',
    getValue: getBoolVal,
  },
  'planInfo.currentPlanPriority': {
    label: 'Current Plan Priority',
    getValue: (val: string) => {
      switch (val) {
        case 'INF_CURRENT_PLAN_PRIORITY_PRIMARY':
          return 'Primary';
        case 'INF_CURRENT_PLAN_PRIORITY_SECONDARY':
          return 'Secondary';
        case 'INF_CURRENT_PLAN_PRIORITY_TERTIARY':
          return 'Tertiary';
        default:
          return '';
      }
    },
  },
  'planInfo.providerNetworkStatusOnly': {
    label: 'Provider Network Status',
    getValue: getNetworkStatusOnlyVal,
  },
  'planInfo.providerNetworkStatus': {
    label: 'Benefit Network Status',
    getValue: getProviderNetworkStatusVal,
  },
  'planInfo.practiceNetworkStatus': {
    label: 'Practice Network Status',
    getValue: (val: string) => {
      switch (val) {
        case 'INF_PRACTICE_NETWORK_STATUS_IN_NETWORK':
          return 'In Network';
        case 'INF_PRACTICE_NETWORK_STATUS_OUT_OF_NETWORK':
          return 'Out of Network';
        default:
          return '';
      }
    },
  },
  'planInfo.providerInNetworkAddress.streetAddress': {
    label: 'In Network Provider Street Address',
    getValue: getStringVal,
  },
  'planInfo.providerInNetworkAddress.streetAddressLine2': {
    label: 'In Network Provider Street Address 2',
    getValue: getStringVal,
  },
  'planInfo.providerInNetworkAddress.city': {
    label: 'In Network Provider City',
    getValue: getStringVal,
  },
  'planInfo.providerInNetworkAddress.state': {
    label: 'In Network Provider State',
    getValue: getStringVal,
  },
  'planInfo.providerInNetworkAddress.zip': {
    label: 'In Network Provider Zip',
    getValue: getStringVal,
  },
  'planInfo.inNetworkConsideration': {
    label: 'How to be considered in network?',
    getValue: getStringVal,
  },
  'planInfo.planType': {
    label: 'Plan Type',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PLAN_TYPE_EPO':
          return 'EPO';
        case 'INF_PLAN_TYPE_HMO':
          return 'HMO';
        case 'INF_PLAN_TYPE_INDEMNITY':
          return 'Indemnity';
        case 'INF_PLAN_TYPE_MEDICAID':
          return 'Medicaid';
        case 'INF_PLAN_TYPE_OAP':
          return 'OAP';
        case 'INF_PLAN_TYPE_POS':
          return 'POS';
        case 'INF_PLAN_TYPE_PPO':
          return 'PPO';
        case 'INF_PLAN_TYPE_TRICARE':
          return 'Tricare';
        case 'INF_PLAN_TYPE_MEDICARE_SUPPLEMENT':
          return 'Medicare Supplement';
        case 'INF_PLAN_TYPE_HMO_POS':
          return 'HMO POS';
        case 'INF_PLAN_TYPE_HDHP':
          return 'HDHP';
        default:
          return '';
      }
    },
  },
  'planInfo.planGroupName': {
    label: 'Plan Group Name',
    getValue: getStringVal,
  },
  'planInfo.hasStandardPlanLetter': {
    label: 'Has Standard Plan Letter?',
    getValue: getBoolVal,
  },
  'planInfo.standardPlanLetter': {
    label: 'Standard Plan Letter',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_A':
          return 'A';
        case 'INF_B':
          return 'B';
        case 'INF_C':
          return 'C';
        case 'INF_D':
          return 'D';
        case 'INF_E':
          return 'E';
        case 'INF_F':
          return 'F';
        case 'INF_G':
          return 'G';
        case 'INF_H':
          return 'H';
        case 'INF_I':
          return 'I';
        case 'INF_J':
          return 'J';
        case 'INF_K':
          return 'K';
        case 'INF_L':
          return 'L';
        case 'INF_M':
          return 'M';
        case 'INF_N':
          return 'N';
        default:
          return '';
      }
    },
  },
  'planInfo.followsMedicareGuidelines': {
    label: 'Follows Medicare Guidelines?',
    getValue: getBoolVal,
  },
  'planInfo.otherInsuranceName': {
    label: 'Other Insurance Name',
    getValue: getStringVal,
  },
  'planInfo.coordinationOfBenefits': {
    label: 'Coordination of Benefits',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_COORDINATION_OF_BENEFITS_SUPPLEMENT':
          return 'Supplement';
        case 'INF_COORDINATION_OF_BENEFITS_STANDARD':
          return 'Standard';
        case 'INF_COORDINATION_OF_BENEFITS_NON_DUPLICATION':
          return 'Non Duplication';
        case 'INF_COORDINATION_OF_BENEFITS_COME_OUT_WHOLE':
          return 'Come Out Whole';
        default:
          return '';
      }
    },
  },
  'planInfo.willCoverIfPrimaryDenies': {
    label: 'Will Cover If Primary Denies?',
    getValue: getBoolVal,
  },
  'planInfo.willCoverPartBDeductible': {
    label: 'Will Cover Part B Deductible?',
    getValue: getBoolVal,
  },
  'planInfo.planRenewalMonth': {
    label: 'Plan Renewal Month',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PLAN_RENEWAL_MONTH_JAN':
          return 'Jan';
        case 'INF_PLAN_RENEWAL_MONTH_FEB':
          return 'Feb';
        case 'INF_PLAN_RENEWAL_MONTH_MAR':
          return 'Mar';
        case 'INF_PLAN_RENEWAL_MONTH_APR':
          return 'Apr';
        case 'INF_PLAN_RENEWAL_MONTH_MAY':
          return 'May';
        case 'INF_PLAN_RENEWAL_MONTH_JUN':
          return 'Jun';
        case 'INF_PLAN_RENEWAL_MONTH_JUL':
          return 'Jul';
        case 'INF_PLAN_RENEWAL_MONTH_AUG':
          return 'Aug';
        case 'INF_PLAN_RENEWAL_MONTH_SEP':
          return 'Sep';
        case 'INF_PLAN_RENEWAL_MONTH_OCT':
          return 'Oct';
        case 'INF_PLAN_RENEWAL_MONTH_NOV':
          return 'Nov';
        case 'INF_PLAN_RENEWAL_MONTH_DEC':
          return 'Dec';
        default:
          return '';
      }
    },
  },
  'planInfo.renewalType': {
    label: 'Renewal Type',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PLAN_RENEWAL_TYPE_CALENDAR_YEARLY':
          return 'Calendar Yearly';
        case 'INF_PLAN_RENEWAL_TYPE_CALENDAR_QUARTERLY':
          return 'Calendar Quarterly';
        case 'INF_PLAN_RENEWAL_TYPE_CALENDAR_MONTHLY':
          return 'Calendar Monthly';
        case 'INF_PLAN_RENEWAL_TYPE_BENEFIT_YEARLY':
          return 'Benefit Yearly';
        case 'INF_PLAN_RENEWAL_TYPE_BENEFIT_QUARTERLY':
          return 'Benefity Quarterly';
        case 'INF_PLAN_RENEWAL_TYPE_BENEFIT_MONTHLY':
          return 'Benefit Monthly';
        default:
          return '';
      }
    },
  },
  'planInfo.fundingType': {
    label: 'Funding Type',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_FUNDING_TYPE_SELF':
          return 'Self Funded';
        case 'INF_FUNDING_TYPE_FULLY':
          return 'Fully Insured';
        default:
          return '';
      }
    },
  },
  'planInfo.isCobraPlan': {
    label: 'Is Cobra Plan?',
    getValue: getBoolVal,
  },
  'planInfo.cobraHasGracePeriod': {
    label: 'Cobra Has Grace Period?',
    getValue: getBoolVal,
  },
  'planInfo.healthExchangeHasGracePeriod': {
    label: 'Health Exchange Has Grace Period?',
    getValue: getBoolVal,
  },
  'planInfo.cobraPaidThroughDate': {
    label: 'Cobra Paid Through Date',
    getValue: getStringVal,
  },
  'planInfo.cobraGracePeriod': {
    label: 'Cobra Grace Period',
    getValue: getStringVal,
  },
  'planInfo.isHealthExchangePlan': {
    label: 'Is Health Exchange Plan?',
    getValue: getBoolVal,
  },
  'planInfo.patientReceivesSubsidies': {
    label: 'Patient Receives Subsidies?',
    getValue: getBoolVal,
  },
  'planInfo.healthExchangePaidThroughDate': {
    label: 'Health exchange paid through date',
    getValue: getStringVal,
  },
  'planInfo.healthExchangeGracePeriod': {
    label: 'Health exchange grace period',
    getValue: getStringVal,
  },
  'planInfo.additionalNotes': {
    label: 'Additional Notes',
    getValue: getStringVal,
  },
  'planInfo.pbmExists': {
    label: 'Pharmacy Benefits Manager exists?',
    getValue: getBoolVal,
  },
  'planInfo.pbmName': {
    label: 'Pharmacy Benefits Manager name',
    getValue: getStringVal,
  },
  'planInfo.pbmPhone': {
    label: 'Pharmacy Benefits Manager phone',
    getValue: getStringVal,
  },
  'planInfo.pbmPolicyID': {
    label: 'Pharmacy Benefits Manager Policy ID',
    getValue: getStringVal,
  },
  'planInfo.policyHolderRelationship': {
    label: 'Relationship to the Policy Holder',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_POLICY_HOLDER_RELATIONSHIP_SELF':
          return 'Self';
        case 'INF_POLICY_HOLDER_RELATIONSHIP_CHILD':
          return 'Child';
        case 'INF_POLICY_HOLDER_RELATIONSHIP_SPOUSE':
          return 'Spouse';
        case 'INF_POLICY_HOLDER_RELATIONSHIP_OTHER':
          return 'Other';
        case 'INF_POLICY_HOLDER_RELATIONSHIP_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'planInfo.policyHolderDateOfBirth': {
    label: 'Policy Holder Date Of Birth',
    getValue: getStringVal,
  },
  'planInfo.policyHolderFirstName': {
    label: 'Policy Holder First Name',
    getValue: getStringVal,
  },
  'planInfo.policyHolderLastName': {
    label: 'Policy Holder Last Name',
    getValue: getStringVal,
  },
  'planInfo.sitesOfCare': {
    label: 'Sites of Care Network Statuses',
    getValue: (socs) => {
      return socs
        ?.map((soc: { name: string; networkStatus: string }) => {
          var humanReadableNetworkStatus = 'Unknown';
          switch (soc.networkStatus) {
            case 'INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_UNKNOWN':
              humanReadableNetworkStatus = 'Unknown';
              break;
            case 'INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK':
              humanReadableNetworkStatus = 'In Network';
              break;
            case 'INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS':
              humanReadableNetworkStatus = 'In Network Different Address';
              break;
            case 'INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_OUT_OF_NETWORK':
              humanReadableNetworkStatus = 'Out of Network';
              break;
            default:
              humanReadableNetworkStatus = 'Unknown';
          }
          return `${soc.name}: ${humanReadableNetworkStatus}`;
        })
        .join(',\n');
    },
  },
  'planInfo.claimsResponsibleOrgName': {
    label: 'Claims Responsible Organization Name',
    getValue: getStringVal,
  },
  'planInfo.benefitsTier': {
    label: 'Benefits Tier',
    getValue: getIntVal,
  },
  'productCodeBenefits.isCoverageSameForBNBAndSP': {
    label: 'Is Coverage Same For BNB And SP?',
    getValue: getBoolVal,
  },
  'productCodeBenefits.valid': {
    label: 'Valid?',
    getValue: getBoolVal,
  },
  'productCodeBenefits.copay': {
    label: 'Copay',
    getValue: getMoneyVal,
  },
  'productCodeBenefits.coinsurance': {
    label: 'Coinsurance',
    getValue: getPercentVal,
  },
  'productCodeBenefits.deductibleApplies': {
    label: 'Deductible Applies?',
    getValue: getBoolVal,
  },
  'productCodeBenefits.specialtyPharmacyCopay': {
    label: 'Specialty Pharmacy Copay',
    getValue: getMoneyVal,
  },
  'productCodeBenefits.specialtyPharmacyCoinsurance': {
    label: 'Specialty Pharmacy Coinsurance',
    getValue: getPercentVal,
  },
  'productCodeBenefits.specialtyPharmacyDeductibleApplies': {
    label: 'Specialty Pharmacy Deductible Applies?',
    getValue: getBoolVal,
  },
  'productCodeBenefits.specialtyPharmacyReasonForNonCoverage': {
    label: 'Specialty Pharmacy Reason For Non Coverage',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM':
          return 'No Rx benefits as drug covered under PBM';
        case 'INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS':
          return 'No Infusion Or No Injectable Benefits';
        case 'INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B':
          return 'Not Enrolled In Part B';
        case 'INF_REASON_FOR_NON_COVERAGE_CAP_MET':
          return 'Non Coverage Cap Met';
        case 'INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS':
          return 'Non Coverage Exclusion Diagnosis';
        case 'INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG':
          return 'Non Coverage Exclusion drug';
        case 'INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT':
          return 'Covered Under Medical Benefit';
        case 'INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG':
          return 'Federal Legend Drug';
        case 'INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL':
          return 'Need Appeal';
        case 'INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY':
          return 'Non-Formulary';
        case 'INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION':
          return 'Plan Exclusion';
        case 'INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY':
          return 'Plan Is Secondary';
        case 'INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED':
          return 'Prior Authorization Required';
        case 'INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED':
          return 'Step Therapy Required';
        default:
          return '';
      }
    },
  },
  'productCodeBenefits.specialtyPharmacyCoverage': {
    label: 'Specialty Pharmacy Coverage',
    getValue: getBoolVal,
  },
  'productCodeBenefits.additionalNotes': {
    label: 'Additional Notes',
    getValue: getStringVal,
  },
  'cptCodeBenefits.valid': {
    label: 'Valid?',
    getValue: getBoolVal,
  },
  'cptCodeBenefits.copay': {
    label: 'Copay',
    getValue: getMoneyVal,
  },
  'cptCodeBenefits.coinsurance': {
    label: 'Coinsurance',
    getValue: getPercentVal,
  },
  'cptCodeBenefits.priorAuthRequired': {
    label: 'Prior Auth Required',
    getValue: getBoolVal,
  },
  'cptCodeBenefits.priorAuthStatus': {
    label: 'Prior Auth Status',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_STATUS_NOT_ON_FILE':
          return 'Not on file';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED':
          return 'Approved';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR':
          return 'Approved for Different Doctor';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS':
          return 'Approved for Different Diagnosis';
        case 'INF_PRIOR_AUTH_STATUS_DENIED':
          return 'Denied';
        case 'INF_PRIOR_AUTH_STATUS_EXPIRED':
          return 'Expired';
        case 'INF_PRIOR_AUTH_STATUS_FUTURE':
          return 'Future';
        case 'INF_PRIOR_AUTH_STATUS_PENDING':
          return 'Pending';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS':
          return 'Approved for Different POS';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS':
          return 'Approved for Different Address';
        case 'INF_PRIOR_AUTH_STATUS_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'cptCodeBenefits.preDeterminationRequirement': {
    label: 'PreDetermination Requirement',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PREDETERMINATION_REQUIREMENT_REQUIRED':
          return 'Required';
        case 'INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED':
          return 'Highly Recommended';
        case 'INF_PREDETERMINATION_REQUIREMENT_AVAILABLE':
          return 'Available';
        case 'INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE':
          return 'Not Available';
        default:
          return '';
      }
    },
  },
  'cptCodeBenefits.preDeterminationOnFile': {
    label: 'PreDetermination On File',
    getValue: getBoolVal,
  },
  'cptCodeBenefits.reasonForNonCoverage': {
    label: 'Reason For Non Coverage',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM':
          return 'No Rx benefits as drug covered under PBM';
        case 'INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS':
          return 'No Infusion Or No Injectable Benefits';
        case 'INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B':
          return 'Not Enrolled In Part B';
        case 'INF_REASON_FOR_NON_COVERAGE_CAP_MET':
          return 'Non Coverage Cap Met';
        case 'INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS':
          return 'Non Coverage Exclusion Diagnosis';
        case 'INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG':
          return 'Non Coverage Exclusion drug';
        case 'INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT':
          return 'Covered Under Medical Benefit';
        case 'INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG':
          return 'Federal Legend Drug';
        case 'INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL':
          return 'Need Appeal';
        case 'INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY':
          return 'Non-Formulary';
        case 'INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION':
          return 'Plan Exclusion';
        case 'INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY':
          return 'Plan Is Secondary';
        case 'INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED':
          return 'Prior Authorization Required';
        case 'INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED':
          return 'Step Therapy Required';
        case 'INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK':
          return 'Out of Network';
        default:
          return '';
      }
    },
  },
  'cptCodeBenefits.deductibleApplies': {
    label: 'Deductible Applies?',
    getValue: getBoolVal,
  },
  'cptCodeBenefits.additionalNotes': {
    label: 'Additional Notes',
    getValue: getStringVal,
  },
  'cptCodePriorAuthRequirements.priorAuthRequired': {
    label: 'Prior Auth Required',
    getValue: getBoolVal,
  },
  'productCoverage.isProductCovered': {
    label: 'Product Covered?',
    getValue: getBoolVal,
  },
  'productCoverage.specialtyPharmacyCopay': {
    label: 'Specialty Pharmacy Copay',
    getValue: getMoneyVal,
  },
  'productCoverage.specialtyPharmacyCoinsurance': {
    label: 'Specialty Pharmacy Coinsurance',
    getValue: getPercentVal,
  },
  'productCoverage.isProductCoveredRetail': {
    label: 'Product Covered in Retail Pharmacy?',
    getValue: getBoolVal,
  },
  'productCoverage.retailPharmacyCopay': {
    label: 'Retail Pharmacy Copay',
    getValue: getMoneyVal,
  },
  'productCoverage.retailPharmacyCoinsurance': {
    label: 'Retail Pharmacy Coinsurance',
    getValue: getPercentVal,
  },
  'productCoverage.isProductCoveredMailOrder': {
    label: 'Product Covered in Mail Order?',
    getValue: getBoolVal,
  },
  'productCoverage.mailOrderPharmacyCopay': {
    label: 'Mail Order Pharmacy Copay',
    getValue: getMoneyVal,
  },
  'productCoverage.mailOrderPharmacyCoinsurance': {
    label: 'Mail Order Pharmacy Coinsurance',
    getValue: getPercentVal,
  },
  'productCoverage.costShareTransparencyNotes': {
    label: 'Cost Share Transparency Notes',
    getValue: getStringVal,
  },
  'deductiblesAndOops.individualDeductibleExists': {
    label: 'Individual Deductible Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.individualTotalDeductible': {
    label: 'Individual Deductible Total',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.individualDeductibleMetAmount': {
    label: 'Individual Deductible Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.spendDownExists': {
    label: 'Spend Down Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.spendDownTotal': {
    label: 'Spend Down Total',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.spendDownMet': {
    label: 'Spend Down Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.familyDeductibleExists': {
    label: 'Family Deductible Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.familyTotalDeductible': {
    label: 'Family Deductible Total',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.familyDeductibleMetAmount': {
    label: 'Family Deductible Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.individualOopMaximumExists': {
    label: 'Individual OOP Maximum Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.individualOopMaximum': {
    label: 'Individual OOP Maximum',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.individualOopMetAmount': {
    label: 'Individual OOP Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.familyOopMaximumExists': {
    label: 'Family OOP Maximum Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.familyOopMaximum': {
    label: 'Family OOP Maximum',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.familyOopMetAmount': {
    label: 'Family OOP Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.deductibleIncludedInOop': {
    label: 'Deductible Included In OOP?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.copayAppliesToOop': {
    label: 'Copay Applies To OOP?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.copayWaivedAfterOop': {
    label: 'Copay Waived After OOP?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.coinsuranceWaivedAfterOop': {
    label: 'Coinsurance Waived After OOP',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.multipleCopayResponsibility': {
    label: 'Multiple Copay Responsibility?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.lifetimeMaximumExists': {
    label: 'Lifetime Maximum Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.lifetimeMaximum': {
    label: 'Lifetime Maximum',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.lifetimeMaximumMetAmount': {
    label: 'Lifetime Maximum Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.annualBenefitCapExists': {
    label: 'Annual Benefit Cap Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.annualBenefitCap': {
    label: 'Annual Benefit Cap',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.annualBenefitCapMetAmount': {
    label: 'Annual Benefit Cap Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.additionalNotes': {
    label: 'Additional Notes',
    getValue: getStringVal,
  },
  'deductiblesAndOops.initialCoverageLimitTotal': {
    label: 'Initial Coverage Limit Total',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.initialCoverageLimitMetAmount': {
    label: 'Initial Coverage Limit Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.isOnLowIncomeSubsidy': {
    label: 'On Low Income Subsidy?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.lowIncomeSubsidyLevel': {
    label: 'Low Income Subsidy Level',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_LOW_INCOME_SUBSIDY_LEVEL_ONE':
          return '1';
        case 'INF_LOW_INCOME_SUBSIDY_LEVEL_TWO':
          return '2';
        case 'INF_LOW_INCOME_SUBSIDY_LEVEL_THREE':
          return '3';
        case 'INF_LOW_INCOME_SUBSIDY_LEVEL_FOUR':
          return '4';
        default:
          return '';
      }
    },
  },
  'deductiblesAndOops.medicarePartD.partDCurrentStage': {
    label: 'Part D Current Stage',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PART_D_STAGE_INITIAL_COVERAGE':
          return 'Initial Coverage';
        case 'INF_PART_D_STAGE_COVERAGE_GAP':
          return 'Coverage Gap';
        case 'INF_PART_D_STAGE_CATASTROPHIC':
          return 'Catastrophic';
        default:
          return '';
      }
    },
  },
  'deductiblesAndOops.medicarePartD.coverageGapCoinsurance': {
    label: 'Coverage Gap Coinsurance',
    getValue: getPercentVal,
  },
  'deductiblesAndOops.medicarePartD.coverageGapCopay': {
    label: 'Coverage Gap Copay',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.medicarePartD.catastrophicCoinsurance': {
    label: 'Catastrophic Coinsurance',
    getValue: getPercentVal,
  },
  'deductiblesAndOops.medicarePartD.catastrophicCopay': {
    label: 'Catastrophic Copay',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.medicarePartD.troopMetAmount': {
    label: 'Catastrophic Copay',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.isAccumulatorPlan': {
    label: 'Is Accumulator Plan?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.isMaximizerPlan': {
    label: 'Is Maximizer Plan?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.homeHealthDeductibleExists': {
    label: 'Home Health Deductible Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.homeHealthTotalDeductible': {
    label: 'Home Health Total Deductible',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.homeHealthDeductibleMetAmount': {
    label: 'Home Health Deductible Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.homeHealthOopMaximumExists': {
    label: 'Home Health OOP Max Exists?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.homeHealthOopMaximum': {
    label: 'Home Health OOP Maximum',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.homeHealthOopMaximumMetAmount': {
    label: 'Home Health OOP Met',
    getValue: getMoneyVal,
  },
  'deductiblesAndOops.deductibleMetBeforeCOB': {
    label: 'Deductible needs to met before COB?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.oopMetBeforeCOB': {
    label: 'Out of Pocket needs to met before COB?',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.canUseCoPayAssistance': {
    label: 'Can use copay assistance',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.fullAmtCoPayAssistanceApplies': {
    label: 'Full amount copay assistance applies',
    getValue: getBoolVal,
  },
  'deductiblesAndOops.maximizerProgramName': {
    label: 'Maximizer Program Name',
    getValue: getStringVal,
  },
  'deductiblesAndOops.maximizerProgramPhone': {
    label: 'Maximizer Program Phone',
    getValue: getStringVal,
  },
  'deductiblesAndOops.maximizerOptInDate': {
    label: 'Maximizer Opt In Date',
    getValue: getStringVal,
  },
  'officeBenefits.copay': {
    label: 'Copay',
    getValue: getMoneyVal,
  },
  'officeBenefits.coinsurance': {
    label: 'Coinsurance',
    getValue: getPercentVal,
  },
  'officeBenefits.alwaysApplies': {
    label: 'Copay Always Applies?',
    getValue: getBoolVal,
  },
  'officeBenefits.deductibleApplies': {
    label: 'Deductible Applies?',
    getValue: getBoolVal,
  },
  'officeBenefits.additionalNotes': {
    label: 'Additional Notes',
    getValue: getStringVal,
  },
  'homeHealthBenefits.copay': {
    label: 'Copay',
    getValue: getMoneyVal,
  },
  'homeHealthBenefits.coinsurance': {
    label: 'Coinsurance',
    getValue: getPercentVal,
  },
  'homeHealthBenefits.deductibleApplies': {
    label: 'Deductible Applies?',
    getValue: getBoolVal,
  },
  'accessInfo.priorAuthRequired': {
    label: 'Prior Auth Required?',
    getValue: getBoolVal,
  },
  'accessInfo.priorAuthRequiredCodes': {
    label: 'Prior Auth Required Codes',
    getValue: (values: string[]) => (values || []).map(getStringVal).join(', '),
  },
  'accessInfo.priorAuthOnFile': {
    label: 'Prior Auth on File?',
    getValue: getBoolVal,
  },
  'accessInfo.priorAuthCodesOnFile': {
    label: 'Prior Auth Codes On File',
    getValue: (values: string[]) => (values || []).map(getStringVal).join(', '),
  },
  'accessInfo.priorAuthType': {
    label: 'Type of Prior Authorization required',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_TYPE_PRIOR_AUTHORIZATION':
          return 'Prior Authorization';
        case 'INF_PRIOR_AUTH_TYPE_PRECERTIFICATION':
          return 'Precertification';
        case 'INF_PRIOR_AUTH_TYPE_PREDETERMINATION':
          return 'Predetermination';
        case 'INF_PRIOR_AUTH_TYPE_FORMULARY_EXCEPTION':
          return 'Formulary Exception';
        case 'INF_PRIOR_AUTH_TYPE_TIER_EXCEPTION':
          return 'Tier Exception';
        case 'INF_PRIOR_AUTH_TYPE_NONE':
          return 'None';
        case 'INF_PRIOR_AUTH_TYPE_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'accessInfo.predeterminationStatus': {
    label: 'Predetermination Status',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PREDETERMINATION_STATUS_NOT_APPLICABLE':
          return 'Not Applicable';
        case 'INF_PREDETERMINATION_STATUS_NOT_ON_FILE':
          return 'Not On File';
        case 'INF_PREDETERMINATION_STATUS_APPROVED':
          return 'Approved';
        case 'INF_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR':
          return 'Approved for Different Doctor';
        case 'INF_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS':
          return 'Approved for Different Diagnosis';
        case 'INF_PREDETERMINATION_STATUS_DENIED':
          return 'Denied';
        case 'INF_PREDETERMINATION_STATUS_EXPIRED':
          return 'Expired';
        case 'INF_PREDETERMINATION_STATUS_FUTURE':
          return 'Future';
        case 'INF_PREDETERMINATION_STATUS_PENDING':
          return 'Pending';
        case 'INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS':
          return 'Approved for Different POS';
        case 'INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS':
          return 'Approved for Different Address';
        default:
          return '';
      }
    },
  },
  'accessInfo.priorAuthStatus': {
    label: 'Prior Auth Status',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_STATUS_NOT_ON_FILE':
          return 'Not on file';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED':
          return 'Approved';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR':
          return 'Approved for Different Doctor';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS':
          return 'Approved for Different Diagnosis';
        case 'INF_PRIOR_AUTH_STATUS_DENIED':
          return 'Denied';
        case 'INF_PRIOR_AUTH_STATUS_EXPIRED':
          return 'Expired';
        case 'INF_PRIOR_AUTH_STATUS_FUTURE':
          return 'Future';
        case 'INF_PRIOR_AUTH_STATUS_PENDING':
          return 'Pending';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS':
          return 'Approved for Different POS';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS':
          return 'Approved for Different Address';
        case 'INF_PRIOR_AUTH_STATUS_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'accessInfo.priorAuthSubmissionDate': {
    label: 'Prior Auth Submission Date',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationSubmissionDate': {
    label: 'Predetermination Submission Date',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthDenialReason': {
    label: 'Prior Auth Denial Reason',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthAppealsContactFax': {
    label: 'Prior Auth Appeals Contact Fax',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthApprovalDate': {
    label: 'Prior Auth Approval Date',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthStartDate': {
    label: 'Prior Auth Start Date',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthOnFileForDose': {
    label: 'Prior Auth On File For Dose',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthEndDate': {
    label: 'Prior Auth End Date',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthApprovalNumber': {
    label: 'Prior Auth Approval Number',
    getValue: getStringVal,
  },
  'accessInfo.obtainPriorAuthOrg': {
    label: 'Obtain Prior Auth Org',
    getValue: getStringVal,
  },
  'accessInfo.obtainPriorAuthPhone': {
    label: 'Obtain Prior Auth Phone',
    getValue: getStringVal,
  },
  'accessInfo.obtainPriorAuthWebsite': {
    label: 'Obtain Prior Auth Website',
    getValue: getStringVal,
  },
  'accessInfo.obtainPriorAuthFax': {
    label: 'Obtain Prior Auth Fax',
    getValue: getStringVal,
  },
  'accessInfo.timelyFilingPeriod': {
    label: 'Timely Filing Period',
    getValue: getStringVal,
  },
  'accessInfo.obtainPriorAuthRequirements': {
    label: 'Obtain Prior Auth Requirements',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_REQUIREMENT_PA_FORM':
              return 'PA Form';
            case 'INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY':
              return 'Letter of Necessity';
            case 'INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS':
              return 'Medical Documents';
            case 'INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET':
              return 'Fax Coversheet';
            case 'INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE':
              return 'SP Must Initiate';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.priorAuthTurnaroundTime': {
    label: 'Prior Auth Turnaround Time',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthResponsibleOrg': {
    label: 'Prior Auth Responsible Org',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_RESPONSIBLE_ORG_PLAN':
          return 'Plan';
        case 'INF_PRIOR_AUTH_RESPONSIBLE_ORG_MEDICAL_GROUP':
          return 'Medical Group';
        default:
          return '';
      }
    },
  },
  'accessInfo.priorAuthApprovedQuantity': {
    label: 'Prior Auth Approved Quantity',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthApprovedQuantityUsed': {
    label: 'Prior Auth Approved Quantity Used',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthRenewalProcessExists': {
    label: 'Prior Auth Renewal Process Exists?',
    getValue: getBoolVal,
  },
  'accessInfo.priorAuthApprovedTreatments': {
    label: 'Prior Auth Approved Treatments',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthApprovedTreatmentsUsed': {
    label: 'Prior Auth Approved Treatments Used',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthProviderNameOnFile': {
    label: 'Prior Auth Provider Name On File',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthOnFileApplies': {
    label: 'Prior AuthOn File Applies',
    getValue: getBoolVal,
  },
  'accessInfo.priorAuthAddressOnFile': {
    label: 'Prior Auth Address On File',
    getValue: getAddressVal,
  },
  'accessInfo.priorAuthExceptionReason': {
    label: 'Prior Auth Exception Reason',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthCompletionDate': {
    label: 'Prior Auth Completion Date',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthSubmissionMethod': {
    label: 'Prior Auth Submission Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE':
          return 'Phone';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX':
          return 'Fax';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL':
          return 'Mail';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE':
          return 'Website';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL':
          return 'Email';
        default:
          return '';
      }
    },
  },
  'accessInfo.priorAuthSubmissionMethods': {
    label: 'Prior Auth Submission Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE':
              return 'Phone';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX':
              return 'Fax';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL':
              return 'Mail';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE':
              return 'Website';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL':
              return 'Email';
            case 'INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION':
              return 'Electronic Transmission';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.obtainPriorAuthFormLink': {
    label: 'Obtain Prior Auth Form Link',
    getValue: getStringVal,
  },
  'accessInfo.obtainPriorAuthAddress': {
    label: 'Obtain Prior Auth Address',
    getValue: getAddressVal,
  },
  'accessInfo.therapySeasonStartDate': {
    label: 'Season Start Date for the therapy',
    getValue: getStringVal,
  },
  'accessInfo.specialtyCoverageCarveout': {
    label: 'Is there a carveout for Specialty Coverage?',
    getValue: getBoolVal,
  },
  'accessInfo.specialtyCoverageCarveoutEntity': {
    label: 'Entity responsible for Specialty coverage carveout',
    getValue: getStringVal,
  },

  'accessInfo.specialtyPharmacyPriorAuthRequired': {
    label: 'Specialty Pharmacy Prior Auth Required?',
    getValue: getBoolVal,
  },
  'accessInfo.specialtyPharmacyPredeterminationStatus': {
    label: 'Specialty Pharmacy Predetermination Status',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_APPLICABLE':
          return 'Not Applicable';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_ON_FILE':
          return 'Not On File';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED':
          return 'Approved';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR':
          return 'Approved for Different Doctor';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS':
          return 'Approved for Different Diagnosis';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_DENIED':
          return 'Denied';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_EXPIRED':
          return 'Expired';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_FUTURE':
          return 'Future';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_PENDING':
          return 'Pending';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS':
          return 'Approved for Different POS';
        case 'INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS':
          return 'Approved for Different Address';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyPriorAuthStatus': {
    label: 'Specialty Pharmacy Prior Auth Status',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_STATUS_NOT_ON_FILE':
          return 'Not on file';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED':
          return 'Approved';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR':
          return 'Approved for Different Doctor';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS':
          return 'Approved for Different Diagnosis';
        case 'INF_PRIOR_AUTH_STATUS_DENIED':
          return 'Denied';
        case 'INF_PRIOR_AUTH_STATUS_EXPIRED':
          return 'Expired';
        case 'INF_PRIOR_AUTH_STATUS_FUTURE':
          return 'Future';
        case 'INF_PRIOR_AUTH_STATUS_PENDING':
          return 'Pending';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS':
          return 'Approved for Different POS';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS':
          return 'Approved for Different Address';
        case 'INF_PRIOR_AUTH_STATUS_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyPriorAuthNotificationMethod': {
    label: 'Specialty Pharmacy Prior Auth Notification Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE':
          return 'Phone';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX':
          return 'Fax';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE':
          return 'Website';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL':
          return 'Email';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL':
          return 'Mail';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyPriorAuthNotificationMethods': {
    label: 'Specialty Pharmacy Prior Auth Notification Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE':
              return 'Phone';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX':
              return 'Fax';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL':
              return 'Mail';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE':
              return 'Website';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL':
              return 'Email';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.specialtyPharmacyPriorAuthStartDate': {
    label: 'Specialty Pharmacy Prior Auth Start Date',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPriorAuthEndDate': {
    label: 'Specialty Pharmacy Prior Auth End Date',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPriorAuthApprovalNumber': {
    label: 'Specialty Pharmacy Prior Auth Approval Number',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPriorAuthOrg': {
    label: 'Specialty Pharmacy Obtain Prior Auth Org',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPriorAuthPhone': {
    label: 'Specialty Pharmacy Obtain Prior Auth Phone',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPriorAuthWebsite': {
    label: 'Specialty Pharmacy Obtain Prior Auth Website',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPriorAuthFax': {
    label: 'Specialty Pharmacy Obtain Prior Auth Fax',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPriorAuthRequirements': {
    label: 'Specialty Pharmacy Obtain Prior Auth Requirements',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_REQUIREMENT_PA_FORM':
              return 'PA Form';
            case 'INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY':
              return 'Letter of Necessity';
            case 'INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS':
              return 'Medical Documents';
            case 'INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET':
              return 'Fax Coversheet';
            case 'INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE':
              return 'SP Must Initiate';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.specialtyPharmacyPriorAuthTurnaroundTime': {
    label: 'Specialty Pharmacy Prior Auth Turnaround Time',
    getValue: getStringVal,
  },

  'accessInfo.cptPriorAuthProcessDifferent': {
    label: 'CPT Prior Auth Process Different',
    getValue: getBoolVal,
  },
  'accessInfo.cptPriorAuthSubmissionMethods': {
    label: 'CPT Prior Auth Submission Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE':
              return 'Phone';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX':
              return 'Fax';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL':
              return 'Mail';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE':
              return 'Website';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL':
              return 'Email';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.cptPriorAuthSubmissionMethod': {
    label: 'CPT Prior Auth Submission Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE':
          return 'Phone';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX':
          return 'Fax';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL':
          return 'Mail';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE':
          return 'Website';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL':
          return 'Email';
        default:
          return '';
      }
    },
  },
  'accessInfo.cptPriorAuthNotificationMethod': {
    label: 'CPT Prior Auth Notification Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE':
          return 'Phone';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX':
          return 'Fax';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE':
          return 'Website';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL':
          return 'Email';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL':
          return 'Mail';
        default:
          return '';
      }
    },
  },
  'accessInfo.cptPriorAuthNotificationMethods': {
    label: 'CPT Prior Auth Notification Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE':
              return 'Phone';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX':
              return 'Fax';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL':
              return 'Mail';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE':
              return 'Website';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL':
              return 'Email';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.cptPriorAuthStartDate': {
    label: 'CPT Prior Auth Start Date',
    getValue: getStringVal,
  },
  'accessInfo.cptPriorAuthEndDate': {
    label: 'CPT Prior Auth End Date',
    getValue: getStringVal,
  },
  'accessInfo.cptPriorAuthApprovalNumber': {
    label: 'CPT Prior Auth Approval Number',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPriorAuthOrg': {
    label: 'CPT Obtain Prior Auth Org',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPriorAuthPhone': {
    label: 'CPT Obtain Prior Auth Phone',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPriorAuthWebsite': {
    label: 'CPT Obtain Prior Auth Website',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPriorAuthFormLink': {
    label: 'CPT Obtain Prior Auth Form Link',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPriorAuthAddress': {
    label: 'CPT Obtain Prior Auth Address',
    getValue: getAddressVal,
  },
  'accessInfo.cptObtainPriorAuthFax': {
    label: 'CPT Obtain Prior Auth Fax',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPriorAuthRequirements': {
    label: 'CPT Obtain Prior Auth Requirements',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_REQUIREMENT_PA_FORM':
              return 'PA Form';
            case 'INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY':
              return 'Letter of Necessity';
            case 'INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS':
              return 'Medical Documents';
            case 'INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET':
              return 'Fax Coversheet';
            case 'INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE':
              return 'SP Must Initiate';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.cptPriorAuthTurnaroundTime': {
    label: 'CPT Prior Auth Turnaround Time',
    getValue: getStringVal,
  },
  'accessInfo.cptPriorAuthApprovedQuantity': {
    label: 'CPT Prior Auth Approved Quantity',
    getValue: getStringVal,
  },
  'accessInfo.cptPriorAuthApprovedQuantityUsed': {
    label: 'CPT Prior Auth Approved Quantity Used',
    getValue: getStringVal,
  },
  'accessInfo.cptPriorAuthApprovedTreatments': {
    label: 'CPT Prior Auth Approved Treatments',
    getValue: getStringVal,
  },
  'accessInfo.cptPriorAuthApprovedTreatmentsUsed': {
    label: 'CPT Prior Auth Approved Treatments Used',
    getValue: getStringVal,
  },
  'accessInfo.cptPredeterminationNumber': {
    label: 'CPT Predetermination Number',
    getValue: getStringVal,
  },
  'accessInfo.cptPredeterminationStartDate': {
    label: 'CPT Predetermination Start Date',
    getValue: getStringVal,
  },
  'accessInfo.cptPredeterminationEndDate': {
    label: 'CPT Predetermination End Date',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPreDeterminationOrg': {
    label: 'CPT Obtain PreDetermination Org',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPredeterminationPhone': {
    label: 'CPT Obtain Predetermination Phone',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPredeterminationWebsite': {
    label: 'CPT Obtain Predetermination Website',
    getValue: getStringVal,
  },
  'accessInfo.cptObtainPredeterminationFax': {
    label: 'CPT Obtain Predetermination Fax',
    getValue: getStringVal,
  },
  'accessInfo.cptPredeterminationTurnaroundTime': {
    label: 'CPT Predetermination Turnaround Time',
    getValue: getStringVal,
  },
  'accessInfo.cptPredeterminationApprovedQuantity': {
    label: 'CPT Predetermination Approved Quantity',
    getValue: getStringVal,
  },
  'accessInfo.cptPredeterminationApprovedQuantityUsed': {
    label: 'CPT Predetermination Approved Quantity Used',
    getValue: getStringVal,
  },
  'accessInfo.medicalGroupName': {
    label: 'Medical Group Name',
    getValue: getStringVal,
  },
  'accessInfo.medicalPolicyAvailableOnWebsite': {
    label: 'Medical Policy Available on Website?',
    getValue: getBoolVal,
  },
  'accessInfo.medicalPolicyNumber': {
    label: 'Medical Policy Number',
    getValue: getStringVal,
  },
  'accessInfo.medicalGroupPhoneNumber': {
    label: 'Medical Group Phone Number',
    getValue: getStringVal,
  },
  'accessInfo.preDeterminationRequired': {
    label: 'PreDetermination Required? (Deprecated)',
    getValue: getBoolVal,
  },
  'accessInfo.preDeterminationAvailable': {
    label: 'PreDetermination Available? (Deprecated)',
    getValue: getBoolVal,
  },
  'accessInfo.preDeterminationRequirement': {
    label: 'PreDetermination Requirement',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PREDETERMINATION_REQUIREMENT_REQUIRED':
          return 'Required';
        case 'INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED':
          return 'Highly Recommended';
        case 'INF_PREDETERMINATION_REQUIREMENT_AVAILABLE':
          return 'Available';
        case 'INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE':
          return 'Not Available';
        default:
          return '';
      }
    },
  },
  'accessInfo.preDeterminationOnFile': {
    label: 'PreDetermination On File?',
    getValue: getBoolVal,
  },
  'accessInfo.preDeterminationNumber': {
    label: 'PreDetermination Number',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationStartDate': {
    label: 'PreDetermination Start Date',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationEndDate': {
    label: 'PreDetermination End Date',
    getValue: getStringVal,
  },
  'accessInfo.obtainPreDeterminationOrg': {
    label: 'Obtain PreDetermination Org',
    getValue: getStringVal,
  },
  'accessInfo.obtainPredeterminationPhone': {
    label: 'Obtain PreDetermination Phone',
    getValue: getStringVal,
  },
  'accessInfo.obtainPredeterminationWebsite': {
    label: 'Obtain PreDetermination Website',
    getValue: getStringVal,
  },
  'accessInfo.obtainPredeterminationFax': {
    label: 'Obtain PreDetermination Fax',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationTurnaroundTime': {
    label: 'PreDetermination Turnaround Time',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationApprovedQuantity': {
    label: 'PreDetermination Approved Quantity',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationApprovedQuantityUsed': {
    label: 'PreDetermination Approved Quantity Used',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationRenewalProcessExists': {
    label: 'PreDetermination Renewal Process Exists',
    getValue: getBoolVal,
  },
  'accessInfo.predeterminationReceivedDate': {
    label: 'PreDetermination Received Date',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationCompletionDate': {
    label: 'PreDetermination Completion Date',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationOnFileApplies': {
    label: 'PreDetermination On File Applies',
    getValue: getBoolVal,
  },
  'accessInfo.predeterminationProviderNameOnFile': {
    label: 'PreDetermination Provider Name On File',
    getValue: getStringVal,
  },
  'accessInfo.predeterminationAddressOnFile': {
    label: 'PreDetermination Address On File',
    getValue: getAddressVal,
  },
  'accessInfo.stepTherapyRequired': {
    label: 'Step Therapy Required?',
    getValue: getBoolVal,
  },
  'accessInfo.stepTherapyTreatment': {
    label: 'Step Therapy Treatment',
    getValue: getStringVal,
  },
  'accessInfo.pbmSpecialtyPharmacyRequirements': {
    label: 'Specialty Pharmacy Requirements',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_PREFERRED':
          return 'Preferred';
        case 'INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_REQUIRED':
          return 'Required';
        case 'INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyNetwork': {
    label: 'Specialty Pharmacy Network',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_SPECIALTY_PHARMACY_NETWORK_TYPE_EXCLUSIVE':
          return 'Exclusive';
        case 'INF_SPECIALTY_PHARMACY_NETWORK_TYPE_PREFERRED':
          return 'Preferred';
        case 'INF_SPECIALTY_PHARMACY_NETWORK_TYPE_LIMITED':
          return 'Limited';
        case 'INF_SPECIALTY_PHARMACY_NETWORK_TYPE_OPEN':
          return 'Open';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyAvailability': {
    label: 'Specialty Pharmacy Availability',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_SPECIALTY_PHARMACY_AVAILABILITY_REQUIRED':
          return 'Required';
        case 'INF_SPECIALTY_PHARMACY_AVAILABILITY_AVAILABLE':
          return 'Available';
        case 'INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_AVAILABLE':
          return 'Not Available';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyName': {
    label: 'Specialty Pharmacy Name',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyAddress': {
    label: 'Specialty Pharmacy Address',
    getValue: getAddressVal,
  },
  'accessInfo.specialtyPharmacyObtainPriorAuthFormLink': {
    label: 'Specialty Pharmacy Obtain Prior Auth Form Link',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPriorAuthAddress': {
    label: 'Specialty Pharmacy Obtain Prior Auth Address',
    getValue: getAddressVal,
  },
  'accessInfo.specialtyPharmacyPriorAuthApprovedQuantity': {
    label: 'Specialty Pharmacy Prior Auth Approved Quantity',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPriorAuthApprovedQuantityUsed': {
    label: 'Specialty Pharmacy Prior Auth Approved Quantity Used',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPriorAuthApprovedTreatments': {
    label: 'Specialty Pharmacy Prior Auth Approved Treatments',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPriorAuthApprovedTreatmentsUsed': {
    label: 'Specialty Pharmacy Prior Auth Approved Treatments Used',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPredeterminationRequirementType': {
    label: 'Specialty Pharmacy Predetermination Requirement Type',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PREDETERMINATION_REQUIREMENT_REQUIRED':
          return 'Required';
        case 'INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED':
          return 'Recommended';
        case 'INF_PREDETERMINATION_REQUIREMENT_AVAILABLE':
          return 'Available';
        case 'INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE':
          return 'Not Available';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyPredeterminationOnFile': {
    label: 'Specialty Pharmacy Predetermination On File',
    getValue: getBoolVal,
  },
  'accessInfo.specialtyPharmacyPredeterminationNumber': {
    label: 'Specialty Pharmacy Predetermination Number',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPredeterminationStartDate': {
    label: 'Specialty Pharmacy Predetermination Start Date',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPredeterminationEndDate': {
    label: 'Specialty Pharmacy Predetermination End Date',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPreDeterminationOrg': {
    label: 'Specialty Pharmacy Obtain PreDetermination Org',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPredeterminationPhone': {
    label: 'Specialty Pharmacy Obtain Predetermination Phone',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPredeterminationWebsite': {
    label: 'Specialty Pharmacy Obtain Predetermination Website',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyObtainPredeterminationFax': {
    label: 'Specialty Pharmacy Obtain Predetermination Fax',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPredeterminationTurnaroundTime': {
    label: 'Specialty Pharmacy Predetermination Turnaround Time',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPredeterminationApprovedQuantity': {
    label: 'Specialty Pharmacy Predetermination Approved Quantity',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPredeterminationApprovedQuantityUsed': {
    label: 'Specialty Pharmacy Predetermination Approved Quantity Used',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPhone': {
    label: 'Specialty Pharmacy Phone',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyFax': {
    label: 'Specialty Pharmacy Fax',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacy2Name': {
    label: 'Specialty Pharmacy 2 Name',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacy2Address': {
    label: 'Specialty Pharmacy 2 Address',
    getValue: getAddressVal,
  },
  'accessInfo.specialtyPharmacy2Phone': {
    label: 'Specialty Pharmacy 2 Phone',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacy2Fax': {
    label: 'Specialty Pharmacy 2 Fax',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacy3Name': {
    label: 'Specialty Pharmacy 3 Name',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacy3Address': {
    label: 'Specialty Pharmacy 3 Address',
    getValue: getAddressVal,
  },
  'accessInfo.specialtyPharmacy3Phone': {
    label: 'Specialty Pharmacy 3 Phone',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacy3Fax': {
    label: 'Specialty Pharmacy 3 Fax',
    getValue: getStringVal,
  },
  'accessInfo.mailOrderPharmacyName': {
    label: 'Mail Order Pharmacy Name',
    getValue: getStringVal,
  },
  'accessInfo.mailOrderPharmacyPhone': {
    label: 'Mail Order Pharmacy Phone',
    getValue: getStringVal,
  },
  'accessInfo.separatePriorAuthRequired': {
    label: 'Separate Prior Auth Required',
    getValue: getBoolVal,
  },
  'accessInfo.formularyDrug': {
    label: 'Formulary Drug?',
    getValue: getBoolVal,
  },
  'accessInfo.formularyExceptionRequired': {
    label: 'Formulary Exception Required',
    getValue: getStringVal,
  },
  'accessInfo.obtainFormularyExceptionDocument': {
    label: 'Obtain Formulary Exception Document',
    getValue: getStringVal,
  },
  'accessInfo.obtainFormularyExceptionOrg': {
    label: 'Obtain Formulary Exception Org',
    getValue: getStringVal,
  },
  'accessInfo.obtainFormularyExceptionPhone': {
    label: 'Obtain Formulary Exception Phone',
    getValue: getStringVal,
  },
  'accessInfo.obtainFormularyExceptionWebsite': {
    label: 'Obtain Formulary Exception Website',
    getValue: getStringVal,
  },
  'accessInfo.obtainFormularyExceptionFax': {
    label: 'Obtain Formulary Exception Fax',
    getValue: getStringVal,
  },
  'accessInfo.obtainFormularyExceptionTurnaroundTime': {
    label: 'Obtain Formulary Exception Turnaround Time',
    getValue: getStringVal,
  },
  'accessInfo.formularyExceptionStatus': {
    label: 'Formulary Exception Status',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_FORMULARY_EXCEPTION_STATUS_ON_FILE':
          return 'On File';
        case 'INF_FORMULARY_EXCEPTION_STATUS_NOT_ON_FILE':
          return 'Not On File';
        default:
          return '';
      }
    },
  },
  'accessInfo.obtainFormularyExceptionNotificationMethod': {
    label: 'Obtain Formulary Exception Notification Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_PHONE':
          return 'Phone';
        case 'INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_FAX':
          return 'Fax';
        case 'INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_EMAIL':
          return 'Email';
        case 'INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_WEBSITE':
          return 'Website';
        case 'INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_MAIL':
          return 'Mail';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyExclusions': {
    label: 'Specialty Pharmacy Exclusions',
    getValue: getStringVal,
  },
  'accessInfo.specialtyPharmacyPriorAuthProcessDifferent': {
    label: 'Specialty Pharmacy Prior Auth Process Different',
    getValue: getBoolVal,
  },
  'accessInfo.specialtyPharmacyPriorAuthSubmissionMethod': {
    label: 'Specialty Pharmacy Prior Auth Submission Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE':
          return 'Phone';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX':
          return 'Fax';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL':
          return 'Mail';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE':
          return 'Website';
        case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL':
          return 'Email';
        default:
          return '';
      }
    },
  },
  'accessInfo.specialtyPharmacyPriorAuthSubmissionMethods': {
    label: 'Specialty Pharmacy Prior Auth Submission Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE':
              return 'Phone';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX':
              return 'Fax';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL':
              return 'Mail';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE':
              return 'Website';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL':
              return 'Email';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.preferredDrug': {
    label: 'Preferred Drug?',
    getValue: getBoolVal,
  },
  'accessInfo.priorAuthInitiationDate': {
    label: 'Prior Auth Initiation Date',
    getValue: getStringVal,
  },
  'accessInfo.quantityLimit': {
    label: 'Prescription Quantity Limit',
    getValue: getStringVal,
  },
  'accessInfo.productTier': {
    label: 'Product Tier',
    getValue: getIntVal,
  },
  'accessInfo.therapyAvailabilityDate': {
    label: 'Therapy Availability Date',
    getValue: getStringVal,
  },
  'accessInfo.totalTiers': {
    label: 'Total Tiers',
    getValue: getIntVal,
  },
  'accessInfo.tierExceptionProcess': {
    label: 'Tier Exception Process Exists?',
    getValue: getBoolVal,
  },
  'accessInfo.obtainTierExceptionPhone': {
    label: 'Obtain Tier Exception Phone',
    getValue: getStringVal,
  },
  'accessInfo.appealsTurnaroundTime': {
    label: 'Resubmission Turnaround Time',
    getValue: getStringVal,
  },
  'accessInfo.appealsNotificationMethod': {
    label: 'Resubmission Notification Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_APPEALS_NOTIFICATION_METHOD_PHONE':
          return 'Phone';
        case 'INF_APPEALS_NOTIFICATION_METHOD_FAX':
          return 'Fax';
        case 'INF_APPEALS_NOTIFICATION_METHOD_WEBSITE':
          return 'Website';
        case 'INF_APPEALS_NOTIFICATION_METHOD_EMAIL':
          return 'Email';
        default:
          return '';
      }
    },
  },
  'accessInfo.priorAuthNotificationMethod': {
    label: 'Prior Auth Notification Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE':
          return 'Phone';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX':
          return 'Fax';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE':
          return 'Website';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL':
          return 'Email';
        case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL':
          return 'Mail';
        default:
          return '';
      }
    },
  },
  'accessInfo.priorAuthNotificationMethods': {
    label: 'Prior Auth Notification Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE':
              return 'Phone';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX':
              return 'Fax';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL':
              return 'Mail';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE':
              return 'Website';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL':
              return 'Email';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'accessInfo.claimsAddress': {
    label: 'Claims Address',
    getValue: getAddressVal,
  },
  'accessInfo.referralRequired': {
    label: 'Referral Required? (Deprecated)',
    getValue: getBoolVal,
  },
  'accessInfo.referralRequirements': {
    label: 'Referral Requirements',
    getValue: getReferralRequirementsVal,
  },
  'accessInfo.referralNumVisitsApproved': {
    label: 'Referral Visits Approved',
    getValue: getIntVal,
  },
  'accessInfo.referralNumVisitsUsed': {
    label: 'Referral Visits Used',
    getValue: getIntVal,
  },
  'accessInfo.referralOnFile': {
    label: 'Referral On File?',
    getValue: getBoolVal,
  },
  'accessInfo.referralNumber': {
    label: 'Referral Number',
    getValue: getStringVal,
  },
  'accessInfo.referralEffectiveDate': {
    label: 'Referral Effective Date',
    getValue: getStringVal,
  },
  'accessInfo.referralWebsite': {
    label: 'Referral Website',
    getValue: getStringVal,
  },
  'accessInfo.referralRecertDate': {
    label: 'Referral Recert Date',
    getValue: getStringVal,
  },
  'accessInfo.referralFax': {
    label: 'Referral Fax',
    getValue: getStringVal,
  },
  'accessInfo.referralAddress': {
    label: 'Referral Address',
    getValue: getAddressVal,
  },

  'accessInfo.referralSubmissionProcess': {
    label: 'Referral Submission Process',
    getValue: getStringVal,
  },
  'accessInfo.coverageRestrictions': {
    label: 'Coverage Restrictions',
    getValue: getStringVal,
  },
  'accessInfo.pcpName': {
    label: 'PCP Name',
    getValue: getStringVal,
  },
  'accessInfo.pcpPhone': {
    label: 'PCP Phone',
    getValue: getStringVal,
  },
  'accessInfo.buyAndBillAvailability': {
    label: 'Buy and Bill Availability',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_BUY_AND_BILL_AVAILABILITY_REQUIRED':
          return 'Required';
        case 'INF_BUY_AND_BILL_AVAILABILITY_AVAILABLE':
          return 'Available';
        case 'INF_BUY_AND_BILL_AVAILABILITY_NOT_AVAILABLE':
          return 'Not Available';
        default:
          return '';
      }
    },
  },
  'accessInfo.additionalNotes': {
    label: 'Additional Notes',
    getValue: getStringVal,
  },
  'accessInfo.homeboundRequired': {
    label: 'Homebound Required?',
    getValue: getBoolVal,
  },
  'accessInfo.homeHealthCovered': {
    label: 'Home Health Covered?',
    getValue: getBoolVal,
  },
  'accessInfo.homeHealthProviderName': {
    label: 'Home Health Provider Name',
    getValue: getStringVal,
  },
  'accessInfo.homeHealthProviderPhone': {
    label: 'Home Health Provider Phone',
    getValue: getStringVal,
  },
  'accessInfo.homeHealthServices': {
    label: 'Home Health Services',
    getValue: getStringVal,
  },
  'accessInfo.homeHealthPriorAuthRequired': {
    label: 'Home Health Prior Auth Required?',
    getValue: getBoolVal,
  },
  'accessInfo.homeHealthObtainPriorAuthPhone': {
    label: 'Home Health Prior Auth Phone',
    getValue: getStringVal,
  },
  'accessInfo.homeHealthObtainPriorAuthFax': {
    label: 'Home Health Prior Auth Fax',
    getValue: getStringVal,
  },
  'accessInfo.coverageExceptionProcessExists': {
    label: 'Coverage Exception Process Exists',
    getValue: getBoolVal,
  },
  'accessInfo.obtainCoverageExceptionProcess': {
    label: 'Obtain Coverage Exception Process',
    getValue: getStringVal,
  },
  'accessInfo.obtainCoverageExceptionPhone': {
    label: 'Obtain Coverage Exception Phone',
    getValue: getStringVal,
  },
  'accessInfo.obtainCoverageExceptionFax': {
    label: 'Obtain Coverage Exception Fax',
    getValue: getStringVal,
  },
  'accessInfo.obtainQuantityLimitExceptionProcess': {
    label: 'Obtain Quantity Limit Exception Process',
    getValue: getStringVal,
  },
  'accessInfo.obtainQuantityLimitExceptionPhone': {
    label: 'Obtain Quantity Limit Exception Phone',
    getValue: getStringVal,
  },
  'accessInfo.obtainCoverageExceptionWebsite': {
    label: 'Obtain Coverage Exception Website',
    getValue: getStringVal,
  },
  'accessInfo.obtainCoverageExceptionAddress': {
    label: 'Obtain Coverage Exception Address',
    getValue: getAddressVal,
  },
  'accessInfo.coverageExceptionTurnaroundTime': {
    label: 'Coverage Exception Turnaround Time',
    getValue: getStringVal,
  },
  'accessInfo.transportationServiceCovered': {
    label: 'Transportation Service Covered',
    getValue: getBoolVal,
  },
  'accessInfo.lodgingCovered': {
    label: 'Lodging Covered',
    getValue: getBoolVal,
  },
  'accessInfo.transportationServicePhoneNumber': {
    label: 'Transportation services phone number',
    getValue: getStringVal,
  },
  'accessInfo.lodgingServicePhoneNumber': {
    label: 'Lodging services phone number',
    getValue: getStringVal,
  },
  'accessInfo.preferredDrugName': {
    label: 'Preferred Drug Name',
    getValue: getStringVal,
  },
  'accessInfo.priorAuthApprovalNeededToGetBenefits': {
    label: 'Prior Auth Approval Needed to Get Benefits',
    getValue: getBoolVal,
  },
  'accessInfo.attestationProcessExists': {
    label: 'Attestation Process Exists',
    getValue: getBoolVal,
  },
  'accessInfo.attestationProcess': {
    label: 'Attestation Process',
    getValue: getStringVal,
  },
  'denialInfo.denialReason': {
    label: 'Denial Reason',
    getValue: getStringVal,
  },
  'denialInfo.denialDate': {
    label: 'Denial Date',
    getValue: getStringVal,
  },
  'denialInfo.denialNumber': {
    label: 'Denial Number',
    getValue: getStringVal,
  },
  'denialInfo.peerToPeerAvailable': {
    label: 'Peer To Peer Available?',
    getValue: getBoolVal,
  },
  'denialInfo.peerToPeerPhone': {
    label: 'Peer to Peer Contact Phone',
    getValue: getStringVal,
  },
  'denialInfo.peerToPeerSubmissionDeadline': {
    label: 'Peer to Peer Submission Deadline',
    getValue: getStringVal,
  },
  'denialInfo.priorAuthAppealsContactFax': {
    label: 'Prior Auth Appeals Contact Fax',
    getValue: getStringVal,
  },
  'denialInfo.appealAvailable': {
    label: 'Appeals available?',
    getValue: getBoolVal,
  },
  'denialInfo.numberOfAppealsAvailable': {
    label: 'Number of Appeals Available',
    getValue: getIntVal,
  },
  'denialInfo.appealsContactWebsite': {
    label: 'Appeals Contact Website',
    getValue: getStringVal,
  },
  'denialInfo.appealsContactOrg': {
    label: 'Appeals Contact Org',
    getValue: getStringVal,
  },
  'denialInfo.appealsContactAddress': {
    label: 'Appeals Contact Address',
    getValue: getAddressVal,
  },
  'denialInfo.appealsContactPhone': {
    label: 'Appeals Contact Phone',
    getValue: getStringVal,
  },
  'denialInfo.appealsContactFax': {
    label: 'Appeals Contact Fax',
    getValue: getStringVal,
  },
  'denialInfo.appealsTurnaroundTime': {
    label: 'Appeals Turnaround Time',
    getValue: getStringVal,
  },
  'denialInfo.appealsSubmissionDeadline': {
    label: 'Appeals Submission Deadline',
    getValue: getStringVal,
  },
  'denialInfo.appealsRequiredDocuments': {
    label: 'Appeals Required Documents',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_APPEALS_REQUIRED_DOCUMENTS_DENIAL_LETTER':
          return 'Denial Letter';
        case 'INF_APPEALS_REQUIRED_DOCUMENTS_LETTER_OF_MEDICAL_NECESSITY':
          return 'Letter of Medical Necessity';
        case 'INF_APPEALS_REQUIRED_DOCUMENTS_NONE':
          return 'None';
        default:
          return '';
      }
    },
  },
  'denialInfo.additionalNotes': {
    label: 'Additional Notes',
    getValue: getStringVal,
  },
  'products.code': {
    label: 'Product Code',
    getValue: getStringVal,
  },
  'products.copayAfterSavings': {
    label: 'Copay After Savings',
    getValue: getStringVal,
  },
  'products.isMedicationOnFile': {
    label: 'Is Medication On File?',
    getValue: getBoolVal,
  },
  'products.isPrescriptionActive': {
    label: 'Is Prescription Active?',
    getValue: getBoolVal,
  },
  'products.lastRefillCost': {
    label: 'Last Refill Cost',
    getValue: getStringVal,
  },
  'products.lastRefillDate': {
    label: 'Last Refill Date',
    getValue: getStringVal,
  },
  'products.name': {
    label: 'Product Name',
    getValue: getStringVal,
  },
  'products.outcome': {
    label: 'Outcome',
    getValue: getStringVal,
  },
  'products.reasonForNotApplyingSavings': {
    label: 'Reason For Not Applying Savings',
    getValue: getStringVal,
  },
  'products.rebilled': {
    label: 'Rebilled?',
    getValue: getBoolVal,
  },
  'products.refillDenialReason': {
    label: 'Refill Denial Reason',
    getValue: getStringVal,
  },
  'products.refillRequested': {
    label: 'Refill Requested?',
    getValue: getBoolVal,
  },
  'products.savingsCardAdded': {
    label: 'Savings Card Added?',
    getValue: getBoolVal,
  },
  'products.savingsCardApplied': {
    label: 'Savings Card Applied?',
    getValue: getBoolVal,
  },
  'productCodePriorAuthRequirements.priorAuthRequired': {
    label: 'Prior Auth Required?',
    getValue: getBoolVal,
  },
  // Prior Auth Appeal Info
  'priorAuthAppealInfo.priorAuthAppealAvailable': {
    label: 'Prior Auth Appeal Available?',
    getValue: getBoolVal,
  },
  'priorAuthAppealInfo.priorAuthAppealsContactOrg': {
    label: 'Prior Auth Appeal Contact Organization',
    getValue: getStringVal,
  },
  'priorAuthAppealInfo.priorAuthAppealsContactPhone': {
    label: 'Prior Auth Appeal Contact Phone',
    getValue: getStringVal,
  },
  'priorAuthAppealInfo.priorAuthAppealsNotificationMethods': {
    label: 'Prior Auth Appeal Notification Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((value) => {
          switch (value) {
            case 'INF_APPEALS_NOTIFICATION_METHOD_UNKNOWN':
              return 'Unknown';
            case 'INF_APPEALS_NOTIFICATION_METHOD_PHONE':
              return 'Phone';
            case 'INF_APPEALS_NOTIFICATION_METHOD_FAX':
              return 'Fax';
            case 'INF_APPEALS_NOTIFICATION_METHOD_EMAIL':
              return 'Email';
            case 'INF_APPEALS_NOTIFICATION_METHOD_WEBSITE':
              return 'Website';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'priorAuthAppealInfo.priorAuthAppealsRequiredDocuments': {
    label: 'Prior Auth Appeal Required Documents',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_APPEALS_REQUIRED_DOCUMENTS_UNKNOWN':
              return 'Unknown';
            case 'INF_APPEALS_REQUIRED_DOCUMENTS_NOT_APPLICABLE':
              return 'Not Applicable';
            case 'INF_APPEALS_REQUIRED_DOCUMENTS_DENIAL_LETTER':
              return 'Denial Letter';
            case 'INF_APPEALS_REQUIRED_DOCUMENTS_LETTER_OF_MEDICAL_NECESSITY':
              return 'Letter of Medical Necessity';
            case 'INF_APPEALS_REQUIRED_DOCUMENTS_NONE':
              return 'None';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'priorAuthAppealInfo.priorAuthAppealsSubmissionDeadline': {
    label: 'Prior Auth Appeal Submission Deadline',
    getValue: getStringVal,
  },
  'priorAuthAppealInfo.priorAuthAppealsTurnaroundTime': {
    label: 'Prior Auth Appeal Turnaround Time',
    getValue: getStringVal,
  },
  // Prior Auth Info Details
  'priorAuthInfo.priorAuthInfoDetails.priorAuthApprovalDate': {
    label: 'Prior Auth Approval Date',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthApprovalLevel': {
    label: 'Prior Auth Approval Level',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_APPROVAL_LEVEL_NDC':
          return 'NDC';
        case 'INF_PRIOR_AUTH_APPROVAL_LEVEL_DRUG':
          return 'Drug';
        case 'INF_PRIOR_AUTH_APPROVAL_LEVEL_NOT_APPLICABLE':
          return 'Not Applicable';
        case 'INF_PRIOR_AUTH_APPROVAL_LEVEL_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthApprovalNumber': {
    label: 'Prior Auth Approval Number',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthCodesOnFile': {
    label: 'Prior Auth Codes On File',
    getValue: (values: string[]) => (values || []).map((val) => val).join(', '),
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthCompletionDate': {
    label: 'Prior Auth Completion Date',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthDenialReason': {
    label: 'Prior Auth Denial Reason',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_DENIAL_REASON_OTHER':
          return 'Other';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_MISSING_CLINICAL_DOCUMENTATION':
          return 'Missing Clinical Documentation';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_CRITERIA_NOT_MET':
          return 'Criteria Not Met';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_LACK_OF_MEDICAL_NECESSITY':
          return 'Lack of Medical Necessity';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_MISSING_LETTER_OF_MEDICAL_NECESSITY':
          return 'Missing Letter of Medical Necessity';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_NON_FORMULARY_DRUG':
          return 'Non-Formulary Drug';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_STEP_THERAPY_REQUIRED':
          return 'Step Therapy Required';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_NON_PREFERRED_TREATMENT':
          return 'Non-Preferred Treatment';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_DATE_OF_SERVICE_PASSED':
          return 'Date of Service Passed';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_PROVIDER_NOT_IN_NETWORK':
          return 'Provider Not In Network';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_NOT_COVERED_BY_PATIENT_PLAN':
          return 'Not Covered By Patient Plan';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_PATIENT_PLAN_PROCEDURES_NOT_FOLLOWED':
          return 'Patient Plan Procedures Not Followed';
        case 'INF_PRIOR_AUTH_DENIAL_REASON_NOT_APPLICABLE':
          return 'Not Applicable';
        default:
          return '';
      }
    },
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthDenialReasonOther': {
    label: 'Prior Auth Denial Reason Other',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthDoseOnFile': {
    label: 'Prior Auth Dose On File',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthEndDate': {
    label: 'Prior Auth End Date',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthEntityOnFile': {
    label: 'Prior Auth Entity On File',
    getValue: getBoolVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthEntityOnFile.name': {
    label: 'Prior Auth Entity On File Name',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthEntityOnFile.npi': {
    label: 'Prior Auth Entity On File NPI',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthEntityOnFile.type': {
    label: 'Prior Auth Entity On File Type',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PROVIDER':
          return 'Provider';
        case 'INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PLACE_OF_SERVICE':
          return 'Place of Service';
        case 'INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthStartDate': {
    label: 'Prior Auth Start Date',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthStatus': {
    label: 'Prior Auth Status',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_STATUS_NOT_ON_FILE':
          return 'Not on file';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED':
          return 'Approved';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR':
          return 'Approved for Different Doctor';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS':
          return 'Approved for Different Diagnosis';
        case 'INF_PRIOR_AUTH_STATUS_DENIED':
          return 'Denied';
        case 'INF_PRIOR_AUTH_STATUS_EXPIRED':
          return 'Expired';
        case 'INF_PRIOR_AUTH_STATUS_FUTURE':
          return 'Future';
        case 'INF_PRIOR_AUTH_STATUS_PENDING':
          return 'Pending';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS':
          return 'Approved for Different POS';
        case 'INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS':
          return 'Approved for Different Address';
        case 'INF_PRIOR_AUTH_STATUS_UNKNOWN':
          return 'Unknown';
        default:
          return '';
      }
    },
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthSubmissionMethods': {
    label: 'Prior Auth Submission Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE':
              return 'Phone';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX':
              return 'Fax';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL':
              return 'Mail';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE':
              return 'Website';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL':
              return 'Email';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN':
              return 'Unknown';
            case 'INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE':
              return 'Not Applicable';
            default:
              return '';
          }
        })
        .join(', '),
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthTurnaroundNotes': {
    label: 'Prior Auth Turnaround Notes',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.productSourcingMethod': {
    label: 'Product Sourcing Method',
    getValue: (value: string) => {
      switch (value) {
        case 'INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_BUY_AND_BILL':
          return 'Buy and Bill';
        case 'INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_SPECIALTY_PHARMACY':
          return 'Specialty Pharmacy';
        case 'INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_PHARMACY_BENEFIT_MANAGER':
          return 'Pharmacy Benefit Manager';
        case 'INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_NOT_APPLICABLE':
          return 'Not Applicable';
        default:
          return '';
      }
    },
  },
  'priorAuthInfo.priorAuthInfoDetails.quantityLimit': {
    label: 'Quantity Limit',
    getValue: getStringVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.quantityLimitExists': {
    label: 'Quantity Limit Exists',
    getValue: getBoolVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthOnFileApplies': {
    label: 'Prior Auth On File Applies',
    getValue: getBoolVal,
  },
  'priorAuthInfo.priorAuthInfoDetails.priorAuthNotificationMethods': {
    label: 'Prior Auth Notification Methods',
    getValue: (values: string[]) =>
      (values || [])
        .map((val) => {
          switch (val) {
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE':
              return 'Phone';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX':
              return 'Fax';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL':
              return 'Mail';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE':
              return 'Website';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL':
              return 'Email';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN':
              return 'Unknown';
            case 'INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE':
              return 'Not Applicable';
            default:
              return '';
          }
        })
        .join(', '),
  },
};
export type BvTaskInputsMapKeys = keyof typeof bvTaskInputsMap;
export const bvTaskInputsMap = {
  'Patient Information': {
    'policyInfo.memberId': {
      label: 'Policy Member ID',
    },
    'policyInfo.groupNumber': {
      label: 'Group ID',
    },
    'policyInfo.groupName': {
      label: 'Group Name',
    },
    'policyInfo.planName': {
      label: 'Plan Name',
    },
    'patientInfo.firstName': {
      label: 'Patient first name',
    },
    'patientInfo.lastName': {
      label: 'Patient last name',
    },
    'patientInfo.birthday': {
      label: 'Patient date of birth',
    },
    'patientInfo.addressInfo.street': {
      label: 'Patient Street Address',
    },
    'patientInfo.addressInfo.city': {
      label: 'Patient City',
    },
    'patientInfo.addressInfo.state': {
      label: 'Patient State',
    },
    'patientInfo.addressInfo.zip': {
      label: 'Patient ZIP Code',
    },
  },
  'Policy Holder Information': {
    'policyHolderInfo.firstName': {
      label: 'Policy Holder First Name',
    },
    'policyHolderInfo.lastName': {
      label: 'Policy Holder Last Name',
    },
    'policyHolderInfo.dateOfBirth': {
      label: 'Policy Holder Date of Birth',
    },
  },
  'Payer Information': {
    'payerInfo.providedPhoneNumber': {
      label: 'Payer Phone Number',
    },
    'payerInfo.name': {
      label: 'Payer Name',
    },
  },
  'Provider Information': {
    'providerInfo.firstName': {
      label: 'Provider First Name',
    },
    'providerInfo.lastName': {
      label: 'Provider Last Name',
    },
    'providerInfo.taxId': {
      label: 'Provider Tax ID',
    },
    'providerInfo.npi': {
      label: 'Provider NPI',
    },
    'providerInfo.addressInfo.street': {
      label: 'Provider Street Address',
    },
    'providerInfo.addressInfo.city': {
      label: 'Provider City',
    },
    'providerInfo.addressInfo.state': {
      label: 'Provider State',
    },
    'providerInfo.addressInfo.zip': {
      label: 'Provider ZIP Code',
    },
    'providerInfo.ptan': {
      label: 'Provider PTAN',
    },
  },
  'Practice Information': {
    'practiceInfo.name': {
      label: 'Practice Name',
    },
    'practiceInfo.taxId': {
      label: 'Practice Tax ID',
    },
    'practiceInfo.npi': {
      label: 'Practice NPI',
    },
    'practiceInfo.addressInfo.street': {
      label: 'Practice Street Address',
    },
    'practiceInfo.addressInfo.city': {
      label: 'Practice City',
    },
    'practiceInfo.addressInfo.state': {
      label: 'Practice State',
    },
    'practiceInfo.addressInfo.zip': {
      label: 'Practice ZIP Code',
    },
  },
  'Product Information': {
    productName: {
      label: 'Product Name',
    },
    productCode: {
      label: 'Product Code',
    },
    productDaysSupply: {
      label: 'Product Days Supply',
    },
    productOffLabel: {
      label: 'Product Off Label',
    },
    productNumberOfUnits: {
      label: 'Product Number of Units',
    },
  },
  'CPT Codes Information': {
    adminCodes: {
      label: 'CPT Codes',
    },
  },
  'Treatment Information': {
    'treatmentInfo.treatmentCode': { label: 'Treatment Code' },
    'treatmentInfo.facilityType.type': { label: 'Facility Type' },
    'treatmentInfo.facilityTypeAdditionalNotes': { label: 'Facility Type Additional Notes' },
    'treatmentInfo.anticipatedDateOfTreatment': { label: 'Anticipated Date of Treatment' },
    'treatmentInfo.secondaryTreatmentCode': { label: 'Secondary Treatment Code' },
    'treatmentInfo.skipSpecialtyPharmacyInfo': {
      // We are reversing the label and value to keep consistent with what Customer sends us in INFBVTaskInputPlaceOfService.specialtyPharmacyInfoRequired in customer API
      label: 'SPECIALTY PHARMACY INFO REQUIRED',
      getValue: getBoolVal,
    },
    'treatmentInfo.tertiaryTreatmentCode': { label: 'Tertiary Treatment Code' },
  },

  'Sites of Care Information': {},
};

export const pbmTaskOutputsMap = (enableBillingNotes?: boolean): TaskOutputsMap[] => {
  let outputs = [
    {
      label: 'Task Notes',
      fields: ['additionalNotes'],
    },
    {
      label: 'Task Info',
      fields: [
        'pbmName',
        'payerPhone',
        'payerAgentName',
        'referenceNumber',
        'reasonForNonCoverage',
        'payerName',
        'infinitusPayerId',
      ],
    },
    {
      label: 'Plan Info',
      fields: [
        'planInfo.planName',
        'planInfo.planType',
        'planInfo.planGroupNumber',
        'planInfo.planGroupName',
        'planInfo.planFax',
        'planInfo.fundingType',
        'planInfo.RxGroupNumber',
        'planInfo.bankIdentificationNumber',
        'planInfo.processorControlNumber',
        'planInfo.renewalType',
        'planInfo.planRenewalMonth',
        'planInfo.policyType',
        'planInfo.policyNumber',
        'planInfo.otherInsuranceExists',
        'planInfo.currentPlanPriority',
        'planInfo.planEffectiveDate',
        'planInfo.planTerminationDate',
        'planInfo.newPlanAvailable',
        'planInfo.newPlanSubscriberId',
        'planInfo.newPlanEffectiveDate',
        'planInfo.willCoverIfPrimaryDenies',
        'planInfo.coordinationOfBenefits',
        'planInfo.policyHolderRelationship',
        'planInfo.policyHolderDateOfBirth',
        'planInfo.policyHolderFirstName',
        'planInfo.policyHolderLastName',
        'planInfo.isCobraPlan',
        'planInfo.cobraHasGracePeriod',
        'planInfo.cobraGracePeriod',
        'planInfo.cobraPaidThroughDate',
        'planInfo.isHealthExchangePlan',
        'planInfo.healthExchangeGracePeriod',
        'planInfo.healthExchangeHasGracePeriod',
        'planInfo.healthExchangePaidThroughDate',
      ],
    },
    {
      label: 'Deductibles and OOPs',
      fields: [
        'deductiblesAndOops.individualDeductibleExists',
        'deductiblesAndOops.individualTotalDeductible',
        'deductiblesAndOops.individualDeductibleMetAmount',
        'deductiblesAndOops.familyDeductibleExists',
        'deductiblesAndOops.familyTotalDeductible',
        'deductiblesAndOops.familyDeductibleMetAmount',
        'deductiblesAndOops.individualOopMaximumExists',
        'deductiblesAndOops.individualOopMaximum',
        'deductiblesAndOops.individualOopMetAmount',
        'deductiblesAndOops.familyOopMaximumExists',
        'deductiblesAndOops.familyOopMaximum',
        'deductiblesAndOops.familyOopMetAmount',
        'deductiblesAndOops.deductibleIncludedInOop',
        'deductiblesAndOops.copayAppliesToOop',
        'deductiblesAndOops.copayWaivedAfterOop',
        'deductiblesAndOops.lifetimeMaximumExists',
        'deductiblesAndOops.lifetimeMaximum',
        'deductiblesAndOops.lifetimeMaximumMetAmount',
        'deductiblesAndOops.annualBenefitCapExists',
        'deductiblesAndOops.annualBenefitCap',
        'deductiblesAndOops.annualBenefitCapMetAmount',
        'deductiblesAndOops.initialCoverageLimitTotal',
        'deductiblesAndOops.initialCoverageLimitMetAmount',
        'deductiblesAndOops.isOnLowIncomeSubsidy',
        'deductiblesAndOops.lowIncomeSubsidyLevel',
        'deductiblesAndOops.isAccumulatorPlan',
        'deductiblesAndOops.isMaximizerPlan',
        'deductiblesAndOops.canUseCoPayAssistance',
        'deductiblesAndOops.fullAmtCoPayAssistanceApplies',
        'deductiblesAndOops.maximizerProgramName',
        'deductiblesAndOops.maximizerProgramPhone',
        'deductiblesAndOops.maximizerOptInDate',
      ],
    },
    {
      label: 'Medicare Part D Coverage',
      parentField: 'deductiblesAndOops.medicarePartD',
      fields: [
        'deductiblesAndOops.medicarePartD.partDCurrentStage',
        'deductiblesAndOops.medicarePartD.coverageGapCoinsurance',
        'deductiblesAndOops.medicarePartD.coverageGapCopay',
        'deductiblesAndOops.medicarePartD.catastrophicCoinsurance',
        'deductiblesAndOops.medicarePartD.catastrophicCopay',
        'deductiblesAndOops.medicarePartD.troopMetAmount',
      ],
    },
    {
      label: 'Product Coverage',
      parentField: 'productCoverage',
      idField: 'code',
      fields: [
        'isProductCovered',
        'specialtyPharmacyCoinsurance',
        'specialtyPharmacyCopay',
        'isProductCoveredRetail',
        'retailPharmacyCoinsurance',
        'retailPharmacyCopay',
        'isProductCoveredMailOrder',
        'mailOrderPharmacyCopay',
        'mailOrderPharmacyCoinsurance',
        'costShareTransparencyNotes',
      ],
    },
    {
      label: 'Access Info',
      fields: [
        'accessInfo.preferredDrug',
        'accessInfo.quantityLimit',
        'accessInfo.productTier',
        'accessInfo.therapySeasonStartDate',
        'accessInfo.totalTiers',
        'accessInfo.tierExceptionProcess',
        'accessInfo.obtainTierExceptionPhone',
        'accessInfo.therapyAvailabilityDate',
        'accessInfo.appealsTurnaroundTime',
        'accessInfo.appealsNotificationMethod',
        'accessInfo.priorAuthRequired',
        'accessInfo.priorAuthApprovalNeededToGetBenefits',
        'accessInfo.priorAuthOnFile',
        'accessInfo.priorAuthNotificationMethod',
        'accessInfo.priorAuthNotificationMethods',
        'accessInfo.priorAuthType',
        'accessInfo.priorAuthStatus',
        'accessInfo.priorAuthTurnaroundTime',
        'accessInfo.priorAuthSubmissionDate',
        'accessInfo.priorAuthDenialReason',
        'accessInfo.priorAuthAppealsContactFax',
        'accessInfo.priorAuthOnFileForDose',
        'accessInfo.priorAuthApprovalDate',
        'accessInfo.priorAuthStartDate',
        'accessInfo.priorAuthEndDate',
        'accessInfo.priorAuthInitiationDate',
        'accessInfo.priorAuthApprovalNumber',
        'accessInfo.obtainPriorAuthOrg',
        'accessInfo.obtainPriorAuthPhone',
        'accessInfo.obtainPriorAuthWebsite',
        'accessInfo.obtainPriorAuthFax',
        'accessInfo.obtainPriorAuthRequirements',
        'accessInfo.separatePriorAuthRequired',
        'accessInfo.specialtyCoverageCarveout',
        'accessInfo.specialtyCoverageCarveoutEntity',
        'accessInfo.specialtyPharmacyName',
        'accessInfo.specialtyPharmacyAddress',
        'accessInfo.specialtyPharmacyPhone',
        'accessInfo.specialtyPharmacyFax',
        'accessInfo.specialtyPharmacy2Name',
        'accessInfo.specialtyPharmacy2Address',
        'accessInfo.specialtyPharmacy2Phone',
        'accessInfo.specialtyPharmacy2Fax',
        'accessInfo.specialtyPharmacy3Name',
        'accessInfo.specialtyPharmacy3Address',
        'accessInfo.specialtyPharmacy3Phone',
        'accessInfo.specialtyPharmacy3Fax',
        'accessInfo.pbmSpecialtyPharmacyRequirements',
        'accessInfo.stepTherapyRequired',
        'accessInfo.stepTherapyTreatment',
        'accessInfo.mailOrderPharmacyName',
        'accessInfo.mailOrderPharmacyPhone',
        'accessInfo.formularyDrug',
        'accessInfo.formularyExceptionRequired',
        'accessInfo.formularyExceptionStatus',
        'accessInfo.obtainFormularyExceptionDocument',
        'accessInfo.obtainFormularyExceptionOrg',
        'accessInfo.obtainFormularyExceptionPhone',
        'accessInfo.obtainFormularyExceptionWebsite',
        'accessInfo.obtainFormularyExceptionFax',
        'accessInfo.obtainFormularyExceptionTurnaroundTime',
        'accessInfo.obtainFormularyExceptionNotificationMethod',
        'accessInfo.predeterminationSubmissionDate',
        'accessInfo.preferredDrugName',
        'accessInfo.priorAuthSubmissionMethods',
      ],
    },
    {
      label: 'Denial Info',
      fields: [
        'denialInfo.denialReason',
        'denialInfo.denialDate',
        'denialInfo.denialNumber',
        'denialInfo.peerToPeerAvailable',
        'denialInfo.peerToPeerPhone',
        'denialInfo.peerToPeerSubmissionDeadline',
        'denialInfo.priorAuthAppealsContactFax',
        'denialInfo.appealAvailable',
        'denialInfo.numberOfAppealsAvailable',
        'denialInfo.appealsContactOrg',
        'denialInfo.appealsContactAddress',
        'denialInfo.appealsContactPhone',
        'denialInfo.appealsContactWebsite',
        'denialInfo.appealsContactFax',
        'denialInfo.appealsTurnaroundTime',
        'denialInfo.appealsSubmissionDeadline',
        'denialInfo.appealsRequiredDocuments',
        'denialInfo.additionalNotes',
      ],
    },
  ];
  if (enableBillingNotes) {
    outputs = [
      {
        label: 'Billing Notes',
        fields: ['billingNotes'],
      },
      ...outputs,
    ];
  }
  return outputs;
};

export const bvTaskOutputsMap = (enableBillingNotes?: boolean): TaskOutputsMap[] => {
  let outputs = [
    {
      label: 'Task Notes',
      fields: ['additionalNotes'],
    },
    {
      label: 'Task Info',
      fields: [
        'payerPhone',
        'infinitusPayerId',
        'referenceNumber',
        'payerAgentName',
        'reasonForNonCoverage',
        'payerName',
      ],
    },
    {
      label: 'Plan Info',
      fields: [
        'planInfo.providerNetworkStatusOnly',
        'planInfo.providerNetworkStatus',
        'planInfo.providerInNetworkAddress.streetAddress',
        'planInfo.providerInNetworkAddress.streetAddressLine2',
        'planInfo.providerInNetworkAddress.city',
        'planInfo.providerInNetworkAddress.state',
        'planInfo.providerInNetworkAddress.zip',
        'planInfo.inNetworkConsideration',
        'planInfo.practiceNetworkStatus',
        'planInfo.planType',
        'planInfo.planName',
        'planInfo.planCode',
        'planInfo.planGroupNumber',
        'planInfo.planGroupName',
        'planInfo.policyType',
        'planInfo.hasStandardPlanLetter',
        'planInfo.standardPlanLetter',
        'planInfo.followsMedicareGuidelines',
        'planInfo.otherInsuranceExists',
        'planInfo.otherInsuranceName',
        'planInfo.currentPlanPriority',
        'planInfo.coordinationOfBenefits',
        'planInfo.willCoverIfPrimaryDenies',
        'planInfo.willCoverPartBDeductible',
        'planInfo.planEffectiveDate',
        'planInfo.planRenewalMonth',
        'planInfo.renewalType',
        'planInfo.fundingType',
        'planInfo.planTerminationDate',
        'planInfo.newPlanAvailable',
        'planInfo.newPlanSubscriberId',
        'planInfo.newPlanEffectiveDate',
        'planInfo.isCobraPlan',
        'planInfo.cobraPaidThroughDate',
        'planInfo.cobraGracePeriod',
        'planInfo.isHealthExchangePlan',
        'planInfo.patientReceivesSubsidies',
        'planInfo.healthExchangePaidThroughDate',
        'planInfo.healthExchangeGracePeriod',
        'planInfo.additionalNotes',
        'planInfo.pbmExists',
        'planInfo.pbmName',
        'planInfo.pbmPhone',
        'planInfo.pbmPolicyID',
        'planInfo.policyHolderRelationship',
        'planInfo.policyHolderDateOfBirth',
        'planInfo.policyHolderFirstName',
        'planInfo.policyHolderLastName',
        'planInfo.sitesOfCare',
        'planInfo.claimsResponsibleOrgName',
        'planInfo.benefitsTier',
      ],
    },
    {
      label: 'Deductibles and OOPs',
      fields: [
        'deductiblesAndOops.individualDeductibleExists',
        'deductiblesAndOops.individualTotalDeductible',
        'deductiblesAndOops.individualDeductibleMetAmount',
        'deductiblesAndOops.spendDownExists',
        'deductiblesAndOops.spendDownTotal',
        'deductiblesAndOops.spendDownMet',
        'deductiblesAndOops.familyDeductibleExists',
        'deductiblesAndOops.familyTotalDeductible',
        'deductiblesAndOops.familyDeductibleMetAmount',
        'deductiblesAndOops.individualOopMaximumExists',
        'deductiblesAndOops.individualOopMaximum',
        'deductiblesAndOops.individualOopMetAmount',
        'deductiblesAndOops.familyOopMaximumExists',
        'deductiblesAndOops.familyOopMaximum',
        'deductiblesAndOops.familyOopMetAmount',
        'deductiblesAndOops.deductibleIncludedInOop',
        'deductiblesAndOops.copayAppliesToOop',
        'deductiblesAndOops.copayWaivedAfterOop',
        'deductiblesAndOops.multipleCopayResponsibility',
        'deductiblesAndOops.lifetimeMaximumExists',
        'deductiblesAndOops.lifetimeMaximum',
        'deductiblesAndOops.lifetimeMaximumMetAmount',
        'deductiblesAndOops.annualBenefitCapExists',
        'deductiblesAndOops.annualBenefitCap',
        'deductiblesAndOops.annualBenefitCapMetAmount',
        'deductiblesAndOops.additionalNotes',
        'deductiblesAndOops.isAccumulatorPlan',
        'deductiblesAndOops.isMaximizerPlan',
        'deductiblesAndOops.coinsuranceWaivedAfterOop',
        'deductiblesAndOops.homeHealthDeductibleExists',
        'deductiblesAndOops.homeHealthTotalDeductible',
        'deductiblesAndOops.homeHealthDeductibleMetAmount',
        'deductiblesAndOops.homeHealthOopMaximumExists',
        'deductiblesAndOops.homeHealthOopMaximum',
        'deductiblesAndOops.homeHealthOopMaximumMetAmount',
        'deductiblesAndOops.deductibleMetBeforeCOB',
        'deductiblesAndOops.oopMetBeforeCOB',
      ],
    },
    {
      label: 'Product Code',
      parentField: 'productCodeBenefits',
      idField: 'code',
      fields: [
        'isCoverageSameForBNBAndSP',
        'valid',
        'copay',
        'coinsurance',
        'deductibleApplies',
        'specialtyPharmacyCopay',
        'specialtyPharmacyCoinsurance',
        'specialtyPharmacyDeductibleApplies',
        'specialtyPharmacyCoverage',
        'additionalNotes',
        'specialtyPharmacyReasonForNonCoverage',
      ],
    },
    {
      label: 'CPT Code',
      parentField: 'cptCodeBenefits',
      idField: 'code',
      fields: [
        'valid',
        'copay',
        'coinsurance',
        'deductibleApplies',
        'additionalNotes',
        'priorAuthRequired',
        'priorAuthStatus',
        'preDeterminationRequirement',
        'preDeterminationOnFile',
        'reasonForNonCoverage',
      ],
    },
    {
      label: 'Office Benefits',
      fields: [
        'officeBenefits.copay',
        'officeBenefits.coinsurance',
        'officeBenefits.alwaysApplies',
        'officeBenefits.deductibleApplies',
        'officeBenefits.additionalNotes',
      ],
    },
    {
      label: 'Home Health Benefits',
      fields: [
        'homeHealthBenefits.copay',
        'homeHealthBenefits.coinsurance',
        'homeHealthBenefits.deductibleApplies',
      ],
    },
    {
      label: 'Access Info',
      fields: [
        'accessInfo.priorAuthRequired',
        'accessInfo.priorAuthRequiredCodes',
        'accessInfo.priorAuthOnFile',
        'accessInfo.priorAuthCodesOnFile',
        'accessInfo.priorAuthStatus',
        'accessInfo.priorAuthStartDate',
        'accessInfo.priorAuthEndDate',
        'accessInfo.priorAuthApprovalDate',
        'accessInfo.priorAuthApprovalNumber',
        'accessInfo.priorAuthSubmissionDate',
        'accessInfo.priorAuthExceptionReason',
        'accessInfo.priorAuthCompletionDate',
        'accessInfo.priorAuthSubmissionMethod',
        'accessInfo.priorAuthSubmissionMethods',
        'accessInfo.priorAuthNotificationMethod',
        'accessInfo.priorAuthNotificationMethods',
        'accessInfo.priorAuthType',
        'accessInfo.therapySeasonStartDate',
        'accessInfo.obtainPriorAuthOrg',
        'accessInfo.obtainPriorAuthPhone',
        'accessInfo.obtainPriorAuthWebsite',
        'accessInfo.obtainPriorAuthFax',
        'accessInfo.obtainPriorAuthFormLink',
        'accessInfo.obtainPriorAuthAddress',
        'accessInfo.timelyFilingPeriod',
        'accessInfo.obtainPriorAuthRequirements',
        'accessInfo.priorAuthTurnaroundTime',
        'accessInfo.priorAuthResponsibleOrg',
        'accessInfo.priorAuthApprovedQuantity',
        'accessInfo.priorAuthApprovedQuantityUsed',
        'accessInfo.priorAuthApprovedTreatments',
        'accessInfo.priorAuthApprovedTreatmentsUsed',
        'accessInfo.priorAuthOnFileApplies',
        'accessInfo.priorAuthProviderNameOnFile',
        'accessInfo.priorAuthAddressOnFile',
        'accessInfo.priorAuthRenewalProcessExists',
        'accessInfo.priorAuthInitiationDate',
        'accessInfo.medicalGroupName',
        'accessInfo.medicalPolicyAvailableOnWebsite',
        'accessInfo.medicalPolicyNumber',
        'accessInfo.medicalGroupPhoneNumber',
        'accessInfo.preDeterminationRequired',
        'accessInfo.preDeterminationAvailable',
        'accessInfo.preDeterminationRequirement',
        'accessInfo.preDeterminationOnFile',
        'accessInfo.preDeterminationNumber',
        'accessInfo.predeterminationStartDate',
        'accessInfo.predeterminationEndDate',
        'accessInfo.obtainPreDeterminationOrg',
        'accessInfo.obtainPredeterminationPhone',
        'accessInfo.obtainPredeterminationWebsite',
        'accessInfo.obtainPredeterminationFax',
        'accessInfo.predeterminationTurnaroundTime',
        'accessInfo.predeterminationApprovedQuantity',
        'accessInfo.predeterminationApprovedQuantityUsed',
        'accessInfo.predeterminationRenewalProcessExists',
        'accessInfo.predeterminationReceivedDate',
        'accessInfo.predeterminationCompletionDate',
        'accessInfo.predeterminationOnFileApplies',
        'accessInfo.predeterminationProviderNameOnFile',
        'accessInfo.predeterminationAddressOnFile',
        'accessInfo.predeterminationStatus',
        'accessInfo.stepTherapyRequired',
        'accessInfo.stepTherapyTreatment',
        'accessInfo.specialtyPharmacyAvailability',
        'accessInfo.specialtyPharmacyNetwork',
        'accessInfo.specialtyPharmacyObtainPriorAuthFormLink',
        'accessInfo.specialtyPharmacyObtainPriorAuthAddress',
        'accessInfo.specialtyPharmacyPriorAuthApprovedQuantity',
        'accessInfo.specialtyPharmacyPriorAuthApprovedQuantityUsed',
        'accessInfo.specialtyPharmacyPriorAuthApprovedTreatments',
        'accessInfo.specialtyPharmacyPriorAuthApprovedTreatmentsUsed',
        'accessInfo.specialtyPharmacyPredeterminationRequirementType',
        'accessInfo.specialtyPharmacyPredeterminationOnFile',
        'accessInfo.specialtyPharmacyPredeterminationNumber',
        'accessInfo.specialtyPharmacyPredeterminationStartDate',
        'accessInfo.specialtyPharmacyPredeterminationEndDate',
        'accessInfo.specialtyPharmacyObtainPreDeterminationOrg',
        'accessInfo.specialtyPharmacyObtainPredeterminationPhone',
        'accessInfo.specialtyPharmacyObtainPredeterminationWebsite',
        'accessInfo.specialtyPharmacyObtainPredeterminationFax',
        'accessInfo.specialtyPharmacyPredeterminationTurnaroundTime',
        'accessInfo.specialtyPharmacyPredeterminationApprovedQuantity',
        'accessInfo.specialtyPharmacyPredeterminationApprovedQuantityUsed',
        'accessInfo.specialtyPharmacyName',
        'accessInfo.specialtyPharmacyAddress',
        'accessInfo.specialtyPharmacyPhone',
        'accessInfo.specialtyPharmacyFax',
        'accessInfo.specialtyPharmacy2Name',
        'accessInfo.specialtyPharmacy2Address',
        'accessInfo.specialtyPharmacy2Phone',
        'accessInfo.specialtyPharmacy2Fax',
        'accessInfo.specialtyPharmacy3Name',
        'accessInfo.specialtyPharmacy3Address',
        'accessInfo.specialtyPharmacy3Phone',
        'accessInfo.specialtyPharmacy3Fax',
        'accessInfo.specialtyPharmacyExclusions',
        'accessInfo.specialtyPharmacyPriorAuthProcessDifferent',
        'accessInfo.specialtyPharmacyPriorAuthSubmissionMethod',
        'accessInfo.specialtyPharmacyPriorAuthSubmissionMethods',
        'accessInfo.specialtyPharmacyPriorAuthRequired',
        'accessInfo.specialtyPharmacyPriorAuthStatus',
        'accessInfo.specialtyPharmacyPredeterminationStatus',
        'accessInfo.specialtyPharmacyPriorAuthNotificationMethod',
        'accessInfo.specialtyPharmacyPriorAuthNotificationMethods',
        'accessInfo.specialtyPharmacyPriorAuthStartDate',
        'accessInfo.specialtyPharmacyPriorAuthEndDate',
        'accessInfo.specialtyPharmacyPriorAuthApprovalNumber',
        'accessInfo.specialtyPharmacyObtainPriorAuthOrg',
        'accessInfo.specialtyPharmacyObtainPriorAuthPhone',
        'accessInfo.specialtyPharmacyObtainPriorAuthWebsite',
        'accessInfo.specialtyPharmacyObtainPriorAuthFax',
        'accessInfo.specialtyPharmacyObtainPriorAuthRequirements',
        'accessInfo.specialtyPharmacyPriorAuthTurnaroundTime',
        'accessInfo.cptPriorAuthProcessDifferent',
        'accessInfo.cptPriorAuthSubmissionMethod',
        'accessInfo.cptPriorAuthSubmissionMethods',
        'accessInfo.cptPriorAuthNotificationMethod',
        'accessInfo.cptPriorAuthNotificationMethods',
        'accessInfo.cptPriorAuthStartDate',
        'accessInfo.cptPriorAuthEndDate',
        'accessInfo.cptPriorAuthApprovalNumber',
        'accessInfo.cptObtainPriorAuthOrg',
        'accessInfo.cptObtainPriorAuthPhone',
        'accessInfo.cptObtainPriorAuthWebsite',
        'accessInfo.cptObtainPriorAuthFormLink',
        'accessInfo.cptObtainPriorAuthAddress',
        'accessInfo.cptObtainPriorAuthFax',
        'accessInfo.cptObtainPriorAuthRequirements',
        'accessInfo.cptPriorAuthTurnaroundTime',
        'accessInfo.cptPriorAuthApprovedQuantity',
        'accessInfo.cptPriorAuthApprovedQuantityUsed',
        'accessInfo.cptPriorAuthApprovedTreatments',
        'accessInfo.cptPriorAuthApprovedTreatmentsUsed',
        'accessInfo.cptPredeterminationNumber',
        'accessInfo.cptPredeterminationStartDate',
        'accessInfo.cptPredeterminationEndDate',
        'accessInfo.cptObtainPreDeterminationOrg',
        'accessInfo.cptObtainPredeterminationPhone',
        'accessInfo.cptObtainPredeterminationWebsite',
        'accessInfo.cptObtainPredeterminationFax',
        'accessInfo.cptPredeterminationTurnaroundTime',
        'accessInfo.cptPredeterminationApprovedQuantity',
        'accessInfo.cptPredeterminationApprovedQuantityUsed',
        'accessInfo.claimsAddress',
        'accessInfo.referralRequired',
        'accessInfo.referralRequirements',
        'accessInfo.referralSubmissionProcess',
        'accessInfo.referralOnFile',
        'accessInfo.referralNumVisitsApproved',
        'accessInfo.referralNumVisitsUsed',
        'accessInfo.referralNumber',
        'accessInfo.referralEffectiveDate',
        'accessInfo.referralRecertDate',
        'accessInfo.referralWebsite',
        'accessInfo.referralFax',
        'accessInfo.referralAddress',
        'accessInfo.coverageRestrictions',
        'accessInfo.therapyAvailabilityDate',
        'accessInfo.quantityLimit',
        'accessInfo.pcpName',
        'accessInfo.pcpPhone',
        'accessInfo.buyAndBillAvailability',
        'accessInfo.homeHealthCovered',
        'accessInfo.homeHealthProviderName',
        'accessInfo.homeHealthProviderPhone',
        'accessInfo.homeHealthServices',
        'accessInfo.homeHealthPriorAuthRequired',
        'accessInfo.homeHealthObtainPriorAuthPhone',
        'accessInfo.homeHealthObtainPriorAuthFax',
        'accessInfo.coverageExceptionProcessExists',
        'accessInfo.obtainCoverageExceptionProcess',
        'accessInfo.obtainCoverageExceptionPhone',
        'accessInfo.obtainCoverageExceptionFax',
        'accessInfo.obtainCoverageExceptionWebsite',
        'accessInfo.obtainCoverageExceptionAddress',
        'accessInfo.obtainQuantityLimitExceptionProcess',
        'accessInfo.obtainQuantityLimitExceptionPhone',
        'accessInfo.coverageExceptionTurnaroundTime',
        'accessInfo.transportationServiceCovered',
        'accessInfo.lodgingCovered',
        'accessInfo.additionalNotes',
        'accessInfo.transportationServicePhoneNumber',
        'accessInfo.lodgingServicePhoneNumber',
        'accessInfo.homeboundRequired',
        'accessInfo.predeterminationSubmissionDate',
        'accessInfo.attestationProcessExists',
        'accessInfo.attestationProcess',
      ],
    },
    {
      label: 'Denial Info',
      fields: [
        'denialInfo.denialReason',
        'denialInfo.denialDate',
        'denialInfo.denialNumber',
        'denialInfo.peerToPeerAvailable',
        'denialInfo.peerToPeerPhone',
        'denialInfo.peerToPeerSubmissionDeadline',
        'denialInfo.priorAuthAppealsContactFax',
        'denialInfo.appealAvailable',
        'denialInfo.numberOfAppealsAvailable',
        'denialInfo.appealsContactOrg',
        'denialInfo.appealsContactAddress',
        'denialInfo.appealsContactPhone',
        'denialInfo.appealsContactWebsite',
        'denialInfo.appealsContactFax',
        'denialInfo.appealsTurnaroundTime',
        'denialInfo.appealsSubmissionDeadline',
        'denialInfo.appealsRequiredDocuments',
        'denialInfo.additionalNotes',
      ],
    },
  ];
  if (enableBillingNotes) {
    outputs = [
      {
        label: 'Billing Notes',
        fields: ['billingNotes'],
      },
      ...outputs,
    ];
  }
  return outputs;
};

export const pbmDiscoveryTaskOutputsMap = (enableBillingNotes?: boolean): TaskOutputsMap[] => {
  let outputs: TaskOutputsMap[] = [
    {
      label: 'Task Notes',
      fields: ['additionalNotes', 'taskLink'],
    },
    {
      label: 'Task Info',
      fields: ['referenceNumber', 'payerAgentName'],
    },
    {
      label: 'Plan Info',
      fields: [
        'planInfo.planEffectiveDate',
        'planInfo.planTerminationDate',
        'planInfo.newPlanAvailable',
        'planInfo.newPlanEffectiveDate',
        'planInfo.newPlanSubscriberId',
        'planInfo.pbmExists',
        'planInfo.pbmName',
        'planInfo.pbmPhone',
        'planInfo.pbmPolicyID',
      ],
    },
  ];
  if (enableBillingNotes) {
    outputs = [
      {
        label: 'Billing Notes',
        fields: ['billingNotes'],
      },
      ...outputs,
    ];
  }
  return outputs;
};

export const ptTaskOutputsMap = [
  {
    label: 'Task Notes',
    fields: ['additionalNotes'],
  },
];

export const psTaskOutputsMap = [
  {
    label: 'Task Notes',
    fields: ['patientOnFileConfirmation', 'taskLink'],
  },
  {
    label: 'Products',
    idField: 'code',
    fields: [
      'products.code',
      'products.copayAfterSavings',
      'products.isMedicationOnFile',
      'products.isPrescriptionActive',
      'products.lastRefillCost',
      'products.lastRefillDate',
      'products.name',
      'products.outcome',
      'products.reasonForNotApplyingSavings',
      'products.rebilled',
      'products.refillDenialReason',
      'products.refillRequested',
      'products.savingsCardAdded',
      'products.savingsCardApplied',
    ],
  },
];

export const paTaskOutputsMap = [
  {
    label: 'Task Notes',
    fields: ['additionalNotes'],
  },
  {
    label: 'Task Info',
    fields: ['payerAgentName', 'referenceNumber', 'payerPhone'],
  },
  {
    label: 'Plan Info',
    fields: ['planInfo.planTerminationDate'],
  },
  {
    label: 'Prior Auth Info',
    parentField: 'priorAuthInfo.priorAuthInfoDetails',
    idField: ARRAY_INDEX,
    prefix: 'Group',
    fields: [
      'priorAuthApprovalDate',
      'priorAuthApprovalLevel',
      'priorAuthApprovalNumber',
      'priorAuthCodesOnFile',
      'priorAuthCompletionDate',
      'priorAuthDenialReason',
      'priorAuthDenialReasonOther',
      'priorAuthDoseOnFile',
      'priorAuthEndDate',
      'priorAuthEntityOnFile',
      'priorAuthEntityOnFile.name',
      'priorAuthEntityOnFile.npi',
      'priorAuthEntityOnFile.type',
      'priorAuthOnFileApplies',
      'priorAuthStartDate',
      'priorAuthStatus',
      'priorAuthSubmissionMethods',
      'priorAuthNotificationMethods',
      'priorAuthTurnaroundNotes',
      'productSourcingMethod',
      'quantityLimit',
      'quantityLimitExists',
    ],
  },
  {
    label: 'CPT Code',
    parentField: 'cptCodePriorAuthRequirements',
    idField: 'code',
    fields: ['priorAuthRequired'],
  },
  {
    label: 'Product Code',
    parentField: 'productCodePriorAuthRequirements',
    idField: 'code',
    fields: ['priorAuthRequired'],
  },
  {
    label: 'Prior Auth Appeal Info',
    fields: [
      'priorAuthAppealInfo.priorAuthAppealAvailable',
      'priorAuthAppealInfo.priorAuthAppealsContactOrg',
      'priorAuthAppealInfo.priorAuthAppealsContactPhone',
      'priorAuthAppealInfo.priorAuthAppealsNotificationMethods',
      'priorAuthAppealInfo.priorAuthAppealsRequiredDocuments',
      'priorAuthAppealInfo.priorAuthAppealsSubmissionDeadline',
      'priorAuthAppealInfo.priorAuthAppealsTurnaroundTime',
    ],
  },
];

export const paTaskInputsMap: {
  [key: string]: TaskInputsMap;
} = {
  'Place of Service Network Status': {
    getValue: getNetworkStatusVal,
    type: 'string',
  },
  'Provider Network Status': {
    getValue: getNetworkStatusVal,
    type: 'string',
  },
  'Place of Service Type': {
    getValue: getPlaceOfServiceVal,
    type: 'string',
  },
};

export interface OutputDataFieldMap {
  [outputfieldName: string]: OutputDataField;
}

interface OutputDataField {
  getValue: (val?: any | null) => string;
  label: string;
}

enum DataFieldType {
  STRING = 'string',
  INT = 'int',
  BOOLEAN = 'bool',
  DATE = 'date',
  AMOUNT_IN_DOLLARS = 'amountInDollars',
}

const getFlexibleOutputValue = (output: infinitusapi.IINFOutputValue) => {
  switch (output.type || '') {
    case DataFieldType.AMOUNT_IN_DOLLARS:
      return output.amountInDollarsValue;
    case DataFieldType.INT:
      return output.intValue;
    case DataFieldType.BOOLEAN:
      return output.boolValue;
    case DataFieldType.STRING:
      return output.stringValue;
    case DataFieldType.DATE:
      return output.dateValue;
    default:
      return null;
  }
};

// Convert Flexible Task Outputs list into an Object format. This allows quick lookups
// of the raw output values
export const flexibleTaskOutputs = (taskOutputs?: infinitusapi.IINFTaskOutput | null) => {
  const outputs: { [key: string]: any } = {};
  if (!taskOutputs) return outputs;

  taskOutputs.outputs?.forEach((o) => {
    if (o.name) {
      outputs[o.name] = getFlexibleOutputValue(o) ?? null;
    }
  });

  if (taskOutputs.additionalNotes) {
    outputs['additionalNotes'] = taskOutputs.additionalNotes;
  }

  return outputs;
};

// Groups Flexible Task Outputs into buckets by category
export const flexibleTaskOutputsMap = (taskOutputs: infinitusapi.IINFTaskOutput) => {
  const outputsMap: { fields: string[]; label: string }[] = [];
  const outputsBucket: { [label: string]: string[] } = {};

  if (taskOutputs.additionalNotes) {
    outputsMap.push({
      label: 'Task Notes',
      fields: ['additionalNotes'],
    });
  }

  taskOutputs.outputs?.forEach((o) => {
    const category = o.category || 'Miscellaneous Outputs';

    if (!(category in outputsBucket)) {
      outputsBucket[category] = [];
    }
    if (o.name) {
      outputsBucket[category].push(o.name);
    }
  });

  Object.keys(outputsBucket).forEach((label) =>
    outputsMap.push({
      label: label,
      fields: outputsBucket[label] || [],
    })
  );

  outputsMap.sort((a, b) => a.label.localeCompare(b.label)).forEach((oMap) => oMap.fields.sort());

  return outputsMap;
};

const getOutputValueDisplayMethod = (outputValueType: DataFieldType | string) => {
  switch (outputValueType) {
    case DataFieldType.AMOUNT_IN_DOLLARS:
      return getMoneyVal;
    case DataFieldType.INT:
      return getIntVal;
    case DataFieldType.BOOLEAN:
      return getBoolVal;
    case DataFieldType.STRING:
    case DataFieldType.DATE:
    default:
      return getStringVal;
  }
};

// Creates map of output fields to an object used to help display
// outputs in a readable format
export const flexibleTaskOutputsDataFieldMap = (taskOutputs: infinitusapi.IINFTaskOutput) => {
  const outputsMap: OutputDataFieldMap = {};

  if (!taskOutputs) return outputsMap;

  taskOutputs.outputs?.forEach((o) => {
    if (!o.name) return;

    let dataField: OutputDataField = {
      // Apply the appropriate getValue method for the first non-nullish value found
      getValue: getOutputValueDisplayMethod(o.type || ''),
      label: o.description || o.name,
    };

    outputsMap[o.name] = dataField;
  });

  if (taskOutputs.additionalNotes) {
    outputsMap['additionalNotes'] = {
      label: 'Task Notes',
      getValue: getStringVal,
    };
  }

  return outputsMap;
};
