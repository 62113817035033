import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  useAppState,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
} from '@infinitusai/shared';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import CableRoundedIcon from '@mui/icons-material/CableRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMatch } from 'react-router-dom';

interface Props {
  hasMatch: boolean;
  openSettings: boolean;
  onItemClick: (path: string) => void;
  styleIcon: (match: boolean) => {};
}

function SettingsCollapsible({ hasMatch, openSettings, onItemClick, styleIcon }: Props) {
  const appState = useAppState();
  const { hasPermission } = useAuth();

  const accountSettingsRouteMatch = useMatch(`/${appState.orgName}/account/settings`);
  const accountTeamsMembersRouteMatch = useMatch(`/${appState.orgName}/account/members`);
  const accountRolesRouteMatch = useMatch(`/${appState.orgName}/account/roles`);
  const accountApiRouteMatch = useMatch(`/${appState.orgName}/account/api`);
  const accountPayerIntelligenceMatch = useMatch(`/${appState.orgName}/account/payer-intelligence`);

  const hasCompanyProfileReadPermission = hasPermission([PermissionName.CUSTOMER_SETTINGS_READ]);
  const hasMemberReadPermission = hasPermission([PermissionName.CUSTOMER_RBAC_READ]);
  const hasAPIKeysReadPermission = hasPermission([PermissionName.CUSTOMER_API_KEYS_READ]);
  const hasPIEReadPermission = hasPermission([PermissionName.CUSTOMER_PIE_READ]);

  const hasTeamMgmtFeature = useCustomerPortalFeatureEnabled(CustomerPortalFeature.TEAM_MANAGEMENT);

  const settingsItems = [
    hasCompanyProfileReadPermission && {
      path: `/${appState.orgName}/account/settings`,
      title: 'Company Profile',
      icon: <BusinessRoundedIcon sx={styleIcon(hasMatch && accountSettingsRouteMatch !== null)} />,
      match: hasMatch && accountSettingsRouteMatch !== null,
    },
    hasMemberReadPermission && {
      path: `/${appState.orgName}/account/members`,
      title: hasTeamMgmtFeature ? `Teams & Members` : `Members`,
      icon: (
        <SupervisorAccountRoundedIcon
          sx={styleIcon(hasMatch && accountTeamsMembersRouteMatch !== null)}
        />
      ),
      match: hasMatch && accountTeamsMembersRouteMatch !== null,
    },
    hasMemberReadPermission && {
      path: `/${appState.orgName}/account/roles`,
      title: `Roles & Permissions`,
      icon: <RecentActorsRoundedIcon sx={styleIcon(hasMatch && accountRolesRouteMatch !== null)} />,
      match: hasMatch && accountRolesRouteMatch !== null,
    },
    hasAPIKeysReadPermission && {
      path: `/${appState.orgName}/account/api`,
      title: `API Keys`,
      icon: <KeyRoundedIcon sx={styleIcon(hasMatch && accountApiRouteMatch !== null)} />,
      match: hasMatch && accountApiRouteMatch !== null,
    },
    hasPIEReadPermission && {
      path: `/${appState.orgName}/account/payer-intelligence`,
      title: `Payor Intelligence`,
      icon: <CableRoundedIcon sx={styleIcon(hasMatch && accountPayerIntelligenceMatch !== null)} />,
      match: hasMatch && accountPayerIntelligenceMatch !== null,
    },
  ].filter(Boolean);

  return (
    <Collapse in={openSettings} timeout="auto" unmountOnExit>
      <List>
        {settingsItems.map((item) =>
          item ? (
            <ListItemButton
              key={item.title as string}
              sx={{ ml: 4 }}
              onClick={() => onItemClick(item.path)}
              selected={item.match}
              disableRipple
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  variant: 'subtitle2',
                }}
              />
            </ListItemButton>
          ) : null
        )}
      </List>
    </Collapse>
  );
}

export default SettingsCollapsible;
