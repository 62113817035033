import { PageHeader } from '@infinitusai/shared';
import { useBreakpoint } from '@infinitusai/ui';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import CountUp from 'react-countup';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { monthLabels, useGetBillingMetricsQuery, useGetBillingReportQuery } from 'api/customer';

const START_YEAR = 2020;
let currentYear = new Date().getFullYear();
let years: number[] = [];
while (currentYear >= START_YEAR) {
  years.push(currentYear--);
}

function BillingPage() {
  const smBreakpoint = useBreakpoint('sm');

  const lgBreakpoint = useBreakpoint('lg');

  const date = new Date();
  const getBillingMetricsQuery = useGetBillingMetricsQuery();
  const getBillingReportQuery = useGetBillingReportQuery();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  let year = searchParams.get('year') || date.getFullYear().toString();
  let month = searchParams.get('month') || monthLabels[date.getMonth()];

  function setDateToMonthStart(date: Date, year: number, month: number) {
    date.setDate(1);
    date.setFullYear(year);
    date.setMonth(month);
    date.setHours(0, 0, 0, 0);
  }

  function handleYearDropdown(event: any) {
    let selectedYear = event.target.value as number;
    let start = new Date();
    let end = new Date();
    setDateToMonthStart(start, selectedYear, monthLabels.indexOf(month));
    setDateToMonthStart(end, selectedYear, monthLabels.indexOf(month) + 1);

    searchParams.set('month', monthLabels[start.getMonth()]);
    searchParams.set('year', start.getFullYear().toString());
    navigate({
      search: `?${searchParams.toString()}`,
    });
  }

  function handleMonthDropdown(event: any) {
    let selectedMonth = event.target.value as string;
    let start = new Date();
    let end = new Date();

    setDateToMonthStart(start, parseInt(year), monthLabels.indexOf(selectedMonth));
    setDateToMonthStart(end, parseInt(year), monthLabels.indexOf(selectedMonth) + 1);

    searchParams.set('month', monthLabels[start.getMonth()]);
    searchParams.set('year', start.getFullYear().toString());
    navigate({
      search: `?${searchParams.toString()}`,
    });
  }
  function saveReport() {
    window.open(getBillingReportQuery.data?.url, '_blank');
  }

  const billingBreakdownData = [
    {
      title: 'Total Tasks',
      count: getBillingMetricsQuery.data
        ? getBillingMetricsQuery.data.tasksCompletedCount +
          getBillingMetricsQuery.data.tasksFailedBadDataCount +
          getBillingMetricsQuery.data.tasksFailedOtherCount
        : 0,
      color: 'textPrimary',
    },
    {
      title: 'Successfully Completed Tasks',
      count: getBillingMetricsQuery.data ? getBillingMetricsQuery.data.tasksCompletedCount : 0,
      color: 'success.main',
    },
    {
      title: 'Bad Data Tasks',
      count: getBillingMetricsQuery.data ? getBillingMetricsQuery.data.tasksFailedBadDataCount : 0,
      color: 'warning.main',
    },
    {
      title: 'Failed Tasks',
      count: getBillingMetricsQuery.data ? getBillingMetricsQuery.data.tasksFailedOtherCount : 0,
      color: 'error.main',
    },
  ];

  return (
    <React.Fragment>
      <PageHeader title="Billing" variant="h5" />
      <Box>
        <Typography variant="h6"> Select a Billing Period Below</Typography>
        <Stack direction={lgBreakpoint ? 'row' : 'column'} gap={4} mt={2}>
          {/* Year Selection */}
          <FormControl
            sx={{
              minWidth: '300px',
            }}
          >
            <InputLabel>Year</InputLabel>
            <Select
              labelId="label-for-year"
              label="Year"
              onChange={(event) => handleYearDropdown(event)}
              value={year}
              size="small"
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Month Selection */}
          <FormControl sx={{ minWidth: '300px' }}>
            <InputLabel>Month</InputLabel>
            <Select
              labelId="label-for-month"
              label="Month"
              onChange={(event) => handleMonthDropdown(event)}
              value={month}
              size="small"
            >
              {monthLabels.map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Tooltip title={getBillingReportQuery.data?.url ? '' : 'Report Not Available'}>
            <span>
              <Button
                size="large"
                sx={{ pl: 3, pr: 3 }}
                color="primary"
                variant="contained"
                disabled={!getBillingReportQuery.data?.url}
                onClick={saveReport}
                endIcon={<CloudDownloadRoundedIcon />}
              >
                Download Report
              </Button>
            </span>
          </Tooltip>
        </Stack>
        <Box p="1 0" mt={1}>
          {!getBillingReportQuery.data?.url && (
            <Typography variant="body1" color="textSecondary">
              {month} {year} billing report is not yet available
            </Typography>
          )}
        </Box>

        {/* Billing Breakdown for Tasks */}
        <Box mt={4}>
          <Typography variant="h6" textAlign={smBreakpoint ? 'left' : 'center'}>
            High Level Breakdown for Period of {month} {year}{' '}
          </Typography>
          <Box mt={2}>
            <Stack
              direction={smBreakpoint ? 'row' : 'column'}
              justifyContent="space-around"
              display="flex"
              alignContent="center"
            >
              {billingBreakdownData.map((billingData, index) => (
                <Box key={index}>
                  <Stack alignItems="center">
                    <Typography variant="h2" color={billingData.color}>
                      {getBillingMetricsQuery.isLoading ? (
                        '-'
                      ) : (
                        <CountUp end={billingData.count} duration={0.5} />
                      )}
                    </Typography>
                    <Typography variant="overline">{billingData.title}</Typography>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default BillingPage;
