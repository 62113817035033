import { infinitusai } from 'proto/pbjs';

export type WebhookDeliveryStatusKey = keyof typeof infinitusai.be.WebhookDeliveryStatus;

const WEBHOOK_DELIVERY_DICT: Record<string, WebhookDeliveryStatusKey> = {};
Object.entries(infinitusai.be.WebhookDeliveryStatus).forEach(([key, value]) => {
  WEBHOOK_DELIVERY_DICT[value] = key as WebhookDeliveryStatusKey;
});

const WEBHOOK_DELIVERY_STATUS_DISPLAY_MAP = new Map([
  [infinitusai.be.WebhookDeliveryStatus.WEBHOOK_DELIVERY_STATUS_UNKNOWN, 'Unknown'],
  [infinitusai.be.WebhookDeliveryStatus.WEBHOOK_DELIVERY_STATUS_SUCCESS, 'Success'],
  [infinitusai.be.WebhookDeliveryStatus.WEBHOOK_DELIVERY_STATUS_PENDING_RETRY, 'Pending Retry'],
  [infinitusai.be.WebhookDeliveryStatus.WEBHOOK_DELIVERY_STATUS_FAILED, 'Failed'],
]);

export function getWebhookStatusDisplayName(
  webhookDeliveryStatus?: infinitusai.be.WebhookDeliveryStatus | null
) {
  if (!webhookDeliveryStatus) {
    return '';
  }
  return WEBHOOK_DELIVERY_STATUS_DISPLAY_MAP.get(webhookDeliveryStatus);
}

export const WEBHOOK_DELIVERY_STATUS_CHOICES = [
  // Intentionally not showing Unknown
  infinitusai.be.WebhookDeliveryStatus.WEBHOOK_DELIVERY_STATUS_SUCCESS,
  infinitusai.be.WebhookDeliveryStatus.WEBHOOK_DELIVERY_STATUS_PENDING_RETRY,
  infinitusai.be.WebhookDeliveryStatus.WEBHOOK_DELIVERY_STATUS_FAILED,
].map((item) => {
  return {
    label: getWebhookStatusDisplayName(item),
    value: WEBHOOK_DELIVERY_DICT[item],
  };
});

export function getWebhookStatusFilterOptions() {
  return WEBHOOK_DELIVERY_STATUS_CHOICES;
}

function plural(word: string, length: number): string {
  if (word === 'task') {
    return length < 2 ? 'task' : 'tasks';
  }
  if (word === 'was') {
    return length < 2 ? 'was' : 'were';
  }
  return '';
}

type ProcessResendWebhookResponseResult = {
  variant: 'success' | 'info';
  message: string;
};
export function processResendWebhookResponse(
  result: infinitusai.be.ResendWebhookNotificationsResponse
): ProcessResendWebhookResponseResult {
  const responses = result.responses;
  const successList = [];
  const customerProblemList = [];
  const failedList = [];
  const skippedList = [];

  responses.forEach((response) => {
    if (response.status === infinitusai.be.ResendWebhookStatus.RESEND_WEBHOOK_STATUS_SUCCESS) {
      successList.push(response);
    }
    if (
      response.status ===
      infinitusai.be.ResendWebhookStatus.RESEND_WEBHOOK_STATUS_NON_SUCCESS_RESPONSE
    ) {
      customerProblemList.push(response);
    }
    if (
      response.status ===
      infinitusai.be.ResendWebhookStatus.RESEND_WEBHOOK_STATUS_INTERNAL_SERVER_ERROR
    ) {
      failedList.push(response);
    }
    if (
      response.status ===
      infinitusai.be.ResendWebhookStatus.RESEND_WEBHOOK_STATUS_SKIP_DUE_TO_INCOMPLETE_BUNDLE_TASK
    ) {
      skippedList.push(response);
    }
  });

  // All tasks delivered successfully
  if (responses.length === successList.length) {
    return {
      variant: 'success',
      message: 'All tasks delivered successfully.',
    };
  }

  // Some did not deliver successfully
  const messages = [];
  messages.push(
    [
      `Out of ${responses.length} ${plural('task', responses.length)},`,
      `${successList.length} ${plural('task', successList.length)}`,
      `${plural('was', successList.length)} delivered successfully.`,
    ].join(' ')
  );
  if (customerProblemList.length !== 0) {
    messages.push(
      [
        `${customerProblemList.length} ${plural('task', customerProblemList.length)}`,
        `failed to deliver because webhook consumer returned non successful status code.`,
      ].join(' ')
    );
  }
  if (failedList.length !== 0) {
    messages.push(
      [
        `${failedList.length} ${plural('task', failedList.length)}`,
        `failed to deliver because of unknown issue.`,
      ].join(' ')
    );
  }
  if (skippedList.length !== 0) {
    messages.push(
      [
        `${skippedList.length} ${plural('task', skippedList.length)}`,
        `${plural('was', skippedList.length)} skipped`,
        `because task state did not qualify for webhook delivery`,
      ].join(' ')
    );
  }
  return {
    variant: 'info',
    message: messages.join('\n'),
  };
}
