import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';

import DataField from 'components/DataField';
import { infinitusapi } from 'proto/pbjs';
import { formatValue, getFTIValue } from 'utils/displayNames';

import DetailsAccordion from './DetailsAccordion';
import { TaskInputsMap, useTaskInputsMap } from './constants';

export interface FlexibleTaskInputsDisplayProps {
  fti: infinitusapi.IINFInputValue[];
  map: { [key: string]: TaskInputsMap };
}

export const formatFTI = (taskInput: any) => {
  if (!taskInput) return null;
  if (taskInput.array) {
    if (taskInput.array?.nestedFields?.length) {
      const values: any[] = [];
      taskInput.array.nestedFields.forEach((value: any) => {
        if (Array.isArray(value.values)) {
          values.push(...value.values);
        }
      });
      return {
        displayName: taskInput.array?.displayName || taskInput.array?.name,
        value: values,
      };
    } else if (taskInput.array.values) {
      return {
        displayName: taskInput.array.displayName || taskInput.array.name,
        value: taskInput.array.values,
      };
    }
  } else if (taskInput.nestedFields) {
    return {
      displayName: taskInput.nestedFields.displayName || taskInput.nestedFields.name,
      value: taskInput.nestedFields.values,
    };
  } else if (taskInput.value) {
    return {
      displayName: taskInput.value.displayName || taskInput.value.name,
      value: [taskInput.value],
    };
  }
  return null;
};

export const maybeRenderScalar = (name: any, value: any) => {
  if (!name) return null;

  const val = getFTIValue(value);

  return (
    <Box width="100%" sx={{ mb: 2 }} key={name}>
      <DataField label={name} value={val} whiteSpace="normal" />
    </Box>
  );
};

function FlexibleTaskInputsDisplay({ fti: taskInputs, map }: FlexibleTaskInputsDisplayProps) {
  const generalTaskInputs = taskInputs
    .filter((input) => input.value)
    .reduce((accum: any, val) => {
      const formattedData = formatFTI(val);
      return [...accum, formattedData];
    }, []);
  const visibleComplexTaskInputs = taskInputs
    .filter((input) => !input.value)
    .reduce((accum: any, val) => {
      const formattedData = formatFTI(val);
      return [...accum, formattedData];
    }, []);
  const mappedVisibleComplexTaskInputs = useTaskInputsMap(visibleComplexTaskInputs, map);

  const [expanded, setExpanded] = React.useState<Set<string>>(new Set(['General Inputs']));
  const accordions = [
    'General Inputs',
    ...visibleComplexTaskInputs.map((complexInput: any) => complexInput.displayName),
  ];

  const handleExpandAll = () => {
    setExpanded(new Set(accordions));
  };

  const handleCollapseAll = () => {
    setExpanded(new Set());
  };

  const renderComplex = (complexInput: any) => {
    return (
      <DetailsAccordion
        displayName={complexInput.displayName}
        numberOfEntities={complexInput.value.length}
        setExpanded={setExpanded}
        expanded={expanded}
        data={complexInput.value.map((input: any) => {
          let displayName = input.displayName;
          if (complexInput.array?.name === 'sitesOfCare') {
            displayName = displayName?.substring(0, displayName.indexOf('%'));
          }
          return (
            <Grid item xs={complexInput.value.length === 1 ? 12 : 3} key={input.displayName}>
              {maybeRenderScalar(
                input.displayName || displayName || input.name || complexInput.displayName,
                formatValue(input)
              )}
            </Grid>
          );
        })}
      />
    );
  };

  const renderGeneralSection = (label: string, data: any) => {
    return (
      <DetailsAccordion
        displayName="General Inputs"
        numberOfEntities={generalTaskInputs.length}
        setExpanded={setExpanded}
        expanded={expanded}
        data={generalTaskInputs.map((input: any) => (
          <Grid item xs={generalTaskInputs.length === 1 ? 12 : 3} key={input.displayName}>
            {maybeRenderScalar(input.displayName, formatValue(input.value))}
          </Grid>
        ))}
      />
    );
  };

  return (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        {expanded.size === Object.keys(accordions).length ? (
          <Button color="primary" onClick={handleCollapseAll}>
            Collapse All
          </Button>
        ) : (
          <Button color="primary" onClick={handleExpandAll}>
            Expand All
          </Button>
        )}
      </Box>
      {generalTaskInputs.length > 0 && (
        <Grid>{renderGeneralSection('General Inputs', generalTaskInputs)}</Grid>
      )}
      {mappedVisibleComplexTaskInputs.map((input: any) => (
        <Grid key={input.displayName}>{renderComplex(input)}</Grid>
      ))}
    </Grid>
  );
}
export default FlexibleTaskInputsDisplay;
