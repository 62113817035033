import Papa from 'papaparse';

import { infinitusai } from 'proto/pbjs';
import { TASK_TYPES_FOR_IMPORT_TASK } from 'utils/taskTypes';

export function generateCsvTemplate(
  inputFields: infinitusai.be.CSVSpec.IField[],
  onlyIncludeRequiredFields: boolean
): string {
  const rows = inputFields
    .map((field) => {
      const columnName = field.csvColumn || field.csvColumnTemplate?.example || '';
      const required = field.required || false;
      const type = field.type || '';
      const example = field.example || '';
      const enumValues = field.type === 'enum' ? field.allowedValues || [] : [];

      return {
        columnName,
        required,
        type,
        example,
        enumValues,
      };
    })
    .filter((row) => {
      if (onlyIncludeRequiredFields) return row.required;
      return true;
    });

  const columnNames = rows.map((row) => row.columnName);
  const requiredRows = rows.map((row) => {
    if (row.required) return 'required';
    return '';
  });
  const typeRows = rows.map((row) => row.type);
  const exampleRows = rows.map((row) => row.example);
  const enumRows = rows.map((row) => {
    if (row.enumValues.length === 0) return '';
    return `"${row.enumValues.join('\n')}"`;
  });

  return [
    columnNames.join(','),
    '//Note that rows starting with // will be ignored',
    '//Required: ' + requiredRows.join(','),
    '//Type: ' + typeRows.join(','),
    '//Example: ' + exampleRows.join(','),
    '//Enums: ' + enumRows.join(','),
  ].join('\n');
}

export function cleanCsvString(csvString: string): string {
  const parsed = Papa.parse<string[]>(csvString);
  const rows = parsed.data;

  // Filter out rows that start with '//' added in generateCsvTemplate
  const cleanRows = rows.filter((row) => {
    if (row[0].startsWith('//')) {
      return false;
    }
    return true;
  });

  const newCsvString = Papa.unparse(cleanRows);
  return newCsvString;
}

export function taskImportHasRejectedTasks(
  uploadCsvResponse: infinitusai.tasks.UploadCSVResponse
): boolean {
  return (uploadCsvResponse.numRejected as number) > 0;
}

export function getTaskTypesForTaskImport(
  taskTypesForImportTask: typeof TASK_TYPES_FOR_IMPORT_TASK,
  orgTaskTypes: infinitusai.tasks.IOrgTaskType[]
) {
  const infTaskTypeEnumsMap = new Map<infinitusai.tasks.INFTaskType, string>();
  Object.entries(infinitusai.tasks.INFTaskType).forEach(([str, int]) => {
    infTaskTypeEnumsMap.set(int as infinitusai.tasks.INFTaskType, str);
  });

  const orgTaskTypesMap = new Map<string, infinitusai.tasks.IOrgTaskType>();
  orgTaskTypes.forEach((orgTaskType) => {
    const infTaskTypeInt =
      orgTaskType.INFTaskType || infinitusai.tasks.INFTaskType.INF_TASK_TYPE_UNKNOWN;
    const infTaskTypeStr = infTaskTypeEnumsMap.get(infTaskTypeInt) || '';
    orgTaskTypesMap.set(infTaskTypeStr, orgTaskType);
  });

  const taskTypesForTaskImport = taskTypesForImportTask.filter((taskType) => {
    return orgTaskTypesMap.get(taskType.value)?.csvTaskImportSupported === true;
  });

  return taskTypesForTaskImport;
}
