import Chip from '@mui/material/Chip';
import { CustomerFacingTaskState } from 'utils';

interface Props {
  label: string;
}
function StatusChip({ label }: Props) {
  let color:
    | 'primary'
    | 'default'
    | 'success'
    | 'error'
    | 'warning'
    | 'secondary'
    | 'info'
    | undefined = 'default';
  let progressText = 'Unknown';

  switch (label) {
    case CustomerFacingTaskState.IN_QUEUE:
      color = 'primary';
      progressText = 'Queued';
      break;
    case CustomerFacingTaskState.IN_PROGRESS:
      color = 'info';
      progressText = 'In Progress';
      break;
    case CustomerFacingTaskState.COMPLETED:
      color = 'success';
      progressText = 'Completed';
      break;
    case CustomerFacingTaskState.BAD_DATA_INPUT:
    case CustomerFacingTaskState.CALL_FAILED:
      color = 'error';
      progressText = 'Unsuccessful';
      break;
    case CustomerFacingTaskState.SUCCESSFUL:
      color = 'success';
      progressText = 'Successful';
      break;
    case CustomerFacingTaskState.PARTIAL:
      color = 'success';
      progressText = 'Partially Successful';
      break;
  }
  return (
    <Chip
      variant="outlined"
      color={color}
      label={progressText}
      sx={{ textTransform: 'capitalize', color: 'textPrimary' }}
    />
  );
}

export default StatusChip;
