import { infinitusai } from 'proto/pbjs';
import { isTaskInFinalState } from 'utils/taskState';

export function shouldShowResendWebhookButton(
  taskState: infinitusai.be.TaskState,
  customerDocWebhook: infinitusai.be.CustomerTaskDoc['webhook'],
  webhookConfigs: infinitusai.be.IWebhookConfigDoc[]
) {
  if (!isTaskInFinalState(taskState)) {
    return false;
  }
  // There should be at least one delivery attempt from pubsub before showing the button
  if (!customerDocWebhook) {
    return false;
  }
  // Check if the webhook is configured for the org
  if (webhookConfigs?.length === 0) {
    return false;
  }
  return true;
}

export function shouldShowSuccessWebhookIcon(
  customerDocWebhook: infinitusai.be.CustomerTaskDoc['webhook']
) {
  if (!customerDocWebhook) {
    return false;
  }
  return (
    customerDocWebhook.status ===
    infinitusai.be.WebhookDeliveryStatus.WEBHOOK_DELIVERY_STATUS_SUCCESS
  );
}
