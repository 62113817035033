import { infinitusai, infinitusapi } from 'proto/pbjs';

export enum CustomerFacingTaskState {
  UNKNOWN = 'UNKNOWN',
  IN_QUEUE = 'IN_QUEUE',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESSFUL = 'SUCCESSFUL',
  UNSUCCESSFUL = 'UNSUCCESSFUL',
  COMPLETED = 'COMPLETED',
  CALL_FAILED = 'CALL_FAILED',
  BAD_DATA_INPUT = 'BAD_DATA_INPUT',
  CANCELLED = 'CANCELLED',
  PARTIAL = 'PARTIAL',
}

export const getProtoTaskFailReason = (
  taskFailReason:
    | infinitusai.be.TaskFailReason.Type
    | keyof typeof infinitusai.be.TaskFailReason.Type
): infinitusai.be.TaskFailReason.Type => {
  if (!taskFailReason) return infinitusai.be.TaskFailReason.Type.REASON_UNKNOWN;
  const enumValue =
    typeof taskFailReason === 'string'
      ? infinitusai.be.TaskFailReason.Type[taskFailReason]
      : taskFailReason;
  return enumValue;
};

export const getProtoTaskState = (
  taskState: infinitusai.be.TaskState | keyof typeof infinitusai.be.TaskState
): infinitusai.be.TaskState => {
  if (!taskState) return infinitusai.be.TaskState.TASK_STATE_UNKNOWN;
  const enumValue = typeof taskState === 'string' ? infinitusai.be.TaskState[taskState] : taskState;
  return enumValue;
};

function isCustomerTaskDoc(
  task?: infinitusai.be.TaskDoc | infinitusai.be.CustomerTaskDoc
): task is infinitusai.be.CustomerTaskDoc {
  if (!task) return false;
  return 'operatorTaskUuids' in task;
}

export const getCustomerFacingTaskState = (
  task?: infinitusai.be.TaskDoc | infinitusai.be.CustomerTaskDoc
): CustomerFacingTaskState => {
  if (!task) return CustomerFacingTaskState.UNKNOWN;
  switch (task.state) {
    case infinitusai.be.TaskState.TASK_STATE_CREATED: {
      if (isCustomerTaskDoc(task) && task.operatorTaskUuids.length > 1) {
        // the task is the 2nd task in a chained task
        return CustomerFacingTaskState.IN_PROGRESS;
      }
      return CustomerFacingTaskState.IN_QUEUE;
    }
    case infinitusai.be.TaskState.TASK_STATE_READY_FOR_SELF_REVIEW:
    case infinitusai.be.TaskState.TASK_STATE_CALL_DISCONNECTED:
    case infinitusai.be.TaskState.TASK_STATE_CALL_CONNECTED:
    case infinitusai.be.TaskState.TASK_STATE_READY_FOR_REVIEW:
    case infinitusai.be.TaskState.TASK_STATE_IN_REVIEW:
    case infinitusai.be.TaskState.TASK_STATE_IN_PROGRESS:
    case infinitusai.be.TaskState.TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL:
    case infinitusai.be.TaskState.TASK_STATE_PENDING_AUTO_REQUEUE:
      return CustomerFacingTaskState.IN_PROGRESS;
    case infinitusai.be.TaskState.TASK_STATE_REVIEWED:
      return CustomerFacingTaskState.SUCCESSFUL;
    case infinitusai.be.TaskState.TASK_STATE_PARTIAL_SUCCESS:
      return CustomerFacingTaskState.PARTIAL;
    case infinitusai.be.TaskState.TASK_STATE_FAILED:
      // We now wish to determine the reason for the failure as well
      switch (task.failureReasonChosen?.type) {
        case infinitusai.be.TaskFailReason.Type.REASON_BAD_DATA:
        case infinitusai.be.TaskFailReason.Type.REASON_MISSING_DATA:
          return CustomerFacingTaskState.BAD_DATA_INPUT;
      }
      return CustomerFacingTaskState.CALL_FAILED;
    default:
      return CustomerFacingTaskState.UNKNOWN;
  }
};

export const getCustomerFacingTaskStateCustomer = (
  task?: infinitusai.be.CustomerTaskDoc
): CustomerFacingTaskState => {
  if (!task) return CustomerFacingTaskState.UNKNOWN;
  const state = getProtoTaskState(task.state);
  switch (state) {
    case infinitusai.be.TaskState.TASK_STATE_CREATED: {
      if (isCustomerTaskDoc(task) && task.operatorTaskUuids.length > 1) {
        // the task is the 2nd task in a chained task
        return CustomerFacingTaskState.IN_PROGRESS;
      }
      return CustomerFacingTaskState.IN_QUEUE;
    }
    case infinitusai.be.TaskState.TASK_STATE_READY_FOR_SELF_REVIEW:
    case infinitusai.be.TaskState.TASK_STATE_CALL_DISCONNECTED:
    case infinitusai.be.TaskState.TASK_STATE_CALL_CONNECTED:
    case infinitusai.be.TaskState.TASK_STATE_READY_FOR_REVIEW:
    case infinitusai.be.TaskState.TASK_STATE_IN_REVIEW:
    case infinitusai.be.TaskState.TASK_STATE_IN_PROGRESS:
    case infinitusai.be.TaskState.TASK_STATE_PENDING_AUTO_REQUEUE:
    case infinitusai.be.TaskState.TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL:
      return CustomerFacingTaskState.IN_PROGRESS;
    case infinitusai.be.TaskState.TASK_STATE_REVIEWED:
      return CustomerFacingTaskState.COMPLETED;
    case infinitusai.be.TaskState.TASK_STATE_PARTIAL_SUCCESS:
      return CustomerFacingTaskState.PARTIAL;
    case infinitusai.be.TaskState.TASK_STATE_FAILED:
      // We now wish to determine the reason for the failure as well
      const taskFailReason = getProtoTaskFailReason(
        task.failureReasonChosen?.type || infinitusai.be.TaskFailReason.Type.REASON_UNKNOWN
      );
      switch (taskFailReason) {
        case infinitusai.be.TaskFailReason.Type.REASON_BAD_DATA:
        case infinitusai.be.TaskFailReason.Type.REASON_MISSING_DATA:
        case infinitusai.be.TaskFailReason.Type.REASON_INVALID_PLAN:
          return CustomerFacingTaskState.BAD_DATA_INPUT;
        case infinitusai.be.TaskFailReason.Type.REASON_CUSTOMER_CANCELED:
          return CustomerFacingTaskState.CANCELLED;
      }
      return CustomerFacingTaskState.CALL_FAILED;
    default:
      return CustomerFacingTaskState.UNKNOWN;
  }
};

export const getOrgName = (location: string) => {
  return location.split('/')[1];
};

export const getTaskFailureReason = (failureReasonType: infinitusai.be.TaskFailReason.Type) => {
  switch (failureReasonType) {
    case infinitusai.be.TaskFailReason.Type.REASON_CUSTOMER_CANCELED:
      return 'Task cancelled';
    case infinitusai.be.TaskFailReason.Type.REASON_BAD_DATA:
      return 'Bad data was provided';
    case infinitusai.be.TaskFailReason.Type.REASON_MISSING_DATA:
      return 'Data was missing';
    case infinitusai.be.TaskFailReason.Type.REASON_INVALID_PLAN:
      return 'The plan is not valid';
    case infinitusai.be.TaskFailReason.Type.REASON_PAYER_UNREACHABLE:
      return 'Payer not reachable';
    case infinitusai.be.TaskFailReason.Type.REASON_PAYER_REFUSES_THIRD_PARTY:
      return 'Payer refused to provide benefits to third party';
    case infinitusai.be.TaskFailReason.Type.REASON_PAYER_REFUSES_DA:
      return 'Payer refused to talk to digital assistant';
    case infinitusai.be.TaskFailReason.Type.REASON_PAST_DUE:
      return 'Task is past due';
    case infinitusai.be.TaskFailReason.Type.REASON_TASK_EXPIRED:
      return 'Task is expired';
    case infinitusai.be.TaskFailReason.Type.REASON_IVR_NAVIGATION_FAILURE:
      return 'IVR Navigation Failure';
    case infinitusai.be.TaskFailReason.Type.REASON_HOLD_NAVIGATION_FAILURE:
      return 'Hold Navigation Failure';
    case infinitusai.be.TaskFailReason.Type.REASON_PRESCRIBER_UNREACHABLE:
      return 'Prescriber is not reachable';
    case infinitusai.be.TaskFailReason.Type.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD:
      return 'Need to call the number on the back of the ID card';
    case infinitusai.be.TaskFailReason.Type.REASON_AGENT_AUTOMATION_HANGUP:
      return 'Agent Automation Hangup';
    case infinitusai.be.TaskFailReason.Type.REASON_OTHER:
      return 'Other';
    default:
      return 'Unknown';
  }
};

export const getSortedTaskOutputsResults = (
  customerTask: infinitusai.be.CustomerTaskDoc,
  taskResults?: infinitusapi.GetTaskResultsResponse.IDataMessage[]
) => {
  if (customerTask.chainingRequired) {
    taskResults?.sort((result1, result2) => {
      return (
        new Date(result1.taskCreationDate!).getTime() -
        new Date(result2.taskCreationDate!).getTime()
      );
    });
  }

  return taskResults;
};
