import { ErrorMessage, Org } from '@infinitusai/shared';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import uniqBy from 'lodash/uniqBy';
import { useSearchParams } from 'react-router-dom';

import { infinitusai, infinitusapi } from 'proto/pbjs';
import { getTaskTypeDisplayNamePortal } from 'utils/displayNames';

import FlexibleTaskInputsDisplay from './FlexibleTaskInputsDisplay';
import InputsPanel from './InputsPanel';
import { getTaskInputsMap } from './constants';

interface Props {
  tasks: infinitusai.be.TaskDoc[];
  org: Org | null;
  flexibleTaskInputs: infinitusapi.IINFTaskInput[];
}

function TaskInputsTab({ tasks, org, flexibleTaskInputs }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const show = searchParams.get('show') || '0';

  const flexibleTaskInput = flexibleTaskInputs[Number(show)];
  const task = tasks[Number(show)];
  const taskInputs: any = {
    ...task?.bvInputs,
    customerAssignedId: task?.customerAssignedId,
  };
  const flexibleTaskInputMap = getTaskInputsMap(task.taskType);
  return (
    <>
      {tasks.length > 1 && (
        <Box>
          <ToggleButtonGroup
            exclusive
            aria-label="show"
            value={show}
            onChange={(_, newValue) => {
              if (newValue) {
                searchParams.set('show', String(newValue));
                setSearchParams(searchParams, { replace: true });
              }
            }}
            sx={{ mt: 2 }}
          >
            {uniqBy(tasks, 'taskType').map((task, index) => {
              const taskType = getTaskTypeDisplayNamePortal(task.taskType);
              return (
                <ToggleButton value={String(index)} key={index}>
                  {taskType}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Box>
      )}

      <Grid container spacing={2}>
        {flexibleTaskInput.inputs?.length !== 0 ? (
          <FlexibleTaskInputsDisplay
            fti={flexibleTaskInput.inputs as infinitusapi.IINFInputValue[]}
            map={flexibleTaskInputMap}
          />
        ) : taskInputs ? (
          <InputsPanel taskInputs={taskInputs} />
        ) : (
          <ErrorMessage
            title="No Task Inputs Found..."
            description="No task inputs were found for this task."
          />
        )}
      </Grid>
    </>
  );
}

export default TaskInputsTab;
