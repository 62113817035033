import { useAuth, PermissionName } from '@infinitusai/auth';
import { useCustomerPortalFeatureEnabled, CustomerPortalFeature } from '@infinitusai/shared';
import { Button, useBreakpoint } from '@infinitusai/ui';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { subDays, startOfDay, endOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';

import { useGenerateReportMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { TASK_TYPE_CHOICES_FOR_CSV_REPORT } from 'utils/taskTypes';

export interface GenerateReportBoxProps {
  programs: infinitusai.tasks.OrgProgram[];
}

const GenerateReportBox = ({ programs }: GenerateReportBoxProps) => {
  const auth = useAuth();
  const smBreakpoint = useBreakpoint('sm');
  const lgBreakpoint = useBreakpoint('lg');
  const { enqueueSnackbar } = useSnackbar();
  const generateReportMutation = useGenerateReportMutation();
  const readableCSVReportEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.HUMAN_READABLE_CSV_REPORT
  );

  const excludeUnusedApiOutputsEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.CSV_REPORT_EXCLUDE_UNUSED_API_OUTPUTS
  );

  return (
    <div>
      <Formik
        initialValues={{
          startTime: subDays(startOfDay(new Date()), 1),
          endTime: endOfDay(new Date()),
          publish: false,
          programId: '',
          isHumanReadable: false,
          excludeUnusedApiOutputs: false,
          taskType: infinitusai.tasks.TaskType.TASK_TYPE_UNKNOWN,
        }}
        onSubmit={async (values, actions) => {
          const startTime = values.startTime.getTime();
          const endTime = values.endTime.getTime();
          const body = infinitusai.tasks.GenerateReportRequest.fromObject({
            publish: true,
            isHumanReadable: values.isHumanReadable,
            excludeUnusedApiOutputs: values.excludeUnusedApiOutputs,
            query: {
              programId: values.programId,
              reviewedMillisStart: startTime,
              reviewedMillisEnd: endTime,
              taskType: values.taskType,
            },
          });

          generateReportMutation.mutate(body, {
            onSuccess: () => {
              enqueueSnackbar(`Report will be available to download when ready.`, {
                variant: 'success',
              });
              actions.setSubmitting(false);
            },
            onError: (error) => {
              enqueueSnackbar(`Failed to generate Report: ${error.message}`, {
                variant: 'error',
              });
              actions.setSubmitting(false);
            },
          });
        }}
      >
        {({ submitForm, values, isSubmitting, setFieldValue }) => (
          <Form>
            <Box display="flex" flexDirection={'column'} gap={2}>
              <Stack direction="column" gap={2}>
                <Typography variant="h6">1. Select a Range</Typography>
                <Stack direction={smBreakpoint ? 'row' : 'column'} gap={2}>
                  <DateTimePicker
                    label="Start time"
                    disabled={isSubmitting}
                    slotProps={{ textField: { size: 'small' } }}
                    orientation="portrait"
                    value={values.startTime}
                    onChange={(value, data) => {
                      if (value !== null) {
                        setFieldValue('startTime', value);
                      }
                    }}
                  />
                  <DateTimePicker
                    label="End time"
                    disabled={isSubmitting}
                    slotProps={{ textField: { size: 'small' } }}
                    orientation="portrait"
                    value={values.endTime}
                    onChange={(value, date) => {
                      if (value !== null) {
                        setFieldValue('endTime', value);
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack direction="column" gap={2}>
                <Typography variant="h6">2. Select a Task Type</Typography>
                <Stack direction={lgBreakpoint ? 'row' : 'column'} gap={2}>
                  <FormControl sx={{ width: smBreakpoint ? '300px' : '100%' }}>
                    <InputLabel>Task Type</InputLabel>
                    <Select
                      labelId="label-for-taskType"
                      label="tasktype"
                      id="taskType-select"
                      value={values.taskType || 'All'}
                      displayEmpty
                      onChange={(event) => {
                        setFieldValue('taskType', event.target.value);
                      }}
                      size="small"
                    >
                      <MenuItem key="All" value="All">
                        All
                      </MenuItem>
                      {TASK_TYPE_CHOICES_FOR_CSV_REPORT.map((task) => (
                        <MenuItem key={task.value} value={task.value}>
                          {task.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
              <Stack direction="column" gap={2}>
                <Typography variant="h6">3. Select a Program</Typography>
                <Stack direction={lgBreakpoint ? 'row' : 'column'} gap={2}>
                  <FormControl sx={{ width: smBreakpoint ? '300px' : '100%' }}>
                    <InputLabel>Program</InputLabel>
                    <Select
                      labelId="label-for-program"
                      label="program"
                      id="program-select"
                      value={values.programId || 'All'}
                      displayEmpty
                      onChange={(event) => {
                        setFieldValue('programId', event.target.value);
                      }}
                      size="small"
                    >
                      <MenuItem key="All" value="All">
                        All
                      </MenuItem>
                      {programs.map((program) => (
                        <MenuItem key={program.name} value={program.name}>
                          {program.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {readableCSVReportEnabled ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="medium"
                          checked={values.isHumanReadable}
                          color="primary"
                          name="isHumanReadable"
                          onChange={(e) => setFieldValue('isHumanReadable', e.target.checked)}
                        />
                      }
                      sx={{ whiteSpace: 'nowrap' }}
                      label="Human Readable CSV"
                    />
                  ) : null}

                  {excludeUnusedApiOutputsEnabled ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="medium"
                          checked={values.excludeUnusedApiOutputs}
                          color="primary"
                          name="excludeUnusedApiOutputs"
                          onChange={(e) =>
                            setFieldValue('excludeUnusedApiOutputs', e.target.checked)
                          }
                        />
                      }
                      sx={{ whiteSpace: 'nowrap' }}
                      label="Exclude Unused API Outputs"
                    />
                  ) : null}

                  <Button
                    sx={{ width: smBreakpoint ? '300px' : '100%' }}
                    onClick={submitForm}
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    endIcon={<CloudDownloadRoundedIcon />}
                    unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_TASK_REPORTS_WRITE])}
                  >
                    Generate CSV Report
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(GenerateReportBox);
