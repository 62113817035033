import { eulaHtml } from '@infinitusai/shared';
import { Container, Box } from '@mui/material';
import Typography from '@mui/material/Typography';

function EulaPage() {
  return (
    <Container maxWidth="lg" sx={{ pt: 2 }}>
      <Box
        sx={{
          pt: 2,
          pb: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h4" component="h4" sx={{ textAlign: 'center' }}>
          Infinitus FastTrack™ <br />
          End User License Agreement
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          mb: 2,
          overflow: 'auto',
          padding: '8px',
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: eulaHtml }} />
      </Box>
    </Container>
  );
}
export default EulaPage;
