import { infinitusai } from 'proto/pbjs';
import {
  getTaskTypeDisplayName,
  getTaskTypeDisplayNamePortal,
  getTaskTypeSearchParamDisplayName,
} from 'utils/displayNames';

export const ALL_TASK_TYPES = Object.keys(infinitusai.be.TaskType).map((key) => {
  return infinitusai.be.TaskType[key as keyof typeof infinitusai.be.TaskType];
});

const TASK_TYPE_DICT: Record<string, TaskTypeKey> = {};
Object.entries(infinitusai.tasks.TaskType).forEach(([key, value]) => {
  TASK_TYPE_DICT[value] = key as TaskTypeKey;
});

export type TaskTypeKey = keyof typeof infinitusai.tasks.TaskType;
export const TASK_TYPE_CHOICES_FOR_CSV_REPORT = [
  infinitusai.tasks.TaskType.TASK_TYPE_PBM_BV,
  infinitusai.tasks.TaskType.TASK_TYPE_FULL_BI,
  infinitusai.tasks.TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER,
  infinitusai.tasks.TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS,
  infinitusai.tasks.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP,
  infinitusai.tasks.TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP,
  infinitusai.tasks.TaskType.TASK_TYPE_PATIENT_OUTREACH,
  infinitusai.tasks.TaskType.TASK_TYPE_PROVIDER_OUTREACH,
  infinitusai.tasks.TaskType.TASK_TYPE_PBM_DISCOVERY,
].map((taskItem: infinitusai.tasks.TaskType) => {
  return {
    label: getTaskTypeDisplayNamePortal(taskItem),
    value: TASK_TYPE_DICT[taskItem],
  };
});

const INF_TASK_TYPE_DICT: Record<string, InfTaskTypeKey> = {};
Object.entries(infinitusai.tasks.INFTaskType).forEach(([key, value]) => {
  INF_TASK_TYPE_DICT[value] = key as InfTaskTypeKey;
});

export type InfTaskTypeKey = keyof typeof infinitusai.tasks.INFTaskType;
export const TASK_TYPES_FOR_IMPORT_TASK = [
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_SHIPMENT_FOLLOWUP,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BASIC_BV,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_PBM,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_MM,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL,
  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PBM_DISCOVERY,
].map((taskItem: infinitusai.tasks.INFTaskType) => {
  return {
    label: getTaskTypeDisplayName(taskItem),
    value: INF_TASK_TYPE_DICT[taskItem],
    specSearchParam: getTaskTypeSearchParamDisplayName(taskItem),
  };
});
