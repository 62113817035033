import { useAuth, PermissionName } from '@infinitusai/auth';
import { useAppState, Tabs } from '@infinitusai/shared';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import CompanyProfileTab from './CompanyProfileTab';
import ProgramIDsTab from './ProgramIDsTab';
import SecurityTab from './SecurityTab';

function OrgSettingsPage() {
  const auth = useAuth();
  const appState = useAppState();

  if (!appState.org) return null;

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Company Profile & Settings
      </Typography>
      <Tabs
        sx={{ mb: 1 }}
        id="settings"
        syncUrl
        variant="scrollable"
        tabs={[
          {
            value: 'company_profile',
            label: 'Company Profile',
            content: <CompanyProfileTab org={appState.org} />,
            unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_READ]),
          },
          {
            value: 'security',
            label: 'Security',
            content: <SecurityTab org={appState.org} />,
            unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_READ]),
          },
          {
            value: 'program_ids',
            label: 'Program IDs',
            content: <ProgramIDsTab org={appState.org} />,
            unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_SETTINGS_READ]),
          },
        ]}
      />
    </React.Fragment>
  );
}

export default OrgSettingsPage;
