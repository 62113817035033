import { ErrorType } from '@infinitusai/api';
import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  ErrorMessage,
  PageHeader,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
  Tabs,
} from '@infinitusai/shared';
import Box from '@mui/material/Box';
import * as React from 'react';

import CreateTaskTab from './CreateTaskTab';
import ImportHistoryTab from './ImportHistoryTab';
import ImportTasksTab from './ImportTasksTab';

function CreateTasksPage() {
  const auth = useAuth();
  const createTaskEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.CREATE_TASK);
  const hasCreateTaskPermission = auth.hasPermission([PermissionName.CUSTOMER_TASKS_WRITE]);
  const csvImportEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.IMPORT_TASKS_CSV);

  const hasTasksImportReadPermission = auth.hasPermission([
    PermissionName.CUSTOMER_TASK_IMPORTS_READ,
  ]);

  const hasTasksImportWritePermission = auth.hasPermission([
    PermissionName.CUSTOMER_TASK_IMPORTS_WRITE,
  ]);

  const hasSomeTaskPermissions =
    hasTasksImportReadPermission || hasTasksImportWritePermission || hasCreateTaskPermission;
  return (
    <React.Fragment>
      {hasSomeTaskPermissions && (csvImportEnabled || createTaskEnabled) ? (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            p: '1rem',
            flexDirection: 'column',
          }}
        >
          <PageHeader title="Create Tasks" variant="h5" />

          <React.Fragment>
            <Tabs
              sx={{ mb: 1 }}
              id="import-tasks"
              syncUrl
              tabs={[
                {
                  value: 'import-tasks',
                  label: 'Import Tasks',
                  content: <ImportTasksTab />,
                  unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_WRITE]),
                  hidden: !csvImportEnabled || !hasTasksImportWritePermission,
                },
                {
                  value: 'import-history',
                  label: 'Import History',
                  content: <ImportHistoryTab />,
                  unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_READ]),
                  hidden: !csvImportEnabled || !hasTasksImportWritePermission,
                },
                {
                  value: 'create-task',
                  label: 'Create Task',
                  content: <CreateTaskTab />,
                  unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_TASKS_WRITE]),
                  hidden: !createTaskEnabled || !hasCreateTaskPermission,
                },
              ]}
            />
          </React.Fragment>
        </Box>
      ) : (
        <ErrorMessage errorType={ErrorType.UNAUTHORIZED} />
      )}
    </React.Fragment>
  );
}
export default CreateTasksPage;
