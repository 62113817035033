import { useCustomerPortalFeatureEnabled, CustomerPortalFeature } from '@infinitusai/shared';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import get from 'lodash/get';
import React from 'react';

import DataField from 'components/DataField';
import { infinitusai } from 'proto/pbjs';
import { getFacilityTypeDisplayName, getProductDaysSupplyDisplay } from 'utils/displayNames';

import DetailsAccordion from './DetailsAccordion';
import { bvTaskInputsMap, BvTaskInputsMapKeys, getReversedBoolVal } from './constants';

const TypedObjectEntries = Object.entries as <T>(o: T) => [Extract<keyof T, string>, T[keyof T]][];

const HIDDEN_PHI_SECTIONS: BvTaskInputsMapKeys[] = [
  // Note that BE has a logic to strip out PHI information. FE also has the logic to only show
  // the following sections if EXPOSE_PHI feature flag is enabled.
  'Patient Information',
  'Policy Holder Information',
];

interface Props {
  taskInputs: any;
}
function InputsPanel({ taskInputs }: Props) {
  const phiEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.EXPOSE_PHI);
  const numberOfProducts = taskInputs.productInfos?.length;

  const bvTaskMapping = TypedObjectEntries(bvTaskInputsMap).filter(([sectionName, _fields]) => {
    if (sectionName === 'Sites of Care Information' && taskInputs.sitesOfCare?.length === 0) {
      return false;
    }
    if (!phiEnabled) {
      return !HIDDEN_PHI_SECTIONS.includes(sectionName);
    }
    return true;
  });

  const accordions = [...bvTaskMapping.map(([sectionName, _fields]) => sectionName)];

  const [expanded, setExpanded] = React.useState<Set<string>>(new Set([accordions[0]]));

  const handleExpandAll = () => {
    setExpanded(new Set(accordions));
  };

  const handleCollapseAll = () => {
    setExpanded(new Set());
  };

  const nullableStringToString = (
    nullableString?: infinitusai.be.INullableString | null
  ): string => {
    if (nullableString && nullableString.value) return nullableString.value;
    return '';
  };

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          {expanded.size === Object.keys(accordions).length ? (
            <Button color="primary" onClick={handleCollapseAll}>
              Collapse All
            </Button>
          ) : (
            <Button color="primary" onClick={handleExpandAll}>
              Expand All
            </Button>
          )}
        </Box>
        {bvTaskMapping?.map(([sectionHeader, fields]) =>
          sectionHeader === 'Product Information' && taskInputs && taskInputs.productInfos ? (
            <DetailsAccordion
              key={sectionHeader}
              displayName={sectionHeader}
              numberOfEntities={numberOfProducts * Object.keys(fields).length}
              setExpanded={setExpanded}
              expanded={expanded}
              data={taskInputs.productInfos.map((productInfo: any, index: string) => (
                <React.Fragment key={productInfo.productCode}>
                  {TypedObjectEntries(fields).map(([fieldPath, fieldConfig]) => {
                    let value = productInfo[fieldPath].toString() || '';
                    if ((fieldConfig as any).label === 'Product Days Supply') {
                      value = getProductDaysSupplyDisplay(value);
                    }

                    return (
                      <Grid item md={3} key={`${(fieldConfig as any)?.label} #${index + 1}`}>
                        <DataField
                          label={`${(fieldConfig as any)?.label} #${index + 1}`}
                          value={value}
                          whiteSpace="normal"
                        />
                      </Grid>
                    );
                  })}
                </React.Fragment>
              ))}
            />
          ) : sectionHeader === 'Sites of Care Information' &&
            taskInputs &&
            taskInputs.sitesOfCare?.length !== 0 ? (
            <DetailsAccordion
              key="sitesOfCare"
              displayName="Sites of Care Information"
              setExpanded={setExpanded}
              expanded={expanded}
              data={taskInputs.sitesOfCare?.map((siteOfCare: any, index: number) => {
                return (
                  <React.Fragment key={`siteOfCare-${index}`}>
                    <Grid container px={2} mt={3} columnSpacing={{ xs: 4, md: 12 }}>
                      <Grid item md={3}>
                        <DataField
                          label={`Site Of Care Name #${index + 1}`}
                          value={siteOfCare.name}
                          whiteSpace="normal"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DataField
                          label={`Site Of Care NPI #${index + 1}`}
                          value={siteOfCare.npi}
                          whiteSpace="normal"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DataField
                          label={`Site Of Care Tax ID #${index + 1}`}
                          value={siteOfCare.taxId}
                          whiteSpace="normal"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DataField
                          label={`Site Of Care Street Address #${index + 1}`}
                          value={siteOfCare.address?.streetAddress}
                          whiteSpace="normal"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DataField
                          label={`Site Of Care Street Address Line 2 #${index + 1}`}
                          value={siteOfCare.address?.streetAddressLine2}
                          whiteSpace="normal"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DataField
                          label={`Site Of Care City #${index + 1}`}
                          value={siteOfCare.address?.city}
                          whiteSpace="normal"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DataField
                          label={`Site Of Care Zip #${index + 1}`}
                          value={siteOfCare.address?.zip}
                          whiteSpace="normal"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DataField
                          label={`Site Of Care State #${index + 1}`}
                          value={siteOfCare.address?.state}
                          whiteSpace="normal"
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })}
            />
          ) : (
            <Grid key={sectionHeader}>
              <DetailsAccordion
                displayName={sectionHeader}
                numberOfEntities={TypedObjectEntries(fields).length}
                setExpanded={setExpanded}
                expanded={expanded}
                data={TypedObjectEntries(fields).map(([fieldPath, fieldConfig]) => {
                  let value = get(taskInputs, fieldPath) ?? '-';
                  let field: any = fieldConfig;

                  if (Array.isArray(value)) value = value.join(', ');
                  if (
                    [
                      'policyInfo.groupNumber',
                      'policyInfo.planName',
                      'policyInfo.groupName',
                    ].includes(fieldPath)
                  ) {
                    value = nullableStringToString(value);
                  }
                  if (fieldPath === 'treatmentInfo.facilityType.type') {
                    value = getFacilityTypeDisplayName(value);
                  }
                  if (fieldPath === 'treatmentInfo.skipSpecialtyPharmacyInfo') {
                    value = getReversedBoolVal(value);
                  }

                  if (
                    fieldPath === 'treatmentInfo.anticipatedDateOfTreatment' ||
                    fieldPath === 'patientInfo.birthday' ||
                    fieldPath === 'policyHolderInfo.dateOfBirth'
                  ) {
                    if (value.day) {
                      value = `${value.month}/${value.day}/${value.year}`;
                    }
                  }

                  return (
                    <Grid
                      item
                      xs={TypedObjectEntries(fields).length === 1 ? 12 : 3}
                      key={field.label}
                    >
                      <DataField label={field.label} value={value} />
                    </Grid>
                  );
                })}
              />
            </Grid>
          )
        )}
      </Grid>
    </>
  );
}
export default InputsPanel;
