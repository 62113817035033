import { Toolbar } from '@infinitusai/table';
import WebhookIcon from '@mui/icons-material/Webhook';
import { Divider, IconButton, Switch, Tooltip } from '@mui/material';
import { Table as TableInterface } from '@tanstack/react-table';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

import { useGetWebhooks, useResendWebhookNotifications } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { processResendWebhookResponse } from 'utils/webhook';

export function CustomToolbar({ table }: { table: TableInterface<any> }) {
  return <Toolbar table={table} searchPlaceholder="Search Customer ID" />;
}

export function CustomToolbarWithResendWebhook({
  table,
  setShowOptions,
  showOptions,
  onSuccess,
}: {
  table: TableInterface<any>;
  setShowOptions: React.Dispatch<React.SetStateAction<boolean>>;
  showOptions: boolean;
  onSuccess: () => void;
}) {
  const rowsAreSelected = table.getIsAllRowsSelected() || table.getIsSomeRowsSelected();
  const selectedRows = table.getSelectedRowModel().flatRows;

  const getWebhooks = useGetWebhooks();
  const resendWebhookNotifications = useResendWebhookNotifications();

  const handleBulkResendWebhookNotifications = async () => {
    const reqBody = infinitusai.be.ResendWebhookNotificationsRequest.fromObject({
      taskIds: selectedRows.map((row) => row.original.taskUuid),
    });

    resendWebhookNotifications.mutate(reqBody, {
      onSuccess: (data) => {
        const result = processResendWebhookResponse(data);
        enqueueSnackbar(result.message, { variant: result.variant });
        onSuccess();
      },
      onError: () => {
        enqueueSnackbar(`Webhook Failed To Deliver`, { variant: 'error' });
      },
    });
  };

  const handleShowOptions = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };

  return (
    <Toolbar table={table} searchPlaceholder="Search Customer ID">
      <React.Fragment>
        <Tooltip title="Manage Tasks">
          <Switch onChange={handleShowOptions} disabled={getWebhooks.data.length <= 0} />
        </Tooltip>
        {getWebhooks.data.length > 0 && rowsAreSelected && showOptions && (
          <Tooltip title="Resend Webhook Notifications">
            <IconButton onClick={handleBulkResendWebhookNotifications}>
              <WebhookIcon />
            </IconButton>
          </Tooltip>
        )}
        <Divider orientation="vertical" variant="middle" flexItem />
      </React.Fragment>
    </Toolbar>
  );
}
