import { useUserIncidentsQueryClient } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { TaskImportCache } from 'utils/localStorage';

const TASK_IMPORT_FEEDBACK_THRESHOLD_COUNT = 3; // 3 times in a single session
const TASK_IMPORT_FEEDBACK_THRESHOLD_MS = 1000 * 60 * 60 * 24 * 14; // 14 days

export async function shouldTriggerFeedbackDialog(
  userIncidentsQueryClient: ReturnType<typeof useUserIncidentsQueryClient>,
  incidentTypeUUID: string,
  nowInMs: number,
  taskImportCache: TaskImportCache
): Promise<boolean> {
  try {
    // Check the number of success/failed counts in a single session
    if (
      taskImportCache.failedCount < TASK_IMPORT_FEEDBACK_THRESHOLD_COUNT &&
      taskImportCache.successCount < TASK_IMPORT_FEEDBACK_THRESHOLD_COUNT &&
      taskImportCache.errorCount < TASK_IMPORT_FEEDBACK_THRESHOLD_COUNT
    ) {
      return false;
    }

    // Check if the user has submitted a feedback recently
    const reqBody = infinitusai.be.GetUserIncidentsRequest.fromObject({
      page: 1,
      pageSize: 1,
      orderByCol: 'create_time', // Note that this needs to match db column name
      orderByDir: 'DESC',
      filter: {
        incidentTypeUUID: incidentTypeUUID,
      },
    });

    const userIncidentRes = await userIncidentsQueryClient(reqBody);
    if (userIncidentRes.incidents.length === 0) {
      return true;
    }
    const mostRecentIncidentCreateTime = userIncidentRes.incidents[0].createTime;
    if (!mostRecentIncidentCreateTime) {
      return false;
    }
    return nowInMs - Number(mostRecentIncidentCreateTime) > TASK_IMPORT_FEEDBACK_THRESHOLD_MS;
  } catch (err) {
    console.error('Error in shouldTriggerFeedbackDialog', err);
    return false;
  }
}

export function getTaskUploadFeedbackType(
  incidentTypes: infinitusai.be.IncidentType[]
): infinitusai.be.IncidentType | undefined {
  // This incident type needs to be pre-defined at
  // https://portal-dev.infinitusai.dev/admin/incidentConfig?sort_dir=asc&sort=category&search=Customer+Portal
  const CSV_TASK_UPLOAD_FEEDBACK = 'CSV Task Upload Feedback';
  return incidentTypes.find((incidentType) => {
    return incidentType.subcategory === CSV_TASK_UPLOAD_FEEDBACK;
  });
}

export function getCreateIncidentFormData(
  feedbackType: infinitusai.be.IncidentType,
  description: string,
  score: string
): FormData {
  // Reference:
  // https://github.com/infinitusai/frontend/blob/850a31e30aa7f3d623f739570356593d7fe27300/packages/customer/src/layout/FeedbackDrawer.tsx#L117-L145

  // Create a new FormData object to store form data to be uploaded
  const formData = new FormData();

  // For now we can leave payerID as an empty string
  formData.append('payerID', '');
  // Leaving orgRole as blank given customer portal is now on RBAC
  formData.append('orgRole', '');
  // Add the feedback type to the form data
  formData.append('incidentTypeUUID', feedbackType.id);
  // Add the description to the form data
  formData.append('description', description);
  // Add the environment to the form data, with value taken from environment variable
  formData.append('environment', process.env.REACT_APP_BUILD_ENV as string);
  // Add the current page URL to the form data
  formData.append('pageURL', window.location.href);

  // Info needed for feedback emails for CASH team and Salesforce support
  formData.append('feedbackTypeCategory', feedbackType.category);
  formData.append('feedbackTypeSubcategory', feedbackType.subcategory);
  formData.append('feedbackTypePriority', feedbackType.priority);

  // formData only take string or Blob. Convert to int in BE
  formData.append('score', score);

  return formData;
}
