import { useAuth } from '@infinitusai/auth';
import { TextField, useBreakpoint } from '@infinitusai/ui';
import { getInitialsFromName } from '@infinitusai/utils';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const PublicProfileTab = () => {
  const auth = useAuth();
  const smBreakpoint = useBreakpoint('sm');
  const mdBreakpoint = useBreakpoint('md');
  const lgBreakpoint = useBreakpoint('lg');

  return (
    <>
      <Stack direction={lgBreakpoint ? 'row' : 'column'} gap={lgBreakpoint ? 3 : 1}>
        <Stack gap={1}>
          <Typography variant="h6">Name</Typography>
          <TextField
            disabled
            key="search"
            value={auth.user?.displayName || ''}
            helperText="This will be displayed for all your internal members"
            sx={{ minWidth: smBreakpoint ? '450px' : '100%' }}
            size="small"
          />
        </Stack>
        <Stack gap={1}>
          <Typography variant="h6">Email</Typography>
          <TextField
            disabled
            key="search"
            value={auth.user?.email || ''}
            sx={{ minWidth: smBreakpoint ? '450px' : '100%' }}
            helperText="Email notifications (such as items that need your review) will be sent here."
            size="small"
          />
        </Stack>
      </Stack>
      <Stack gap={1} mt={lgBreakpoint ? 3 : 1}>
        <Typography variant="h6">Custom Profile Photo</Typography>
        <TextField
          disabled
          key="search"
          value={auth.user?.photoURL || ''}
          helperText="This will be displayed for all your internal members"
          sx={{
            width: mdBreakpoint ? '900px' : '100%',
          }}
          size="small"
        />

        <Avatar
          sx={{ width: 100, height: 100, mt: 2, fontSize: '42px' }}
          {...getInitialsFromName(auth.user?.displayName || auth.user?.email || '')}
        />
      </Stack>
    </>
  );
};

export default PublicProfileTab;
