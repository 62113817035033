import { FadeTransition, useBreakpoint } from '@infinitusai/ui';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link } from 'react-router-dom';

import { useMarkAnnouncementViewed } from 'api/customer';

/**
 * Props interface for the AnnouncementsDialog component.
 */
interface AnnouncementsDialogProps {
  announcement: any;
  steps: any[];
  links?: any[];
  open: boolean;
}

/**
 * AnnouncementsDialog component. This component is used to render a announcements dialog.
 */
function AnnouncementsDialog({ announcement, steps, links, open }: AnnouncementsDialogProps) {
  const smBreakpoint = useBreakpoint('sm');
  const { enqueueSnackbar } = useSnackbar();
  const markAnnouncementViewed = useMarkAnnouncementViewed();
  const [openDialog, setOpenDialog] = React.useState(false || open);
  const [activeStep, setActiveStep] = React.useState(0);

  const announcementSteps = links
    ? [
        ...steps,
        links && {
          links: links,
        },
      ]
    : steps;

  const maxSteps = announcementSteps.length;
  const onLastStep = activeStep === maxSteps - 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDismissAnnouncement = () => {
    handleCloseDialog();
    const req = {
      uuid: announcement.uuid,
    };
    markAnnouncementViewed.mutate(req, {
      onError: (error) => {
        enqueueSnackbar(`Failed to generate token: ${error.message}.`, {
          variant: 'error',
        });
      },
    });
  };

  if (announcementSteps.length === 0) return null;

  return (
    <Dialog
      fullWidth
      BackdropProps={{
        style: { backdropFilter: 'blur(5px)' },
      }}
      open={openDialog}
      onClose={onLastStep ? handleDismissAnnouncement : handleCloseDialog}
      TransitionComponent={FadeTransition}
    >
      <Box maxHeight={smBreakpoint ? '450px' : '80%'}>
        {links && !announcementSteps[activeStep].src ? (
          <>
            {announcementSteps[activeStep].links?.map((link: any) => (
              <Box key={link} display="flex" flexDirection="column">
                <DialogTitle variant="h5" id="confirm-dialog-title" display="flex">
                  {link.name}
                </DialogTitle>
                <DialogContent>
                  <Typography variant="body1" color="textSecondary" mt={0.5}>
                    {link.description}
                  </Typography>
                  <Box mt={1.5}>
                    <Button
                      variant="outlined"
                      target="_blank"
                      component={Link}
                      to={link.url}
                      endIcon={<ArrowForwardRoundedIcon />}
                    >
                      {link.linkButtonText}
                    </Button>
                  </Box>
                </DialogContent>
              </Box>
            ))}
          </>
        ) : (
          <>
            {announcementSteps[activeStep].src ? (
              <Box
                sx={{
                  height: smBreakpoint ? '275px' : '175px',
                  objectFit: smBreakpoint ? 'cover' : 'contain',
                  objectPosition: 'top',
                  width: '100%',
                  borderRadius: 8,
                }}
                component="img"
                borderRadius="8px"
                src={announcementSteps[activeStep].src}
                p={3}
              />
            ) : null}
            <DialogTitle
              variant="h5"
              id="confirm-dialog-title"
              display="flex"
              justifyContent="center"
              textAlign="center"
            >
              {announcementSteps[activeStep].label}
            </DialogTitle>
            <DialogContent
              sx={{
                color: 'textSecondary',
              }}
            >
              <Typography
                variant="subtitle1"
                color="textSecondary"
                display="flex"
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                {announcementSteps[activeStep].description}
              </Typography>
            </DialogContent>
          </>
        )}

        <DialogActions>
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            sx={{ width: '100%' }}
            activeStep={activeStep}
            nextButton={
              <Button
                variant="contained"
                size="large"
                onClick={onLastStep ? handleDismissAnnouncement : handleNext}
              >
                {onLastStep ? 'Done' : 'Next'}
              </Button>
            }
            backButton={
              <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                Back
              </Button>
            }
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
}
export default AnnouncementsDialog;
