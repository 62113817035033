import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  useAppState,
  CustomerPortalFeature,
  useCustomerPortalFeatureEnabled,
} from '@infinitusai/shared';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ViewTimelineRoundedIcon from '@mui/icons-material/ViewTimelineRounded';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMatch } from 'react-router-dom';

interface Props {
  hasMatch: boolean;
  openTasks: boolean;
  onItemClick: (path: string) => void;
  styleIcon: (match: boolean) => {};
}

function TaskCollapsible({ hasMatch, openTasks, onItemClick, styleIcon }: Props) {
  const auth = useAuth();
  const appState = useAppState();

  const hasCreateTaskEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.CREATE_TASK);
  const hasImportTaskEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.IMPORT_TASKS_CSV
  );

  const tasksAllRouteMatch = useMatch(`/${appState.orgName}/tasks/all-tasks`);
  const tasksReportsRouteMatch = useMatch(`/${appState.orgName}/tasks/reports`);
  const tasksCreateRouteMatch = useMatch(`/${appState.orgName}/tasks/new`);

  const hasTasksImportReadPermission = auth.hasPermission([
    PermissionName.CUSTOMER_TASK_IMPORTS_READ,
  ]);

  const hasTasksReadPermission = auth.hasPermission([PermissionName.CUSTOMER_TASKS_READ]);

  const hasCreateTaskPermission = auth.hasPermission([PermissionName.CUSTOMER_TASKS_WRITE]);

  const hasTasksImportWritePermission = auth.hasPermission([
    PermissionName.CUSTOMER_TASK_IMPORTS_WRITE,
  ]);

  const hasTasksReportsReadPermission = auth.hasPermission([
    PermissionName.CUSTOMER_TASK_REPORTS_READ,
  ]);

  const taskItems = [
    (hasCreateTaskPermission || hasTasksImportWritePermission || hasTasksImportReadPermission) &&
    (hasCreateTaskEnabled || hasImportTaskEnabled)
      ? {
          path: `/${appState.orgName}/tasks/new`,
          title: 'Create Tasks',
          icon: <AddRoundedIcon sx={styleIcon(hasMatch && tasksCreateRouteMatch !== null)} />,
          match: hasMatch && tasksCreateRouteMatch !== null,
        }
      : null,
    hasTasksReadPermission && {
      path: `/${appState.orgName}/tasks/all-tasks`,
      title: 'All Tasks',
      icon: <ViewTimelineRoundedIcon sx={styleIcon(hasMatch && tasksAllRouteMatch !== null)} />,
      match: hasMatch && tasksAllRouteMatch !== null,
    },
    hasTasksReportsReadPermission
      ? {
          path: `/${appState.orgName}/tasks/reports`,
          title: 'Tasks Reports',
          icon: <DownloadRoundedIcon sx={styleIcon(hasMatch && tasksReportsRouteMatch !== null)} />,
          match: hasMatch && tasksReportsRouteMatch !== null,
        }
      : null,
  ].filter(Boolean);

  return (
    <Collapse in={openTasks} timeout="auto" unmountOnExit>
      <List>
        {taskItems.map((item) =>
          item ? (
            <ListItemButton
              key={item.title as string}
              sx={{ ml: 4 }}
              onClick={() => onItemClick(item.path)}
              selected={item.match}
              disableRipple
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{ variant: 'subtitle2' }}
              />
            </ListItemButton>
          ) : null
        )}
      </List>
    </Collapse>
  );
}

export default TaskCollapsible;
