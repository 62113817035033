import MuiAccordionDetails from '@mui/material/AccordionDetails';
import React from 'react';

interface Props {
  children?: React.ReactNode;
}

function AccordionDetails({ children }: Props) {
  return <MuiAccordionDetails>{children}</MuiAccordionDetails>;
}
export default AccordionDetails;
