import { useAuth } from '@infinitusai/auth';
import { useBreakpoint } from '@infinitusai/ui';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';

import { useIngestPayerIntelRulesMutation } from 'api/customer';
import Dropzone from 'components/Dropzone';
import { infinitusapi, infinitusai } from 'proto/pbjs';
import { BACKEND_SERVER_URL } from 'utils/constants';
import { getTaskTypeDisplayName, getTaskTypeSearchParamDisplayName } from 'utils/displayNames';

export type TaskTypeKey = keyof typeof infinitusapi.INFTaskType;

const TASK_TYPES: { value: TaskTypeKey; label: string; specSearchParam: string }[] = [
  {
    value: 'INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER
    ),
  },
  {
    value: 'INF_TASK_TYPE_BENEFITS_VERIFICATION',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION
    ),
  },
  {
    value: 'INF_TASK_TYPE_PRESCRIPTION_TRANSFER',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER
    ),
  },
  {
    value: 'INF_TASK_TYPE_PRESCRIPTION_SAVINGS',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS
    ),
  },
  {
    value: 'INF_TASK_TYPE_CLAIMS_FOLLOWUP',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP
    ),
  },
  {
    value: 'INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP
    ),
  },
  {
    value: 'INF_TASK_TYPE_PHARMACY_STOCK_CHECK',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK
    ),
  },
  {
    value: 'INF_TASK_TYPE_PROVIDER_OUTREACH',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH
    ),
  },
  {
    value: 'INF_TASK_TYPE_PATIENT_OUTREACH',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH
    ),
  },
  {
    value: 'INF_TASK_TYPE_BASIC_BV',
    label: getTaskTypeDisplayName(infinitusapi.INFTaskType.INF_TASK_TYPE_BASIC_BV),
    specSearchParam: getTaskTypeSearchParamDisplayName(
      infinitusapi.INFTaskType.INF_TASK_TYPE_BASIC_BV
    ),
  },
];

function ImportPIERulesTab() {
  const smBreakpoint = useBreakpoint('sm');
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const ingestRulesMutation = useIngestPayerIntelRulesMutation();

  const [selectedTaskType, setSelectedTaskType] = React.useState<TaskTypeKey>(
    'INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER'
  );
  const [fileToUpload, setFileToUpload] = React.useState<File | undefined>();
  const [uploading, setUploading] = React.useState(false);

  const handleSelectTaskType = (event: any) => {
    setSelectedTaskType(event.target.value);
  };

  const handleFileDrop = (file: File) => {
    setFileToUpload(file);
  };

  const getFileExtension = (filename: String) => {
    return filename.split('.')[1];
  };

  const uploadFile = async (user: any) => {
    try {
      var formData = new FormData();
      formData.append('file', fileToUpload || '');
      const fileName = fileToUpload?.name || '';
      var fileExt = '';
      if (fileName.length) {
        fileExt = getFileExtension(fileName);
      }
      formData.append('fileExt', fileExt);
      const tasktype = (() => {
        if (selectedTaskType === 'INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER') {
          return 'TASK_TYPE_PBM_BV';
        } else if (selectedTaskType === 'INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP') {
          return 'TASK_TYPE_PRIOR_AUTH_FOLLOWUP';
        } else {
          return 'TASK_TYPE_FULL_BI';
        }
      })();
      const tasktypePB = (() => {
        if (selectedTaskType === 'INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER') {
          return infinitusai.tasks.TaskType.TASK_TYPE_PBM_BV;
        } else if (selectedTaskType === 'INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP') {
          return infinitusai.tasks.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP;
        } else {
          return infinitusai.tasks.TaskType.TASK_TYPE_FULL_BI;
        }
      })();
      formData.append('taskType', tasktype);
      setUploading(true);
      const token = (await user?.getIdToken()) || '';
      if (!token) {
        console.warn('No current firebase user! Requests will not have authorization.');
      }
      const response = await fetch(`${BACKEND_SERVER_URL}${'/c/uploadRules'}`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'X-INF-ORG-UUID': sessionStorage.getItem('orgUuid') || '',
        },
        body: formData,
      });
      let result;
      if (response.status === 200) {
        result = await response.json();

        if (result.error) {
          throw new Error(result.error);
        }
      }

      ingestRulesMutation.mutate(
        infinitusai.tasks.IngestPayerIntelRulesRequest.fromObject({
          taskType: tasktypePB,
          uploadId: result.uploadID,
          fileExt: fileExt,
        }),
        {
          onSuccess: (res) => {
            if (!res.numRulesCreated || res.numRulesCreated.toString() === '0') {
              enqueueSnackbar(`Failed to upload rules from file`, {
                variant: 'error',
              });
            } else {
              enqueueSnackbar(`Successfully uploaded ${res.numRulesCreated} rules.`, {
                variant: 'success',
              });
            }
          },
        }
      );
    } catch (e: any) {
    } finally {
      setUploading(false);
    }
  };
  return (
    <Stack gap={2}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" gap={2}>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Typography variant={smBreakpoint ? 'h6' : 'subtitle1'} sx={{ flexWrap: 'wrap' }}>
              Select a task type, then drag and drop a CSV/Excel file below to import.{' '}
            </Typography>
          </Box>

          <FormControl sx={{ minWidth: smBreakpoint ? '450px' : '100%', mt: 2 }}>
            <InputLabel>Task Type</InputLabel>
            <Select
              onChange={(e) => handleSelectTaskType(e)}
              label="Task Type"
              value={selectedTaskType}
              size="small"
            >
              {TASK_TYPES.map((task) => (
                <MenuItem key={task.value} value={task.value}>
                  {task.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box></Box>
      </Box>
      <Box>
        <Box>
          <Dropzone
            accept={['.csv', '.xlsx']}
            handleDrop={handleFileDrop}
            text={`Drag and drop (or click to select) your Rules CSV or Excel here.`}
            subText="Only *.csv or *.xlsx files are accepted."
          />
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              disabled={!fileToUpload || uploading}
              onClick={() => uploadFile(user)}
              startIcon={<CloudUploadIcon />}
            >
              {uploading ? 'Uploading' : 'Import Rules'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
export default ImportPIERulesTab;
