import { Table, useTable, DateField } from '@infinitusai/table';
import { downloadCsvFromString, possibleLongToNumber } from '@infinitusai/utils';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React from 'react';

import { useGetCsvUploadHistoryQuery, useDownloadCsvByTypeQuery } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { getTaskTypeDisplayName } from 'utils/displayNames';

function ImportHistoryTab() {
  const { enqueueSnackbar } = useSnackbar();
  const [downloadFilename, setDownloadFilename] = React.useState('');
  const downloadCsvQuery = useDownloadCsvByTypeQuery();
  const csvUploadHistoryQuery = useGetCsvUploadHistoryQuery();

  React.useEffect(() => {
    if (downloadCsvQuery.data?.csvObject) {
      downloadCsvFromString(downloadCsvQuery.data.csvObject, `${downloadFilename}`);
    }
  }, [downloadCsvQuery?.data?.csvObject, downloadFilename]);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.tasks.UploadCSVResponse>();
    return [
      columnHelper.accessor(
        (originalRow) => {
          return new Date(possibleLongToNumber(originalRow.uploadTimestamp as number)).getTime();
        },
        {
          header: 'Date',
          cell: (info) => (info.getValue() ? <DateField date={new Date(info.getValue())} /> : '-'),
          enableSorting: true,
          meta: {
            filterType: 'daterange',
            download: true,
            downloadValue: (row) =>
              Number(row.original.uploadTimestamp) <= 0
                ? ' '
                : format(
                    new Date(possibleLongToNumber(row.original.uploadTimestamp as number)),
                    'yyyy-MM-dd HH:mm:ss'
                  ),
          },
        }
      ),
      columnHelper.accessor(
        (originalRow) =>
          getTaskTypeDisplayName(
            originalRow.infTaskType as unknown as infinitusai.tasks.INFTaskType
          ),
        {
          header: 'Task Type',
          cell: (info) => info.getValue(),
          meta: {
            download: true,
            filterType: 'select',
            filterSelectOptions: [
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER
                ),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION
                ),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_TRANSFER
                ),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRESCRIPTION_SAVINGS
                ),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP
                ),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_SHIPMENT_FOLLOWUP
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_SHIPMENT_FOLLOWUP
                ),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PATIENT_OUTREACH
                ),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PROVIDER_OUTREACH
                ),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_PHARMACY_STOCK_CHECK
                ),
              },
              {
                value: getTaskTypeDisplayName(infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BASIC_BV),
                label: getTaskTypeDisplayName(infinitusai.tasks.INFTaskType.INF_TASK_TYPE_BASIC_BV),
              },
              {
                value: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP
                ),
                label: getTaskTypeDisplayName(
                  infinitusai.tasks.INFTaskType.INF_TASK_TYPE_CLAIMS_FOLLOWUP
                ),
              },
            ],
          },
        }
      ),
      columnHelper.accessor('uploadId', {
        header: 'Uploaded CSV',
        id: 'uploadedCSV',
        enableColumnFilter: false,
        cell: (info) => (
          <Link
            sx={{ fontSize: '0.875rem' }}
            component="button"
            onClick={() => {
              setDownloadFilename(
                `${format(
                  new Date(String(info.row.original.uploadTimestamp)),
                  'yyyy-MM-dd_HHmm'
                )}_all.csv`
              );
              downloadCsvQuery.mutate(
                infinitusai.tasks.DownloadCSVByTypeRequest.fromObject({
                  csvUuid: info.row.original.uploadId,
                  csvType: infinitusai.tasks.CSVType.CSV_INPUT,
                }),
                {
                  onSuccess: () => {
                    enqueueSnackbar(`Successfully downloaded CSV file:    `, {
                      variant: 'success',
                    });
                  },
                  onError: () => {
                    enqueueSnackbar(`Unsuccessful attempt in downloading CSV file:    `, {
                      variant: 'error',
                    });
                  },
                }
              );
            }}
          >
            {`${format(
              new Date(String(info.row.original.uploadTimestamp)),
              'yyyy-MM-dd_HHmm'
            )}_all.csv`}
          </Link>
        ),
        meta: {
          download: true,
          downloadValue: (row) =>
            `${format(
              new Date(row.original.uploadTimestamp as number),
              'yyyy-MM-dd_HHmm'
            )}_all.csv`,
        },
      }),
      columnHelper.accessor('numAccepted', {
        header: '✓',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          download: true,
          filterType: 'numberrange',
        },
        cell: (info) => (
          <Tooltip title="Number of accepted tasks">
            <Typography>{info.getValue() as number}</Typography>
          </Tooltip>
        ),
      }),
      columnHelper.accessor('numRejected', {
        header: '✗',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          download: true,
          filterType: 'numberrange',
        },
        cell: (info) => (
          <Tooltip title="Number of rejected tasks">
            <Typography>{info.getValue() as number}</Typography>
          </Tooltip>
        ),
      }),
      columnHelper.accessor('uploadId', {
        id: 'rejectedTasks',
        header: 'Rejected Tasks',
        enableColumnFilter: false,
        meta: {
          download: true,
          downloadValue: (row) =>
            `${format(
              new Date(row.original.uploadTimestamp as number),
              'yyyy-MM-dd_HHmm'
            )}_rejected.csv`,
        },
        cell: (info) => (
          <Link
            sx={{ fontSize: '0.875rem' }}
            component="button"
            onClick={() => {
              setDownloadFilename(
                `${format(
                  new Date(String(info.row.original.uploadTimestamp)),
                  'yyyy-MM-dd_HHmm'
                )}_rejected.csv`
              );
              downloadCsvQuery.mutate(
                infinitusai.tasks.DownloadCSVByTypeRequest.fromObject({
                  csvUuid: info.row.original.uploadId,
                  csvType: infinitusai.tasks.CSVType.CSV_REJECTED,
                }),
                {
                  onSuccess: () => {
                    enqueueSnackbar(`Successfully downloaded CSV file:    `, {
                      variant: 'success',
                    });
                  },
                  onError: () => {
                    enqueueSnackbar(`Unsuccessful attempt in downloading CSV file:    `, {
                      variant: 'error',
                    });
                  },
                }
              );
            }}
          >
            {`${format(
              new Date(String(info.row.original.uploadTimestamp)),
              'yyyy-MM-dd_HHmm'
            )}_rejected.csv`}
          </Link>
        ),
      }),
      columnHelper.accessor('userEmail', {
        header: 'Uploaded By',
        enableColumnFilter: false,
        meta: {
          download: true,
        },
      }),
    ];
  }, [downloadCsvQuery, enqueueSnackbar]);

  const table = useTable({
    id: 'tasks-import-history',
    data: csvUploadHistoryQuery.data,
    columns,
    isLoading: csvUploadHistoryQuery.isLoading,
    totalRows: csvUploadHistoryQuery.data.length,
    noRowsMessage: 'There are no uploads. Get started by creating a new one.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableHiding: true,
    enableDownload: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableUrlSync: true,
  });

  return <Table table={table} />;
}

export default ImportHistoryTab;
