import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Checklist,
  useConfirm,
} from '@infinitusai/ui';
import { isArrayEqual } from '@infinitusai/utils';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import { useGetOrgRoles, useAssignRolesOrCreateInvite } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

interface Props {
  open: boolean;
  invite?: infinitusai.auth.Invite;
  onClose: () => void;
  onUpdate: (inviteUuid: string) => void;
}

function InviteDrawer({ open, invite, onClose, onUpdate }: Props) {
  const confirm = useConfirm();
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const getOrgRoles = useGetOrgRoles();
  const assignRolesOrCreateInvite = useAssignRolesOrCreateInvite();
  const hasOwnerPermission = hasPermission([PermissionName.CUSTOMER_OWNER]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleConfirmClose = () => {
    if (formik.dirty) {
      confirm({
        title: 'Abandon Updating an Invite?',
        description: 'Are you sure you want to abandon updating the invite?',
        footnote: 'If you proceed, invitation update progress will be lost.',
        confirmText: 'Yes, Abandon Changes',
        onConfirm: handleClose,
      });
    } else {
      handleClose();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { roles: invite?.roleUuids || [] },
    onSubmit: (values) => {
      if (invite) {
        const req = infinitusai.auth.AssignRolesOrCreateInviteRequest.fromObject({
          inviteeEmail: invite.inviteeEmail,
          roleUuids: values.roles,
          type: infinitusai.auth.InviteType.INVITE_TYPE_CUSTOMER,
        });

        assignRolesOrCreateInvite.mutate(req, {
          onSuccess: () => {
            onClose();
            enqueueSnackbar(`Invite Successfully Updated`, {
              variant: 'success',
            });
            formik.resetForm();
            onUpdate(invite.uuid);
          },
          onError: (error) => {
            enqueueSnackbar(`Update was unsuccessful: ${error.message}`, {
              variant: 'error',
            });
          },
        });
      }
    },
  });

  return (
    <Drawer open={open} onClose={handleConfirmClose}>
      <DrawerHeader title="Edit Invite" onClose={handleConfirmClose} />
      <DrawerBody>
        <Typography variant="overline" sx={{ mx: 2 }}>
          Select Roles for {invite?.inviteeEmail || 'member'}
        </Typography>
        <Box px={2}>
          <Checklist
            value={formik.values.roles}
            options={
              getOrgRoles.data.map((role) => ({
                value: role.uuid,
                label: role.name,
                disabled: role.readOnly && !hasOwnerPermission,
              })) || []
            }
            onChange={(_, newValue) => formik.setFieldValue('roles', newValue)}
          />
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          size="large"
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={handleConfirmClose}
          unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
        >
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={formik.submitForm}
          disabled={
            !formik.dirty ||
            assignRolesOrCreateInvite.isLoading ||
            formik.values.roles.length === 0 ||
            isArrayEqual(formik.values.roles, invite?.roleUuids || [])
          }
          unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
        >
          Update Invite
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}
export default InviteDrawer;
