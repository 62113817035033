import { PermissionName, useAuth } from '@infinitusai/auth';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useRemoveTeam } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

type Props = {
  open: boolean;
  onClose: () => void;
  teamUuid?: string;
  teamName?: string;
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '615px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  px: 4,
  py: 2,
};

function RemoveTeamModal({ teamUuid, teamName, open, onClose }: Props) {
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const removeTeam = useRemoveTeam();

  const handleRemoveTeam = () => {
    const req = infinitusai.teams.DeleteTeamRequest.fromObject({ teamUuid });
    removeTeam.mutate(req, {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(`Successfully removed team '${teamName}'`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(`Failed to remove team '${teamName}'`, {
          variant: 'error',
        });
      },
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="remove-team-modal-title">
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography id="remove-team-modal-title" variant="h6" component="h2">
            Remove team?
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography>
            Are you sure you want to remove this team? You can always add a new team via the{' '}
            <strong>Add new team</strong> button.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography>
            Team name: <strong>{teamName}</strong>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ borderRadius: '16px', mr: 1, minWidth: '150px' }}
          >
            Cancel
          </Button>
          <LoadingButton
            color="error"
            disabled={!hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
            loading={removeTeam.isLoading}
            onClick={handleRemoveTeam}
            sx={{
              borderRadius: '16px',
              bgcolor: (theme) => theme.palette.warning.main,
              minWidth: '150px',
              textTransform: 'none',
            }}
            variant="contained"
          >
            Remove team
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default RemoveTeamModal;
