import { AppName } from '@infinitusai/api';
import { fetchApi } from '@infinitusai/api';
import { QueryClient, QueryKey } from '@tanstack/react-query';
import { Auth } from 'firebase/auth';

// Used for react query calls (e.g. useQuery(['123', 'c', 'getOrg']))
function createQueryApi(auth: Auth) {
  return async function queryApi({ queryKey }: { queryKey: QueryKey }) {
    let [orgUuid, apiRoot, apiPath, apiBody] = queryKey;
    const path = `/${apiRoot}/${apiPath}`;
    const body = apiBody || {};
    return fetchApi({
      appName: AppName.CUSTOMER,
      orgUuid: orgUuid as string,
      user: auth.currentUser,
      path,
      body,
    });
  };
}

const createQueryClient = (auth: Auth) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 min
        queryFn: createQueryApi(auth),
      },
    },
  });

export default createQueryClient;
