import { Table, useTable } from '@infinitusai/table';
import { downloadCsvFromString, downloadExcelFromString } from '@infinitusai/utils';
import { format } from 'date-fns';
import React from 'react';

import { useDownloadPayerIntelCsvMutation, useGetPayerIntelUploadHistoryQuery } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { getRuleTaskTypeDisplayName } from 'utils/displayNames';

import { UploadHistoryTableRowData } from '../types';
import { FileUploadHistoryColumnDefinitions } from './columnDefinitions';

function ImportRulesHistoryTab() {
  const getFileUploadHistory = useGetPayerIntelUploadHistoryQuery();
  const downloadFileMutation = useDownloadPayerIntelCsvMutation();
  const [uploadHistory, setUploadHistory] = React.useState<UploadHistoryTableRowData[]>([]);

  React.useEffect(() => {
    if (getFileUploadHistory.data && getFileUploadHistory.data.length > 0) {
      const data = getFileUploadHistory.data.map((fileUpload) => {
        const timestamp = format(
          new Date(Number(fileUpload.uploadTimestampMillis)),
          'yyyy-MM-dd_HHmm'
        );

        return {
          date: fileUpload.uploadTimestampMillis,
          taskType: getRuleTaskTypeDisplayName(fileUpload.taskType),
          uploadedFile: {
            uploadId: fileUpload.uploadId,
            timestamp: timestamp,
            fileExt: fileUpload.fileExt,
          },
          numRules: fileUpload.numRulesCreated,
          errorFile: {
            uploadId: String(new Date(Number(fileUpload.uploadTimestampMillis))),
            timestamp: timestamp,
            errorMsg: fileUpload.uploadError,
          },
          uploadedBy: fileUpload.userEmail,
        };
      });
      setUploadHistory(data);
    }
  }, [getFileUploadHistory.data]);

  const downloadFile = (uploadId: string, fileExt: string, fileName: string) => {
    downloadFileMutation.mutate(
      infinitusai.tasks.DownloadPayerIntelCsvRequest.fromObject({
        uploadId: uploadId,
        fileExt: fileExt,
      }),
      {
        onSuccess: (res) => {
          if (res.content) {
            if (fileExt === 'xlsx') {
              downloadExcelFromString(res.content, fileName);
            } else if (fileExt === 'csv') {
              downloadCsvFromString(res.content, fileName);
            }
          }
        },
      }
    );
  };

  const columns = FileUploadHistoryColumnDefinitions({
    downloadFile: downloadFile,
  });

  const table = useTable({
    id: 'import-rules',
    data: uploadHistory,
    columns,
    isLoading: getFileUploadHistory.isLoading,
    totalRows: uploadHistory.length,
    noRowsMessage: 'There are no uploads. Get started by creating a new one.',
    enableSharing: true,
    enableSorting: true,
    enableDownload: true,
    enableFilters: true,
    enableHiding: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableUrlSync: true,
  });

  return <Table table={table} />;
}
export default ImportRulesHistoryTab;
