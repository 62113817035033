import { Org } from '@infinitusai/shared';
import { Table, useTable } from '@infinitusai/table';
import Box from '@mui/material/Box';
import { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

interface Props {
  org: Org;
}

type ProgramDoc = {
  name: string;
  displayName: string | null | undefined;
};

function ProgramIDsTab({ org }: Props) {
  // Convert an object into an array to display in a table
  const data = useMemo(() => {
    return Object.keys(org.programs).map((programId) => {
      return { name: programId, displayName: org.programs[programId].displayName };
    });
  }, [org.programs]);

  const columns: ColumnDef<ProgramDoc, keyof ProgramDoc>[] = useMemo(() => {
    const columnHelper = createColumnHelper<ProgramDoc>();
    return [
      columnHelper.accessor('name', {
        header: 'Program ID',
        cell: (props) => (
          <>
            {props.getValue()}
            {/* If program ID is same as the default program ID, we want to add "(default)" in italicized font to the Program ID */}
            {org.defaultProgramId === props.getValue() ? (
              <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}> (default)</span>
            ) : (
              ''
            )}
          </>
        ),
      }),
      columnHelper.accessor('displayName', { header: 'Display Name' }),
    ];
  }, [org.defaultProgramId]);

  const table = useTable({
    id: 'program-ids',
    data,
    columns,
    isLoading: false,
    totalRows: data.length,
    noRowsMessage: '',
    hideToolbar: true,
    hidePagination: true,
    enableSharing: false,
    enableSorting: false,
    enableFilters: false,
    enableGlobalFilter: true,
    enableUrlSync: true,
  });

  return (
    <Box sx={{ mt: 2 }}>
      <Table table={table} />
    </Box>
  );
}
export default ProgramIDsTab;
