import { Tabs } from '@infinitusai/shared';
import * as React from 'react';

import CreateTask from './CreateTask';
import TasksTab from './TasksTab';

interface Props {
  accessToken: string;
  patientId: string;
  idToken: string;
}

export default function SmartLanding({ accessToken, patientId, idToken }: Props) {
  return (
    <React.Fragment>
      <Tabs
        id="all-tasks"
        syncUrl
        unmountOnChange
        resetUrlOnChange={false}
        sx={{ mb: 2, mt: 2 }}
        variant="fullWidth"
        tabs={[
          {
            value: 'all-tasks',
            label: 'All Tasks',
            content: <TasksTab patientId={patientId} accessToken={accessToken} idToken={idToken} />,
          },
          {
            value: 'create-task',
            label: 'Create Task',
            content: (
              <CreateTask patientId={patientId} accessToken={accessToken} idToken={idToken} />
            ),
          },
        ]}
      />
    </React.Fragment>
  );
}
