import { useBreakpoint, THEME_COLORS } from '@infinitusai/ui';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { useMatch } from 'react-router-dom';

import { Basename } from '../types';
import PermanentDrawer from './PermanentDrawer';
import PortalListItems from './PortalListItems';

interface Props {
  basename: Basename;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

function NavDrawer({ basename, open, onOpen, onClose }: Props) {
  const smBreakpoint = useBreakpoint('sm');
  const lgBreakpoint = useBreakpoint('lg');
  const portalRouteMatch = useMatch(`/:org/*`);

  if (!portalRouteMatch) return null;

  if (!smBreakpoint) {
    return (
      <Drawer
        open={open}
        anchor="left"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 2,
          backgroundImage: 'none',
          '& .MuiPaper-root': (theme) => ({
            ...(theme.palette.mode === 'light'
              ? {
                  background: THEME_COLORS.lightBackground,
                }
              : {
                  background: THEME_COLORS.darkBackground,
                }),
          }),
        }}
        onClose={onClose}
        PaperProps={{
          sx: { width: '85%' },
        }}
      >
        <Toolbar />
        <PortalListItems basename={basename} drawerOpen={open} onItemClick={onClose} />
      </Drawer>
    );
  }

  return (
    <PermanentDrawer
      variant="permanent"
      anchor="left"
      open={open}
      onMouseOver={() => {
        if (!lgBreakpoint) onOpen();
      }}
      onMouseLeave={() => {
        if (!lgBreakpoint) onClose();
      }}
    >
      <Toolbar />
      <PortalListItems basename={basename} drawerOpen={open} onDropdownClick={onOpen} />
    </PermanentDrawer>
  );
}

export default NavDrawer;
