import { Table, useTable, DateField } from '@infinitusai/table';
import { Chips } from '@infinitusai/ui';
import { possibleLongToNumber, getInitialsFromName } from '@infinitusai/utils';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetOrgUsers } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

import MemberDrawer from './MemberDrawer';

function MembersTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const getOrgUsers = useGetOrgUsers();
  const showUserId = searchParams.get('show') || '';

  const handleMemberDrawerClose = () => {
    searchParams.delete('show');
    setSearchParams(searchParams, { replace: true });
  };

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.auth.User>();
    return [
      columnHelper.display({
        id: 'avatar',
        header: '',
        cell: (info) => (
          <Avatar
            {...getInitialsFromName(info.row.original.displayName || info.row.original.email || '')}
          />
        ),
      }),
      columnHelper.accessor('displayName', {
        header: 'Name',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.accessor((user) => user.roleNames.join(','), {
        id: 'roleNames',
        header: 'Roles Assigned',
        enableSorting: false,
        cell: (info) => <Chips labelSize={25} labels={info.getValue().split(',')} />,
      }),
      columnHelper.accessor((user) => new Date(possibleLongToNumber(user.lastActiveTimeMillis)), {
        id: 'lastActiveTimeMillis',
        header: 'Last Active',
        cell: (info) => <DateField date={info.getValue()} />,
      }),
      columnHelper.display({
        id: 'navigate',
        cell: () => (
          <Stack display="flex">
            <IconButton
              disableRipple
              sx={{
                display: 'flex',
                justifyContent: 'right',
                ':hover': { bgcolor: 'transparent' },
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, []);
  const table = useTable({
    id: 'members',
    data: getOrgUsers.data,
    columns,
    isLoading: getOrgUsers.isLoading,
    totalRows: getOrgUsers.data.length,
    noRowsMessage: 'No members found. Invite a teammate.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableGlobalFilter: true,
    enableUrlSync: true,
    getRowId: (row) => row.uid,
    onRowClick: (row) => {
      searchParams.set('show', row.original.uid);
      setSearchParams(searchParams, { replace: true });
    },
  });

  return (
    <React.Fragment>
      <Box sx={{ mt: 2 }}>
        <Table table={table} />
      </Box>
      <MemberDrawer
        open={getOrgUsers.data.find((user) => user.uid === showUserId) !== undefined}
        user={getOrgUsers.data.find((user) => user.uid === showUserId)}
        onClose={handleMemberDrawerClose}
      />
    </React.Fragment>
  );
}
export default MembersTab;
