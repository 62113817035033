import {
  useAppState,
  useCustomerPortalFeatureEnabled,
  CustomerPortalFeature,
} from '@infinitusai/shared';
import { Table, useTable, DateField } from '@infinitusai/table';
import { Chips } from '@infinitusai/ui';
import NavigateNext from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { createColumnHelper } from '@tanstack/react-table';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetOrgPrograms, useGetTasks } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import {
  checkFilters,
  getProductNamesFromTasks,
  getTaskTypeColumnDisplayName,
  getTaskTypeDisplayName,
  getTaskTypeFilterOptionsPortal,
  getTasksWithProducts,
  getUuidDisplayName,
} from 'utils/displayNames';

import { CustomToolbar } from './CustomToolbar';

function TasksInProgressTab() {
  const appState = useAppState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Fetch data
  const getTasksInProgress = useGetTasks(searchParams.get('tab') || '');
  const getOrgPrograms = useGetOrgPrograms();

  const filters = JSON.parse(searchParams.get('filters') as string);
  const products = filters
    ?.filter((filter: any) => filter.id === 'productNames')[0]
    ?.value.join(', ');
  const taskType = filters?.filter((filter: any) => filter.id === 'taskType')[0]?.value;

  // Filters on FE
  const updatedTasks = React.useMemo(
    () => getTasksWithProducts(getTasksInProgress.data.tasks, getTasksInProgress.data.taskInputs),
    [getTasksInProgress.data.taskInputs, getTasksInProgress.data.tasks]
  );
  const filteredTasks = React.useMemo(
    () =>
      checkFilters(updatedTasks, {
        taskTypeFilter: taskType,
        productNamesFilter: products,
      }),
    [products, taskType, updatedTasks]
  );

  const hasProductsFilterEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.TASK_TABLE_PRODUCTS
  );
  const hasTaskTypeFilterEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.TASK_TABLE_TASK_TYPES
  );
  const bundledTasksEnabled = useCustomerPortalFeatureEnabled(
    CustomerPortalFeature.BUNDLE_CUSTOMER_TASKS
  );

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.be.CustomerTaskDoc>();
    return [
      columnHelper.accessor('customerAssignedId', {
        id: 'customerAssignedId',
        header: 'Customer ID',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.accessor('taskCreationMillis', {
        header: 'Created On',
        enableGlobalFilter: false,
        cell: (info) =>
          info.getValue() !== 0 ? <DateField date={new Date(info.getValue() as number)} /> : '-',
        meta: {
          filterType: 'daterange',
        },
      }),
      columnHelper.accessor((originalRow) => getTaskTypeDisplayName(originalRow.taskType), {
        id: 'taskType',
        header: 'Task Type',
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilter: hasTaskTypeFilterEnabled,
        cell: (info) => getTaskTypeColumnDisplayName(info.row.original),
        meta: {
          filterType: 'select',
          filterSelectOptions: getTaskTypeFilterOptionsPortal().map((key) => {
            return {
              value: key,
              label: key,
            };
          }),
        },
      }),
      columnHelper.accessor('taskUuid', {
        id: 'taskUuid',
        header: 'Infinitus ID',
        enableGlobalFilter: false,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => getUuidDisplayName(info.getValue()),
      }),
      columnHelper.accessor('calleeName', {
        header: 'Callee Name',
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.accessor('programName', {
        id: 'programName',
        enableSorting: false,
        enableGlobalFilter: false,
        header: 'Program ID',
        meta: {
          filterType: 'select',
          filterSelectOptions: getOrgPrograms?.data.map((program) => {
            return { value: program.name, label: program.displayName };
          }),
        },
      }),
      columnHelper.accessor((original) => original.bvInputs?.productInfos, {
        id: 'productNames',
        header: 'Products',
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilter: hasProductsFilterEnabled,
        cell: (info) =>
          info.getValue() ? (
            <Chips
              size="small"
              max={2}
              direction="column"
              labelSize={10}
              labels={info.getValue()?.map((product) => product.productName) as string[]}
            />
          ) : (
            '-'
          ),
        meta: {
          filterType: 'multiselect',
          filterSelectOptions: getProductNamesFromTasks(updatedTasks).map((product) => {
            return { value: product, label: product };
          }),
        },
      }),
      bundledTasksEnabled
        ? columnHelper.accessor('bundleTaskId', {
            header: 'Has Bundle Task',
            cell: (info) => {
              return info.getValue() ? 'Yes' : 'No';
            },
            meta: {
              filterType: 'boolean',
            },
          })
        : null,
      columnHelper.display({
        id: 'action',
        enableHiding: false,
        enableColumnFilter: false,
        cell: (info) => (
          <Stack display="flex">
            <IconButton
              sx={{
                display: 'flex',
                justifyContent: 'right',
                ':hover': { bgcolor: 'transparent' },
              }}
              disableRipple
            >
              <NavigateNext />
            </IconButton>
          </Stack>
        ),
      }),
    ].filter(Boolean) as any[];
  }, [
    getOrgPrograms?.data,
    hasProductsFilterEnabled,
    hasTaskTypeFilterEnabled,
    bundledTasksEnabled,
    updatedTasks,
  ]);

  const table = useTable({
    id: 'in-progress-tasks',
    data: filteredTasks,
    columns,
    manualFiltering: true,
    isLoading: getTasksInProgress.isLoading,
    totalRows: filteredTasks.length,
    noRowsMessage: 'No tasks in progress.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableColumnFilters: true,
    enableHiding: true,
    enableGlobalFilter: true,
    enableSavedFilters: true,
    enableUrlSync: true,
    getRowId: (row) => row.taskUuid,
    onRowClick: (row) => {
      navigate(`/${appState.org?.name}/tasks/${row.original.taskUuid}`);
    },
  });

  React.useEffect(() => {
    if (getTasksInProgress.isError) {
      enqueueSnackbar(`Please try again. We currently don't support that filter combination.`, {
        variant: 'error',
      });
    }
  }, [getTasksInProgress.isError]);

  return (
    <Box mt={3}>
      <Table
        table={table}
        overrides={{
          toolbar: <CustomToolbar table={table} />,
        }}
      />
    </Box>
  );
}

export default TasksInProgressTab;
