import { FadeTransition } from '@infinitusai/ui';
import EscalationIcon from '@mui/icons-material/Error';
import UnknownIcon from '@mui/icons-material/Help';
import OverrideIcon from '@mui/icons-material/NewReleasesOutlined';
import PushbackIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { infinitusai } from 'proto/pbjs';

import { PayerIntelligenceDataRow } from './types';
interface Props {
  data?: PayerIntelligenceDataRow;
  dialogOpen: boolean;
}

const RuleDetailsDialog: React.FC<Props> = ({ data, dialogOpen }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getEffectTypeIcon = (effectType: infinitusai.tasks.OutputFieldValueEffectType) => {
    const makeIcon = (tooltip: string, Icon: typeof SvgIcon) => (
      <Tooltip title={tooltip}>
        <Icon />
      </Tooltip>
    );
    switch (effectType) {
      case infinitusai.tasks.OutputFieldValueEffectType.PUSH_BACK:
        return makeIcon('Push back required', PushbackIcon);
      case infinitusai.tasks.OutputFieldValueEffectType.ESCALATE:
        return makeIcon('Escalate to supervisor', EscalationIcon);
      case infinitusai.tasks.OutputFieldValueEffectType.OVERRIDE:
        return makeIcon('Override', OverrideIcon);
      default:
        return <UnknownIcon />;
    }
  };

  const conditionListItem = (name: string, value?: string) => {
    return (
      <ListItem>
        <ListItemText style={{ wordWrap: 'break-word' }} primary={name} secondary={value || '-'} />
      </ListItem>
    );
  };
  // convenience method to display string effects as-is, and boolean effects as "Yes" or "No"
  const effectListItem = (
    name: string,
    effectType: infinitusai.tasks.OutputFieldValueEffectType,
    value: string
  ) => {
    return (
      <ListItem>
        <ListItemIcon>{getEffectTypeIcon(effectType)}</ListItemIcon>
        <ListItemText primary={name} secondary={value} />
      </ListItem>
    );
  };

  const handleClose = () => {
    searchParams.delete('show');
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Dialog
      fullWidth
      open={dialogOpen}
      onClose={handleClose}
      TransitionComponent={FadeTransition}
      maxWidth="md"
    >
      <DialogTitle>Payer Intelligence Rule {data?.payerIntelligenceID || ''}</DialogTitle>
      <DialogContent>
        <Grid container>
          {/* two columns */}
          {[0, 1].map((idx) => (
            <Grid item xs={6} key={`conds-col-${idx}`}>
              <List sx={{ bgcolor: 'background.paper' }}>
                {data?.conditions
                  ?.filter((_, i) => i % 2 === idx)
                  .map((c) => conditionListItem(c.displayName, c.value))}
              </List>
            </Grid>
          ))}
        </Grid>

        <DialogContentText>Validations and Overrides</DialogContentText>
        <Grid container>
          {/* two columns */}
          {[0, 1].map((idx) => (
            <Grid item xs={6} key={`effects-col-${idx}`}>
              <List sx={{ bgcolor: 'background.paper' }}>
                {data?.effects
                  ?.filter((_, i) => i % 2 === idx)
                  .map((e) => effectListItem(e.displayName, e.effectType, e.value))}
              </List>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(RuleDetailsDialog);
