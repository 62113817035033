import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useCancelTaskMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

function CancelTaskButton() {
  const { enqueueSnackbar } = useSnackbar();
  const { tid } = useParams();
  const cancelTaskMutation = useCancelTaskMutation();
  const [processing, setProcessing] = React.useState(false);

  const handleCancel = async () => {
    setProcessing(true);
    const body = infinitusai.be.CancelTaskRequest.fromObject({ taskUuid: tid });
    cancelTaskMutation.mutate(body, {
      onSuccess: () => {
        setProcessing(false);
        enqueueSnackbar(`Cancelled task ${tid}.`, { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(`Task ${tid} could not be cancelled. ${error.message}`, {
          variant: 'error',
        });
      },
    });
  };

  return (
    <Button variant="outlined" color="error" onClick={handleCancel} disabled={processing}>
      Cancel Task
    </Button>
  );
}

export default CancelTaskButton;
