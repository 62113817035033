import Box from '@mui/material/Box';

import { useGetDocsQuery } from 'api/customer';

function DocsApiPage() {
  const getDocsQuery = useGetDocsQuery();

  return (
    <Box sx={{ height: '100%', backgroundColor: 'rgba(244, 244, 244, 1)' }}>
      {getDocsQuery.isLoading || !getDocsQuery.data ? null : (
        <iframe
          style={{ border: 0, width: '100%', height: '100%' }}
          title="Infinitus API Docs"
          src={getDocsQuery.data}
        ></iframe>
      )}
    </Box>
  );
}

export default DocsApiPage;
