import { fetchApi } from '@infinitusai/api';
import { useAuth, PermissionName } from '@infinitusai/auth';
import { useApiParams } from '@infinitusai/shared';
import { dateFromTimestamp } from '@infinitusai/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Long } from 'protobufjs';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { infinitusai, infinitusapi } from 'proto/pbjs';

export const monthLabels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// To show only product announcements, but not FastTrack EULA,
// the title that starts with this prefix should NOT be displayed because they are FastTrack EULAs
export const fastTrackEulaTitlePrefix = 'fasttrack-eula-';

export type RowData = (string | boolean)[];

export function useGetMyAnnouncements() {
  const { orgUuid } = useApiParams();
  const req = {
    appName: 'APP_NAME_CUSTOMER',
  };
  const getMyAnnouncements = useQuery<infinitusai.announcements.GetMyAnnouncementsResponse, Error>([
    orgUuid,
    'c',
    'getMyAnnouncements',
    req,
  ]);
  const allAnnouncementData = React.useMemo(() => {
    if (!getMyAnnouncements.data) return [];
    return (
      getMyAnnouncements.data.announcements.map((announcement) =>
        infinitusai.announcements.Announcement.fromObject(announcement)
      ) || []
    );
  }, [getMyAnnouncements.data]);

  // From product announcements, we are filtering out FastTrack EULA
  // to retrieve just only product announcements (not FastTrack EULA)
  const announcementDataExceptEula = allAnnouncementData.filter(
    (announcement) => announcement.title.startsWith(fastTrackEulaTitlePrefix) === false
  );
  return { ...getMyAnnouncements, data: announcementDataExceptEula };
}
export function useMarkAnnouncementViewed() {
  const { appName, orgUuid, user } = useApiParams();
  const markAnnouncementViewedMutation = useMutation<any, Error, any>((req) => {
    return fetchApi({
      appName,
      orgUuid,
      user,
      path: '/c/markAnnouncementViewed',
      body: req,
    });
  });

  return markAnnouncementViewedMutation;
}

export function useUpdateOrgMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const updateOrgMutation = useMutation<
    infinitusai.orgs.UpdateOrgResponse,
    Error,
    infinitusai.orgs.UpdateOrgRequest
  >(
    (req) => {
      return fetchApi({
        appName,
        orgUuid,
        user,
        path: '/c/updateOrg',
        body: req,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getOrg']);
      },
    }
  );

  return updateOrgMutation;
}

export function useGetProducts() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetProductCodesRequest.create();
  const getProducts = useQuery<infinitusai.be.GetProductCodesResponse, Error>([
    orgUuid,
    'c',
    'getProducts',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getProducts.data) return {};
    return infinitusai.be.GetProductCodesResponse.fromObject(getProducts.data).codes;
  }, [getProducts.data]);

  return { ...getProducts, data };
}

export function useGetApiKeys() {
  const { orgUuid } = useApiParams();
  const req = infinitusai.be.GetAPIKeysRequest.fromObject({});
  const apiKeysQuery = useQuery<infinitusai.be.GetAPIKeysResponse, Error>([
    orgUuid,
    'c',
    'getApiKeys',
    req,
  ]);
  const data = React.useMemo(
    () =>
      apiKeysQuery.data
        ? apiKeysQuery.data?.keys?.map((key) => infinitusai.be.ApiKeyDoc.fromObject(key))
        : [],
    [apiKeysQuery.data]
  );

  return { ...apiKeysQuery, data };
}

export function useGetWebhooks() {
  const { orgUuid } = useApiParams();
  const req = infinitusai.be.GetWebhookConfigsRequest.fromObject({});
  const webhooksQuery = useQuery<infinitusai.be.GetWebhookConfigsResponse, Error>([
    orgUuid,
    'c',
    'getWebhookConfigs',
    req,
  ]);

  const data = React.useMemo(
    () =>
      webhooksQuery.data
        ? webhooksQuery.data?.responses.map((webhook) =>
            infinitusai.be.WebhookConfigDoc.fromObject(webhook)
          )
        : [],
    [webhooksQuery.data]
  );

  return { ...webhooksQuery, data };
}

export function useGenerateApiKeyMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const generateApiKeyMutation = useMutation<
    infinitusai.be.GenerateApiKeyResponse,
    Error,
    infinitusai.be.GenerateApiKeyRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/generateApiKey', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getApiKeys']);
      },
    }
  );

  return generateApiKeyMutation;
}

export function useCreateNewWebhookMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const createNewWebhookMutation = useMutation<
    infinitusai.be.CreateWebhookConfigResponse,
    Error,
    infinitusai.be.GetWebhookConfigsRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/createWebhookConfig', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getWebhookConfigs']);
      },
    }
  );
  return createNewWebhookMutation;
}

export function useDisableApiKeyMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const disableApiKeyMutation = useMutation<
    infinitusai.be.DisableApiKeyResponse,
    Error,
    infinitusai.be.DisableApiKeyRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/disableApiKey', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getApiKeys']);
      },
    }
  );

  return disableApiKeyMutation;
}

export function useDeleteWebhookConfig() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const deleteWebhookConfig = useMutation<
    infinitusai.be.DeleteWebhookConfigResponse,
    Error,
    infinitusai.be.DeleteWebhookConfigRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/deleteWebhookConfig', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getWebhookConfigs']);
      },
    }
  );

  return deleteWebhookConfig;
}

export function useGetBillingMetricsQuery() {
  const { orgUuid } = useApiParams();
  const date = new Date();
  function setDateToMonthStart(date: Date, year: number, month: number) {
    date.setDate(1);
    date.setFullYear(year);
    date.setMonth(month);
    date.setHours(0, 0, 0, 0);
  }

  const [searchParams] = useSearchParams();
  let year = searchParams.get('year') || date.getFullYear().toString();
  let month = searchParams.get('month') || monthLabels[date.getMonth()];
  let start = React.useMemo(() => new Date(), []);
  let end = React.useMemo(() => new Date(), []);
  setDateToMonthStart(start, parseInt(year), monthLabels.indexOf(month));
  setDateToMonthStart(end, parseInt(year), monthLabels.indexOf(month) + 1);

  const reqBody = infinitusai.be.GetBillingMetricsRequest.fromObject({
    completedGteMillis: start.getTime(),
    completedLteMillis: end.getTime(),
  });
  const getBillingMetricsQuery = useQuery<infinitusai.be.GetBillingMetricsResponse, Error>([
    orgUuid,
    'c',
    'getBillingMetrics',
    reqBody,
  ]);
  const data = React.useMemo(() => {
    if (!getBillingMetricsQuery.data) return null;
    const { tasksCompletedCount, tasksFailedBadDataCount, tasksFailedOtherCount } =
      getBillingMetricsQuery.data;
    return { tasksCompletedCount, tasksFailedBadDataCount, tasksFailedOtherCount };
  }, [getBillingMetricsQuery.data]);

  return { ...getBillingMetricsQuery, data };
}

export function useGetBillingReportQuery() {
  const { orgUuid } = useApiParams();
  const date = new Date();
  const [searchParams] = useSearchParams();
  let year = searchParams.get('year') || date.getFullYear().toString();
  let month = searchParams.get('month') || monthLabels[date.getMonth()];

  const reqBody = infinitusai.orgs.GetBillingReportRequest.fromObject({
    month: month,
    year: year,
  });
  const getBillingReportQuery = useQuery<infinitusai.orgs.GetBillingReportResponse, Error>([
    orgUuid,
    'c',
    'getBillingReport',
    reqBody,
  ]);
  const data = React.useMemo(() => {
    if (!getBillingReportQuery.data) return null;
    const { id, url } = getBillingReportQuery.data;
    return { id, url };
  }, [getBillingReportQuery.data]);

  return { ...getBillingReportQuery, data };
}

export function useGetDocsQuery() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.docs.GetDocsRequest.fromObject({});
  const getDocsQuery = useQuery<infinitusai.docs.GetDocsResponse, Error>([
    orgUuid,
    'c',
    'getDocs',
    reqBody,
  ]);
  const data = React.useMemo(() => {
    if (!getDocsQuery.data || !getDocsQuery.data.docsUrl) return null;
    return getDocsQuery.data.docsUrl;
  }, [getDocsQuery.data]);

  return { ...getDocsQuery, data };
}

export function useGetCsvSpec(taskType: number) {
  const { orgUuid } = useApiParams();
  const body = infinitusai.be.GetCSVSpecRequest.fromObject({
    taskType: taskType,
  });

  const csvSpecQuery = useQuery<infinitusai.be.GetCSVSpecResponse, Error>([
    orgUuid,
    'c',
    'getCsvSpec',
    body,
  ]);

  const inputSpecData = React.useMemo(() => {
    if (!csvSpecQuery.data) return null;
    const specQueryData = csvSpecQuery.data.inputSpec?.fields;

    const formattedData =
      specQueryData &&
      specQueryData.map((field: any) => [
        field.csvColumn || field.csvColumnTemplate?.example || '',
        field.required || false,
        field.type || '',
        field.description || '',
        field.example || '',
        field.allowedValues || [],
      ]);

    return formattedData as RowData[];
  }, [csvSpecQuery.data]);

  const outputSpecData = React.useMemo(() => {
    if (!csvSpecQuery.data) return null;
    const specQueryData = csvSpecQuery.data.outputSpec?.fields;

    const formattedData =
      specQueryData &&
      specQueryData.map((field: any) => [
        field.csvColumn || '',
        field.type || '',
        field.description || '',
        field.example || '',
        field.allowedValues || [],
      ]);

    return formattedData as RowData[];
  }, [csvSpecQuery.data]);

  return { ...csvSpecQuery, inputSpecData, outputSpecData };
}

export function useDownloadPayerIntelCsvMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const downloadPayerIntelCsvMutation = useMutation<
    infinitusai.tasks.DownloadPayerIntelCsvResponse,
    Error,
    infinitusai.tasks.DownloadPayerIntelCsvRequest
  >((req) => {
    return fetchApi({ appName, orgUuid, user, path: '/c/downloadPayerIntelCsv', body: req });
  });

  return downloadPayerIntelCsvMutation;
}

export function useIngestPayerIntelRulesMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const ingestPayerIntelRulesMutation = useMutation<
    infinitusai.tasks.IngestPayerIntelRulesResponse,
    Error,
    infinitusai.tasks.IngestPayerIntelRulesRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/ingestPayerIntelRules', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getPayerIntelRules']);
        queryClient.invalidateQueries([orgUuid, 'c', 'getPayerIntelUploadHistory']);
      },
    }
  );

  return ingestPayerIntelRulesMutation;
}

export function useGetPayerIntelUploadHistoryQuery() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.tasks.GetPayerIntelUploadHistoryRequest.fromObject({
    limit: 100,
  });
  const getPayerIntelUploadHistoryQuery = useQuery<
    infinitusai.tasks.GetPayerIntelUploadHistoryResponse,
    Error
  >([orgUuid, 'c', 'getPayerIntelUploadHistory', reqBody]);

  const data = React.useMemo(() => {
    if (!getPayerIntelUploadHistoryQuery.data) return null;
    const { uploads } = getPayerIntelUploadHistoryQuery.data;
    if (uploads.length < 1) {
      return [];
    }
    return uploads.map((upload) => infinitusai.tasks.RulesFileUpload.fromObject(upload));
  }, [getPayerIntelUploadHistoryQuery.data]);

  const isLoading = getPayerIntelUploadHistoryQuery.isLoading;

  return { ...getPayerIntelUploadHistoryQuery, data, isLoading };
}

export function useGetPayerIntelRulesQuery() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.tasks.GetPayerIntelRulesRequest.fromObject({
    taskType: 'TASK_TYPE_FULL_BI',
    callUuid: '',
    taskUuid: '',
    includeInputCond: true,
  });
  const getPayerIntelRulesQuery = useQuery<infinitusai.tasks.GetPayerIntelRulesResponse, Error>([
    orgUuid,
    'c',
    'getPayerIntelRules',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getPayerIntelRulesQuery.data) return null;
    const { rules } = getPayerIntelRulesQuery.data;
    if (rules.length < 1) {
      return [];
    }
    return rules.map((rule) => infinitusai.tasks.Rule.fromObject(rule));
  }, [getPayerIntelRulesQuery.data]);

  const isLoading = getPayerIntelRulesQuery.isLoading;

  return { ...getPayerIntelRulesQuery, data, isLoading };
}

export function useIncidentTypes() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetIncidentTypesRequest.fromObject({
    filter: {
      category: 'Customer Portal',
    },
  });
  const getIncidentTypesQuery = useQuery<infinitusai.be.GetIncidentTypesResponse, Error>([
    orgUuid,
    'c',
    'getIncidentTypes',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getIncidentTypesQuery.data) return null;
    const { incidentTypes } = getIncidentTypesQuery.data;
    if (incidentTypes.length < 1) {
      return [];
    }
    return incidentTypes.map((type) => infinitusai.be.IncidentType.fromObject(type));
  }, [getIncidentTypesQuery.data]);

  return { ...getIncidentTypesQuery, data };
}

export function useUserIncidentsQueryClient({ staleTime }: { staleTime: number }) {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();

  return (reqBody: infinitusai.be.GetUserIncidentsRequest) => {
    const result = queryClient.fetchQuery<
      infinitusai.be.GetUserIncidentsRequest,
      Error,
      infinitusai.be.GetUserIncidentsResponse
    >({
      queryKey: [orgUuid, 'c', 'getIncidentTypes', reqBody],
      queryFn: () => {
        return fetchApi({
          appName,
          orgUuid,
          user,
          path: '/c/getUserIncidents',
          body: reqBody,
        });
      },
      staleTime,
    });

    return result;
  };
}

export function useGetCsvSpecQuery() {
  const { appName, orgUuid, user } = useApiParams();
  const downloadCsvQuery = useMutation<
    infinitusai.be.GetCSVSpecResponse,
    Error,
    infinitusai.be.GetCSVSpecRequest
  >((req) => {
    return fetchApi({ appName, orgUuid, user, path: '/c/getCsvSpec', body: req });
  });
  return downloadCsvQuery;
}

export function useUploadCsvMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const uploadCsvMutation = useMutation<
    infinitusai.tasks.UploadCSVResponse,
    Error,
    infinitusai.tasks.UploadCSVRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/uploadCsv', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getCsvUploadHistory']);
        queryClient.invalidateQueries([orgUuid, 'c', 'getTasks']);
      },
    }
  );

  return uploadCsvMutation;
}

export function useCreateIncidentMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const createIncidentMutation = useMutation<
    infinitusai.be.CreateIncidentResponse,
    Error,
    any // Passing FormData instead of infinitusai.be.CreateIncidentRequest
  >({
    mutationFn: async (formData) => {
      return await fetchApi({
        appName,
        orgUuid,
        user,
        path: '/c/createIncident',
        body: formData,
        bodyJson: false,
      });
    },
  });

  return createIncidentMutation;
}

export function useGetCsvUploadHistoryQuery() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.tasks.GetCSVUploadHistoryRequest.fromObject({});
  const csvUploadHistoryQuery = useQuery<infinitusai.tasks.GetCSVUploadHistoryResponse, Error>([
    orgUuid,
    'c',
    'getCsvUploadHistory',
    reqBody,
  ]);
  const data = React.useMemo(() => {
    if (!csvUploadHistoryQuery.data || !csvUploadHistoryQuery.data.uploads) return [];
    const { uploads } = csvUploadHistoryQuery.data;
    if (!uploads) return [];
    return uploads.map(
      (upload) =>
        ({
          userEmail: upload.userEmail,
          infTaskType: upload.infTaskType,
          uploadId: upload.uploadId,
          uploadTimestamp: upload.uploadTimestamp,
          numAccepted: upload.numAccepted,
          numRejected: upload.numRejected,
          gcsPath: upload.gcsPath,
        } as infinitusai.tasks.UploadCSVResponse)
    );
  }, [csvUploadHistoryQuery.data]);

  return { ...csvUploadHistoryQuery, data };
}

export function useDownloadCsvByTypeQuery() {
  const { appName, orgUuid, user } = useApiParams();
  const downloadCsvQuery = useMutation<
    infinitusai.tasks.DownloadCSVByTypeResponse,
    Error,
    infinitusai.tasks.DownloadCSVByTypeRequest
  >((req) => {
    return fetchApi({ appName, orgUuid, user, path: '/c/downloadCsvByType', body: req });
  });
  return downloadCsvQuery;
}

export function useGenerateReportMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const generateReportMutation = useMutation<
    infinitusai.tasks.GenerateReportResponse,
    Error,
    infinitusai.tasks.GenerateReportRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/generateReport', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getReports']);
      },
    }
  );

  return generateReportMutation;
}

export function useDownloadReportMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const downloadReportMutation = useMutation<any, Error, infinitusai.be.DownloadCSVRequest>(
    (req) => {
      return fetchApi({
        appName,
        orgUuid,
        user,
        path: '/c/downloadReport',
        body: req,
      });
    }
  );

  return downloadReportMutation;
}

export function useGetReportsQuery() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.tasks.GetReportsRequest.fromObject({});
  const getReportsQuery = useQuery<infinitusai.tasks.GetReportsResponse, Error>([
    orgUuid,
    'c',
    'getReports',
    reqBody,
  ]);
  const data = React.useMemo(() => {
    if (!getReportsQuery.data || !getReportsQuery.data.reports) return [];
    const { reports } = getReportsQuery.data;
    return reports.map((report) =>
      infinitusai.tasks.GeneratedCSVDoc.fromObject({
        query: report.query,
        generatedAtMillis: report.generatedAtMillis,
        gcsPath: report.gcsPath,
        csvUuid: report.csvUuid,
        published: report.published,
        errorMessage: report.errorMessage,
      })
    );
  }, [getReportsQuery.data]);

  return { ...getReportsQuery, data };
}

export function useGetOrgPrograms() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.tasks.GetOrgProgramsRequest.fromObject({});
  const getOrgProgramsQuery = useQuery<infinitusai.tasks.GetOrgProgramsResponse, Error>([
    orgUuid,
    'c',
    'getOrgPrograms',
    reqBody,
  ]);
  const data = React.useMemo(() => {
    if (!getOrgProgramsQuery.data || !getOrgProgramsQuery.data.programs) return [];
    const { programs } = getOrgProgramsQuery.data;
    return programs.map((program) =>
      infinitusai.tasks.OrgProgram.fromObject({
        name: program.name,
        displayName: program.displayName,
      })
    );
  }, [getOrgProgramsQuery.data]);

  return { ...getOrgProgramsQuery, data };
}

export function useCreateTasks() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const createTasks = useMutation<
    infinitusapi.CreateTasksResponse,
    Error,
    infinitusapi.CreateTasksRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/createTasks', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getTasks']);
      },
    }
  );
  return createTasks;
}

export function useGetTask(taskUuid: string) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetTaskRequest.fromObject({
    taskUuid,
  });

  const getTask = useQuery<infinitusai.be.GetTaskResponse, Error>([
    orgUuid,
    'c',
    'getTask',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getTask.data) return null;
    let customerTask = getTask.data.customerTask;
    if (customerTask) {
      customerTask = infinitusai.be.CustomerTaskDoc.fromObject(customerTask);
    }
    const previousOutputs = getTask.data.previousOutputs;
    let task = getTask.data.task;
    if (task) {
      task = infinitusai.be.TaskDoc.fromObject(task);
    }

    const tasks = getTask.data.tasks.map((task) => infinitusai.be.TaskDoc.fromObject(task));
    const flexibleTaskInputs = getTask.data.taskInputs;
    return { customerTask, previousOutputs, task, tasks, flexibleTaskInputs };
  }, [getTask.data]);
  return { ...getTask, data };
}

export function useGetTaskResults(taskUuids: string[]) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetTaskResultsRequest.fromObject({
    taskUuids,
  });

  const getTaskResults = useQuery<infinitusapi.GetTaskResultsResponse, Error>([
    orgUuid,
    'c',
    'getTaskResults',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getTaskResults.data) return null;
    const taskResults = getTaskResults.data.data;
    const total = Number(getTaskResults.data.data.length);
    return { taskResults, total };
  }, [getTaskResults.data]);
  return { ...getTaskResults, data };
}

export function useBundledCustomerTasks(taskUuid: string, enabled: boolean) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetBundledCustomerTasksRequest.fromObject({
    taskUuid,
  });

  const getBundledCustomerTasks = useQuery<infinitusai.be.GetBundledCustomerTasksResponse, Error>(
    [orgUuid, 'c', 'getBundledCustomerTasks', reqBody],
    {
      enabled: enabled,
    }
  );

  const data = React.useMemo(() => {
    if (!getBundledCustomerTasks.data) return null;
    const customerTasks = getBundledCustomerTasks.data.tasks.map((customerTask) => {
      return infinitusai.be.CustomerTaskDoc.fromObject(customerTask);
    });

    return {
      tasks: customerTasks,
    };
  }, [getBundledCustomerTasks.data]);

  return { ...getBundledCustomerTasks, data };
}

export function useProcessTaskMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const processTaskMutation = useMutation<
    infinitusai.be.ProcessTaskResponse,
    Error,
    infinitusai.be.ProcessTaskRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/processTask', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getTask']);
      },
    }
  );
  return processTaskMutation;
}

export function useCancelTaskMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const cancelTaskMutation = useMutation<
    infinitusai.be.CancelTaskResponse,
    Error,
    infinitusai.be.CancelTaskRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/cancelTask', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getTask']);
        queryClient.invalidateQueries([orgUuid, 'c', 'getTasks']);
      },
    }
  );
  return cancelTaskMutation;
}

export function useCompleteTaskMutation() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();
  const cancelTaskMutation = useMutation<
    infinitusai.be.CompleteTaskResponse,
    Error,
    infinitusai.be.CompleteTaskRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/completeTask', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getTask']);
      },
    }
  );
  return cancelTaskMutation;
}

export function useGetTaskCounts() {
  const { orgUuid } = useApiParams();
  const { hasPermission } = useAuth();
  const hasCustomerPortalAccessPermission = hasPermission([PermissionName.CUSTOMER_PORTAL_ACCESS]);
  const hasTasksReadPermissions = hasPermission([PermissionName.CUSTOMER_TASKS_READ]);
  const reqBody = infinitusai.be.GetTaskCountsRequest.fromObject({});
  const getTaskCountsQuery = useQuery<infinitusai.be.GetTaskCountsResponse, Error>(
    [orgUuid, 'c', 'getTaskCounts', reqBody],
    { enabled: hasCustomerPortalAccessPermission || hasTasksReadPermissions }
  );
  const data = React.useMemo(() => {
    if (!getTaskCountsQuery.data) return null;
    const { tasksInQueueCount, tasksInProgressCount, tasksCompletedTodayCount } =
      getTaskCountsQuery.data;
    return { tasksInQueueCount, tasksInProgressCount, tasksCompletedTodayCount };
  }, [getTaskCountsQuery.data]);

  return { ...getTaskCountsQuery, data };
}

export function useGetTasksToReview() {
  const { orgUuid } = useApiParams();
  const { hasPermission } = useAuth();
  const hasTasksReadPermissions = hasPermission([PermissionName.CUSTOMER_TASKS_READ]);

  const reqBody = infinitusai.be.GetTasksRequest.fromObject({});

  const getTasksToReviewQuery = useQuery<infinitusai.be.GetTasksResponse, Error>(
    [orgUuid, 'c', 'getTasksToReview', reqBody],
    { enabled: hasTasksReadPermissions }
  );
  const data = React.useMemo(() => {
    if (!getTasksToReviewQuery.data || !getTasksToReviewQuery.data.tasks) return [];
    const { tasks } = getTasksToReviewQuery.data;
    return tasks.map((task) => infinitusai.be.CustomerTaskDoc.fromObject(task));
  }, [getTasksToReviewQuery.data]);

  return { ...getTasksToReviewQuery, data };
}

function getDefaultSortDescParam(reqBodyObj: any) {
  for (const key in reqBodyObj) {
    if (reqBodyObj[key].length > 0) {
      return '';
    }
  }
  return 'taskUpdatedMillis';
}

export function useGetTasks(state?: string) {
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const filters = JSON.parse(searchParams.get('filters') as string);
  let customerAssignedId = '';
  let calleeName = '';
  let programName = '';
  let taskCreatedMillisGte = '';
  let taskCreatedMillisLte = '';
  let taskReviewedMillisGte = '';
  let taskReviewedMillisLte = '';
  let taskStates: string[] = [];
  let failureReasonChosen = '';
  let processed = '';
  let flaggedForReview = '';
  let taskState = state || '';
  let hasHumanInvolvement = '';
  let webhookDeliveryStatus = '';
  customerAssignedId = search || '';

  if (filters) {
    taskCreatedMillisGte = filters.filter((filter: any) => filter.id === 'taskCreationMillis')[0]
      ?.value[0]
      ? dateFromTimestamp(
          filters.filter((filter: any) => filter.id === 'taskCreationMillis')[0]?.value[0]
        )
          .getTime()
          .toString()
      : '';

    taskCreatedMillisLte = filters.filter((filter: any) => filter.id === 'taskCreationMillis')[0]
      ?.value[1]
      ? dateFromTimestamp(
          filters.filter((filter: any) => filter.id === 'taskCreationMillis')[0]?.value[1]
        )
          .getTime()
          .toString()
      : '';
    taskReviewedMillisGte = filters.filter((filter: any) => filter.id === 'taskReviewMillis')[0]
      ?.value[0]
      ? dateFromTimestamp(
          filters.filter((filter: any) => filter.id === 'taskReviewMillis')[0]?.value[0]
        )
          .getTime()
          .toString()
      : '';

    taskReviewedMillisLte = filters.filter((filter: any) => filter.id === 'taskReviewMillis')[0]
      ?.value[1]
      ? dateFromTimestamp(
          filters.filter((filter: any) => filter.id === 'taskReviewMillis')[0]?.value[1]
        )
          .getTime()
          .toString()
      : '';
    customerAssignedId =
      search || filters.filter((filter: any) => filter.id === 'customerAssignedId')[0]?.value || '';
    processed = filters.filter((filter: any) => filter.id === 'customerReview')[0]?.value || '';
    flaggedForReview =
      filters.filter((filter: any) => filter.id === 'hasPotentialAdverseEvent')[0]?.value || '';

    //exact match
    calleeName = filters.filter((filter: any) => filter.id === 'calleeName')[0]?.value || '';
    programName = filters.filter((filter: any) => filter.id === 'programName')[0]?.value || '';
    taskState = filters?.filter((filter: any) => filter.id === 'state')[0]?.value || state;
    failureReasonChosen =
      filters?.filter((filter: any) => filter.id === 'failureReasonChosen')[0]?.value || '';
    hasHumanInvolvement =
      filters?.filter((filter: any) => filter.id === 'humanInvolved')[0]?.value || '';
    webhookDeliveryStatus =
      filters?.filter((filter: any) => filter.id === 'webhookDeliveryStatus')[0]?.value || '';
  }

  const reqBodyObj: any = {
    customerAssignedId,
    calleeName,
    programName,
    taskCreatedMillisGte,
    taskCreatedMillisLte,
    taskReviewedMillisGte,
    taskReviewedMillisLte,
    taskStates,
    failureReasonChosen,
    processed,
    flaggedForReview,
    hasHumanInvolvement,
    webhookDeliveryStatus,
  };

  switch (taskState) {
    case 'in-queue': {
      reqBodyObj.taskStates = ['TASK_STATE_CREATED'];
      reqBodyObj.sortDesc = search || customerAssignedId || calleeName ? '' : 'taskCreationMillis';
      reqBodyObj.limit = 1000;
      break;
    }
    case 'in-progress': {
      reqBodyObj.taskStates = [
        'TASK_STATE_CALL_CONNECTED',
        'TASK_STATE_CALL_DISCONNECTED',
        'TASK_STATE_READY_FOR_REVIEW',
        'TASK_STATE_IN_REVIEW',
        'TASK_STATE_IN_PROGRESS',
      ];
      reqBodyObj.sortDesc = search || customerAssignedId || calleeName ? '' : 'taskCreationMillis';
      reqBodyObj.limit = 1000;
      break;
    }
    case 'completed': {
      reqBodyObj.sortDesc = 'taskReviewMillis';
      reqBodyObj.taskStates = [
        'TASK_STATE_REVIEWED',
        'TASK_STATE_PARTIAL_SUCCESS',
        'TASK_STATE_FAILED',
      ];
      reqBodyObj.limit = 1000;
      break;
    }
    case 'successful': {
      reqBodyObj.taskStates = ['TASK_STATE_REVIEWED'];
      reqBodyObj.sortDesc = 'taskReviewMillis';
      reqBodyObj.limit = 1000;
      break;
    }
    case 'partial': {
      reqBodyObj.taskStates = ['TASK_STATE_PARTIAL_SUCCESS'];
      reqBodyObj.sortDesc = 'taskReviewMillis';

      break;
    }
    case 'unsuccessful': {
      reqBodyObj.taskStates = ['TASK_STATE_FAILED'];
      reqBodyObj.sortDesc = 'taskReviewMillis';
      break;
    }

    default:
      reqBodyObj.taskStates = taskStates ? taskStates : [];
      reqBodyObj.limit = 1000;
      reqBodyObj.sortDesc = search ? '' : getDefaultSortDescParam(reqBodyObj);
  }

  const { orgUuid } = useApiParams();
  const { hasPermission } = useAuth();
  const hasTasksReadPermissions = hasPermission([PermissionName.CUSTOMER_TASKS_READ]);
  const reqBody = infinitusai.be.GetTasksRequest.fromObject(reqBodyObj);
  const getTasks = useQuery<infinitusai.be.GetTasksResponse, Error>(
    [orgUuid, 'c', 'getTasks', reqBody],
    {
      enabled: hasTasksReadPermissions,
    }
  );
  const data = React.useMemo(() => {
    if (!getTasks.data || !getTasks.data.tasks) return { taskInputs: [], tasks: [] };
    const { tasks, taskInputs } = getTasks.data;

    return {
      taskInputs,
      tasks: tasks.map((task) => infinitusai.be.CustomerTaskDoc.fromObject(task)),
    };
  }, [getTasks.data]);
  return { ...getTasks, data };
}

export function useGetPayers() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetPayersRequest.fromObject({});
  const getPayers = useQuery<infinitusai.be.GetPayersResponse, Error>([
    orgUuid,
    'c',
    'getPayers',
    reqBody,
  ]);

  const data = React.useMemo(
    () =>
      getPayers.data
        ? getPayers.data.payers
            ?.map((payer) => infinitusai.be.PayerDoc.fromObject(payer))
            .filter((payer) => payer.id !== '')
        : [],
    [getPayers.data]
  );

  return { ...getPayers, data };
}

export function useGetPayersWithDetails() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetPayersWithDetailsRequest.fromObject({});
  const getPayersWithDetails = useQuery<infinitusai.be.GetPayersWithDetailsResponse, Error>([
    orgUuid,
    'c',
    'getPayersWithDetails',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (getPayersWithDetails.data) {
      return infinitusai.be.GetPayersWithDetailsResponse.fromObject(getPayersWithDetails.data);
    }
    return undefined;
  }, [getPayersWithDetails.data]);

  return { ...getPayersWithDetails, data };
}

export function useGetAvailableTags(
  taskType?: infinitusai.be.TaskType,
  tagUuids?: string[],
  name?: string
) {
  const { orgUuid } = useApiParams();

  const reqBody = infinitusai.tags.GetAvailableTagsRequest.fromObject({
    uuids: tagUuids,
    taskType,
    name,
  });

  const getAvailableTags = useQuery<infinitusai.tags.GetAvailableTagsResponse, Error>(
    [orgUuid, 'c', 'getAvailableTags', reqBody],
    {
      enabled: !!tagUuids,
    }
  );

  const data = React.useMemo(
    () =>
      getAvailableTags.data
        ? getAvailableTags.data.tags?.map((tag) => infinitusai.tags.Tag.fromObject(tag))
        : [],
    [getAvailableTags.data]
  );

  return { ...getAvailableTags, data };
}

export function useCompleteIntegrationTask() {
  const { appName, orgUuid, user } = useApiParams();
  const queryClient = useQueryClient();

  const completeIntegrationTaskMutation = useMutation<
    infinitusai.tags.CompleteIntegrationTaskResponse,
    Error,
    infinitusai.tags.CompleteIntegrationTaskRequest
  >(
    (req) => {
      return fetchApi({ appName, orgUuid, user, path: '/c/completeIntegrationTask', body: req });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([orgUuid, 'c', 'getAvailableTags']);
        queryClient.invalidateQueries([orgUuid, 'c', 'getTask']);
        queryClient.invalidateQueries([orgUuid, 'c', 'getTasks']);
      },
    }
  );
  return completeIntegrationTaskMutation;
}

export function useGetTaskSummary(duration: string, startDateMillis?: number | Long) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetTaskSummaryRequest.fromObject({ startDateMillis, duration });
  const getTaskSummary = useQuery<infinitusai.be.GetTaskSummaryResponse, Error>([
    orgUuid,
    'c',
    'getTaskSummary',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getTaskSummary.data) return null;
    const {
      tasksSuccessful,
      tasksPartiallySuccessul,
      tasksUnsuccessful,
      tasksBadData,
      tasksDataMissing,
      tasksThirdPartyReasons,
      tasksCancelled,
      tasksOther,
      tasksTotal,
    } = getTaskSummary.data;
    return {
      tasksSuccessful,
      tasksPartiallySuccessul,
      tasksUnsuccessful,
      tasksBadData,
      tasksDataMissing,
      tasksThirdPartyReasons,
      tasksCancelled,
      tasksOther,
      tasksTotal,
    };
  }, [getTaskSummary.data]);

  return { ...getTaskSummary, data };
}

export function useGetTimeSavings(
  taskType?: Number,
  programName?: string,
  startDateRange?: string,
  endDateRange?: string
) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.be.GetCustomerTimeSavingsRequest.fromObject({
    taskType: taskType ?? null,
    programName,
    timeSavingsStartDate: startDateRange,
    timeSavingsEndDate: endDateRange,
  });

  const getTimeSavings = useQuery<infinitusai.be.GetCustomerTimeSavingsResponse, Error>([
    orgUuid,
    'c',
    'getTimeSavings',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getTimeSavings.data) return null;
    const { avgTimeSavingsByRange, avgTimeSavingsForRange } = getTimeSavings.data;
    return { avgTimeSavingsByRange, avgTimeSavingsForRange };
  }, [getTimeSavings.data]);
  return { ...getTimeSavings, data };
}

export function useOrgTaskTypes() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.tasks.GetOrgTaskTypesRequest.create({ orgUUID: orgUuid });
  const getOrgTaskTypes = useQuery<
    infinitusai.tasks.GetOrgTaskTypesResponse,
    Error,
    infinitusai.tasks.GetOrgTaskTypesRequest
  >([orgUuid, 'c', 'getOrgTaskTypes', reqBody]);

  const data = React.useMemo(() => {
    if (!getOrgTaskTypes.data) return [];
    return infinitusai.tasks.GetOrgTaskTypesResponse.fromObject(getOrgTaskTypes.data).orgTaskTypes;
  }, [getOrgTaskTypes.data]);

  return { ...getOrgTaskTypes, data };
}

export function useResendWebhookNotifications() {
  const { appName, orgUuid, user } = useApiParams();
  const resendWebhookNotificationMutation = useMutation<
    infinitusai.be.ResendWebhookNotificationsResponse,
    Error,
    infinitusai.be.ResendWebhookNotificationsRequest
  >(async (req) => {
    try {
      const response = await fetchApi({
        appName,
        orgUuid,
        user,
        path: '/c/resendWebhookNotifications',
        body: req,
      });
      const protoData = infinitusai.be.ResendWebhookNotificationsResponse.fromObject(response);
      return protoData;
    } catch (err) {
      throw err;
    }
  });

  return resendWebhookNotificationMutation;
}

export function useGetTeams() {
  const { orgUuid } = useApiParams();

  const reqBody = infinitusai.teams.GetTeamsRequest.fromObject({});
  const getTeams = useQuery<infinitusai.teams.GetTeamsResponse, Error>([
    orgUuid,
    'fasttrack/v1',
    'getTeams',
    reqBody,
  ]);

  const data = React.useMemo(
    () =>
      getTeams.data
        ? getTeams.data.teams?.map((team) => infinitusai.teams.Team.fromObject(team))
        : [],
    [getTeams.data]
  );

  return { ...getTeams, data };
}

export function useGetTeamDetails(teamUuid: string) {
  const { orgUuid } = useApiParams();

  const reqBody = infinitusai.teams.GetTeamRequest.fromObject({ teamUuid });
  const getTeam = useQuery<infinitusai.teams.GetTeamResponse, Error>([
    orgUuid,
    'fasttrack/v1',
    'getTeam',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    const team = getTeam.data?.team ? infinitusai.teams.Team.fromObject(getTeam.data.team) : null;
    const members = getTeam.data?.team?.members?.map((member) =>
      infinitusai.teams.Member.fromObject(member)
    );
    return { team, members };
  }, [getTeam.data]);

  return { ...getTeam, data };
}

export function useRemoveTeam() {
  const { appName, user, orgUuid } = useApiParams();
  const queryClient = useQueryClient();

  const removeTeam = useMutation<
    infinitusai.teams.DeleteTeamResponse,
    Error,
    infinitusai.teams.DeleteTeamRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/fasttrack/v1/removeTeam', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeams']);
    },
  });

  return removeTeam;
}

export function useRemoveMember() {
  const { appName, user, orgUuid } = useApiParams();
  const queryClient = useQueryClient();

  const removeMember = useMutation<
    infinitusai.teams.DeleteMembershipResponse,
    Error,
    infinitusai.teams.DeleteMembershipRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/fasttrack/v1/removeMember', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeam']);
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeams']);
    },
  });

  return removeMember;
}

export function useAddTeam() {
  const { appName, user, orgUuid } = useApiParams();
  const queryClient = useQueryClient();

  const addTeam = useMutation<
    infinitusai.teams.CreateTeamResponse,
    Error,
    infinitusai.teams.CreateTeamRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/fasttrack/v1/createTeam', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeams']);
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeam']);
    },
  });

  return addTeam;
}

export function useAddMembers() {
  const { appName, user, orgUuid } = useApiParams();
  const queryClient = useQueryClient();

  const addMembers = useMutation<
    infinitusai.teams.AddMembersResponse,
    Error,
    infinitusai.teams.AddMembersRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/fasttrack/v1/addMembers', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeam']);
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeams']);
    },
  });

  return addMembers;
}

export function useEditTeamName() {
  const { appName, user, orgUuid } = useApiParams();
  const queryClient = useQueryClient();

  const editTeam = useMutation<
    infinitusai.teams.EditTeamResponse,
    Error,
    infinitusai.teams.EditTeamRequest
  >((req) => fetchApi({ appName, user, orgUuid, path: '/fasttrack/v1/editTeam', body: req }), {
    onSuccess: () => {
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeam']);
      queryClient.invalidateQueries([orgUuid, 'fasttrack/v1', 'getTeams']);
    },
  });

  return editTeam;
}
