import { useAuth } from '@infinitusai/auth';
import { getFirebaseErrorMessage } from '@infinitusai/utils';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';

import { useGetMyAuthConfig } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

const AuthProvidersTab = () => {
  const auth = useAuth();
  const myEmail = auth.user?.email || '';
  const getMyAuthConfig = useGetMyAuthConfig();
  const { enqueueSnackbar } = useSnackbar();

  if (!myEmail || getMyAuthConfig.isLoading || getMyAuthConfig.isError || !getMyAuthConfig.data) {
    return null;
  }

  const googleEnabled = getMyAuthConfig.data.approvedAuthProviders.includes(
    infinitusai.auth.AuthProvider.AUTH_PROVIDER_GOOGLE
  );
  const microsoftEnabled = getMyAuthConfig.data.approvedAuthProviders.includes(
    infinitusai.auth.AuthProvider.AUTH_PROVIDER_MICROSOFT
  );

  const googleProvider = auth.user?.providerData?.find(
    (provider) => provider.providerId === 'google.com'
  );
  const googleLinked = !!googleProvider;
  const microsoftProvider = auth.user?.providerData?.find(
    (provider) => provider.providerId === 'microsoft.com'
  );
  const microsoftLinked = !!microsoftProvider;

  return (
    <Stack gap={1}>
      {googleEnabled ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            disabled={googleLinked}
            variant="contained"
            onClick={() => {
              if (!googleLinked) {
                auth
                  .linkWithGoogle()
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((error) => {
                    const errorMessage = getFirebaseErrorMessage(error);
                    enqueueSnackbar(errorMessage, { variant: 'error' });
                  });
              }
            }}
          >
            {googleLinked ? 'Google Linked' : 'Link Google'}
          </Button>
        </Stack>
      ) : null}
      {microsoftEnabled ? (
        <Stack direction="row" alignItems="center">
          <Button
            disabled={microsoftLinked}
            variant="contained"
            onClick={() => {
              if (!microsoftLinked) {
                auth
                  .linkWithMicrosoft()
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((error) => {
                    const errorMessage = getFirebaseErrorMessage(error);
                    enqueueSnackbar(errorMessage, { variant: 'error' });
                  });
              }
            }}
          >
            {microsoftLinked ? 'Microsoft Linked' : 'Link Microsoft'}
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default AuthProvidersTab;
