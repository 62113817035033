import { useAuth, PermissionName } from '@infinitusai/auth';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  TextField,
  FadeTransition,
  useConfirm,
} from '@infinitusai/ui';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import { useGenerateApiKeyMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

interface Props {
  open: boolean;
  onClose: () => void;
}

function ApiKeyDrawer({ open, onClose }: Props) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const generateApiKey = useGenerateApiKeyMutation();
  const [newKeyName, setNewKeyName] = React.useState('');
  const [newKeyValue, setNewKeyValue] = React.useState('');
  const [keyDialogOpen, setKeyDialogOpen] = React.useState(false);
  const textFieldRef = React.useRef<HTMLDivElement>(null);
  const confirm = useConfirm();

  const handleGenerateApiKey = () => {
    const req = infinitusai.be.GenerateApiKeyRequest.fromObject({
      keyName: newKeyName,
    });
    generateApiKey.mutate(req, {
      onSuccess: (data) => {
        setNewKeyName('');
        setNewKeyValue(data.apiKey);
        setKeyDialogOpen(true);
      },
      onError: (error) => {
        enqueueSnackbar(`Failed to generate token: ${error.message}.`, {
          variant: 'error',
        });
      },
    });
  };

  const handleFieldFocus = (ref: HTMLDivElement | null) => {
    ref?.querySelector('input')?.select();
  };

  const handleClose = () => {
    if (newKeyName) {
      confirm({
        title: 'Abandon Creating New API Key?',
        description: 'Are you sure you want to abandon creating a new API key?',
        confirmText: 'Yes, Abandon',
        onConfirm: () => {
          setNewKeyName('');
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  return (
    <Drawer open={open} onClose={handleClose}>
      <DrawerHeader title="Create New API Key" onClose={handleClose} />
      <DrawerBody>
        <ListSubheader>
          <Typography variant="overline">DEFINE THE API KEY</Typography>
        </ListSubheader>
        <Stack mb="1rem" ml="1rem" mr="1rem">
          <TextField
            debounce
            id="apiName"
            label="API Key Reference Name"
            placeholder="API Test"
            size="medium"
            helperText="Add a name that will help you find this role later on (30 characters max*)"
            sx={{ mb: 1 }}
            value={newKeyName}
            onChange={(event) => setNewKeyName(event.target.value)}
            unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
          />
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          size="large"
          color="primary"
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={handleGenerateApiKey}
          disabled={!newKeyName || generateApiKey.isLoading}
          unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
        >
          Generate Key
        </Button>
      </DrawerFooter>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={keyDialogOpen}
        TransitionComponent={FadeTransition}
        disableEscapeKeyDown
        aria-labelledby="key-dialog-title"
        aria-describedby="key-dialog-description"
      >
        <DialogTitle id="key-dialog-title">Copy New API Key</DialogTitle>
        <DialogContent>
          <DialogContentText id="key-dialog-text">
            Make sure to copy your new API key now. You won't be able to see it again!
          </DialogContentText>
          <TextField
            sx={{
              mt: 2,
            }}
            fullWidth
            label="API Key"
            variant="outlined"
            value={newKeyValue}
            ref={textFieldRef}
            onFocus={() => handleFieldFocus(textFieldRef.current)}
            onKeyPress={(event) => event.preventDefault()}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              spellCheck: false,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="copy key"
                    edge="end"
                    onClick={() => {
                      navigator.clipboard.writeText(newKeyValue);
                      enqueueSnackbar(
                        'API Key has been successfully copied! Please keep it safe!',
                        {
                          variant: 'success',
                        }
                      );
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            color="primary"
            onClick={() => {
              setKeyDialogOpen(false);
              setNewKeyValue('');
              onClose();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
}

export default ApiKeyDrawer;
