import { Button } from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as IconTaskAccepted } from 'assets/checkmark-with-circle.svg';
import { ReactComponent as IconTaskRejected } from 'assets/progress-call-failed.svg';
import { infinitusai } from 'proto/pbjs';

interface Props {
  filename: string;
  numAccepted: number;
  numRejected: number;
  onDownloadCsv: (type: infinitusai.tasks.CSVType) => void;
}

const FileProcessed: React.FC<Props> = ({
  filename = 'test.csv',
  numAccepted = 2,
  numRejected = 1123,
  onDownloadCsv,
}) => {
  const { org } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <Grid justifyContent="center">
        <Typography
          variant="h4"
          sx={{ display: 'flexbox', textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}
        >
          CSV/XLS/XLSX file processed
        </Typography>
        <Typography variant="body2" mt={2} sx={{ marginLeft: 2, textAlign: 'center' }}>
          We have successfully processed your file,{' '}
          <Link
            component="button"
            onClick={() => onDownloadCsv(infinitusai.tasks.CSVType.CSV_INPUT)}
          >
            {filename}
          </Link>
          . The results are summarized below:
        </Typography>
        {numAccepted > 0 && (
          <Card
            elevation={0}
            sx={{
              marginY: 2,
              backgroundColor: 'rgba(24, 24, 24, .15)',
            }}
          >
            <CardContent>
              <Grid container justifyContent="center" alignItems="center" direction="row">
                <Stack direction="row">
                  <IconTaskAccepted />
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6">{numAccepted}</Typography>
                    <Typography>Tasks Accepted</Typography>
                  </Grid>
                </Stack>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button
                variant="text"
                onClick={() => navigate(`/${org}/tasks/all-tasks?tab=in-queue`)}
              >
                View new tasks
              </Button>
            </CardActions>
          </Card>
        )}
        {numRejected > 0 && (
          <Card
            elevation={0}
            sx={{
              marginY: 2,
              backgroundColor: 'rgba(24, 24, 24, .15)',
            }}
          >
            <CardContent>
              <Grid container justifyContent="center" alignItems="center" direction="row">
                <Stack direction="row">
                  <IconTaskRejected />
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6">{numRejected}</Typography>
                    <Typography>Tasks Rejected</Typography>
                  </Grid>
                </Stack>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Link
                variant="body1"
                component="button"
                onClick={() => onDownloadCsv(infinitusai.tasks.CSVType.CSV_REJECTED)}
              >
                Download Rejected Tasks
              </Link>
            </CardActions>
          </Card>
        )}
      </Grid>
      {numRejected > 0 && (
        <Grid container mx={2}>
          <Typography variant="h4" sx={{ fontSize: 18 }}>
            Why were some tasks rejected?
          </Typography>
          <Typography variant="body2">
            To ensure your data will not be rejected by payers, we run a series of validation
            checks, and also verify that we support each payer. We import all the rows that pass,
            and return the ones that fail via the download link above.
          </Typography>
          <Box mt={2}>
            <Typography variant="body2">
              You will find a specific explanation for each row that was rejected in the returned
              CSV file. Correct the errors and re-upload the corrected tasks to try again:
            </Typography>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default FileProcessed;
