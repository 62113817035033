// Type definitions for the CS Wii page

// represents a single row in a CSV
// maps from column name to cell value
export type ParsedCSVRow = {
  [key: string]: string;
};

export const CSWII_ROW_ID = 'Customer BV Task ID';

// Refers to the entire data structure of the CS Wii table
// maps from customer task id (row id) to a CS Wii row
export type CsWiiTable = Map<string, CSWiiRow>;

// represents the err response from the BE for the CSV cell
// has the id of the row and error message for why the cell in the row is wrong from the BE API
export type ErrorRecord = {
  customerTaskId: string;
  errorMessage: string;
};

// represents a single cell in the CSWii table which needs metadata
// has the cell value, and if it has an error, and the error message
export type CSWiiCell = {
  value: string;
  hasError: boolean;
  errorMessage: string;
};

// represents a single row in the CSWii table
//maps column name to a CSV cell
export type CSWiiRow = {
  [columnName: string]: CSWiiCell;
};

export enum CSVColumnName {
  ProgramID = 'Program ID',
  InfinitusPayerID = 'Infinitus Payer ID',
  FacilityType = 'Facility Type',
  ProviderNetworkStatus = 'Provider Network Status',
  PracticeNPI = 'Practice NPI',
  ProviderNPI = 'Provider NPI',
  PracticeTaxID = 'Practice Tax ID',
  ProviderTaxID = 'Provider Tax ID',
  MemberZip = 'Member Zip',
  ProviderZip = 'Provider Zip',
  PracticeZip = 'Practice Zip',
  MemberDateOfBirth = 'Member Date of Birth',
  TreatmentDate = 'Treatment Date',
  SubscriberID = 'Subscriber ID',
  OtherInsuranceStatus = 'Other Insurance Status',
  PatientState = 'Member State',
  ProviderState = 'Provider State',
  PracticeState = 'Practice State',
}
