import { DateField } from '@infinitusai/table';
import { downloadTextFromString, possibleLongToNumber } from '@infinitusai/utils';
import Link from '@mui/material/Link';
import { createColumnHelper } from '@tanstack/react-table';
import format from 'date-fns/format';
import React from 'react';

import { UploadHistoryTableRowData } from '../types';

interface Props {
  downloadFile: (uploadId: string, fileExt: string, fileName: string) => void;
}

export const FileUploadHistoryColumnDefinitions = ({ downloadFile }: Props) => {
  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<UploadHistoryTableRowData>();
    return [
      columnHelper.accessor(
        (originalRow) => new Date(possibleLongToNumber(originalRow.date as number)).getTime(),
        {
          header: 'Date',
          cell: (info) => (info.getValue() ? <DateField date={new Date(info.getValue())} /> : '-'),
          meta: {
            filterType: 'daterange',
            download: true,
            downloadValue: (row) =>
              Number(row.original.date) <= 0
                ? ' '
                : format(
                    new Date(possibleLongToNumber(row.original.date as number)),
                    'yyyy-MM-dd HH:mm:ss'
                  ),
          },
        }
      ),
      columnHelper.accessor('taskType', {
        header: 'Task Type',
        meta: {
          download: true,
          filterType: 'select',
          filterSelectOptions: [
            { value: 'PBM', label: 'PBM' },
            { value: 'Major Medical', label: 'Major Medical' },
          ],
        },
      }),
      columnHelper.accessor('uploadedFile', {
        header: 'Uploaded File',
        enableColumnFilter: false,
        cell: (info) => (
          <Link
            component="button"
            onClick={() => {
              downloadFile(
                info.getValue().uploadId,
                info.getValue().fileExt,
                `${info.getValue().timestamp}_all.${info.getValue().fileExt}`
              );
            }}
          >
            {`${info.getValue().timestamp}_all.${info.getValue().fileExt}`}
          </Link>
        ),
        meta: {
          download: true,
          downloadValue: (row) =>
            `${row.original.uploadedFile.timestamp}_all.${row.original.uploadedFile.fileExt}`,
        },
      }),
      columnHelper.accessor('numRules', {
        header: '# of Rules Created',
        enableColumnFilter: false,
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor('errorFile', {
        header: 'Ingest Errors',
        enableColumnFilter: false,
        cell: (info) => (
          <span>
            {info.getValue().errorMsg.length === 0 ? (
              '-'
            ) : (
              <Link
                component="button"
                onClick={() => {
                  const filename = `${info.getValue().timestamp}_errors.txt`;
                  downloadTextFromString(info.getValue().errorMsg, filename);
                }}
              >
                {`${info.getValue().timestamp}_errors.txt`}
              </Link>
            )}
          </span>
        ),
        meta: {
          download: true,
          downloadValue: (row) => `${row.original.errorFile.timestamp}_errors.txt`,
        },
      }),
      columnHelper.accessor('uploadedBy', {
        header: 'Uploaded By',
        enableColumnFilter: false,
        meta: {
          download: true,
        },
      }),
    ];
  }, [downloadFile]);
  return columns;
};
