// For our prod builds, REACT_APP_BUILD_ENV is set by deploy-app-engine.sh
// We have a rewrite rule / -> /api for the api domains
// (i.e. requests to api.infinitusai.com/ will hit the backend service at /api/)

let backendServerHost = 'localhost';
let frontendServerHost = 'backendapi.ngrok.io';
if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_BUILD_ENV === 'staging') {
    backendServerHost = 'api-staging.infinitusai.com';
    frontendServerHost = 'customer-staging.infinitusai.com';
  } else if (
    process.env.REACT_APP_BUILD_ENV === 'unstable' ||
    process.env.REACT_APP_BUILD_ENV === 'beta-unstable'
  ) {
    backendServerHost = 'api-unstable.infinitusai.com';
    frontendServerHost = 'customer-dev.infinitusai.com';
  } else if (process.env.REACT_APP_BUILD_ENV === 'dev-unstable') {
    backendServerHost = `api-unstable.infinitusai.dev`;
    frontendServerHost = 'customer-dev.infinitusai.dev';
  } else {
    backendServerHost = 'api.infinitusai.com';
    frontendServerHost = 'customer.infinitusai.com';
  }
}
export const BACKEND_SERVER_HOST = backendServerHost;
export const FRONTEND_SERVER_HOST = frontendServerHost;
export const BACKEND_SERVER_URL =
  process.env.NODE_ENV === 'production' ? `https://${BACKEND_SERVER_HOST}` : '/api';

export const BETA_VERSION = process.env.REACT_APP_BETA === 'true';
export const ALLOW_INTERNAL_BETA_TESTERS = false; // set to true to allow internal beta testers
export const ALLOW_EXTERNAL_BETA_TESTERS = false; // set to true to allow external beta testers
export const REDIRECT_URI_TEST_SMART = `https://${FRONTEND_SERVER_HOST}/integration/landing-page`;
export const SMART_SCOPE = 'patient/*.rs user/*.rs launch launch/patient openid fhirUser';

export const IS_DEV_ENV = process.env.NODE_ENV === 'development';
