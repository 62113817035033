import * as yup from 'yup';

export const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

export const ipRegex =
  /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:$|\/(16|24))$/;

export const profileValidationSchema = yup.object().shape({
  imageUrl: yup.string().url().required(),
  displayName: yup.string().trim().required(),
  supportEmail: yup.string().email().required(),
  emailDomains: yup
    .array()
    .of(yup.string().matches(domainRegex, { message: 'Please ensure all domains are valid' }))
    .min(1, 'Please provide at least one domain'),
  whitelistedIpAddresses: yup
    .array()
    .of(
      yup.string().matches(ipRegex, { message: 'Please ensure all IPs and IP ranges are valid' })
    ),
});
