import { useAuth, PermissionName } from '@infinitusai/auth';
import { useCustomerPortalFeatureEnabled, CustomerPortalFeature } from '@infinitusai/shared';
import { Button, useBreakpoint } from '@infinitusai/ui';
import { downloadCsvFromString } from '@infinitusai/utils';
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { read, utils } from 'xlsx';

import {
  useUploadCsvMutation,
  useGetCsvSpecQuery,
  useDownloadCsvByTypeQuery,
  useOrgTaskTypes,
  useIncidentTypes,
  useUserIncidentsQueryClient,
} from 'api/customer';
import Dropzone from 'components/Dropzone';
import { infinitusai } from 'proto/pbjs';
import { updateTaskImportCache, getTaskImportCache, TaskImportCache } from 'utils/localStorage';
import { TASK_TYPES_FOR_IMPORT_TASK, InfTaskTypeKey } from 'utils/taskTypes';

import DialogImportFeedback from './DialogImportFeedback';
import FileNotProcessed from './FileNotProcessed';
import FileProcessed from './FileProcessed';
import TemplateAndSpec from './TemplateAndSpec';
import CSWii from './csWii/csWii';
import { ErrorRecord, CsWiiTable } from './csWii/types';
import {
  clearAllErrors,
  convertCSVTableBackToCsvString,
  convertCsvStringToCswiiTable,
  updateCsWiiTableCellsWithErrors,
} from './csWii/utils';
import {
  taskImportHasRejectedTasks,
  generateCsvTemplate,
  getTaskTypesForTaskImport,
  cleanCsvString,
} from './utils';
import { getTaskUploadFeedbackType, shouldTriggerFeedbackDialog } from './utilsFeedback';

function CreateTasksTab() {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const smBreakpoint = useBreakpoint('sm');
  const lgBreakpoint = useBreakpoint('lg');
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTaskType =
    (searchParams.get('taskType') as InfTaskTypeKey) || 'INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER';

  // Local states
  const [fileToUpload, setFileToUpload] = React.useState<File | undefined>();
  const [fileToUploadContent, setFileToUploadContent] = React.useState('');
  const [uploadedFilename, setUploadedFilename] = React.useState('');
  const [cswiiTable, setCSWiiTable] = React.useState<CsWiiTable>(new Map());
  const [whichColumnsHaveErrors, setWhichColumnsHaveErrors] = React.useState(
    new Map<string, boolean>()
  );
  const [shouldShowCsWiiTable, setShouldShowCsWiiTable] = React.useState(false);
  const [didUserFinishEditingCsWiiTable, setDidUserFinishEditingCsWiiTable] = React.useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = React.useState(false);

  // Queries and mutations
  const downloadCsvQuery = useDownloadCsvByTypeQuery();
  const getCsvSpecQuery = useGetCsvSpecQuery();
  const uploadCsvMutation = useUploadCsvMutation();
  const isCsWiiEnabled = useCustomerPortalFeatureEnabled(CustomerPortalFeature.CSWII);
  const incidentTypes = useIncidentTypes();
  const userIncidentsQueryClient = useUserIncidentsQueryClient({
    staleTime: 0, // Use staleTime of 0 to always fetch the latest data
  });
  const orgTaskTypesQuery = useOrgTaskTypes();

  const selectedTaskObject = TASK_TYPES_FOR_IMPORT_TASK.find(
    (task) => task.value === selectedTaskType
  );

  const taskTypesForTaskImport = React.useMemo(() => {
    return getTaskTypesForTaskImport(TASK_TYPES_FOR_IMPORT_TASK, orgTaskTypesQuery.data);
  }, [orgTaskTypesQuery.data]);

  const CSVColumnNames = React.useMemo(() => {
    const [firstRow] = cswiiTable.values();

    if (!firstRow) return [];

    return [...Object.keys(firstRow)];
  }, [cswiiTable]);

  const wereAnyTasksRejected =
    uploadCsvMutation.data && taskImportHasRejectedTasks(uploadCsvMutation.data);
  const shouldShowTemplates = !uploadCsvMutation.data || wereAnyTasksRejected;

  const handleSelectTaskType = (event: any) => {
    searchParams.set('taskType', event.target.value);
    setSearchParams(searchParams, { replace: true });
  };

  const handleFileDrop = (file: File) => {
    setFileToUpload(file);
    setCSWiiTable(new Map());
    setWhichColumnsHaveErrors(new Map());
    setFileToUploadContent('');
    setUploadedFilename('');
  };

  const shouldShowValidateCsvButton = () => {
    const lessThan50RowsWithErrors = cswiiTable.size < 50 && cswiiTable.size > 0;

    return (
      isCsWiiEnabled &&
      wereAnyTasksRejected &&
      lessThan50RowsWithErrors &&
      !didUserFinishEditingCsWiiTable &&
      uploadCsvMutation.isSuccess
    );
  };

  const handleTaskImportTrigger = async (
    feedbackTypeId: string,
    taskImportCache: TaskImportCache
  ) => {
    const nowInMs = Date.now().valueOf();
    const triggerFeedbackDialog = await shouldTriggerFeedbackDialog(
      userIncidentsQueryClient,
      feedbackTypeId,
      nowInMs,
      taskImportCache
    );
    updateTaskImportCache(taskImportCache);
    if (triggerFeedbackDialog) {
      handleFeedbackDialogOpen();
    }
  };

  const handleUpload = () => {
    const reader = new FileReader();
    const fileExt = getFileExtension(fileToUpload as File);
    reader.onload = () => {
      let csvFileRawString = String(reader.result);
      const filename = fileToUpload?.name!;
      setUploadedFilename(filename);

      if (fileExt === 'xlsx' || fileExt === 'xls') {
        const response = handleExcelFile(csvFileRawString);
        if (response.isFailed) {
          return;
        }
        csvFileRawString = response.data;
      }

      let newCSVRawString = csvFileRawString;

      if (isCsWiiEnabled) {
        const cswiiTableFromCsvString =
          cswiiTable.size > 0 ? cswiiTable : convertCsvStringToCswiiTable(csvFileRawString);

        setCSWiiTable(cswiiTableFromCsvString);

        newCSVRawString = convertCSVTableBackToCsvString(cswiiTableFromCsvString);
        setFileToUploadContent(newCSVRawString);

        if (cswiiTable.size > 0) {
          const clearedCsWiiTableOfErrors = clearAllErrors(cswiiTable);
          setCSWiiTable(clearedCsWiiTableOfErrors);
        }
      }

      uploadCsvMutation.mutate(
        infinitusai.tasks.UploadCSVRequest.fromObject({
          infTaskType: String(selectedTaskType),
          content: cleanCsvString(newCSVRawString),
        }),
        {
          onSuccess: (data) => {
            const feedbackType = getTaskUploadFeedbackType(incidentTypes.data || []);
            if (feedbackType?.id) {
              const taskImportCache = getTaskImportCache();
              if (Number(data.numRejected) > 0) {
                taskImportCache.failedCount++;
              } else {
                taskImportCache.successCount++;
              }
              handleTaskImportTrigger(feedbackType.id, taskImportCache);
            }
          },
          onError: (err) => {
            console.error('Error in importing task', err);
            enqueueSnackbar(`Unsuccessful attempt in importing tasks`, { variant: 'error' });
            const feedbackType = getTaskUploadFeedbackType(incidentTypes.data || []);
            if (feedbackType?.id) {
              const taskImportCache = getTaskImportCache();
              taskImportCache.errorCount++;
              handleTaskImportTrigger(feedbackType.id, taskImportCache);
            }
          },
        }
      );
    };

    if (fileExt === 'xlsx' || fileExt === 'xls') {
      reader.readAsBinaryString(fileToUpload as File);
    } else {
      reader.readAsText(fileToUpload as File);
    }

    setShouldShowCsWiiTable(false);
    setDidUserFinishEditingCsWiiTable(false);
  };

  const handleExcelFile = (content: string) => {
    const response = {
      data: content,
      isFailed: false,
    };
    var workbook = read(content, {
      type: 'binary',
    });
    if (workbook.SheetNames.length > 1) {
      enqueueSnackbar(`The XLS/XLSX file has more than 1 sheet!`, {
        variant: 'error',
      });
      response.isFailed = true;
    } else {
      const sheetName = workbook.SheetNames[0];
      response.data = utils.sheet_to_csv(workbook.Sheets[sheetName]);
    }

    return response;
  };

  const ShowOrHideCSWiiTable = () => {
    if (didUserFinishEditingCsWiiTable) {
      return;
    }

    //@ts-ignore
    const errors: ErrorRecord[] = uploadCsvMutation.data?.errors ?? [];

    const [updatedCsWiiTable, colHasErrorCopy] = updateCsWiiTableCellsWithErrors(
      errors,
      cswiiTable,
      whichColumnsHaveErrors
    );

    setCSWiiTable(updatedCsWiiTable);
    setWhichColumnsHaveErrors(colHasErrorCopy);

    if (shouldShowCsWiiTable) {
      setDidUserFinishEditingCsWiiTable(true);
      handleUpload();
    }

    //Show or hide the table
    setShouldShowCsWiiTable(!shouldShowCsWiiTable);
  };

  const getFileExtension = (file: File) => {
    return file.name.split('.')[1];
  };

  const handleUserJustEditedCsWiiCell = (newValue: string, rowName: string, column: string) => {
    const userEditedCSWiiTable = new Map(cswiiTable);
    const row = userEditedCSWiiTable.get(rowName);

    if (row && row[column]) {
      row[column].value = newValue;
      userEditedCSWiiTable.set(rowName, { ...row, [column]: row[column] });
    }

    setCSWiiTable(userEditedCSWiiTable);
  };

  const handleFeedbackDialogOpen = () => {
    setFeedbackDialogOpen(true);
  };
  const handleFeedbackDialogClose = () => {
    setFeedbackDialogOpen(false);
  };

  return (
    <Stack gap={2}>
      <DialogImportFeedback open={feedbackDialogOpen} onClose={handleFeedbackDialogClose} />
      <Box
        display="flex"
        flexDirection={lgBreakpoint ? 'row' : 'column'}
        justifyContent="space-between"
        gap={2}
      >
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h6">
              Select a task type, then drag and drop a file to import
            </Typography>
          </Box>
          <FormControl
            sx={{
              minWidth: smBreakpoint ? '450px' : '100%',
              mt: 2,
              mb: 2,
            }}
          >
            <InputLabel>Task Type</InputLabel>
            <Select
              onChange={(e) => handleSelectTaskType(e)}
              label="Task Type"
              labelId="task-type-select-label"
              value={selectedTaskType || ''}
              displayEmpty
              fullWidth
              size="small"
              placeholder={orgTaskTypesQuery.isLoading ? 'Loading...' : ''}
              disabled={!auth.hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_WRITE])}
            >
              {taskTypesForTaskImport.map((task) => (
                <MenuItem key={task.value} value={task.value}>
                  {task.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {lgBreakpoint ? (
          <Box>
            <Alert
              iconMapping={{ success: <WebhookRoundedIcon /> }}
              sx={{ maxWidth: smBreakpoint ? '300px' : '100%', bgcolor: 'background.paper' }}
            >
              For frequent task imports, we recommend using our <Link href="/docs">Tasks API</Link>.
            </Alert>
          </Box>
        ) : null}
      </Box>
      <Box>
        {fileToUpload && uploadCsvMutation.data && (
          <Box mb={3}>
            <FileProcessed
              filename={uploadedFilename}
              numAccepted={Number(uploadCsvMutation.data.numAccepted)}
              numRejected={Number(uploadCsvMutation.data.numRejected)}
              onDownloadCsv={(type) => {
                let date = uploadCsvMutation.data.uploadTimestamp
                  ? String(uploadCsvMutation.data.uploadTimestamp)
                  : new Date();
                const timestamp = format(new Date(date), 'yyyy-MM-dd_HHmm');
                downloadCsvQuery.mutate(
                  infinitusai.tasks.DownloadCSVByTypeRequest.fromObject({
                    csvUuid: uploadCsvMutation.data?.uploadId,
                    csvType: infinitusai.tasks.CSVType.CSV_INPUT
                      ? infinitusai.tasks.CSVType.CSV_INPUT
                      : infinitusai.tasks.CSVType.CSV_REJECTED,
                  }),
                  {
                    onSuccess: (res) => {
                      downloadCsvFromString(
                        res.csvObject,
                        `${timestamp}_${
                          type === infinitusai.tasks.CSVType.CSV_INPUT ? 'all' : 'rejected'
                        }.csv`
                      );
                      enqueueSnackbar(`Successfully downloaded CSV file:    `, {
                        variant: 'success',
                      });
                    },
                    onError: (error) => {
                      enqueueSnackbar(
                        `Unsuccessful attempt in downloading CSV file: ${error.message}`,
                        {
                          variant: 'error',
                        }
                      );
                    },
                  }
                );
              }}
            />
          </Box>
        )}
        {uploadCsvMutation.isError && fileToUpload && (
          <Box mb={3}>
            <FileNotProcessed
              filename={uploadedFilename}
              csvString={fileToUploadContent}
              error={uploadCsvMutation.error}
              specSearchParam={selectedTaskObject?.specSearchParam || ''}
            />
          </Box>
        )}
        {selectedTaskType && (
          <Box>
            <Dropzone
              accept={['.csv', '.xls', '.xlsx']}
              handleDrop={handleFileDrop}
              text={`Drag and drop your ${selectedTaskObject?.label} CSV/XLS/XLSX here.`}
              subText="Only *.csv, *.xls, and *.xlsx files are accepted."
              unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_WRITE])}
            />

            <Box mt={3} mb={2}>
              <Button
                fullWidth={!smBreakpoint}
                color="primary"
                variant="contained"
                size="large"
                disabled={!fileToUpload || uploadCsvMutation.isLoading}
                onClick={() => handleUpload()}
                unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_WRITE])}
              >
                Import Tasks
              </Button>
            </Box>

            {shouldShowValidateCsvButton() ? (
              <>
                <Divider sx={{ mt: 3, mb: 2 }} />

                <Button
                  fullWidth={!smBreakpoint}
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={!fileToUpload || uploadCsvMutation.isLoading}
                  onClick={() => ShowOrHideCSWiiTable()}
                  unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_TASK_IMPORTS_WRITE])}
                >
                  {shouldShowCsWiiTable ? 'Finish and Submit' : 'Validate CSV'}
                </Button>
              </>
            ) : null}

            <Box mt={2} />

            {shouldShowCsWiiTable ? (
              <CSWii
                CSVColumnNames={CSVColumnNames}
                whichColumnsHaveErrors={whichColumnsHaveErrors}
                csWiiTable={cswiiTable}
                handleUserJustEditedCsWiiCell={handleUserJustEditedCsWiiCell}
              />
            ) : null}
          </Box>
        )}
      </Box>
      <Divider sx={{ mt: 3, mb: 2 }} />
      {shouldShowTemplates && selectedTaskType && (
        <TemplateAndSpec
          taskType={selectedTaskObject!.label}
          searchParam={selectedTaskObject!.specSearchParam}
          onDownloadTemplate={() => {
            let taskType: infinitusai.tasks.INFTaskType =
              infinitusai.tasks.INFTaskType[selectedTaskType];

            getCsvSpecQuery.mutate(
              infinitusai.be.GetCSVSpecRequest.fromObject({
                taskType: taskType,
              }),
              {
                onSuccess: (res) => {
                  // Use "onlyIncludeRequiredFields" search params to download a template with
                  // required fields only. This is useful for internal testing.
                  const onlyIncludeRequiredFields =
                    searchParams.get('onlyIncludeRequiredFields') === 'true';
                  const csvString = generateCsvTemplate(
                    res.inputSpec?.fields || [],
                    onlyIncludeRequiredFields
                  );
                  downloadCsvFromString(csvString, `${selectedTaskObject?.label}_csv_template.csv`);
                  enqueueSnackbar(`Successfully downloaded CSV file:    `, {
                    variant: 'success',
                  });
                },
                onError: () => {
                  enqueueSnackbar(`Unsuccessful attempt in downloading CSV file:    `, {
                    variant: 'error',
                  });
                },
              }
            );
          }}
        />
      )}
    </Stack>
  );
}
export default CreateTasksTab;
