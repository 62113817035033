import { downloadCsvFromString } from '@infinitusai/utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useDownloadReportMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

interface DownloadButtonProps {
  csv: infinitusai.tasks.GeneratedCSVDoc;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ csv }) => {
  const { org } = useParams();
  const downloadReportMutation = useDownloadReportMutation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        size="small"
        color="primary"
        variant="outlined"
        disabled={downloadReportMutation.isLoading}
        onClick={() => {
          const body = infinitusai.tasks.DownloadReportRequest.fromObject({
            csvUuid: csv.csvUuid,
          });
          downloadReportMutation.mutate(body, {
            onSuccess: async (res) => {
              const csvData = await res.text();
              downloadCsvFromString(
                csvData,
                `report_${org}_${format(
                  new Date(Number(csv.query?.reviewedMillisStart)),
                  'yyyy-mm-dd_HH:mm:ss'
                )}.csv`
              );
            },
            onError: (err) => {
              enqueueSnackbar(err.message, { variant: 'error' });
            },
          });
        }}
      >
        Download
      </Button>
    </Box>
  );
};

export default DownloadButton;
