import { useAuth, PermissionName } from '@infinitusai/auth';
import { useAppState } from '@infinitusai/shared';
import { TextField as InfTextField, useConfirm } from '@infinitusai/ui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import * as Yup from 'yup';

import { useAddMembers } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { EmailCategory, getEmailDomain } from 'utils/email';

type Props = {
  memberEmailHashtable: { [key: string]: boolean };
  onClose: () => void;
  open: boolean;
  orgUserEmailHashtable: { [key: string]: boolean };
  teamName: string;
  teamUuid: string;
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '615px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4,
};

function AddMemberModal({
  teamName,
  teamUuid,
  orgUserEmailHashtable,
  memberEmailHashtable,
  open,
  onClose,
}: Props) {
  const theme = useTheme();
  const confirm = useConfirm();
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const appState = useAppState();
  const addMembers = useAddMembers();
  const [inputValue, setInputValue] = React.useState('');
  const [emailInputTextFocused, setEmailInputTextFocused] = React.useState(false);

  const handleFormSubmitted = async ({ emails }: { emails: string[] }) => {
    const newEmails: string[] = [];
    const existingEmails: string[] = [];

    emails.forEach((email: string) => {
      if (orgUserEmailHashtable[email]) {
        if (memberEmailHashtable[email]) {
          existingEmails.push(email);
        } else {
          newEmails.push(email);
        }
      }
    });

    // Check if email domains are invalid
    const invalidEmailDomains = emails.filter((email) => {
      const emailDomain = email.substring(email.indexOf('@') + 1, email.length);
      return !appState.org?.emailDomains?.includes(emailDomain);
    });

    if (invalidEmailDomains.length > 0) {
      enqueueSnackbar(
        `Invalid Email Domain(s): ${invalidEmailDomains.map((emailDomain: string, index: number) =>
          invalidEmailDomains.length === 1
            ? `${emailDomain}`
            : index === invalidEmailDomains.length - 1
            ? ` and ${emailDomain}.`
            : ` ${emailDomain}`
        )}`,
        {
          variant: 'error',
        }
      );

      return;
    }

    // For now, we will only add new emails to the team
    // TODO: For non-existing emails, send an invite to join the organization
    const req = infinitusai.teams.AddMembersRequest.fromObject({
      teamUuid,
      memberEmails: newEmails,
    });

    addMembers.mutate(req, {
      onSuccess: () => {
        enqueueSnackbar(`Successfully added members to team '${teamName}'`, {
          variant: 'success',
        });
        handleClose();
        formik.resetForm();
        formik.setSubmitting(false);
      },
      onError: () => {
        enqueueSnackbar(`Failed to add members to team '${teamName}'`, {
          variant: 'error',
        });
      },
    });
  };

  const validationSchema = Yup.object().shape({
    emails: Yup.array()
      .required('Required')
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(
        Yup.string()
          .email(({ value }) => `${value} is not a valid email`)
          .test(
            'is-org-user-not-member-yet',
            'Email is not part of the organization or is already a member of the team',
            (value) =>
              !!value && orgUserEmailHashtable[value.toLowerCase()] && !memberEmailHashtable[value]
          )
      ),
  });

  const formik = useFormik<{ emails: string[] }>({
    enableReinitialize: true,
    initialValues: { emails: [] },
    onSubmit: handleFormSubmitted,
    validationSchema: validationSchema,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleConfirmClose = () => {
    if (formik.dirty) {
      confirm({
        title: 'Abandon Member Addition?',
        description: 'Are you sure you want to abandon the process of adding a member?',
        footnote: 'No changes will be saved if you proceed.',
        confirmText: 'Abandon',
        onConfirm: handleClose,
      });
    } else {
      handleClose();
    }
  };

  const handleBlur = () => {
    if (inputValue.length > 0) {
      formik.setFieldValue('emails', [...formik.values.emails, inputValue]);
      setInputValue('');
    }
  };

  const handleInputPaste = (event: any) => {
    const pastedText = event.clipboardData.getData('text');
    const emails = pastedText.split(/[\s,]+/).map((email: string) => email.trim());
    formik.setFieldValue('emails', [...formik.values.emails, ...emails]);
    event.preventDefault();
  };

  function categorizeEmail(email: string): [EmailCategory, string] {
    if (orgUserEmailHashtable[email]) {
      if (memberEmailHashtable[email]) {
        return [EmailCategory.MEMBER, 'This user already exists in this team'];
      } else {
        return [
          EmailCategory.VALID_CANDIDATE,
          'This user is in the current organization and can be added to the team.',
        ];
      }
    } else {
      // Check whether it's a correct email format
      if (!Yup.string().email().isValidSync(email)) {
        return [EmailCategory.INVALID_EMAIL_FORMAT, 'This is not a valid email address format.'];
      }
      // Check whether the email domain is in the valid domain set that the org allows
      const emailDomain = getEmailDomain(email);
      const validEmailDomains = appState.org?.emailDomains;
      if (validEmailDomains && !validEmailDomains?.includes(emailDomain)) {
        return [
          EmailCategory.INVALID_DOMAIN,
          'This email is not valid for the current organization.',
        ];
      }

      // Otherwise, it's a non-org user
      return [EmailCategory.NON_ORG_USER, 'This user does not exist in the current organization.'];
    }
  }

  const displayEmailIcon = (category: EmailCategory) => {
    switch (category) {
      case EmailCategory.VALID_CANDIDATE:
        return <CheckCircleIcon style={{ color: theme.palette.success.main }} />;
      case EmailCategory.MEMBER:
      case EmailCategory.INVALID_EMAIL_FORMAT:
      case EmailCategory.INVALID_DOMAIN:
      case EmailCategory.NON_ORG_USER:
        return <ErrorIcon style={{ color: theme.palette.error.main }} />;
      default:
        return <ErrorIcon />;
    }
  };

  return (
    <Modal aria-labelledby="add-member-modal-title" onClose={handleConfirmClose} open={open}>
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography component="h2" id="add-member-modal-title" variant="h6">
            Add Member
          </Typography>
          <IconButton onClick={handleConfirmClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography>
            Select one or more members you want to add to the <strong>{teamName}</strong> team.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Add member(s): </Typography>
          <Typography>{`${formik.values.emails.length} member${
            formik.values.emails.length > 1 ? 's' : ''
          } total`}</Typography>
        </Box>
        <Autocomplete
          id="add-team-members"
          freeSolo
          multiple
          options={[]}
          inputValue={inputValue}
          value={formik.values.emails}
          onChange={(_, newValue) => {
            formik.setFieldValue('emails', newValue);
          }}
          onBlur={handleBlur}
          onInputChange={(_, newInputValue) => {
            const lastChar = newInputValue.charAt(newInputValue.length - 1);
            if (lastChar === ',' || lastChar === ' ') {
              formik.setFieldValue('emails', [
                ...formik.values.emails,
                newInputValue.slice(0, newInputValue.length - 1),
              ]);
              setInputValue('');
            } else {
              setInputValue(newInputValue);
            }
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((email, index) => {
              const { onDelete, ...tagProps } = getTagProps({ index });

              const [category, msg] = categorizeEmail(email);

              return (
                <Tooltip key={`tooltip-${teamName}-${email}`} title={msg} placement="top">
                  <Chip
                    {...tagProps}
                    key={`${teamName}-${email}`}
                    label={email}
                    avatar={displayEmailIcon(category)}
                    onDelete={onDelete}
                    variant="filled"
                  />
                </Tooltip>
              );
            })
          }
          renderInput={(params) => (
            <InfTextField
              {...params}
              label="Email Addresses"
              placeholder="user@email.com"
              unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
              error={formik.touched.emails && Boolean(formik.errors.emails)}
              helperText={
                emailInputTextFocused &&
                `Enter emails separated by spaces or commas. Only invited members can be added here. ${
                  appState.org?.emailDomains
                    ? `Supported domains: ${appState.org.emailDomains.map(
                        (emailDomain) => ` ${emailDomain}`
                      )}`
                    : null
                }`
              }
              onFocus={() => setEmailInputTextFocused(true)}
              onBlur={() => {
                handleBlur();
              }}
              onPaste={handleInputPaste}
              // This 'multiline' and 'rows' prop will show warning signs in Chrome
              // since Autocomplete expects a single line text input
              // For better UX, we will keep it as multiline for now
              // We can ignore the warning signs for now
              multiline
              rows={7}
              sx={{
                '& .MuiInputBase-root': {
                  minHeight: '200px',
                  alignItems: 'flex-start',
                },
              }}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button
            disabled={addMembers.isLoading}
            onClick={handleConfirmClose}
            sx={{ borderRadius: '16px', mr: 1, minWidth: '150px' }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={
              !hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN]) || // Disable if user does not have permission
              formik.isSubmitting || // Disable during form submission
              !formik.dirty || // Disable if the form has not been edited
              !formik.isValid || // Disable if the form is invalid
              formik.values.emails.length === 0 || // Disable if the emails array is empty
              addMembers.isLoading // Disable when loading
            }
            loading={addMembers.isLoading}
            onClick={formik.submitForm}
            sx={{
              borderRadius: '16px',
              bgcolor: (theme) => theme.palette.primary.main,
              minWidth: '150px',
              textTransform: 'none',
            }}
            variant="contained"
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddMemberModal;
