import { ErrorMessage, useAppState } from '@infinitusai/shared';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useBreakpoint,
  TextField,
  FadeTransition,
  useConfirm,
} from '@infinitusai/ui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { format } from 'date-fns';
import React from 'react';

import { useGetOrgPrograms, useGetTimeSavings } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { getTaskTypeDisplayNamePortal } from 'utils/displayNames';

import TimeSavingsChart from './TimeSavingsChart';

const getUnixDateFormat = (key: string, formatString: string) => {
  const string = new Date(Number(key));
  return format(string, formatString);
};
interface Filter {
  id: string;
  header: string;
  value: string | number;
}

function TimeSavingsSection() {
  const smBreakpoint = useBreakpoint('sm');
  const confirm = useConfirm();
  const appState = useAppState();
  const [filterSelected, setFilterSelected] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [saveFilters, setSaveFilters] = React.useState(false);
  const [currentFilters, setCurrentFilters] = React.useState<Filter[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [savedFilters, setSavedFilters] = React.useState(() => {
    const allFilters = JSON.parse(window.localStorage.getItem('filters') || '{}');
    return allFilters['time-savings-chart'] || {};
  });

  const savedFiltersOpen = Boolean(anchorEl);
  const [newFilterName, setNewFilterName] = React.useState('');
  const [taskType, setTaskType] = React.useState<Number>(
    (currentFilters.find((f) => f.id === 'taskType')?.value as Number) ?? 0
  );
  const [programId, setProgramId] = React.useState(
    (currentFilters.find((f) => f.id === 'programId')?.value as string) ?? ''
  );

  const [startDateRange, setStartDateRange] = React.useState<Date | string>(
    currentFilters.find((f) => f.id === 'startDate')?.value
      ? new Date(currentFilters.find((f) => f.id === 'startDate')?.value as string)
      : new Date(parseInt(appState.org?.timeSavingsStartDate ?? '', 10) * 1000)
  );
  const [endDateRange, setEndDateRange] = React.useState<Date | string>(
    currentFilters.find((f) => f.id === 'endDate')?.value
      ? new Date(currentFilters.find((f) => f.id === 'endDate')?.value as string)
      : new Date()
  );
  const getOrgPrograms = useGetOrgPrograms();
  const getTimeSavings = useGetTimeSavings(
    taskType,
    programId,
    (new Date(startDateRange ?? '').getTime() / 1000).toString(),
    Math.floor(new Date(endDateRange).getTime() / 1000).toString()
  );

  React.useEffect(() => {
    if (currentFilters.find((f) => f.id === 'taskType')?.value) {
      setTaskType(currentFilters.find((f) => f.id === 'taskType')?.value as Number);
    }
    if (currentFilters.find((f) => f.id === 'programId')?.value) {
      setProgramId(currentFilters.find((f) => f.id === 'programId')?.value as string);
    }
    if (currentFilters.find((f) => f.id === 'startDate')?.value) {
      setStartDateRange(
        new Date(currentFilters.find((f) => f.id === 'startDate')?.value as string)
      );
    }
    if (currentFilters.find((f) => f.id === 'endDate')?.value) {
      setEndDateRange(new Date(currentFilters.find((f) => f.id === 'endDate')?.value as string));
    }
  }, [currentFilters]);

  const tasks = React.useMemo(() => {
    if (getTimeSavings.isError) {
      return {};
    }
    return (
      getTimeSavings.isFetched &&
      (getTimeSavings.data?.avgTimeSavingsByRange as { [key: string]: number })
    );
  }, [
    getTimeSavings.data?.avgTimeSavingsByRange,
    getTimeSavings.isError,
    getTimeSavings.isFetched,
  ]);

  const timeSavings = React.useMemo(() => {
    if (!tasks) return [];
    const data = Object.entries(tasks).map(([key, value]) => {
      let startTimestamp = '';
      let endTimestamp = '';

      if (key.includes('-')) {
        const date = key.split('-');
        startTimestamp = getUnixDateFormat(date[0], 'M/d/yyyy');
        endTimestamp = getUnixDateFormat(date[1], 'M/d/yyyy');

        return {
          name: `${startTimestamp}-\n ${endTimestamp}`,
          value: Math.ceil(value || 0),
        };
      } else {
        return {
          name: getUnixDateFormat(key, 'M/d/yyyy'),
          value: Math.ceil(value || 0),
        };
      }
    });
    return data;
  }, [tasks]);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  function Chart() {
    const data = timeSavings;
    const averageTimeSaved = Math.ceil(Number(getTimeSavings.data?.avgTimeSavingsForRange));

    return (
      <Grid container direction={smBreakpoint ? 'row' : 'column'}>
        <Grid item xs={3} mt={4}>
          <Stack
            alignContent="center"
            display="flex"
            gap={2}
            alignItems={smBreakpoint ? '' : 'center'}
          >
            <Typography
              variant="body1"
              fontWeight="bold"
              textAlign={smBreakpoint ? 'left' : 'center'}
            >
              Average time
              <br /> per task:
            </Typography>
            <Box display="flex" alignItems="center" alignContent="center">
              <TimerOutlinedIcon fontSize="large" />
              <Typography variant="h4">{averageTimeSaved} m</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={9}>
          <TimeSavingsChart timeSavingsData={data || []} />
        </Grid>
      </Grid>
    );
  }

  const Loading = () => {
    return (
      <Box
        width="100%"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        display="flex"
      >
        <CircularProgress />
      </Box>
    );
  };

  const handleSavedFiltersClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSavedFiltersClose = () => {
    setAnchorEl(null);
  };

  const handleSaveDialogClose = () => {
    setSaveFilters(false);
    setNewFilterName('');
  };

  const handleFilterNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFilterName(event.target.value);
  };

  const handleSaveFilter = () => {
    const currentFilter = currentFilters;
    const allFilters = JSON.parse(window.localStorage.getItem('filters') || '{}');
    const savedFilters = allFilters['time-savings-chart'] || {};
    savedFilters[newFilterName] = currentFilter;
    allFilters['time-savings-chart'] = savedFilters;
    window.localStorage.setItem('filters', JSON.stringify(allFilters));
    setNewFilterName('');
    setSaveFilters(false);
  };

  // Function to add a new filter
  const addFilter = (newFilter: Filter) => {
    const existingFilterIndex = currentFilters.findIndex((filter) => filter.id === newFilter.id);

    if (existingFilterIndex !== -1) {
      const updatedFilters = [...currentFilters];
      updatedFilters[existingFilterIndex] = newFilter;
      setCurrentFilters(updatedFilters);
    } else {
      const updatedFilters = [...currentFilters, newFilter];
      setCurrentFilters(updatedFilters);
    }
  };

  // Function to remove a filter
  const removeFilter = (filterId: string) => {
    const updatedFilters = currentFilters.filter((filter) => filter.id !== filterId);
    setCurrentFilters(updatedFilters);
    localStorage.setItem('filters', JSON.stringify(updatedFilters));
  };

  const handleConfirmClose = () => {
    if (currentFilters.length !== 0 && !filterSelected) {
      confirm({
        title: 'Are you sure you want to close without saving filter?',
        description:
          'Filters applied can be saved and revisited later. Are you sure you want to abandon this filter?',
        onConfirm: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setTaskType(0);
    setProgramId('');
    setStartDateRange(new Date(parseInt(appState.org?.timeSavingsStartDate ?? '', 10) * 1000));
    setEndDateRange(new Date());
    setCurrentFilters([]);
    setOpen(false);
  };

  return (
    <Grid
      item
      sx={{
        border: `1.75px solid transparent`,
        borderColor: 'grey',
        boxShadow: (theme) => `0px 0px 4px rgba(0,0,0,0.25)`,
        borderOpacity: '5%',
        borderRadius: '10px',
      }}
      flexDirection="column"
      display="flex"
      p={2}
      mt={smBreakpoint ? 1 : 2}
      position="relative"
      height="100%"
      width="100%"
    >
      <Box display="inline-flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
          Time Savings
        </Typography>
        <Tooltip title="Filter Time Savings">
          <IconButton onClick={() => setOpen(true)}>
            <FilterAltRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box>
        {getTimeSavings.isLoading ? (
          <Loading />
        ) : getTimeSavings.isError ? (
          <Box height="250px">
            <ErrorMessage title="Error retrieving time savings data." />
          </Box>
        ) : !getTimeSavings?.data?.avgTimeSavingsForRange ? (
          <Box height="250px">
            <ErrorMessage
              title="No completed tasks."
              description="Time savings data will be displayed once a task has been completed or when tasks are found with given filters."
            />
          </Box>
        ) : getTimeSavings.isFetched ? (
          <Chart />
        ) : null}
      </Box>
      <Drawer open={open} onClose={handleConfirmClose}>
        <DrawerHeader title="Filter Time Savings Data" onClose={handleConfirmClose}>
          <Typography variant="h5" component="div" sx={{ ml: { xs: 0, sm: -1.25 } }}>
            Filters
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <Button
              ref={buttonRef}
              onClick={handleSavedFiltersClick}
              endIcon={<ArrowDropDownIcon />}
            >
              Saved Filters
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={savedFiltersOpen}
              onClose={handleSavedFiltersClose}
              PaperProps={{
                sx: {
                  mt: 1.5,
                  minWidth: '320px',
                },
              }}
              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
              <Box
                sx={{
                  py: 1,
                  pl: 2,
                  pr: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant="subtitle1" ml={1}>
                    Saved Filters
                  </Typography>
                  <IconButton aria-label="close-filters" onClick={handleSavedFiltersClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                {Object.keys(savedFilters).length === 0 && (
                  <Box sx={{ p: 2, width: '100%', textAlign: 'center' }}>
                    <Typography>No filters saved</Typography>
                  </Box>
                )}
                {Object.keys(savedFilters).map((filter) => (
                  <Stack
                    key={filter}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Button
                      onClick={() => {
                        setCurrentFilters(savedFilters[filter]);
                        handleSavedFiltersClose();
                        setFilterSelected(true);
                      }}
                      endIcon={<ArrowOutwardIcon />}
                    >
                      {filter}
                    </Button>
                    <Button
                      color="error"
                      onClick={() => {
                        const allFilters = JSON.parse(
                          window.localStorage.getItem('filters') || '{}'
                        );
                        delete allFilters['time-savings-chart'][filter];
                        setSavedFilters(allFilters['time-savings-chart']);
                        window.localStorage.setItem('filters', JSON.stringify(allFilters));
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                ))}
              </Box>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{ mr: { xs: -1.25, sm: -2 } }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </DrawerHeader>

        <DrawerBody>
          <Box mx={2}>
            {currentFilters?.length ? (
              <>
                <Typography variant="subtitle2">Filters Applied:</Typography>
                <Box mt={1}>
                  {currentFilters?.map((filter: Filter) => (
                    <Chip
                      key={filter.id}
                      variant="outlined"
                      id={filter.id}
                      label={`${filter.header}: ${
                        filter.id === 'taskType'
                          ? getTaskTypeDisplayNamePortal(
                              infinitusai.be.TaskType[
                                filter.value as keyof typeof infinitusai.be.TaskType
                              ]
                            )
                          : filter.id !== 'programId'
                          ? format(new Date(filter.value as string), 'MMMM do, yyyy @ h:mm a')
                          : filter.value
                      }`}
                      sx={{ m: 0.5 }}
                      onDelete={() => removeFilter(filter.id)}
                    />
                  ))}
                  <Button onClick={() => setSaveFilters(true)} size="small" color="primary">
                    Save Filter
                  </Button>
                </Box>
              </>
            ) : null}
          </Box>
          <ListSubheader>
            <Typography variant="overline">TASK TYPE</Typography>
          </ListSubheader>
          <Stack mb={1} ml="1rem" mr="1rem">
            <FormControl fullWidth>
              <InputLabel id={`filter-taskType-select-label`}>Task Type</InputLabel>
              <Select
                id={`filter-taskType-select`}
                labelId={`filter-taskType-select-label`}
                value={taskType || ''}
                label="Task Type"
                onChange={(event) => {
                  setTaskType(Number(event.target.value));
                  if (Number(event.target.value) === 0) {
                    removeFilter('taskType');
                  } else {
                    addFilter({
                      id: 'taskType',
                      header: 'Task Type',
                      value: Number(event.target.value),
                    });
                  }
                }}
              >
                <MenuItem key={'TASK_TYPE_UNKNOWN'} value={''}>
                  All
                </MenuItem>
                {Object.keys(infinitusai.be.TaskType)
                  .filter(
                    (key, value) =>
                      ![
                        infinitusai.be.TaskType.TASK_TYPE_UNKNOWN,
                        infinitusai.be.TaskType.TASK_TYPE_MED_SUPP_BV,
                        infinitusai.be.TaskType.TASK_TYPE_INBOUND,
                        infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_MM,
                        infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_PBM,
                        infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_APPEAL,
                        infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_DENIAL,
                        infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP,
                        infinitusai.be.TaskType.TASK_TYPE_COVID_VACCINATIONS,
                      ].includes(
                        infinitusai.be.TaskType[key as keyof typeof infinitusai.be.TaskType]
                      )
                  )
                  .map((key, value) => (
                    <MenuItem
                      key={infinitusai.be.TaskType[key as keyof typeof infinitusai.be.TaskType]}
                      value={infinitusai.be.TaskType[key as keyof typeof infinitusai.be.TaskType]}
                    >
                      {getTaskTypeDisplayNamePortal(
                        infinitusai.be.TaskType[key as keyof typeof infinitusai.be.TaskType]
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <ListSubheader>
            <Typography variant="overline">PROGRAM ID</Typography>
          </ListSubheader>
          <Stack mb="1rem" ml="1rem" mr="1rem">
            <FormControl fullWidth>
              <InputLabel id={`filter-programID-select-label`}>Program ID</InputLabel>
              <Select
                id={`filter-programID-select`}
                labelId={`filter-programID-select-label`}
                value={programId || ''}
                label="Program ID"
                onChange={(event) => {
                  setProgramId(event.target.value);
                  if (!event.target.value) {
                    removeFilter('programId');
                  } else {
                    addFilter({ id: 'programId', header: 'Program ID', value: event.target.value });
                  }
                }}
              >
                <MenuItem value="">All</MenuItem>
                {getOrgPrograms.data?.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name ?? option.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack direction={smBreakpoint ? 'row' : 'column'} gap={2} marginTop={3}>
              <DateTimePicker
                label={`Time Savings Start Date`}
                orientation="portrait"
                value={startDateRange}
                onChange={(newValue) => {
                  setStartDateRange(newValue as string);
                  addFilter({
                    id: 'startDate',
                    header: 'Start Date Range',
                    value: new Date(newValue as string).toString(),
                  });
                }}
                minDateTime={
                  new Date(parseInt(appState.org?.timeSavingsStartDate ?? '', 10) * 1000)
                }
                maxDateTime={new Date()}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
              <DateTimePicker
                label={`Time Savings End Date`}
                orientation="portrait"
                value={endDateRange}
                onChange={(newValue) => {
                  setEndDateRange(newValue as string);
                  addFilter({
                    id: 'endDate',
                    header: 'End Date Range',
                    value: new Date(newValue as string).toString(),
                  });
                }}
                minDateTime={
                  new Date(parseInt(appState.org?.timeSavingsStartDate ?? '', 10) * 1000)
                }
                maxDateTime={new Date()}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </Stack>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Button
            size="large"
            color="primary"
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
          <Box flexGrow={1} />
          <Button size="large" color="primary" variant="contained" onClick={() => setOpen(false)}>
            Show Chart
          </Button>
        </DrawerFooter>
      </Drawer>
      <Dialog
        fullWidth
        open={saveFilters}
        onClose={handleSaveDialogClose}
        TransitionComponent={FadeTransition}
        aria-labelledby="save-filter-dialog-title"
        aria-describedby="save-filter-dialog-description"
      >
        <DialogTitle>Name your filter</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ my: 2 }}
            value={newFilterName}
            label="Filter Name"
            inputProps={{ maxLength: 50 }}
            placeholder="Filter name"
            helperText="Choose a name you will remember later to reference this set of filters."
            onChange={handleFilterNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: 'action.active' }} onClick={handleSaveDialogClose} size="large">
            Cancel
          </Button>
          <Button onClick={handleSaveFilter} autoFocus disabled={!newFilterName} size="large">
            Save Filter
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
export default TimeSavingsSection;
