import { PermissionName, useAuth } from '@infinitusai/auth';
import { useAppState } from '@infinitusai/shared';
import { Table, useTable } from '@infinitusai/table';
import { TextField as InfTextField } from '@infinitusai/ui';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Chip, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetOrgUsers } from 'api/auth';
import { useEditTeamName, useGetTeamDetails } from 'api/customer';
import { infinitusai } from 'proto/pbjs';
import { stringToColor } from 'utils/color';

import AddMemberModal from './AddMemberModal';
import RemoveMemberModal from './RemoveMemberModal';

interface Props {
  teamUuid: string;
}

const FastTrackTeamDetailsPage = ({ teamUuid }: Props) => {
  const appState = useAppState();
  const navigate = useNavigate();
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const getOrgUsers = useGetOrgUsers();
  const getTeamDetails = useGetTeamDetails(teamUuid);
  const editTeamName = useEditTeamName();

  const [isEditingTeamName, setIsEditingTeamName] = React.useState(false);
  const [newTeamName, setNewTeamName] = React.useState('');
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = React.useState(false);
  const [memberToRemove, setMemberToRemove] = React.useState<{
    email: string;
    name: string;
  } | null>(null);

  // Memoize the email lookup table/hash table for org users
  const orgUserEmailHashtable: { [key: string]: boolean } = React.useMemo(() => {
    const hashtable: { [key: string]: boolean } = {};
    getOrgUsers.data?.forEach((user) => {
      hashtable[user.email] = true;
    });
    return hashtable;
  }, [getOrgUsers.data]);

  // Memoize the email lookup table/hash table for team members
  const memberEmailHashtable: { [key: string]: boolean } = React.useMemo(() => {
    const hashtable: { [key: string]: boolean } = {};
    getTeamDetails.data?.members?.forEach((member) => {
      if (member.user?.email) {
        hashtable[member.user?.email] = true;
      }
    });
    return hashtable;
  }, [getTeamDetails.data?.members]);

  React.useEffect(() => {
    if (getTeamDetails.data?.team?.name) {
      setNewTeamName(getTeamDetails.data.team.name);
    }
  }, [getTeamDetails.data?.team?.name]);

  const handleEditTeamName = () => {
    setIsEditingTeamName(!isEditingTeamName);
    setNewTeamName(getTeamDetails.data?.team?.name || '');
  };

  const handleSaveNewTeamName = () => {
    if (newTeamName !== getTeamDetails.data?.team?.name) {
      const req = infinitusai.teams.EditTeamRequest.fromObject({
        teamUuid,
        name: newTeamName,
      });
      editTeamName.mutate(req, {
        onSuccess: () => {
          enqueueSnackbar(`Successfuly updated to '${newTeamName}'`, {
            variant: 'success',
          });
          setIsEditingTeamName(false);
        },
        onError: () => {
          enqueueSnackbar(`Failed to update team name to '${newTeamName}'`, {
            variant: 'error',
          });
        },
      });
    } else {
      setIsEditingTeamName(false);
    }
  };

  const handleOpenAddMemberModal = () => {
    setIsAddMemberModalOpen(true);
  };

  const handleCloseAddMemberModal = () => {
    setIsAddMemberModalOpen(false);
  };

  const handleOpenRemoveMemberModal = (member: infinitusai.teams.Member) => {
    setMemberToRemove({
      email: member.user?.email || '',
      name: member.user?.displayName || '',
    });
  };

  const handleCloseRemoveMemberModal = () => {
    setMemberToRemove(null);
  };

  // Function to handle the back button click
  const handleBackButtonClick = () => {
    navigate(-1); // This will take you back to the previous page
  };

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.teams.Member>();
    return [
      columnHelper.accessor('user.displayName', {
        header: 'Name',
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('user.email', {
        header: 'Email',
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('roles', {
        header: 'Roles assigned',
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (info) => {
          const roles = info.getValue();
          if (roles) {
            return roles.map((role) => (
              <Chip
                key={role.name}
                label={role.name}
                sx={{
                  border: 'none',
                  backgroundColor: 'default',
                  m: 0.5,
                }}
                variant="filled"
              />
            ));
          }
        },
      }),
      columnHelper.accessor('teams', {
        header: 'Additional teams',
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (info) => {
          const teams = info.getValue();
          if (teams) {
            return teams.map((team) => (
              <Chip
                label={team.teamName}
                key={team.teamUuid}
                onClick={() =>
                  navigate(`/${appState.org?.name}/account/members?teamUuid=${team.teamUuid}`)
                }
                variant="outlined"
                style={{ color: stringToColor(team.teamName || 'default') }}
                sx={{ mr: 1, mb: 1 }}
              />
            ));
          }
        },
      }),
      columnHelper.display({
        id: 'remove_from_team',
        header: () => <Box sx={{ textAlign: 'center' }}>Remove from team</Box>,
        enableHiding: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (info) => (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              color="primary"
              disabled={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
              onClick={() => handleOpenRemoveMemberModal(info.row.original)}
              sx={{
                border: '1px solid',
                borderRadius: '50%',
                color: (theme) => theme.palette.error.main,
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      }),
    ];
  }, [appState.org?.name, navigate, hasPermission]);

  const table = useTable<infinitusai.teams.Member>({
    id: 'team-details',
    data: getTeamDetails.data.members || [],
    columns,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    isLoading: getTeamDetails.isLoading,
    totalRows: getTeamDetails.data?.team?.members?.length || 0,
    noRowsMessage: 'No members found.',
    enableSorting: true,
    enableUrlSync: true,
    getRowId: (row) => row.user?.userId || '',
  });

  const canEditTeamName = hasPermission([PermissionName.CUSTOMER_RBAC_WRITE]);
  const saveIconDisabled = isEditingTeamName && newTeamName === getTeamDetails.data?.team?.name;

  return (
    <React.Fragment>
      {getTeamDetails.isLoading && (
        <Box sx={{ display: 'flex', m: 2 }}>
          <CircularProgress />
          <Typography sx={{ marginLeft: 2 }} variant="body1">
            Loading team details...
          </Typography>
        </Box>
      )}
      {!getTeamDetails.isLoading && (
        <>
          <Stack alignItems="left" direction="column">
            <Box alignItems="center" display="flex" sx={{ my: 0, ml: 0 }}>
              <IconButton onClick={handleBackButtonClick}>
                <ArrowBackIcon sx={{ fontSize: 25 }} />
                <Typography sx={{ ml: 0.5 }} variant="subtitle1">
                  Back
                </Typography>
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 3,
                mb: 3,
              }}
            >
              <Box alignItems="center" display="flex" sx={{ mt: 0 }}>
                {isEditingTeamName ? (
                  <InfTextField
                    value={newTeamName}
                    onChange={(e) => setNewTeamName(e.target.value)}
                    onBlur={handleSaveNewTeamName}
                    autoFocus
                    size="small"
                    unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
                  />
                ) : (
                  <Typography component="h1" variant="h5">
                    {getTeamDetails.data?.team?.name || '-'}
                  </Typography>
                )}
                <IconButton
                  onClick={isEditingTeamName ? handleSaveNewTeamName : handleEditTeamName}
                  disabled={!canEditTeamName || saveIconDisabled}
                >
                  {isEditingTeamName ? (
                    <SaveAsIcon color={saveIconDisabled ? 'disabled' : 'primary'} />
                  ) : (
                    <EditIcon color="primary" />
                  )}
                </IconButton>
              </Box>
              <Button
                color="primary"
                onClick={handleOpenAddMemberModal}
                startIcon={<AddIcon />}
                sx={{ borderRadius: '20px', textTransform: 'none' }}
                variant="contained"
              >
                Add member
              </Button>
            </Box>
            <Box mt={1}>
              <Table table={table} />
            </Box>
          </Stack>
        </>
      )}
      <AddMemberModal
        onClose={handleCloseAddMemberModal}
        open={isAddMemberModalOpen}
        orgUserEmailHashtable={orgUserEmailHashtable}
        memberEmailHashtable={memberEmailHashtable}
        teamName={getTeamDetails.data?.team?.name || 'Unknown name'}
        teamUuid={teamUuid}
      />
      <RemoveMemberModal
        email={memberToRemove?.email}
        name={memberToRemove?.name}
        teamUuid={teamUuid}
        onClose={handleCloseRemoveMemberModal}
        open={Boolean(memberToRemove)}
        teamName={getTeamDetails.data?.team?.name || 'Unknown name'}
      />
    </React.Fragment>
  );
};

export default FastTrackTeamDetailsPage;
