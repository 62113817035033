import { ErrorMessage } from '@infinitusai/shared';
import { Button, TextField, useBreakpoint } from '@infinitusai/ui';
import AddIcon from '@mui/icons-material/AddRounded';
import RemoveIcon from '@mui/icons-material/RemoveRounded';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import format from 'date-fns/format';
import { Formik, FieldAttributes, Field, FieldArray, FormikHelpers } from 'formik';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import Accordion, { AccordionSummary, AccordionDetails } from 'components/Accordion';
import { infinitusai, infinitusapi } from 'proto/pbjs';
import { getFacilityTypeDisplayName } from 'utils/displayNames';

import {
  useGetPatientData,
  useCreateTask,
  useGetOrgPrograms,
  useGetPayers,
  useGetProducts,
} from '../fhirClient';
import { TaskDataFieldMap, miscellaneousPayerId, taskInputsFieldMap } from './constants';

const TypedObjectEntries = Object.entries as <T>(o: T) => [Extract<keyof T, string>, T[keyof T]][];

const MAX_Z_INDEX = 9999;
interface Props {
  patientId: string;
  accessToken: string;
  idToken: string;
}

export function CreateTaskTab({ patientId, accessToken, idToken }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const smBreakpoint = useBreakpoint('sm');
  const createTasks = useCreateTask(accessToken, idToken);
  const getOrgProgramsQuery = useGetOrgPrograms(accessToken, idToken);
  const getProductsQuery = useGetProducts(accessToken, idToken);
  const { data: patientDetails, error: getPatientError } = useGetPatientData(
    patientId,
    accessToken,
    idToken
  );
  const getPayersQuery = useGetPayers(accessToken, idToken);
  const [clearPayersToggle, setClearPayersToggle] = React.useState(false);
  const [clearPracticeTypeToggle, setClearPracticeTypeToggle] = React.useState(false);
  const expandedInititalState = [
    'General Information',
    ...Object.keys(taskInputsFieldMap),
    'Product Information',
  ];
  const [expanded, setExpanded] = React.useState<Set<string>>(new Set(expandedInititalState));
  const [taskDataFields, setTaskDataFields] = React.useState<TaskDataFieldMap>({});

  React.useEffect(() => {
    setTaskDataFields(taskInputsFieldMap);
  }, []);

  function onSubmit(event: any, { setSubmitting, resetForm, setFieldValue }: FormikHelpers<any>) {
    async function createTask() {
      try {
        const body = infinitusapi.CreateTasksRequest.fromObject({
          tasks: [
            infinitusapi.CreateTasksRequest.TasksMessage.fromObject({
              taskType: infinitusapi.INFTaskType.INF_TASK_TYPE_BENEFITS_VERIFICATION, //getTaskType(searchParam).taskType,
              bvTaskInput: infinitusapi.INFPBMTaskInput.fromObject({
                chainPBM: false,
                member: infinitusapi.INFTaskInputMember.fromObject({
                  firstName: event.bvInputs.patientInfo.firstName,
                  lastName: event.bvInputs.patientInfo.lastName,
                  address: infinitusapi.INFAddress.fromObject(
                    event.bvInputs.patientInfo.addressInfo
                  ),
                  dateOfBirth: format(new Date(event.bvInputs.patientInfo.birthday), 'MM/dd/yyyy'),
                }),
                payer: infinitusapi.INFTaskInputPayer.fromObject({
                  customerPayerName: event.bvInputs.payerInfo.name,
                  infinitusPayerId: event.bvInputs.payerInfo.id,
                  customerPayerPhoneNumber:
                    event.bvInputs.payerInfo.id === miscellaneousPayerId
                      ? event.bvInputs.payerInfo.phoneNumber
                      : '',
                  subscriberId: event.bvInputs.payerInfo.subscriberId,
                  ...event.bvInputs.policyInfo,
                }),
                products: event.bvInputs.productInfos.map((product: { [k: string]: any }) =>
                  infinitusapi.INFTaskInputProduct.fromObject(product)
                ),
                practice: infinitusapi.INFTaskInputPractice.fromObject({
                  address: infinitusapi.INFAddress.fromObject({
                    zip: event.bvInputs.practiceInfo.zip,
                  }),
                  ...event.bvInputs.practiceInfo,
                }),

                provider: infinitusapi.INFTaskInputProvider.fromObject({
                  address: infinitusapi.INFAddress.fromObject(
                    event.bvInputs.providerInfo.addressInfo
                  ),
                  ...event.bvInputs.providerInfo,
                }),
                adminCodes: event.bvInputs.treatmentInfo.adminCodes?.replaceAll(' ', '').split(','),
              }),
              customerId: event.customerAssignedId,
              programId: event.programName,
              customerSegment: event.customerSegment,
            }),
          ],
        });

        createTasks.mutate(body, {
          onSuccess: (res) => {
            if (res.data[0].error !== null || res.data[0].errors?.length !== 0) {
              enqueueSnackbar(
                `Failed to create task: ${res.data[0].errors?.map((error) => error.errorMessage)}`,
                {
                  variant: 'error',
                }
              );
            } else {
              enqueueSnackbar(`Succesfully created task: ${res.data[0].taskId}`, {
                variant: 'success',
              });

              setFieldValue('programName', '');
              setFieldValue('customerAssignedId', '');
              setFieldValue('customerSegment', '');
              TypedObjectEntries(taskDataFields).forEach(([sectionName, fields]) => {
                TypedObjectEntries(fields).forEach(([fieldName, _]) => {
                  let newVal: any = '';
                  if (fieldName === 'patientInfo.birthday') newVal = null;
                  setFieldValue(`bvInputs.${fieldName}`, newVal);
                  setClearPayersToggle(!clearPayersToggle);
                  setClearPracticeTypeToggle(!clearPracticeTypeToggle);
                });
              });
              resetForm();
              window.scrollTo(0, 0);
            }
          },
          onError: (err) => {
            enqueueSnackbar(`Failed to create task: ${err.message}`, {
              variant: 'error',
            });
          },
        });
      } catch (err) {
        enqueueSnackbar(`Failed to create task: ${err ? err : ''}`, {
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    }
    createTask();
  }

  const initialValues: { [k: string]: any } = React.useMemo(() => {
    return {
      programName: '',
      customerAssignedId: '',
      customerSegment: '',
      bvInputs: {
        policyInfo: {
          memberId: '',
          groupId: '',
          groupName: '',
          planName: '',
        },
        patientInfo: patientDetails?.member
          ? {
              firstName: patientDetails.member.firstName,
              lastName: patientDetails.member.lastName,
              birthday: new Date(patientDetails.member.dateOfBirth || ''),
              addressInfo: patientDetails.member.address,
            }
          : {
              firstName: '',
              lastName: '',
              birthday: '',
              addressInfo: {
                streetAddress: '',
                city: '',
                state: '',
                zip: '',
              },
            },
        providerInfo: patientDetails?.provider
          ? {
              ...patientDetails.provider,
              addressInfo: patientDetails.provider.address,
            }
          : {
              firstName: '',
              lastName: '',
              npi: '',
              taxId: '',
              addressInfo: {
                streetAddress: '',
                city: '',
                state: '',
                zip: '',
              },
            },
        adminCodes: [],
        practiceInfo: patientDetails?.practice
          ? { ...patientDetails.practice, zip: patientDetails.practice.address?.zip }
          : {
              facilityType: {
                type: '',
              },
              zip: '',
              name: '',
              npi: '',
              taxId: '',
            },
        treatmentInfo: {
          treatmentCode: '',
          secondaryTreatmentCode: '',
          tertiaryTreatmentCode: '',
        },
        payerInfo: patientDetails?.payer
          ? { ...patientDetails.payer, name: patientDetails.payer.customerPayerName }
          : {
              name: '',
              id: '',
              subscriberId: '',
            },
        productInfos:
          patientDetails?.products && patientDetails?.products.length > 0
            ? patientDetails.products.map((product) => {
                return {
                  name: product.name,
                  code: product.code,
                };
              })
            : [
                {
                  name: '',
                  code: '',
                },
              ],
      },
    };
  }, [patientDetails]);
  const styles = {
    title: {
      textTransform: 'uppercase',
      fontSize: '0.9rem',
      fontWeight: 900,
      flexGrow: 1,
      whiteSpace: 'normal',
      overflow: 'hidden',
      m: '8px 0',
    },
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    if (newExpanded) {
      setExpanded(new Set(expanded.add(panel)));
    } else {
      setExpanded((expanded) => {
        const newExpanded = new Set(expanded);
        newExpanded.delete(panel);
        return newExpanded;
      });
    }
  };

  const handleExpandAll = () => {
    setExpanded(new Set(expandedInititalState));
  };

  const handleCollapseAll = () => {
    setExpanded(new Set());
  };

  const showPayerPhoneNumberFieldWhenNecessary = (payerId: string) => {
    if (payerId === miscellaneousPayerId) {
      setTaskDataFields((prevFields) => ({
        ...prevFields,
        'Payer information': {
          ...prevFields['Payer information'],
          'payerInfo.phoneNumber': {
            label: 'Payer Phone Number *',
            required: true,
          },
        },
      }));
    } else {
      setTaskDataFields({ ...taskInputsFieldMap });
    }
  };

  if (!patientDetails)
    return (
      <Box
        sx={{
          minHeight: '100%',
          minWidth: '100%',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          paddingTop: '20%',
        }}
      >
        <CircularProgress size={100} />
      </Box>
    );
  else if (getPatientError) return <ErrorMessage title="unauthorized" />;
  else
    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ submitForm, isSubmitting, setFieldValue, values, dirty, isValid }) => {
          return (
            <>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ flexGrow: 1, overflowY: 'scroll', width: '100%' }}
                mb="50px"
              >
                <Box display="flex" justifyContent="flex-end" mb={1}>
                  {expanded.size === expandedInititalState.length ? (
                    <Button color="primary" onClick={handleCollapseAll}>
                      Collapse All
                    </Button>
                  ) : (
                    <Button color="primary" onClick={handleExpandAll}>
                      Expand All
                    </Button>
                  )}
                </Box>
                <Accordion
                  expanded={expanded.has('General Information')}
                  onChange={handleChange('General Information')}
                  disableGutters
                  elevation={0}
                  sx={{
                    bgcolor: 'transparent',
                    borderBottomColor: 'transparent',
                    ':before': {
                      display: 'none',
                    },
                    mb: 2,
                  }}
                >
                  <AccordionSummary title="General Information" entities={2}>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFieldValue('programName', '');
                        setFieldValue('customerAssignedId', '');
                        setFieldValue('customerSegment', '');
                      }}
                    >
                      Clear
                    </Button>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2} direction={smBreakpoint ? 'row' : 'column'}>
                      <Grid item xs={3}>
                        <InputLabel color="secondary" sx={styles.title}>
                          Program Name
                        </InputLabel>
                        <Field
                          size="small"
                          fullWidth
                          name="programName"
                          component={Select}
                          disabled={isSubmitting}
                          value={values.programName}
                        >
                          {getOrgProgramsQuery.data?.map((program) => {
                            return (
                              <MenuItem
                                value={program.name}
                                key={program.name}
                                onClick={() => {
                                  setFieldValue('programName', program.name);
                                }}
                              >
                                {program.displayName}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel color="secondary" sx={styles.title}>
                          Customer ID
                        </InputLabel>
                        <Field
                          size="small"
                          fullWidth
                          name="customerAssignedId"
                          component={TextField}
                          disabled={isSubmitting}
                          value={values.customerAssignedId}
                          onChange={(e: any) => {
                            setFieldValue('customerAssignedId', e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel color="secondary" sx={styles.title}>
                          Customer Segment
                        </InputLabel>
                        <Field
                          size="small"
                          fullWidth
                          name="customerSegment"
                          component={TextField}
                          disabled={isSubmitting}
                          value={values.customerSegment}
                          onChange={(e: any) => {
                            setFieldValue('customerSegment', e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {TypedObjectEntries(taskDataFields).map(([sectionName, fields]) => {
                  return (
                    <Accordion
                      key={sectionName}
                      expanded={expanded.has(sectionName)}
                      onChange={handleChange(sectionName)}
                      elevation={0}
                      disableGutters
                      sx={{
                        bgcolor: 'transparent',
                        borderBottomColor: 'transparent',
                        ':before': {
                          display: 'none',
                        },
                        mb: 2,
                      }}
                    >
                      <AccordionSummary
                        title={sectionName}
                        entities={TypedObjectEntries(fields).length}
                      >
                        <Button
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            TypedObjectEntries(fields).forEach(([fieldName, _]) => {
                              let newVal: any = '';
                              if (fieldName === 'patientInfo.birthday') newVal = null;
                              setFieldValue(`bvInputs.${fieldName}`, newVal);
                              if (fieldName === 'payerInfo') {
                                setFieldValue(`bvInputs.${fieldName}.name`, newVal);
                                setFieldValue(`bvInputs.${fieldName}.id`, newVal);
                              }
                            });
                            setClearPracticeTypeToggle(!clearPracticeTypeToggle);
                          }}
                        >
                          Clear
                        </Button>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 1, sm: 8, md: 12 }}
                          >
                            {TypedObjectEntries(fields).map(([fieldName, fieldConfig]) => {
                              let componentProps: Partial<FieldAttributes<any>> = {
                                component: TextField,
                                fullWidth: true,
                                InputLabelProps: { shrink: true },
                                disabled: isSubmitting,
                                size: 'small',
                                required: fieldConfig.required,
                                name: `bvInputs.${fieldName}`,
                                helperText: fieldConfig.helperText || '',
                                value: get(values, `bvInputs.${fieldName}`) || '',
                                onChange: (e: any) => {
                                  setFieldValue(`bvInputs.${fieldName}`, e.target.value);
                                },
                              };
                              if (fieldName === 'patientInfo.birthday') {
                                componentProps = {
                                  ...componentProps,
                                  component: DatePicker,
                                  inputFormat: 'MM/dd/yyyy',
                                  value: get(values, `bvInputs.${fieldName}`) || null,
                                  onChange: (momentDate: any) => {
                                    setFieldValue(`bvInputs.${fieldName}`, momentDate);
                                  },

                                  slotProps: { textField: { size: 'small', fullWidth: true } },
                                };
                              }

                              if (fieldName === 'payerInfo') {
                                componentProps = {
                                  component: Autocomplete,
                                  options: getPayersQuery.data || [],
                                  key: clearPayersToggle,
                                  getOptionLabel: (option: infinitusai.be.PayerDoc) =>
                                    option.name || '',
                                  name: `bvInputs.${fieldName}`,
                                  value: get(values, `bvInputs.${fieldName}`),
                                  onChange: (e: any, newValue: infinitusai.be.PayerDoc) => {
                                    showPayerPhoneNumberFieldWhenNecessary(newValue.id);
                                    setFieldValue(`bvInputs.${fieldName}.name`, newValue.name);
                                    setFieldValue(`bvInputs.${fieldName}.id`, newValue.id);
                                  },
                                  renderInput: (params: any) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      size="small"
                                      disabled={isSubmitting}
                                      label={fieldConfig.label}
                                    />
                                  ),
                                };
                              }
                              if (fieldName === 'treatmentInfo.facilityType.type') {
                                return (
                                  <Grid item key={fieldName} xs={3}>
                                    <InputLabel color="secondary" sx={styles.title}>
                                      {fieldConfig.label}
                                    </InputLabel>
                                    <Field
                                      size="small"
                                      fullWidth
                                      name={`bvInputs.${fieldName}`}
                                      component={Select}
                                      disabled={isSubmitting}
                                      key={clearPracticeTypeToggle}
                                    >
                                      {TypedObjectEntries(infinitusai.be.FacilityType.Type)
                                        .slice(1)
                                        .map(([key, value]) => {
                                          return (
                                            <MenuItem
                                              key={value}
                                              value={value}
                                              onClick={() => {
                                                setFieldValue(`bvInputs.${fieldName}`, value);
                                              }}
                                            >
                                              {getFacilityTypeDisplayName(value)}
                                            </MenuItem>
                                          );
                                        })}
                                    </Field>
                                  </Grid>
                                );
                              }

                              return (
                                <Grid item key={fieldName} xs={3}>
                                  <InputLabel color="secondary" sx={styles.title}>
                                    {fieldConfig.label}
                                  </InputLabel>
                                  <Field {...componentProps} />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}

                <Accordion
                  expanded={expanded.has('Product Information')}
                  onChange={handleChange('Product Information')}
                  elevation={0}
                  disableGutters
                  sx={{
                    bgcolor: 'transparent',
                    borderBottomColor: 'transparent',
                    ':before': {
                      display: 'none',
                    },
                    mb: 10,
                  }}
                >
                  <AccordionSummary title="Product Information" />
                  <AccordionDetails>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container item xs={12} direction={smBreakpoint ? 'row' : 'column'}>
                        <FieldArray
                          name="bvInputs.productInfos"
                          render={(arrayHelpers) => {
                            const enableButton =
                              values.bvInputs.productInfos[0]?.name &&
                              values.bvInputs.productInfos[0]?.code;
                            return (
                              <Box>
                                {values.bvInputs.productInfos.map((_: any, index: number) => {
                                  return (
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="bottom"
                                      margin="10px 0"
                                      key={index}
                                      flexDirection={smBreakpoint ? 'row' : 'column'}
                                    >
                                      <Box p={2}>
                                        <InputLabel color="secondary" sx={styles.title}>
                                          {`Product Code ${index + 1} *`}
                                        </InputLabel>
                                        <Field
                                          fullWidth
                                          size="small"
                                          component={Autocomplete}
                                          name={`bvInputs.productInfos.${index}.code`}
                                          options={[...Object.keys(getProductsQuery.data || {})]}
                                          getOptionLabel={(option: any) => option || ''}
                                          value={get(values, `bvInputs.productInfos.${index}.code`)}
                                          onChange={(e: any, newValue: any) => {
                                            setFieldValue(
                                              `bvInputs.productInfos.${index}.code`,
                                              newValue
                                            );
                                          }}
                                          renderInput={(params: any) => (
                                            <TextField {...params} fullWidth />
                                          )}
                                        ></Field>
                                      </Box>

                                      <Box p={2}>
                                        <InputLabel color="secondary" sx={styles.title}>
                                          {`Product Name ${index + 1} *`}
                                        </InputLabel>

                                        <Field
                                          fullWidth
                                          size="small"
                                          component={Autocomplete}
                                          name={`bvInputs.productInfos.${index}.name`}
                                          value={get(values, `bvInputs.productInfos.${index}.name`)}
                                          options={
                                            values.bvInputs.productInfos[index]?.code &&
                                            getProductsQuery.data
                                              ? (getProductsQuery.data[
                                                  values.bvInputs.productInfos[index]?.code
                                                ]?.names as string[])
                                              : [...Object.keys({})]
                                          }
                                          getOptionLabel={(option: any) => option || ''}
                                          onChange={(e: any, newValue: any) =>
                                            setFieldValue(
                                              `bvInputs.productInfos.${index}.name`,
                                              newValue
                                            )
                                          }
                                          renderInput={(params: any) => (
                                            <TextField {...params} fullWidth sx={{ mr: 1 }} />
                                          )}
                                        ></Field>
                                      </Box>

                                      <Box
                                        p={2}
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                      >
                                        <Tooltip title="Remove" placement="top" enterDelay={200}>
                                          <>
                                            <IconButton
                                              type="button"
                                              size="large"
                                              color="error"
                                              disabled={!enableButton}
                                              onClick={() => arrayHelpers.remove(index)}
                                            >
                                              <RemoveIcon />
                                            </IconButton>
                                          </>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  );
                                })}
                                <Tooltip title="Add another" placement="top" enterDelay={200}>
                                  <>
                                    <Button
                                      type="button"
                                      fullWidth
                                      variant="outlined"
                                      color="primary"
                                      size="large"
                                      disabled={!enableButton}
                                      startIcon={<AddIcon />}
                                      onClick={() =>
                                        arrayHelpers.push({
                                          productName: '',
                                          productCode: '',
                                        })
                                      }
                                    >
                                      Add New Product
                                    </Button>
                                  </>
                                </Tooltip>
                              </Box>
                            );
                          }}
                        ></FieldArray>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box
                flexGrow={1}
                position="sticky"
                p={2}
                bottom={0}
                m="-1rem"
                mt={1}
                bgcolor="background.default"
                borderTop={(theme) => `1px solid ${theme.palette.divider}`}
                zIndex={MAX_Z_INDEX}
              >
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth={!smBreakpoint}
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </>
          );
        }}
      </Formik>
    );
}
export default CreateTaskTab;
