import { useAuth, PermissionName } from '@infinitusai/auth';
import { Button, useConfirm } from '@infinitusai/ui';
import { useSnackbar } from 'notistack';

import { useDeleteWebhookConfig } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

interface Props {
  webhooksMetadata: infinitusai.be.WebhookConfigDoc;
}

function DeleteWebhookButton({ webhooksMetadata }: Props) {
  const auth = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const deleteWebhookConfig = useDeleteWebhookConfig();

  const handleDeleteWebhookConfig = () => {
    const req = infinitusai.be.DeleteWebhookConfigRequest.fromObject({
      secretId: webhooksMetadata?.secretId,
    });
    deleteWebhookConfig.mutate(req, {
      onSuccess: () => {
        enqueueSnackbar(`Successfully deleted webhook: ${webhooksMetadata?.url}`, {
          variant: 'success',
        });
      },
    });
  };

  return (
    <Button
      size="small"
      color="error"
      variant="text"
      sx={{
        mx: 1,
      }}
      unauthorized={!auth.hasPermission([PermissionName.CUSTOMER_API_KEYS_WRITE])}
      onClick={() =>
        confirm({
          title: 'Delete API Key',
          description: `Are you sure you want to delete the following webhook: ${webhooksMetadata?.url}?`,
          confirmText: 'Yes, Delete',
          onConfirm: handleDeleteWebhookConfig,
        })
      }
    >
      Delete
    </Button>
  );
}

export default DeleteWebhookButton;
