import { useAuth, PermissionName } from '@infinitusai/auth';
import { AudioPlayer, useCallRecordings } from '@infinitusai/shared';
import { Button } from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useParams } from 'react-router-dom';

import { getRuleTaskTypeDisplayName } from 'utils/displayNames';

function RecordingPlayer() {
  const { tid } = useParams();
  const { superAdmin, hasPermission } = useAuth();
  const { loading, error, requested, recordings, fetch } = useCallRecordings(tid || '');
  const hasDownloadRecordingPermission = hasPermission([
    PermissionName.CUSTOMER_TASK_RECORDINGS_DOWNLOAD,
  ]);

  let recordingContent: React.ReactNode = null;

  if (!requested || loading)
    recordingContent = (
      <Button
        size="medium"
        color="primary"
        variant="contained"
        onClick={() => fetch()}
        disabled={requested || loading}
        unauthorized={!hasPermission([PermissionName.CUSTOMER_TASK_RECORDINGS_LISTEN])}
      >
        Fetch Call Recording
      </Button>
    );
  else if (error)
    recordingContent = (
      <Grid container item justifyContent="center" alignItems="center">
        Could not load recording
      </Grid>
    );
  else
    recordingContent = (
      <Fade in={true} mountOnEnter unmountOnExit>
        <Grid>
          {recordings.map((item) => {
            const taskType = getRuleTaskTypeDisplayName(item.taskType);
            return (
              <Box mt={2} key={item.taskType}>
                {recordings.length > 1 && item?.urls?.filter((url) => url !== '').length !== 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h5">{taskType}</Typography>
                  </Grid>
                ) : null}

                {item.urls
                  .filter((url) => url !== '')
                  .map((url: string) => (
                    <AudioPlayer
                      key={url}
                      url={url}
                      downloadDisabled={!superAdmin && !hasDownloadRecordingPermission}
                    />
                  ))}
              </Box>
            );
          })}
        </Grid>
      </Fade>
    );

  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        {recordingContent}
      </Grid>
    </Grid>
  );
}
export default RecordingPlayer;
