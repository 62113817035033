import { AppName } from '@infinitusai/api';
import { fetchApi } from '@infinitusai/api';
import { QueryKey, useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import { infinitusai, infinitusapi } from 'proto/pbjs';

// Used for fhir related react query calls
function queryApi({ queryKey }: { queryKey: QueryKey }) {
  let [apiRoot, apiPath, apiBody, headers] = queryKey;
  const path = `/${apiRoot}/${apiPath}`;
  const body = apiBody || {};
  return fetchApi({
    appName: AppName.CUSTOMER,
    orgUuid: '',
    user: null,
    path,
    body,
    headers: headers,
  });
}

export function useGetPatientTasks(patientId?: string, accessToken?: string, idToken?: string) {
  const reqBody = infinitusai.be.GetTasksForEhrRequest.fromObject({
    patientId: patientId,
    limit: 100,
  });
  const headers = { Authorization: idToken, 'Fhir-Access-Token': accessToken };
  const getTasks = useQuery<infinitusai.be.GetTasksResponse, Error>(
    ['integration/fhir', 'getTasks', reqBody, headers],
    (key) => queryApi(key)
  );
  const data = React.useMemo(() => {
    if (!getTasks.data || !getTasks.data.tasks) return [];
    const { tasks } = getTasks.data;
    return tasks.map((task) => infinitusai.be.CustomerTaskDoc.fromObject(task));
  }, [getTasks.data]);
  return { ...getTasks, data };
}

export async function initHandshake(url: string | null): Promise<void> {
  if (!url) return;
  const response = await fetch(url + '/.well-known/smart-configuration', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
  const data = await response.json();
  return data;
}

export function useGetPatientData(patientId: string, accessToken: string, idToken: string) {
  const headers = { 'Fhir-Access-Token': accessToken, Authorization: idToken };
  const reqBody = infinitusai.be.FetchPatientTaskInfoFromEhrRequest.fromObject({
    patientId: patientId,
  });
  const getPatientData = useQuery<infinitusapi.INFBVTaskInput, Error>(
    ['integration/fhir', 'getPatientData', reqBody, headers],
    (key) => queryApi(key)
  );
  const data = React.useMemo(() => {
    if (!getPatientData.data) return null;
    return getPatientData.data;
  }, [getPatientData.data]);
  return { ...getPatientData, data };
}

export function useGetOrgPrograms(accessToken: string, idToken: string) {
  const headers = { 'Fhir-Access-Token': accessToken, Authorization: idToken };
  const reqBody = infinitusai.tasks.GetOrgProgramsRequest.create();
  const getOrgProgramsQuery = useQuery<infinitusai.tasks.GetOrgProgramsResponse, Error>(
    ['integration/fhir', 'getOrgPrograms', reqBody, headers],
    (key) => queryApi(key)
  );
  const data = React.useMemo(() => {
    if (!getOrgProgramsQuery.data || !getOrgProgramsQuery.data.programs) return [];
    const { programs } = getOrgProgramsQuery.data;
    return programs.map((program) =>
      infinitusai.tasks.OrgProgram.fromObject({
        name: program.name,
        displayName: program.displayName,
      })
    );
  }, [getOrgProgramsQuery.data]);

  return { ...getOrgProgramsQuery, data };
}

export function useGetPayers(accessToken: string, idToken: string) {
  const headers = { 'Fhir-Access-Token': accessToken, Authorization: idToken };
  const reqBody = infinitusai.be.GetPayersRequest.fromObject({});
  const getPayers = useQuery<infinitusai.be.GetPayersResponse, Error>(
    ['integration/fhir', 'getPayers', reqBody, headers],
    (key) => queryApi(key)
  );

  const data = React.useMemo(
    () =>
      getPayers.data
        ? getPayers.data.payers
            ?.map((payer) => infinitusai.be.PayerDoc.fromObject(payer))
            .filter((payer) => payer.id !== '')
        : [],
    [getPayers.data]
  );

  return { ...getPayers, data };
}

export function useGetProducts(accessToken: string, idToken: string) {
  const headers = { 'Fhir-Access-Token': accessToken, Authorization: idToken };
  const reqBody = infinitusai.be.GetProductCodesRequest.create();
  const getProducts = useQuery<infinitusai.be.GetProductCodesResponse, Error>(
    ['integration/fhir', 'getProducts', reqBody, headers],
    (key) => queryApi(key)
  );

  const data = React.useMemo(() => {
    if (!getProducts.data) return {};
    return infinitusai.be.GetProductCodesResponse.fromObject(getProducts.data).codes;
  }, [getProducts.data]);

  return { ...getProducts, data };
}

export function useCreateTask(accessToken: string, idToken: string) {
  const queryClient = useQueryClient();
  const createTask = useMutation<
    infinitusapi.CreateTasksResponse,
    Error,
    infinitusapi.CreateTasksRequest
  >(
    (req) => {
      return fetchApi({
        path: '/integration/fhir/createTask',
        body: req,
        headers: { 'Fhir-Access-Token': accessToken, Authorization: idToken },
        appName: AppName.CUSTOMER,
        orgUuid: '',
        user: null,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['integration/fhir', 'getTasks']);
      },
    }
  );
  return createTask;
}
