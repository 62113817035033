export const getEmailDomain = (email: string) =>
  email.substring(email.indexOf('@') + 1, email.length);

export enum EmailCategory {
  MEMBER = 'member',
  VALID_CANDIDATE = 'validCandidate',
  INVALID_EMAIL_FORMAT = 'invalidEmailFormat',
  INVALID_DOMAIN = 'invalidDomain',
  NON_ORG_USER = 'nonOrgUser',
}
