import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CustomerFacingTaskState } from 'utils';

import { ReactComponent as ProgressActive } from 'assets/progress-active.svg';
import { ReactComponent as ProgressBadDataInput } from 'assets/progress-bad-data-input.svg';
import { ReactComponent as ProgressCallFailed } from 'assets/progress-call-failed.svg';
import { ReactComponent as ProgressCancelled } from 'assets/progress-cancelled.svg';
import { ReactComponent as ProgressInQueue } from 'assets/progress-in-queue.svg';
import { ReactComponent as ProgressUnknown } from 'assets/progress-unknown.svg';
import { infinitusai } from 'proto/pbjs';

export interface Props {
  customerFacingTaskState: CustomerFacingTaskState;
  task: infinitusai.be.CustomerTaskDoc | infinitusai.be.TaskDoc | null;
  // Relevant when the task has failed (to provide more context into the failure)
  taskNotes?: string;
}

function TaskInProgressPlaceholder({ customerFacingTaskState, task, taskNotes }: Props) {
  let caption = '';
  const additionalNotes = task && 'taskNotes' in task ? task?.taskNotes : taskNotes;

  let explanation = task?.failureReasonChosen?.type
    ? additionalNotes
    : 'Results will be displayed here once completed';

  let ProgressGraphic = ProgressUnknown;

  switch (customerFacingTaskState) {
    case CustomerFacingTaskState.IN_QUEUE:
      caption = 'Task is in the queue...';
      ProgressGraphic = ProgressInQueue;
      break;
    case CustomerFacingTaskState.IN_PROGRESS:
      caption = 'Task is in progress...';
      ProgressGraphic = ProgressActive;
      break;
    case CustomerFacingTaskState.CALL_FAILED:
      caption = 'Call Failed';
      ProgressGraphic = ProgressCallFailed;
      break;
    case CustomerFacingTaskState.BAD_DATA_INPUT:
      caption = 'Call failed due to bad input data';
      ProgressGraphic = ProgressBadDataInput;
      explanation = additionalNotes ? `Notes: ${additionalNotes}` : '';
      break;
    case CustomerFacingTaskState.CANCELLED:
      caption = 'Task cancelled';
      ProgressGraphic = ProgressCancelled;
      explanation = 'This task has been cancelled and will no longer be completed';
      break;
  }

  return (
    <Grid container direction="column" alignItems="center" spacing={2} mt={2}>
      <Grid item>
        <ProgressGraphic />
      </Grid>
      <Grid item>
        <Typography variant="h4">{caption}</Typography>
      </Grid>
      <Grid item>
        <Typography color="textSecondary">{explanation}</Typography>
      </Grid>
    </Grid>
  );
}

export default TaskInProgressPlaceholder;
