import { useAuth, PermissionName } from '@infinitusai/auth';
import { Table, useTable } from '@infinitusai/table';
import { Button, DrawerBody, useConfirm } from '@infinitusai/ui';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { createColumnHelper } from '@tanstack/react-table';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import { useGetUserRoles, useRemoveRoleFromUser } from 'api/auth';
import { infinitusai } from 'proto/pbjs';

interface Props {
  user?: infinitusai.auth.User;
  onEditRoles: () => void;
  onClose: () => void;
}

function RolesTab({ user, onEditRoles, onClose }: Props) {
  const { hasPermission } = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const getUserRoles = useGetUserRoles(user?.uid || '');
  const removeRoleFromUser = useRemoveRoleFromUser();
  const hasRbacAssignPermission = hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN]);
  const hasOwnerPermission = hasPermission([PermissionName.CUSTOMER_OWNER]);

  const handleBulkUnassign = () => {
    let description = '';
    const checkedRoles = Object.keys(table.getState().rowSelection);
    if (checkedRoles.length === getUserRoles.data.length) {
      description = `Are you sure you want to unassign all roles? This will remove ${
        user?.email || ''
      } from the org.`;
    } else if (checkedRoles.length === 1) {
      const role = getUserRoles.data.find(
        (role: infinitusai.auth.Role) => role.uuid === checkedRoles[0]
      );
      description = 'Are you sure you want to unassign the role of "' + role?.name + '"';
      description += ' for ' + user?.email + '?';
    } else {
      description = 'Are you sure you want to unassign the roles of ';
      for (var index in checkedRoles.slice(0, -1)) {
        const uuid = checkedRoles[index];
        const role = getUserRoles.data.find((role: infinitusai.auth.Role) => role.uuid === uuid);
        description = description.concat('"' + role?.name || '') + '", ';
      }
      const lastUuid = checkedRoles.slice(-1)[0];
      const lastRole = getUserRoles.data.find(
        (role: infinitusai.auth.Role) => role.uuid === lastUuid
      );
      description = description.concat('and "' + lastRole?.name + '"');
      description += ' for ' + user?.email + '?';
    }
    confirm({
      title: 'Unassign Roles?',
      description,
      confirmText: 'Unassign Roles',
      onConfirm: () => {
        table.resetRowSelection();
        for (const uuid of Object.keys(table.getState().rowSelection)) {
          const req = infinitusai.auth.RemoveRoleFromUserRequest.fromObject({
            roleUuid: uuid,
            userUid: user?.uid,
          });
          removeRoleFromUser.mutate(req);
          onClose();
        }
      },
    });
  };

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.auth.Role>();
    return [
      columnHelper.accessor('name', {
        header: 'Role Name',
      }),
      columnHelper.accessor('description', {
        header: 'Description',
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      }),
      columnHelper.display({
        id: 'unassign',
        cell: (info) =>
          Object.keys(info.table.getState().rowSelection).length === 0 ? (
            <Button
              size="small"
              variant="outlined"
              disabled={
                removeRoleFromUser.isLoading ||
                (info.cell.row.original.readOnly && !hasOwnerPermission)
              }
              onClick={() => {
                confirm({
                  title: 'Unassign Role?',
                  description:
                    'Are you sure you want to unassign the role "' +
                    info.row.original.name +
                    '" for "' +
                    user?.email +
                    '"',
                  confirmText: 'Unassign Role',
                  onConfirm: () => {
                    const req = infinitusai.auth.RemoveRoleFromUserRequest.fromObject({
                      roleUuid: info.row.original.uuid,
                      userUid: user?.uid,
                    });
                    removeRoleFromUser.mutate(req, {
                      onSuccess: () => {
                        enqueueSnackbar(`Role unassigned successfully`, {
                          variant: 'success',
                        });
                      },
                      onError: (error) => {
                        enqueueSnackbar(`Failed to unassign role: ${error.message}`, {
                          variant: 'error',
                        });
                      },
                    });
                  },
                });
              }}
              unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
            >
              Unassign Role
            </Button>
          ) : null,
      }),
    ];
  }, [confirm, user, removeRoleFromUser, enqueueSnackbar, hasOwnerPermission, hasPermission]);

  const table = useTable({
    id: 'member-roles',
    data: getUserRoles.data,
    columns,
    isLoading: getUserRoles.isLoading,
    totalRows: getUserRoles.data.length,
    hideToolbar: true,
    hidePagination: true,
    enableRowSelection: hasRbacAssignPermission,
    noRowsMessage: 'No roles found. Assign a role.',
    getRowId: (role) => role.uuid,
  });

  return (
    <React.Fragment>
      <DrawerBody>
        <Stack direction="column">
          <Box display="flex" justifyContent="flex-end" mr={2} mt={2}>
            {Object.keys(table.getState().rowSelection).length > 0 ? (
              <Button
                size="medium"
                color="error"
                variant="contained"
                onClick={handleBulkUnassign}
                unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
              >
                Unassign Roles
              </Button>
            ) : (
              <Button
                size="medium"
                variant="contained"
                onClick={onEditRoles}
                startIcon={<AddRoundedIcon />}
                unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
              >
                Assign New Role
              </Button>
            )}
          </Box>
          <Table table={table} sx={{ border: 'none' }} tableRowProps={{ sx: { height: 69 } }} />
        </Stack>
      </DrawerBody>
    </React.Fragment>
  );
}

export default RolesTab;
