import { useAppState } from '@infinitusai/shared';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  TextField,
  useConfirm,
} from '@infinitusai/ui';
import CloseIcon from '@mui/icons-material/Close';
import { Chip, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetAvailableTags, useCompleteIntegrationTask } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

const updateTagFormat = (tags: any) => {
  return tags.reduce((acc: any, tag: any) => {
    if (acc[tag.category]) {
      acc[tag.category].push(tag);
    } else {
      acc[tag.category] = [tag];
    }
    return acc;
  }, {});
};

function GenerateTestOutputsButton({ task }: { task: infinitusai.be.CustomerTaskDoc }) {
  const { tid } = useParams();
  const { org } = useAppState();
  const navigate = useNavigate();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const completeIntegrationTask = useCompleteIntegrationTask();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      search: '',
      tags: [] as infinitusai.tags.Tag[],
    },
    onSubmit: (values) => {
      const tagUuids = values.tags.map((tag: any) => tag.uuid);

      const req = infinitusai.tags.CompleteIntegrationTaskRequest.fromObject({
        tagUuids,
        taskUuid: tid,
      });

      completeIntegrationTask.mutate(req, {
        onSuccess: (data) => {
          enqueueSnackbar('Successfully generated test task', { variant: 'success' });
          setShowDrawer(false);
          navigate(`/${org?.name}/tasks/${tid}?tab=task-outputs`);
          formik.resetForm();
        },
        onError: (error) => {
          enqueueSnackbar(`Failed to generate test task: ${error.message}.`, {
            variant: 'error',
          });
        },
      });
    },
  });
  const getAvailableTags = useGetAvailableTags(
    task.taskType,
    formik.values.tags.map((tag: any) => tag.uuid),
    formik.values.search
  );

  const updatedTags = React.useMemo(
    () => updateTagFormat(getAvailableTags.data),
    [getAvailableTags.data]
  );

  const handleClose = () => {
    if (formik.values.tags.length) {
      confirm({
        title: 'Abandon Generating Test Task?',
        description: 'Are you sure you want to abandon generating a test task?',
        footnote: 'If you proceed, test task generation progress will be lost.',
        onConfirm: () => {
          setShowDrawer(false);
          formik.resetForm();
        },
      });
    } else {
      setShowDrawer(false);
      formik.resetForm();
    }
  };

  return (
    <>
      <Button onClick={() => setShowDrawer(true)} variant="outlined">
        Generate Test Task
      </Button>
      <Drawer open={showDrawer} onClose={handleClose}>
        <DrawerHeader>
          <Typography variant="h6">Generate Test Task</Typography>
          <Box flexGrow={1} />
          <IconButton aria-label="close" onClick={handleClose} sx={{ mr: { xs: -1.25, sm: -2 } }}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </DrawerHeader>

        <DrawerBody>
          <TextField
            debounce
            disabled={formik.isSubmitting}
            id="search"
            label="Search Tags"
            onChange={(event) => {
              formik.setFieldValue('search', event.target.value);
            }}
            size="medium"
            sx={{ m: 2, mt: 1 }}
          />
          <Box mx={2}>
            {formik.values.tags.length ? (
              <>
                <Typography variant="subtitle2">Tags selected: </Typography>
                <Box mt={1}>
                  {formik.values.tags.map((tag: any) => (
                    <Chip
                      key={tag.uuid}
                      variant="outlined"
                      disabled={formik.isSubmitting}
                      id={tag.uuid}
                      label={tag.name}
                      sx={{ m: 0.5 }}
                      onDelete={() => {
                        formik.setFieldValue(
                          'tags',
                          formik.values.tags.filter((t: any) => t !== tag)
                        );
                      }}
                    />
                  ))}
                </Box>
              </>
            ) : null}
          </Box>
          <Divider sx={{ my: 1 }} />
          {Object.keys(updatedTags).map((tag: any) => (
            <>
              <Typography variant="overline" sx={{ mx: 2 }}>
                {tag}
              </Typography>

              <Box mx={1} mb={1}>
                {updatedTags[tag].map((tag: any) => (
                  <Chip
                    clickable
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      formik.setFieldValue(
                        'tags',
                        formik.values.tags.includes(tag)
                          ? formik.values.tags
                          : [...formik.values.tags, tag]
                      );
                    }}
                    label={tag.name}
                    sx={{ mx: 1, mb: 1 }}
                  />
                ))}
              </Box>
            </>
          ))}
        </DrawerBody>
        <DrawerFooter>
          <Box sx={{ flexGrow: 1 }} />
          {formik.values.tags.length ? (
            <Button
              size="large"
              variant="outlined"
              sx={{ mr: 2 }}
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              Cancel
            </Button>
          ) : null}

          <Button
            size="large"
            color="primary"
            variant="contained"
            disabled={!formik.values.tags.length || formik.isSubmitting}
            onClick={formik.submitForm}
          >
            Get Task Outputs
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
}
export default GenerateTestOutputsButton;
