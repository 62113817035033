import { useAppState, AppBar } from '@infinitusai/shared';
import { useBreakpoint } from '@infinitusai/ui';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { ReactComponent as InfinitusLogoDark } from 'assets/new-logo-with-name-dark.svg';
import { ReactComponent as InfinitusLogoLight } from 'assets/new-logo-with-name-light.svg';

import NavDrawer from './NavDrawer';
import { Basename } from './types';

/**
 * Props interface for the Layout component.
 */
export interface LayoutProps {
  basename?: Basename;
}

/**
 * This is the main layout component that wraps the entire app.
 */
export function Layout({ basename = Basename.Default }: LayoutProps) {
  const theme = useTheme();
  const { org } = useAppState();
  const lgBreakpoint = useBreakpoint('lg');
  const smBreakpoint = useBreakpoint('sm');
  const [navDrawerOpen, setNavDrawerOpen] = React.useState(lgBreakpoint);

  const handleNavDrawerToggle = () => {
    setNavDrawerOpen(!navDrawerOpen);
  };

  const handleNavDrawerOpen = () => {
    setNavDrawerOpen(true);
  };

  const handleNavDrawerClose = () => {
    setNavDrawerOpen(false);
  };

  React.useEffect(() => {
    setNavDrawerOpen(lgBreakpoint);
  }, [lgBreakpoint]);

  return (
    <Box sx={{ display: 'flex' }}>
      {org ? (
        <AppBar onMenuClick={handleNavDrawerToggle}>
          {theme.palette.mode === 'dark' ? (
            <InfinitusLogoDark width={130} height={22} />
          ) : (
            <InfinitusLogoLight width={130} height={22} />
          )}
        </AppBar>
      ) : null}
      {org ? (
        <NavDrawer
          basename={basename}
          open={navDrawerOpen}
          onOpen={handleNavDrawerOpen}
          onClose={handleNavDrawerClose}
        />
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          maxHeight: '-webkit-fill-available',
          maxWidth:
            !smBreakpoint || !org
              ? '100vw'
              : navDrawerOpen
              ? 'calc(100vw - 275px)'
              : `calc(100vw - 65px)`,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {org ? <Toolbar /> : null}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
