import { infinitusai } from 'proto/pbjs';
import { getPayerAssociationDisplayName, getTaskTypeDisplayNamePortal } from 'utils/displayNames';
import { ALL_TASK_TYPES } from 'utils/taskTypes';

function getTaskTypesFromPhoneNumbers(phoneNumbers: infinitusai.be.ICustomerPortalPhoneNumber[]) {
  // Get taskTypes from phoneNumber.allowedTaskTypes
  let hasEmptyAllowedTaskTypes = false;
  const allowedTaskTypesDict = new Map<infinitusai.be.TaskType, boolean>();
  phoneNumbers.forEach((phoneNumber) => {
    if (phoneNumber?.allowedTaskTypes?.length === 0) {
      hasEmptyAllowedTaskTypes = true;
    }
    phoneNumber?.allowedTaskTypes?.forEach((taskType) => {
      allowedTaskTypesDict.set(taskType, true);
    });
  });

  // If any of the phone number has empty allowTaskTypes, assume that all tasks are supported
  // See proto definition
  if (hasEmptyAllowedTaskTypes) {
    return ALL_TASK_TYPES;
  }

  const allowedTaskTypes: infinitusai.be.TaskType[] = [];
  for (let [key] of allowedTaskTypesDict) {
    allowedTaskTypes.push(key);
  }
  return allowedTaskTypes;
}

export type PayerRow = {
  name: string;
  id: string;
  association: string;
  supportedTaskTypes: string[];
  enabledAt: string;
};
export function getPayerRows(
  showBlues: boolean,
  payersWithDetails?: infinitusai.be.GetPayersWithDetailsResponse
): PayerRow[] {
  if (!payersWithDetails?.payersWithDetails) {
    return [];
  }

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const payerRows = payersWithDetails?.payersWithDetails
    .map((item) => {
      const payer = item.payer;
      const phoneNumbers = item.phoneNumbers;

      let enabledAt = 'Unknown';
      if (item.payerOrgEnablement?.hasRecord) {
        const timestamp = Number(item.payerOrgEnablement?.timestampMillis || 0);
        const date = new Date(timestamp);
        enabledAt = `${date.toLocaleString()} [${userTimeZone}]`;
      }

      const allowedTaskTypes = getTaskTypesFromPhoneNumbers(phoneNumbers || []);

      return {
        name: payer?.name || '',
        id: payer?.id || '',
        association: getPayerAssociationDisplayName(
          payer?.association || infinitusai.be.Association.ASSOCIATION_UNKNOWN
        ),
        supportedTaskTypes:
          allowedTaskTypes.length !== 0
            ? allowedTaskTypes
                .map((taskType) => getTaskTypeDisplayNamePortal(taskType))
                .filter((taskType) => taskType !== 'Unknown')
            : [],
        enabledAt: enabledAt,
      };
    })
    .filter((payerRow) => {
      // Unsure why but there are some payers with empty id in DB.
      return !!payerRow.id;
    });

  if (showBlues) {
    return payerRows;
  }

  return payerRows.filter((payer) => !payer.name.includes('Blue'));
}
