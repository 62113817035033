export interface Enum {
  [id: number]: string;
}

export interface TaskDataFieldDefinition {
  label: string;
  helperText?: string;
  component?: string;
  fullWidth?: boolean;
  required?: boolean;
  props?: {
    enumClass?: {
      Type: Enum;
      fromObject: (properties?: any) => any;
    };
    displayNameFunction?: (keyString: any) => string;
  };
}

export interface TaskDataFieldMap {
  [sectionLabel: string]: {
    [protoPath: string]: TaskDataFieldDefinition;
  };
}

export const miscellaneousPayerId = '00000000-0000-0000-0000-000000000000';

export const taskInputsFieldMap: TaskDataFieldMap = {
  'Patient information': {
    'policyInfo.groupId': {
      label: 'Group ID',
    },
    'policyInfo.groupName': {
      label: 'Group Name',
    },
    'policyInfo.planName': {
      label: 'Plan Name',
    },
    'patientInfo.firstName': {
      label: 'Patient first name *',
      required: true,
    },
    'patientInfo.lastName': {
      label: 'Patient last name *',
      required: true,
    },
    'patientInfo.birthday': {
      label: 'Patient date of birth *',
      required: true,
    },
    'patientInfo.addressInfo.streetAddress': {
      label: 'Patient Street Address',
    },
    'patientInfo.addressInfo.streetAddress2': {
      label: 'Patient Street Address 2',
    },
    'patientInfo.addressInfo.city': {
      label: 'Patient City',
    },
    'patientInfo.addressInfo.state': {
      label: 'Patient State',
    },
    'patientInfo.addressInfo.zip': {
      label: 'Patient ZIP Code *',
      required: true,
    },
  },
  'Provider information': {
    'providerInfo.firstName': {
      label: 'Provider First Name *',
      required: true,
    },
    'providerInfo.lastName': {
      label: 'Provider Last Name *',
      required: true,
    },
    'providerInfo.taxId': {
      label: 'Provider Tax ID',
    },
    'providerInfo.npi': {
      label: 'Provider NPI *',
      required: true,
    },
    'providerInfo.addressInfo.streetAddress': {
      label: 'Provider Street Address',
    },
    'providerInfo.addressInfo.streetAddress2': {
      label: 'Provider Street Address 2',
    },
    'providerInfo.addressInfo.city': {
      label: 'Provider City',
    },
    'providerInfo.addressInfo.state': {
      label: 'Provider State',
    },
    'providerInfo.addressInfo.zip': {
      label: 'Provider ZIP Code *',
      required: true,
    },
  },
  'Payer information': {
    payerInfo: {
      label: 'Payer *',
    },
    'payerInfo.subscriberId': {
      label: 'Subscriber ID *',
      required: true,
    },
  },
  'Practice Information': {
    'practiceInfo.facilityType.type': {
      label: 'Practice Type',
    },
    'practiceInfo.zip': {
      label: 'Practice Zip *',
      required: true,
    },
    'practiceInfo.name': {
      label: 'Practice Name *',
      required: true,
    },
    'practiceInfo.npi': {
      label: 'Practice NPI',
    },
    'practiceInfo.taxId': {
      label: 'Practice Tax ID',
    },
  },
  'Admin and Treatment Codes': {
    adminCodes: {
      label: 'Admin Codes',
      helperText: 'comma-separated',
    },
    'treatmentInfo.treatmentCode': {
      label: 'Treatment Code',
    },
    'treatmentInfo.secondaryTreatmentCode': {
      label: 'Secondary Treatment Code',
    },
    'treatmentInfo.tertiaryTreatmentCode': {
      label: 'Tertiary Treatment Code',
    },
  },
};
