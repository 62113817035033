import { PermissionName, useAuth } from '@infinitusai/auth';
import { useAppState } from '@infinitusai/shared';
import { Table, useTable } from '@infinitusai/table';
import { Button as InfButton } from '@infinitusai/ui';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { Box, IconButton, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetOrgUsers } from 'api/auth';
import { useGetTeams } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

import AddTeamModal from './AddTeamModal';
import RemoveTeamModal from './RemoveTeamModal';

function TeamsTab() {
  const appState = useAppState();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();
  const getOrgUsers = useGetOrgUsers();
  const getTeams = useGetTeams();

  const [isAddNewTeamModalOpen, setIsAddNewTeamModalOpen] = React.useState(false);
  const [isRemoveTeamModalOpen, setIsRemoveTeamModalOpen] = React.useState(false);
  const [teamToRemove, setTeamToRemove] = React.useState<{ teamUuid: string; name: string } | null>(
    null
  );

  // Memoize the email lookup table/hash table for org users
  const orgUserEmailHashtable: { [key: string]: boolean } = React.useMemo(() => {
    const hashtable: { [key: string]: boolean } = {};
    getOrgUsers.data?.forEach((user) => {
      hashtable[user.email] = true;
    });
    return hashtable;
  }, [getOrgUsers.data]);

  // Memoize the team name lookup table/hash table for team names
  const teamNameHashtable: { [key: string]: string } = React.useMemo(() => {
    const hashtable: { [key: string]: string } = {};
    // To compare new team name with the existing team names, we need to convert all team names to lowercase
    // just to compare lowercase strings
    getTeams.data?.forEach((team) => {
      hashtable[team.name.toLowerCase()] = team.name;
    });
    return hashtable;
  }, [getTeams.data]);

  const handleOpenAddNewTeamModal = () => setIsAddNewTeamModalOpen(true);
  const handleCloseAddNewTeamModal = () => setIsAddNewTeamModalOpen(false);
  const handleOpenRemoveTeamModal = (team: infinitusai.teams.Team) => {
    setTeamToRemove({
      teamUuid: team.teamUuid || 'Team ID unknown',
      name: team.name || 'Team name unknown',
    });
    setIsRemoveTeamModalOpen(true);
  };
  const handleCloseRemoveTeamModal = () => {
    setTeamToRemove(null);
    setIsRemoveTeamModalOpen(false);
  };

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.teams.Team>();

    return [
      columnHelper.accessor('name', {
        header: 'Name',
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('memberCount', {
        header: 'Total members',
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
      }),
      columnHelper.display({
        id: 'view_edit',
        header: () => <Box sx={{ textAlign: 'center' }}>View/Edit</Box>,
        enableHiding: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (info) => (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              color="primary"
              onClick={() =>
                navigate(
                  `/${appState.org?.name}/account/members?teamUuid=${info.row.original.teamUuid}`
                )
              }
              sx={{
                border: '1px solid',
                borderRadius: '50%',
              }}
            >
              <NavigateNext />
            </IconButton>
          </Box>
        ),
      }),
      columnHelper.display({
        id: 'remove_team',
        header: () => <Box sx={{ textAlign: 'center' }}>Remove team</Box>,
        enableHiding: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        cell: (info) => (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              color="primary"
              disabled={!hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
              onClick={() => handleOpenRemoveTeamModal(info.row.original)}
              sx={{
                border: '1px solid',
                borderRadius: '50%',
                color: (theme) => theme.palette.error.main,
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      }),
    ];
  }, [appState.org?.name, navigate, hasPermission]);

  const table = useTable<infinitusai.teams.Team>({
    id: 'all-teams',
    data: getTeams.data,
    columns,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    isLoading: getTeams.isLoading,
    totalRows: getTeams.data?.length ?? 0,
    noRowsMessage: 'No teams found.',
    enableSharing: true,
    enableSorting: true,
    enableFilters: true,
    enableGlobalFilter: true,
    enableUrlSync: true,
    getRowId: (row) => row.teamUuid || '',
  });

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 3,
          mb: 3,
        }}
      >
        <Typography variant="h6">All Teams</Typography>
        <InfButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddNewTeamModal}
          sx={{ borderRadius: '20px', textTransform: 'none' }}
          unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_WRITE])}
        >
          Add new team
        </InfButton>
      </Box>
      <Box mt={3}>
        <Table table={table} />
      </Box>
      <AddTeamModal
        open={isAddNewTeamModalOpen}
        onClose={handleCloseAddNewTeamModal}
        orgUserEmailHashtable={orgUserEmailHashtable}
        teamNameHashtable={teamNameHashtable}
      />
      <RemoveTeamModal
        open={isRemoveTeamModalOpen}
        onClose={handleCloseRemoveTeamModal}
        teamUuid={teamToRemove?.teamUuid}
        teamName={teamToRemove?.name}
      />
    </React.Fragment>
  );
}

export default TeamsTab;
