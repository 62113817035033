import { ErrorMessage } from '@infinitusai/shared';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery, QueryKey } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { REDIRECT_URI_TEST_SMART } from 'utils/constants';

import SmartLanding from './SmartLanding';

export default function PatientTaskList() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') ?? null;
  const state = searchParams.get('state') ?? null;
  const tokenUrl = state?.split(',')[0] ?? null;
  const smartId = state?.split(',')[1] ?? null;
  const authError = searchParams.get('error') ?? null;
  const [accessToken, setAccessToken] = React.useState('');
  const [patientId, setPatientId] = React.useState('');
  const [idToken, setIdToken] = React.useState('');

  async function tokenReq(url: string | null, code: string | null): Promise<void> {
    if (!code || !url || accessToken !== '' || !smartId) {
      const err = new Promise<void>(() => {
        throw new Error('unauthorized');
      });
      return err;
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: smartId,
        redirect_uri: REDIRECT_URI_TEST_SMART,
        code: code,
      }).toString(),
      redirect: 'follow',
    });
    const data = await response.json?.();
    return data;
  }
  const tokenKey: QueryKey = ['auth', tokenUrl];
  const tokenRes: any = useQuery(tokenKey, () => tokenReq(tokenUrl, code), {});
  React.useMemo(() => {
    if (!tokenRes.isLoading && !tokenRes.isError) {
      setAccessToken(tokenRes.data.access_token);
      setPatientId(tokenRes.data.patient);
      setIdToken(tokenRes.data.id_token);
    }
  }, [tokenRes.isError, tokenRes.data, tokenRes.isLoading]);
  return (
    <div>
      {accessToken === '' && !tokenRes.isError && !authError && (
        <Box
          sx={{
            minHeight: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            paddingTop: '20%',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {((accessToken === '' && tokenRes.isError) || authError) && (
        <ErrorMessage title="unauthorized" />
      )}
      {accessToken !== '' && patientId !== '' && idToken !== '' && (
        <SmartLanding patientId={patientId} accessToken={accessToken} idToken={idToken} />
      )}
    </div>
  );
}
