import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useCompleteTaskMutation } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

function CompleteTaskButton() {
  const { enqueueSnackbar } = useSnackbar();
  const { tid } = useParams();
  const completeTaskMutation = useCompleteTaskMutation();
  const [processing, setProcessing] = React.useState(false);

  const handleComplete = async () => {
    setProcessing(true);
    const body = infinitusai.be.CompleteTaskRequest.fromObject({ taskUuid: tid });
    completeTaskMutation.mutate(body, {
      onSuccess: () => {
        setProcessing(false);
        enqueueSnackbar(`Completed task ${tid}.`, { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(`Task ${tid} could not be completed. ${error.message}`, {
          variant: 'error',
        });
      },
    });
  };
  return (
    <Tooltip
      enterDelay={500}
      title="This button marks the task as complete and match sample task outputs"
    >
      <Button variant="outlined" color="primary" onClick={handleComplete} disabled={processing}>
        Complete Task
      </Button>
    </Tooltip>
  );
}
export default CompleteTaskButton;
