import { useAuth, PermissionName } from '@infinitusai/auth';
import { PageHeader, Tabs } from '@infinitusai/shared';
import Box from '@mui/material/Box';
import React from 'react';

import ImportPIERulesTab from './ImportPIERulesTab';
import ImportRulesHistoryTab from './ImportRulesHistoryTab';

const RulesImportPage: React.FC = () => {
  const auth = useAuth();

  return (
    <React.Fragment>
      <PageHeader title="Import Rules" variant="h5" />
      <Box>
        <Box>
          <Tabs
            id="members"
            syncUrl
            resetUrlOnChange
            sx={{ mb: 1 }}
            tabs={[
              {
                value: 'import-rules',
                label: 'Import Rules',
                content: <ImportPIERulesTab />,
                unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_PIE_WRITE]),
              },
              {
                value: 'rules-history',
                label: 'Rules History',
                content: <ImportRulesHistoryTab />,
                unauthorized: !auth.hasPermission([PermissionName.CUSTOMER_PIE_READ]),
              },
            ]}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default RulesImportPage;
