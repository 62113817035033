import { useBreakpoint } from '@infinitusai/ui';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  taskType: string;
  searchParam: string;
  onDownloadTemplate: () => void;
}

const TemplateAndSpec: React.FC<Props> = ({ taskType, searchParam, onDownloadTemplate }) => {
  const smBreakpoint = useBreakpoint('sm');
  const { org } = useParams<{ org: string }>();

  return (
    <React.Fragment>
      <Stack gap={5}>
        <Grid
          direction={smBreakpoint ? 'row' : 'column'}
          container
          gap={5}
          justifyContent="space-between"
        >
          <Grid item xs={3}>
            <Stack direction="column" gap={3}>
              <Typography variant="h6">{taskType} template CSV file</Typography>
              <Typography variant="body2">
                We recommend downloading our template to ensure that your columns are correctly
                labeled for a successful import:
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  onClick={onDownloadTemplate}
                  startIcon={<CloudDownloadRoundedIcon />}
                >
                  Download
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column" gap={3}>
              <Typography variant="h6">{taskType} CSV format specification</Typography>
              <Typography variant="body2">
                All fields must follow the published format for successful import. View our full
                format specification for reference:
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  href={`/${org}/fields-reference?taskType=${searchParam}`}
                  rel="noopener"
                  startIcon={<OpenInNewRoundedIcon />}
                >
                  Go to Specs
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column" gap={3}>
              <Typography variant="h6">Need Help?</Typography>
              <Typography variant="body2">
                Reach out to one of our dedicated team members to help you get started in the
                journey
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<EmailRoundedIcon />}
                  href={`mailto:support+${org}@infinitus.ai`}
                >
                  Request Help
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </React.Fragment>
  );
};

export default TemplateAndSpec;
