import { PageHeader } from '@infinitusai/shared';
import { Table, useTable, Toolbar } from '@infinitusai/table';
import { useBreakpoint } from '@infinitusai/ui';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Table as TableInterface, createColumnHelper } from '@tanstack/react-table';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import { useGetPayers } from 'api/customer';
import { infinitusai } from 'proto/pbjs';

function CustomToolbar({
  table,
  showBlues,
  showDivider,
  onShowBluesChange,
}: {
  table: TableInterface<infinitusai.be.PayerDoc>;
  showBlues: boolean;
  showDivider: boolean;
  onShowBluesChange: (show: boolean) => void;
}) {
  return (
    <Toolbar table={table}>
      <React.Fragment>
        <FormControlLabel
          sx={{ m: 0 }}
          control={
            <Switch
              checked={showBlues}
              onChange={(_, checked) => {
                onShowBluesChange(checked);
              }}
            />
          }
          label="Show Blues"
        />
        {showDivider ? (
          <Divider orientation="horizontal" sx={{ borderWidth: '1px', m: '0 1rem' }} />
        ) : null}
      </React.Fragment>
    </Toolbar>
  );
}

function PayersPage() {
  const smBreakpoint = useBreakpoint('sm');
  const { enqueueSnackbar } = useSnackbar();
  const getPayers = useGetPayers();
  const [showBlues, setShowBlues] = React.useState(false);
  const data = React.useMemo(() => {
    if (showBlues) {
      return getPayers.data;
    }
    return [
      infinitusai.be.PayerDoc.fromObject({
        name: '(Blues Hidden)',
        id: 'N/A',
      }),
    ].concat(getPayers.data.filter((payer) => !payer.name.includes('Blue')));
  }, [getPayers.data, showBlues]);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<infinitusai.be.PayerDoc>();
    return [
      columnHelper.accessor('name', {
        header: 'Payor Name',
        meta: {
          download: true,
        },
      }),
      columnHelper.accessor('id', {
        header: 'Infinitus Payor ID',
        cell: (info) => (
          <Tooltip title="Copy Id">
            <Typography
              onClick={() => {
                navigator.clipboard.writeText(info.getValue());
                enqueueSnackbar(`Infinitus Payer Id copied to clipboard!`, {
                  variant: 'success',
                });
              }}
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            >
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        meta: {
          download: true,
        },
      }),
    ];
  }, [enqueueSnackbar]);

  const table = useTable({
    id: 'payers',
    data,
    columns,
    isLoading: getPayers.isLoading,
    totalRows: data.length,
    noRowsMessage: 'No payors found.',
    enableSharing: smBreakpoint,
    enableSorting: true,
    enableDownload: smBreakpoint,
    enableFilters: true,
    enableGlobalFilter: true,
    enableUrlSync: true,
    getRowId: (row) => row.id,
  });

  return (
    <React.Fragment>
      <PageHeader title="Supported Payors" variant="h5" />
      <Box>
        <Table
          table={table}
          overrides={{
            toolbar: (
              <CustomToolbar
                table={table}
                showBlues={showBlues}
                showDivider={smBreakpoint}
                onShowBluesChange={setShowBlues}
              />
            ),
          }}
        />
      </Box>
    </React.Fragment>
  );
}

export default PayersPage;
