import { PageHeader, Tabs } from '@infinitusai/shared';
import React from 'react';

import AuthProvidersTab from './AuthProvidersTab';
import PublicProfileTab from './PublicProfileTab';
import RolesAssignedTab from './RolesAssignedTab';

/**
 * Displays the profile page.
 */
function ProfilePage() {
  return (
    <React.Fragment>
      <PageHeader title="Profile" variant="h5" />
      <Tabs
        id="members"
        syncUrl
        resetUrlOnChange
        sx={{ mb: 1 }}
        tabs={[
          {
            value: 'public_profile',
            label: 'Public Profile',
            content: <PublicProfileTab />,
          },
          {
            value: 'roles_assigned',
            label: 'Roles Assigned',
            content: <RolesAssignedTab />,
          },
          {
            value: 'providers',
            label: 'Identity Providers',
            content: <AuthProvidersTab />,
          },
        ]}
      />
    </React.Fragment>
  );
}

export default ProfilePage;
