import { useAuth, PermissionName } from '@infinitusai/auth';
import { useAppState } from '@infinitusai/shared';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  TextField,
  Checklist,
  useConfirm,
} from '@infinitusai/ui';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
  useGetOrgInvites,
  useGetOrgRoles,
  useGetOrgUsers,
  useAssignRolesOrCreateInvite,
} from 'api/auth';
import { infinitusai } from 'proto/pbjs';

interface Props {
  open: boolean;
  onClose: () => void;
}

function NewInvitesDrawer({ open, onClose }: Props) {
  const confirm = useConfirm();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();
  const appState = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  const getOrgInvites = useGetOrgInvites();
  const getOrgRoles = useGetOrgRoles();
  const getOrgUsers = useGetOrgUsers();
  const assignRolesOrCreateInvite = useAssignRolesOrCreateInvite();
  const [inputValue, setInputValue] = React.useState('');
  const currentSeats = getOrgInvites.data.length + getOrgUsers.data.length;
  const hasOwnerPermission = hasPermission([PermissionName.CUSTOMER_OWNER]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleConfirmClose = () => {
    if (formik.dirty) {
      confirm({
        title: 'Abandon Creating a New Invite?',
        description: 'Are you sure you want to abandon a new invite?',
        footnote: 'If you proceed, invitation creation progress will be lost.',
        confirmText: 'Abandon New Invite Creation',
        onConfirm: handleClose,
      });
    } else {
      handleClose();
    }
  };

  const validationSchema = Yup.object().shape({
    emails: Yup.array()
      .required('Required')
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
  });

  const handleFormSubmitted = async ({ emails, roles }: { emails: string[]; roles: string[] }) => {
    const newEmails: string[] = [];
    const existingEmails: string[] = [];

    emails.forEach((email: string) => {
      if (getOrgUsers.data?.some((user) => user.email === email)) {
        existingEmails.push(email);
      } else {
        newEmails.push(email);
      }
    });
    const errors: any[] = [];
    // Check if valid email domains are invalid
    const invalidEmailDomains = emails.filter((email) => {
      const emailDomain = email.substring(email.indexOf('@') + 1, email.length);
      return !appState.org?.emailDomains?.includes(emailDomain);
    });

    if (invalidEmailDomains.length > 0) {
      enqueueSnackbar(
        `Invalid Email Domain(s): ${invalidEmailDomains.map((emailDomain: string, index: number) =>
          invalidEmailDomains.length === 1
            ? `${emailDomain}`
            : index === invalidEmailDomains.length - 1
            ? ` and ${emailDomain}.`
            : ` ${emailDomain}`
        )}`,
        {
          variant: 'error',
        }
      );
    } else {
      // create orgInvite
      const promises = emails.map(async (email) => {
        const reqBody = infinitusai.auth.AssignRolesOrCreateInviteRequest.fromObject({
          roleUuids: roles,
          inviteeEmail: email,
          type: infinitusai.auth.InviteType.INVITE_TYPE_CUSTOMER,
        });

        try {
          return await assignRolesOrCreateInvite.mutateAsync(reqBody);
        } catch (err: any) {
          errors.push({
            email: email,
            message: `Failed to send invite or assign role to ${email}: ${err.message}.`,
          });
          newEmails.splice(
            errors.findIndex((error) => error.email === email),
            1
          );
          existingEmails.splice(
            errors.findIndex((error) => error.email === email),
            1
          );
        }
      });

      try {
        await Promise.all(promises);

        if (errors.length > 0) {
          enqueueSnackbar(errors.map((error) => error.message).join(', '), {
            variant: 'error',
          });
        }
        if (newEmails.length !== 0) {
          enqueueSnackbar(`Invites sent to ${newEmails.join(', ')}`, {
            variant: 'success',
          });
        }
        if (existingEmails.length !== 0) {
          enqueueSnackbar(`Roles assigned to ${existingEmails.join(', ')}`, {
            variant: 'success',
          });
        }

        handleClose();
        formik.resetForm();
        formik.setSubmitting(false);
        navigate(`/${appState.orgName}/account/members?tab=invites`, { replace: true });
      } catch (err) {
        const message = err instanceof Error ? err.message : 'Unknown error.';
        enqueueSnackbar(`Failed to send invites: ${message}`, {
          variant: 'error',
        });
      }
    }
  };

  const formik = useFormik<{ emails: string[]; roles: string[] }>({
    enableReinitialize: true,
    initialValues: { emails: [], roles: [] },
    onSubmit: handleFormSubmitted,
    validationSchema: validationSchema,
  });

  const seatsLeft = appState.org?.availableSeats
    ? appState.org?.availableSeats - currentSeats - formik.values.emails.length
    : 0;

  const handleInputPaste = (event: any) => {
    const pastedText = event.clipboardData.getData('text');
    const emails = pastedText.split(/[\s,]+/);
    formik.setFieldValue('emails', [...formik.values.emails, ...emails]);
    event.preventDefault();
  };

  const handleBlur = () => {
    if (inputValue.length > 0) {
      formik.setFieldValue('emails', [...formik.values.emails, inputValue]);
      setInputValue('');
    }
  };

  return (
    <Drawer open={open} onClose={handleConfirmClose}>
      <DrawerHeader title="Invite New Members" onClose={handleConfirmClose} />
      <DrawerBody>
        <Typography variant="overline" sx={{ mx: 2 }}>
          ENTER THE EMAILS FOR THE INVITES
        </Typography>
        <Stack mb="1rem" ml="1rem" mr="1rem" mt={1}>
          <Autocomplete
            id="invite-emails"
            freeSolo
            multiple
            options={[]}
            inputValue={inputValue}
            value={formik.values.emails}
            onChange={(_, newValue) => {
              formik.setFieldValue('emails', newValue);
            }}
            onBlur={handleBlur}
            onInputChange={(_, newInputValue) => {
              const lastChar = newInputValue.charAt(newInputValue.length - 1);
              if (lastChar === ',' || lastChar === ' ') {
                formik.setFieldValue('emails', [
                  ...formik.values.emails,
                  newInputValue.slice(0, newInputValue.length - 1),
                ]);
                setInputValue('');
              } else {
                setInputValue(newInputValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email Addresses"
                placeholder="user@email.com"
                unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
                error={formik.touched.emails && Boolean(formik.errors.emails)}
                helperText={`Enter emails separated by spaces or commas. ${
                  seatsLeft > 0 ? `${seatsLeft} seats left.` : `No remaining seats.`
                } ${
                  appState.org?.emailDomains
                    ? `Supported domains: ${appState.org.emailDomains.map(
                        (emailDomain) => ` ${emailDomain}`
                      )}`
                    : null
                }.`}
                onPaste={handleInputPaste}
              />
            )}
          />
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <ListSubheader>
          <Typography variant="overline">SELECT ROLES</Typography>
        </ListSubheader>
        <Box px={2}>
          <Checklist
            value={formik.values.roles}
            options={
              getOrgRoles.data.map((role) => ({
                value: role.uuid,
                label: role.name,
                disabled: role.readOnly && !hasOwnerPermission,
              })) || []
            }
            onChange={(_, newValue) => formik.setFieldValue('roles', newValue)}
          />
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          size="large"
          variant="outlined"
          onClick={handleConfirmClose}
          unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
        >
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={formik.submitForm}
          sx={{ ml: 2 }}
          disabled={
            !formik.dirty ||
            formik.isSubmitting ||
            formik.values.emails.length === 0 ||
            formik.values.roles.length === 0 ||
            assignRolesOrCreateInvite.isLoading ||
            seatsLeft < 0
          }
          unauthorized={!hasPermission([PermissionName.CUSTOMER_RBAC_ASSIGN])}
        >
          Invite Members
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}
export default NewInvitesDrawer;
