import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { infinitusai } from 'proto/pbjs';

import TaskListItem from './TaskListItem';

export interface Props {
  tasks?: infinitusai.be.CustomerTaskDoc[];
  isLoading: boolean;
}

function TaskListPanel({ tasks = [], isLoading }: Props) {
  return (
    <Box p={0}>
      <List>
        {!tasks?.length && (
          <Box my={2}>
            <Typography color="textSecondary" align="center">
              {isLoading ? 'Fetching tasks...' : 'No matching tasks'}
            </Typography>
          </Box>
        )}
        {tasks.map((task, i) => (
          <TaskListItem task={task} key={i} />
        ))}
      </List>
    </Box>
  );
}

export default TaskListPanel;
